// import * as React from 'react';
// import { useEffect } from 'react';
// import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import { useNavigate } from 'react-router-dom';
// import { managerRequests, devRequests } from '../../services/api-requests';
// import Loader from '../loader';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const Coaches = () => {
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = React.useState(false);
//   const [isSchoolLoading, setisSchoolLoading] = React.useState(false);
//   const [openSnackbar, setOpenSnackbar] = React.useState(false);
//   const [Message, setMessage] = React.useState('');
//   const [deleted, setDeleted] = React.useState(false);
//   const [allCoaches, setAllCoaches] = React.useState([]);
//   const [coachesInSchool, setCoachesInSchool] = React.useState(0);
//   const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
//   const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
//   const [coachToBeDeleted, setCoachToBeDeleted] = React.useState('');
//   const [selectedSchool, setSelectedSchool] = React.useState('');
//   const [receivedSchools, setReceivedSchools] = React.useState([]);
//   const [endpointCoaches, setEndPointCoaches] = React.useState([]);
//   const [coachToBeDeletedFirstName, setCoachToBeDeletedFirstName] = React.useState();
//   const [coachToBeDeletedLastName, setCoachToBeDeletedLastName] = React.useState();

//   const [open, setOpen] = React.useState();
//   const handleOpen = () => setConfirmationModalOpen(true);
//   const handleClose = () => setConfirmationModalOpen(false);

//   const ConfirmationModal = ({ open, onClose }) => {
//     const handleNo = () => {
//       console.log('Handle No clicked');
//       onClose();
//     };

//     const handleYes = () => {
//       console.log('Handle Yes clicked');
//       handleYesClick();
//       onClose();
//     };

//     return (
//       <div>
//         <Modal
//           open={open}
//           onClose={onClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <Typography id="modal-modal-title" variant="h6" component="h2">
//               Delete coach
//             </Typography>
//             <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//               Are you sure you want to delete {coachToBeDeleted}?
//             </Typography>
//             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//               <Button onClick={handleNo} variant="contained">
//                 No
//               </Button>
//               <Button onClick={handleYes} variant="contained" color="error">
//                 Yes
//               </Button>
//             </div>
//           </Box>
//         </Modal>
//       </div>
//     );
//   };

//   useEffect(() => {
//     fetchData();
//     fetchSchools();
//   }, []);

//   useEffect(() => {
//     fetchData();
//     console.log('Hit')
//   }, [selectedSchool]);

//   const fetchSchools = async () => {
//     setisSchoolLoading(true);
//     try {
//       const response = await devRequests.getSchoolList();
//       setisSchoolLoading(false);

//       if (response.status === 200) {
//         setReceivedSchools(response.data);
//       } else {
//         setMessage('Something went wrong');
//         setDeleted(true);
//       }
//     } catch (error) {
//       console.error('Error fetching schools:', error);
//     } finally {
//       setisSchoolLoading(false);
//     }
//   };

//   const fetchData = async () => {
//     setIsLoading(true);
//     const myCoaches = [];
//     try {
//       const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
//       console.log('SchoolIWant: ', schoolIwant)
//       if (schoolIwant) {
//         const response = await managerRequests.getAllCoaches(schoolIwant.id);
//         console.log('All Coaches: ', response.data)
//         setIsLoading(false);
//         setEndPointCoaches(response.data)

//         if (response.status === 200) {
//           response.data.forEach((coach) => {
//             const coachObj = {
//               name: coach.firstName,
//               surname: coach.lastName,
//               ageGroup: `u/${coach.ageGroup}`,
//               coachingLevel: '1', // coach.coachingLevel,
//               coachingRole: coach.role,
//             };

//             myCoaches.push(coachObj);
//           });

//           setAllCoaches(myCoaches);
//         } else {
//           setMessage('Something went wrong');
//           setDeleted(true);
//         }
//       } else {
//         // No school selected
//         setAllCoaches([]);
//       }
//     } catch (error) {
//       console.error('Error fetching coaches:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenSnackbar(false);
//   };

//   const handleEdit = (coachName, coachSurname) => {

//     console.log('CoachName: ', coachName, ' Coach surname: ', coachSurname)
//     const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);

//     console.log('SchoolIWant: ', schoolIwant)
//     console.log('Handle Edit clicked')
//     console.log('Endpoint coaches: ', endpointCoaches)
//     console.log('All Coaches: ', allCoaches)
//     const coachIWant = allCoaches.find((coach) => coach.name === coachName );
//     console.log('Coach I Want: ', coachIWant)
//     const coachIDIWant = coachIWant.id;

//     console.log('CoachID I Want: ', coachIDIWant)
//     navigate(`/Managers/EditCoach/${schoolIwant.id}/${coachIDIWant}`)
//   };

//   const handleDeleted = (coachName, coachSurname) => {
//     setHandleDeletedClicked(true);
//     setCoachToBeDeletedFirstName(coachName)
//     setCoachToBeDeletedLastName(coachSurname)
//     setCoachToBeDeleted(coachName);
//     handleOpen();
//   };

//   const handleYesClick = async () => {
//     setIsLoading(true)
//     try {
//       const coachIWant = allCoaches.filter((coach) => coach.firstName === coachToBeDeletedFirstName && coach.lastName === coachToBeDeletedLastName);
//       const coachIdIWant = coachIWant[0].id;
//       console.log('Coach I want ID IN DELETE: ', coachIdIWant)

//       // Perform the API call to delete the coach
//       const response = await managerRequests.deleteCoach(coachIdIWant);
//       console.log('response: ', response);

//       fetchData()
//       if (response.status === 200) {

//         setOpenSnackbar(true);
//         setMessage('Coach Deleted');
//         setIsLoading(false)
//       } else {
//         setOpenSnackbar(true);
//         setMessage('Something went wrong');
//       }
//     } catch (error) {
//       console.log('error: ', error);
//     } finally {
//       setHandleDeletedClicked(false);
//       setIsLoading(false)
//     }
//   };

//   return (
//     <div>
//       <h4>Filter by School</h4>
//       {
//         isSchoolLoading ?
//           <Loader />
//           :
//           <Select
//             id="demo-simple-select"
//             placeholder="Select School"
//             sx={{ width: '150px', height: '80%', marginTop: -2 }}
//             value={selectedSchool}
//             onChange={(e) => setSelectedSchool(e.target.value)}
//           >
//             {receivedSchools.map((school) => (
//               <MenuItem key={school.name} value={school.name}>
//                 {school.name}
//               </MenuItem>
//             ))}
//           </Select>
//       }

//       <br />
//       <br />

//       <TableContainer component={Paper}>
//         <h3>Coaches</h3>
//         <Table sx={{ minWidth: 650 }} aria-label="simple table">
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell align="right">Surname</TableCell>
//               <TableCell align="right">Age Group</TableCell>
//               <TableCell align="right">Coaching Level</TableCell>
//               <TableCell align="right">Coaching Role</TableCell>
//               <TableCell align="center">Options</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {allCoaches.length === 0 ? (
//               <TableRow>
//                 <TableCell colSpan={6} align="center">
//                   {selectedSchool ? 'No coaches available' : 'No school has been selected'}
//                 </TableCell>
//               </TableRow>
//             ) : (
//               allCoaches.map((row) => (
//                 <TableRow
//                   key={row.name}
//                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                 >
//                   <TableCell component="th" scope="row">
//                     {row.name}
//                   </TableCell>
//                   <TableCell align="right">{row.surname}</TableCell>
//                   <TableCell align="right">{row.ageGroup}</TableCell>
//                   <TableCell align="right">{row.coachingLevel}</TableCell>
//                   <TableCell align="right">{row.coachingRole}</TableCell>
//                   <TableCell align="center">
//                     <Button onClick={() => handleEdit(row.name, row.surname)} variant="text">
//                       Edit
//                     </Button>
//                     <Button color="error" onClick={() => handleDeleted(row.name, row.surname)}>
//                       Delete Coach
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))
//             )}

//             {confirmationModalOpen && (
//               <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       {isLoading ? <Loader /> : <></>}
//       <br />

//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <MuiAlert
//           elevation={6}
//           variant="filled"
//           onClose={handleCloseSnackbar}
//           severity={deleted ? 'error' : 'success'}
//         >
//           {Message}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// };

// export default Coaches;


import * as React from 'react';
import { useEffect } from 'react';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { managerRequests, devRequests } from '../../services/api-requests';
import Loader from '../loader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Coaches = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSchoolLoading, setisSchoolLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [deleted, setDeleted] = React.useState(false);
  const [allCoaches, setAllCoaches] = React.useState([]);
  const [coachesInSchool, setCoachesInSchool] = React.useState(0);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [coachToBeDeleted, setCoachToBeDeleted] = React.useState('');
  const [selectedSchool, setSelectedSchool] = React.useState('');
  const [receivedSchools, setReceivedSchools] = React.useState([]);
  const [endpointCoaches, setEndPointCoaches] = React.useState([]);
  const [open, setOpen] = React.useState();
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);

  const ConfirmationModal = ({ open, onClose }) => {
    const handleNo = () => {
      console.log('Handle No clicked');
      onClose();
    };

    const handleYes = () => {
      console.log('Handle Yes clicked');
      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete coach
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {coachToBeDeleted}?
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleNo} variant="contained">
                No
              </Button>
              <Button onClick={handleYes} variant="contained" color="error">
                Yes
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
    fetchSchools();
  }, []);

  useEffect(() => {
    fetchData();
    console.log('Hit')
  }, [selectedSchool]);

  const fetchSchools = async () => {
    setisSchoolLoading(true);
    try {
      const response = await devRequests.getSchoolList();
      setisSchoolLoading(false);

      if (response.status === 200) {
        setReceivedSchools(response.data);
      } else {
        setMessage('Something went wrong');
        setDeleted(true);
      }
    } catch (error) {
      console.error('Error fetching schools:', error);
    } finally {
      setisSchoolLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const myCoaches = [];
    try {
      const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
      console.log('SchoolIWant: ', schoolIwant)
      if (schoolIwant) {
        const response = await managerRequests.getAllCoaches(schoolIwant.id);
        console.log('All Coaches: ', response.data)
        setIsLoading(false);
        setEndPointCoaches(response.data)

        if (response.status === 200) {
          response.data.forEach((coach) => {
            const coachObj = {
              name: coach.firstName,
              surname: coach.lastName,
              ageGroup: `u/${coach.ageGroup}`,
              coachingLevel: '1', // coach.coachingLevel,
              coachingRole: coach.role,
            };

            myCoaches.push(coachObj);
          });

          setAllCoaches(myCoaches);
        } else {
          setMessage('Something went wrong');
          setDeleted(true);
        }
      } else {
        // No school selected
        setAllCoaches([]);
      }
    } catch (error) {
      console.error('Error fetching coaches:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleEdit = (coachName) => {

    const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);

    console.log('Handle Edit clicked')
    console.log('Endpoint coaches: ', endpointCoaches)
    const coachIWant = endpointCoaches.find((coach) => coach.firstName === coachName);
    console.log('Coach I Want: ', coachIWant)
    const coachIDIWant = coachIWant.id;

    console.log('CoachID I Want: ', coachIDIWant)
    navigate(`/Managers/EditCoach/${schoolIwant.id}/${coachIDIWant}`)
  };

  const handleDeleted = (coachName) => {
    setHandleDeletedClicked(true);
    setCoachToBeDeleted(coachName);
    handleOpen();
  };

  const handleYesClick = async () => {
    setIsLoading(true);
    try {
      const coachIwant = allCoaches.find((coach) => coach.name === coachToBeDeleted);

      if (coachIwant) {
        const coachIDIwant = coachIwant.id; // Add your logic for getting the coach ID
        console.log('Coach I want ID: ', coachIDIwant);

        // Perform the API call to delete the coach
        const response = await devRequests.deleteCoach(coachIDIwant);
        console.log('Response from delete: ', response);

        fetchData();
        if (response.status === 200) {
          setOpenSnackbar(true);
          setMessage('Coach Deleted');
        } else {
          setOpenSnackbar(true);
          setMessage('Something went wrong');
        }
      } else {
        setMessage('Selected coach not found');
        setDeleted(true);
      }
    } catch (error) {
      console.error('Error deleting coach:', error);
    } finally {
      setHandleDeletedClicked(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h4>Filter by School</h4>
      {
        isSchoolLoading ?
          <Loader />
          :
          <Select
            id="demo-simple-select"
            placeholder="Select School"
            sx={{ width: '150px', height: '80%',  }}
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
          >
            {receivedSchools.map((school) => (
              <MenuItem key={school.name} value={school.name}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
      }

      <br />
      <br />

      <TableContainer component={Paper}>
        <h3>Coaches</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Surname</TableCell>
              <TableCell align="right">Age Group</TableCell>
              <TableCell align="right">Coaching Level</TableCell>
              <TableCell align="right">Coaching Role</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCoaches.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {selectedSchool ? 'No coaches available' : 'No school has been selected'}
                </TableCell>
              </TableRow>
            ) : (
              allCoaches.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.surname}</TableCell>
                  <TableCell align="right">{row.ageGroup}</TableCell>
                  <TableCell align="right">{row.coachingLevel}</TableCell>
                  <TableCell align="right">{row.coachingRole}</TableCell>
                  <TableCell align="center">
                    <Button onClick={() => handleEdit(row.name)} variant="text">
                      Edit
                    </Button>
                    <Button color="error" onClick={() => handleDeleted(row.name)}>
                      Delete Coach
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}

            {confirmationModalOpen && (
              <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? <Loader /> : <></>}
      <br />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={deleted ? 'error' : 'success'}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Coaches;
