import * as React from 'react';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { devRequests, managerRequests } from '../../services/api-requests';
import Loader from '../loader';


const EditManager = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = useParams()
    console.log('ID Params: ', id)
    //Spinner
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = React.useState(false);
    //Values
    const [subscriptionStatusOfSchool, setSubscriptionStatusOfSchool] = React.useState()
    const [newSubScriptionStatus, setnewSubScriptionStatus] = React.useState()
    const [allSubs, setAllSubs] = React.useState([])
    const [schoolId, setSchoolId] = React.useState()

    const [statusId, setStatusId] = React.useState()
    const [coaches, setCoaches] = React.useState([])
    const [isSubscriptionStatusChanged, setIsSubscriptionStatusChanged] = React.useState(false)
    const [isNewManagerChanged, setIsNewManagerChanged] = React.useState(false)
    //Errors
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [allGood, setAllGood] = React.useState(false);
    const [subscriptionStatusError, setSubscriptionStatusError] = React.useState(false);
    const [newManagerError, setNewManagerError] = React.useState(false)

    const [manager, setManager] = React.useState();
    const [newManager, setNewManager] = React.useState();
    const [managerName, setManagerName] = React.useState();


    useEffect(() => {
        fetchData();
        fetchCoaches()
        fetchSubscriptionStatuses()
    }, [id]);


    //Not working because the ID grabbed from params is for schools not coaches
    useEffect(() => {
        // console.log('Coaches: ', coaches);
        // const coachIWant = coaches.find((coach) => coach.role === "Manager");
        // console.log('This coach I want: ', coachIWant);

        const currentManager = coaches.find((coach) => coach.id === manager);
        console.log('This manager I want: ', currentManager);

        if (currentManager) {
            setManagerName(`${currentManager.firstName} ${currentManager.lastName}`);
        }
        console.log('Current manager name : ', managerName);
    }, [manager]);



    const fetchSubscriptionStatuses = async () => {
        try {
            setIsSubscriptionStatusLoading(true)
            const response = await devRequests.getSubscriptionStatus();

            console.log('response from statuses: ', response)
            if (response.status === 200) {
                console.log('All subs are going to be: ', response.data)
                setAllSubs(response.data);
                setIsSubscriptionStatusLoading(false)
            } else {
                console.log('Response not good in statuses: ', response);
            }
        } catch (error) {
            console.log('error in statuses: ', error);
        }
        finally {
            setIsSubscriptionStatusLoading(false)
        }
    }

    const fetchData = async () => {
        try {
            const response = await devRequests.getSchoolByID(id);

            console.log('response from schools: ', response)
            if (response.status === 200) {
                const data = response.data;

                setSchoolId(data.id);
                setSubscriptionStatusOfSchool(data.status);
                setManager(data.manager.id);
            } else {
                console.log('Response not good: ', response);
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };


    const fetchCoaches = async () => {
        try {
            const response = await managerRequests.getAllCoaches(id);
            console.log('response from coaches: ', response)
            if (response.status === 200) {
                const data = response.data;
                setCoaches(data);
            } else {
                console.log('Response not good in coaches endpoint: ', response);
            }
        } catch (error) {
            console.log('error in coaches endpoint: ', error);
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!newManager) {
            setNewManagerError(true)
        }
        if (!subscriptionStatusOfSchool) {
            setSubscriptionStatusError(true);
        }

        if (!isSubscriptionStatusChanged && !isNewManagerChanged) {
            setAllGood(false);
            setMessage('Nothing has changed');
            setOpenSnackbar(true);
            return;
        }

        setIsLoading(true)

        //Service call to be done here: 
        if (isNewManagerChanged) {
            try {
                console.log('Before we update Manager: ', schoolId, ' manager: ', newManager)
                const response = await devRequests.updateManager(schoolId, newManager)


                if (response.status === 200) {
                    console.log('All good: ', response)
                    setMessage('Updated')
                    setAllGood(true)
                    setOpenSnackbar(true)
                    setIsLoading(false)
                }
                else {
                    setAllGood(false)
                    setMessage(response.statusText)
                }
            }
            catch (error) {
                console.log('Error: ', error)
            }
            finally {
                setIsLoading(false)
            }
        }


        if (isSubscriptionStatusChanged) {

            const subscriptionStatusIWant = allSubs.find((subscriptionStatus) => subscriptionStatus.id === newSubScriptionStatus)
            try {
                const response = await devRequests.setSubscriptionStatus(schoolId, subscriptionStatusIWant.id)

                if (response.status === 200) {
                    console.log('All good: ', response)
                    setMessage('Updated')
                    setAllGood(true)
                    setOpenSnackbar(true)
                    setIsLoading(false)
                }
                else {
                    setAllGood(false)
                    setMessage(response.statusText)
                }
            }
            catch (error) {
                console.log('Error: ', error)
            }
            finally {
                setIsLoading(false)
            }
        }
        navigate(-1)
    }

    return (

        <Grid container  >
            <CssBaseline />

            <Grid item xs={12} sm={8} md={5} lg={12} >
                <Box
                    sx={{
                        my: 2,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ marginTop: '-40px', height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />

                    <Typography component="h1" variant="h5">
                        Edit Manager and School Profile
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                        <p>Change school Manager to:</p>
                        <Select
                            // labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manager}
                            label="New Manager"
                            onChange={(event) => {
                                setNewManager(event.target.value);
                                setNewManagerError(false)
                                setIsNewManagerChanged(true)
                            }}
                            sx={{ width: '300px' }}
                            error={newManagerError} // Add error prop
                            helperText={newManagerError ? 'Please select a new Manager' : ''}
                        >
                            {/* Get all coaches here */}
                            {
                                coaches.map((coach) => {
                                    return (
                                        <MenuItem key={coach.id} value={coach.id}>{coach.firstName} {coach.lastName}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        < br />

                        <p>Change Subscription Status</p>

                        {
                            isSubscriptionStatusLoading ?
                                <Loader />
                                :
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newSubScriptionStatus || ''}
                                    label="Coaching Role"
                                    onChange={(event) => {
                                        setnewSubScriptionStatus(event.target.value);
                                        setSubscriptionStatusError(false); // Reset error state
                                        setIsSubscriptionStatusChanged(true)
                                    }}
                                    sx={{ width: '300px' }}
                                    error={subscriptionStatusError} // Add error prop
                                    helperText={subscriptionStatusError ? 'Please select a subscription Status' : ''}
                                >
                                    {
                                        allSubs.map((sub) => {
                                            return (
                                                <MenuItem key={sub.id} value={sub.id}>{sub.status}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                        }

                        < br />
                        <Button
                            type="submit"

                            variant="contained"
                            sx={{ mt: 3 }}
                        >Submit
                        </Button>

                        < br />
                        <Button
                            type="submit"
                            onClick={() => navigate(-1)}
                            sx={{ mt: 3 }}
                        >Back
                        </Button>

                    </Box>
                </Box>
                {
                    isLoading ?
                        <Loader />
                        :
                        <></>
                }
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allGood ? "success" : "error"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </Grid>

    );
};


export default EditManager;
