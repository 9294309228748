import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import Copyright from '../../layouts/copyRight';
import { devRequests, userRequests } from '../../services/api-requests';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material'
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setProfile, setUserLoggedIn } from '../user/userSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../../layouts/loader';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {


  const [FirstName, setFirstName] = React.useState()
  const [LastName, setLastName] = React.useState()
  const [Email, setEmail] = React.useState()
  const [Password, setPassword] = React.useState()
  const [SchoolId, setSelectedSchool] = React.useState(-1)
  const [AgeGroup, setAgeGroup] = React.useState(-1)
  const [marketing, setMarketing] = React.useState()
  const [schools, setSchools] = React.useState([]);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [schoolError, setSchoolError] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [ageGroupError, setAgeGroupError] = React.useState(false);
  const [schoolNames, setSchoolNames] = useState([
    "Afrikaans Hoër Seunskool",
    "Hoërskool Ben Vorster",
    "Boland Landbou",
    "Hoërskool Brandwag",
    "Hoërskool Centurion",
    "Hoërskool Dr. EG Jansen",
    "Hoërskool Eldoraigne",
    "Hoërskool Ermelo",
    "Hoërskool Frikkie Meyer",
    "Grey College",
    "Hoërskool Helpmekaar",
    "Hts John Vorster",
    "Hts Middelburg",
    "Hoërskool Kempton Park",
    "Hoërskool Marais Viljoen",
    "Hoërskool Marlow",
    "Hoërskool Menlo Park",
    "Hoërskool Garsfontein",
    "Hoërskool Merensky",
    "Hoërskool Monument Park",
    "Hoërskool Nelspruit",
    "Hoërskool Noordheuwel",
    "Hoërskool Oos Moot",
    "Hoërskool Outeniqua",
    "Paarl Boys High",
    "Paarl Gimnasium",
    "Pietersburg Hoerskool",
    "Hoërskool Rustenburg",
    "Hoërskool Piet Retief",
    "Hoërskool Transvalia",
    "Hoërskool Waterkloof",
    "Hoërskool Wonderboom",
    "Hoërskool Zwartkop"
  ]);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchSchools()
  }, [])

  useEffect(() => {
    console.log('AgeGroup: ', AgeGroup)
  }, [AgeGroup])


  const fetchSchools = async () => {
    try {
      const response = await devRequests.getSchoolList()

      if (response.status === 200) {
        setSchools(response.data)
      }
      else {
        console.log('No Schools Received')
      }
    }
    catch (error) {
      console.log('Error: ', error)
    }

  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    // Reset error states
    setEmail(false);
    setFirstNameError(false);
    setLastNameError(false);
    setSchoolError(false);
    setPasswordError(false);

    // Check if any field is incomplete
    if (!Email || !FirstName || !LastName || !Password || !SchoolId === '' || !AgeGroup) {
      setErrorMessage('Please complete all fields');
      setOpenSnackbar(true);
      return;
    }

    // console.log({
    //   FirstName: data.get('firstName'),
    //   LastName: data.get('lastName'),
    //   Email: data.get('email'),
    //   Password: data.get('password'),
    //   SchoolId: data.get('schoolId'),
    //   AgeGroup: data.get('AgeGroup'),
    //   marketing: true,
    // });
    let globalError = false

    try {
      console.log('Sending over this: ',
        {
          firstName: FirstName,
          lastName: LastName,
          email: Email,
          pw: Password,
          schoolId: SchoolId,
          ageGroup: AgeGroup,
          marketing: true
        }
      )

      setIsLoading(true)
      const response = await userRequests.registerUser(FirstName, LastName, Email, Password, SchoolId, AgeGroup, marketing)

      console.log('Response is: ', response);

      if (response.status === 200) {
        const decodedToken = response.data
        console.log('Token decoded from register: ', decodedToken)
        dispatch(setUserLoggedIn(true));
        dispatch(setProfile(decodedToken))

        localStorage.setItem('token', response.data);

        globalError = false;
      }
      else {
        setErrorMessage('Incorrect details');
        setOpenSnackbar(true);
        setEmailError(true);
        setPasswordError(true);
        globalError = true
      }
    }
    catch (error) {
      // Handle the error here
      setErrorMessage('Could not register User');
      setOpenSnackbar(true);
      globalError = true
    }
    finally {
      if (!globalError) {
        setIsLoading(false)
        navigate("/dashboard");
      }
      setIsLoading(false)
    }

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {
        isLoading ?
          <Loader />
          :
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img style={{ borderRadius: '10px', marginBottom: '20px', height: '180px' }} src={SquadControlLogo} alt='Squad Control Logo' />

              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={FirstName || ''}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameError(false)
                      }}
                      error={firstNameError} // Add error prop
                      helperText={firstNameError ? 'Please fill in your first name' : ''}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value={LastName || ''}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameError(false)
                      }}
                      error={lastNameError} // Add error prop
                      helperText={lastNameError ? 'Please fill in your last name' : ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={Email || ''}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(false)
                      }}
                      error={emailError} // Add error prop
                      helperText={emailError ? 'Please fill in your email' : ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={Password || ''}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(false)
                      }}
                      error={passwordError} // Add error prop
                      helperText={passwordError ? 'Please fill in your password' : ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p>Which school are you coaching at?</p>
                    <Select
                      id="schoolId"
                      name='schoolId'
                      value={SchoolId || ''}
                      onChange={(e) => {
                        setSelectedSchool(e.target.value);
                        setSchoolError(false)
                      }}
                      error={schoolError} // Add error prop
                      helperText={schoolError ? 'Please select the school you are coaching at' : ''}
                      sx={{ width: '150px', height: '60%', }}
                    >
                      {
                        schools.map((school) => {
                          return (
                            <MenuItem key={school.id} value={school.id}>{school.name}</MenuItem>
                          )
                        })
                      }

                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <p>Which age group are you coaching?</p>
                    <Select
                      id="AgeGroup"
                      name='AgeGroup'
                      value={AgeGroup || ''}
                      onChange={(e) => {
                        console.log('Agre group is: ', AgeGroup)
                        setAgeGroup(e.target.value);
                        setAgeGroupError(false)
                      }}
                      error={ageGroupError} // Add error prop
                      helperText={ageGroupError ? 'Please select the age group you are coaching at' : ''}
                      sx={{ width: '150px', height: '60%', }}
                    >
                      <MenuItem key={"u/14"} value={14}>u/14</MenuItem>
                      <MenuItem key={"u/15"} value={15}>u/15</MenuItem>
                      <MenuItem key={"u/16"} value={16}>u/16</MenuItem>
                      <MenuItem key={"u/19"} value={19}>u/19</MenuItem>

                    </Select>
                  </Grid>

                  {/* <Grid item xs={12} style={{ marginTop: '20px' }}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive marketing promotions and updates via email."
                  id='marketing'
                  name='marketing'
                  value={marketing}
                  onChange={(e) => { setMarketing(e.target.value) }}
                />
              </Grid> */}

                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>

                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={4000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity="error"
                  >
                    {errorMessage}
                  </MuiAlert>
                </Snackbar>

              </Box>
            </Box>
            <br />
            <br />
            <Copyright sx={{ mt: 5 }} />
          </Container>
      }

    </ThemeProvider>
  );
}