import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { setProfile, setProfileCreated } from '../features/user/userSlice';
import SquadControlLogo from '../assets/Squad Control Logo New.jpeg'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const defaultTheme = createTheme();

const CreateCoachingProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [school, setSchool] = React.useState()
  const [ageGroup, setAgeGroup] = React.useState()
  const [role, setRole] = React.useState()

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [schoolError, setschoolError] = React.useState(false);
  const [ageGroupError, setageGroupError] = React.useState(false);
  const [roleError, setroleError] = React.useState(false);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    if (!school) {
      setschoolError(true);
    }
    if (!ageGroup) {
      setageGroupError(true);
    }
    if (!role) {
      setroleError(true);
    }


    if (!school || !ageGroup || !role) {
      setErrorMessage('Please complete all fields');
      setOpenSnackbar(true);
      return;
    }


    const profileObj = {
      'school': school,
      'ageGroup': ageGroup,
      'role': role,
    }

    console.log('The coach is: ',
      {
        'school': school,
        'ageGroup': ageGroup,
        'role': role,
      })

    dispatch(setProfileCreated(true))
    dispatch(setProfile(profileObj))
    navigate('/dashboard')

  }

  return (

    <Grid container sx={{ height: '70vh' }} >
      <CssBaseline />
      {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            
            backgroundImage: `url(${SquadControlLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        /> */}
      <Grid item xs={12} sm={8} md={5} lg={12} >
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
          <img height='100px' style={{ borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />
          <Typography component="h1" variant="h5">
            Create Coaching Profile
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <p>School you are coaching at</p>
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={school || ''}
              label="School"
              onChange={(event) => {
                setSchool(event.target.value)
                setschoolError(false); // Reset error state
              }}
              sx={{ width: '300px', marginBottom: '15px' }}
              error={schoolError} // Add error prop
              helperText={schoolError ? 'Please select an age group' : ''}
            >
              <MenuItem key={'Dr EG Jansen'} value={'Dr EG Jansen'}>Dr EG Jansen</MenuItem>
              <MenuItem key={'Waterkloof'} value={'Waterkloof'}>Waterkloof</MenuItem>
              <MenuItem key={'Affies'} value={'Affies'}>Affies</MenuItem>
              <MenuItem key={'Monnas'} value={'Monnas'}>Monnas</MenuItem>
              <MenuItem key={'Menlo'} value={'Menlo'}>Menlo</MenuItem>
              <MenuItem key={'Noordheuwel'} value={'Noordheuwel'}>Noordheuwel</MenuItem>
            </Select>

            <p>Age group you are coaching</p>
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ageGroup || ''}
              label="Age Group"
              onChange={(event) => {
                setAgeGroup(event.target.value)

                setageGroupError(false); // Reset error state
              }}
              sx={{ width: '300px' }}
              error={ageGroupError} // Add error prop
              helperText={ageGroupError ? 'Please select an age group' : ''}
            >
              <MenuItem value={'u14'}>u14</MenuItem>
              <MenuItem value={'u15'}>u15</MenuItem>
              <MenuItem value={'u16'}>u16</MenuItem>
              <MenuItem value={'u19'}>u19</MenuItem>
            </Select>

            <p>Coaching Role</p>
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role || ''}
              label="Coaching Role" 
              onChange={(event) => {
                setRole(event.target.value);
                setroleError(false); // Reset error state
              }}
              sx={{ width: '300px' }}
              error={roleError} // Add error prop
              helperText={roleError ? 'Please select a role' : ''}
            >
              <MenuItem value={'Forward Coach'}>Forward Coach</MenuItem>
              <MenuItem value={'Backline Coach'}>Backline Coach</MenuItem>
              <MenuItem value={'Specialist Coach'}>Specialist Coach</MenuItem>
              <MenuItem value={'Assistant Coach'}>Assistant Coach</MenuItem>
              <MenuItem value={'Trainer'}>Trainer</MenuItem>
              <MenuItem value={'Mentor'}>Mentor</MenuItem>
              <MenuItem value={'Director'}>Director</MenuItem>
            </Select>

            < br />
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3 }}
            >Submit
            </Button>

          </Box>
        </Box>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Grid>

  );
};


export default CreateCoachingProfile;
