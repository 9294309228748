import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Select, MenuItem, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../../layouts/loader';

const defaultTheme = createTheme();

function AddPlayerModal({ isOpen, setReserveReportWindowStatus, onAddPlayer }) {

    const [playerName, setPlayerName] = useState('');
    const [playerLastName, setPlayerLastName] = useState('');

    const [backendTeams, setBackendTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [position, setPosition] = useState();
    const [secondaryPosition, setSecondaryPosition] = useState();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [error, setError] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [height, setHeight] = React.useState(0);
    const [weight, setWeight] = React.useState(0);
    const [positions, setPositions] = useState([
        { id: 1, name: 'Loose Head Prop (1)' },
        { id: 2, name: 'Hooker (2)' },
        { id: 3, name: 'Tight Head Prop (3)' },
        { id: 4, name: 'Lock (4)' },
        { id: 5, name: 'Lock (5)' },
        { id: 6, name: 'Openside Flanker (6)' },
        { id: 7, name: 'Blindside Flanker (7)' },
        { id: 8, name: 'Eight Man (8)' },
        { id: 9, name: 'Scrumhalf (9)' },
        { id: 10, name: 'Flyhalf (10)' },
        { id: 11, name: 'Left Wing (11)' },
        { id: 12, name: 'Inside Center (12)' },
        { id: 13, name: 'Outside Center (13)' },
        { id: 14, name: 'Right Wing (14)' },
        { id: 15, name: 'Fullback (15)' },
    ]);

    const handleAddPlayer = () => {
        if (!playerName || !playerLastName || !position) {
            setError(true);
            setMessage('Complete all fields');
            setOpenSnackbar(true);
        } else {
            setError(false);
            setMessage('Player added');
            setOpenSnackbar(true);

            //Change date
          //  const formattedDateOfBirth = `${dateOfBirth}T00:00:00.000Z`

            const playerObj = {
                playerName,
                playerLastName,
                height,
                weight,
                position,
                secondaryPosition,
                dateOfBirth
            };

            onAddPlayer(playerObj);

            console.log('In CHild: ', playerName, playerLastName, position, secondaryPosition, dateOfBirth);
            // Move the following line inside the else block
            setReserveReportWindowStatus(false);
        }
    };


    // const handleAddPlayer = () => {

    //     setReserveReportWindowStatus(false);

    //     if (!playerName || !playerLastName || !position) {
    //         console.log('Playername: ', playerName)
    //         console.log('playerLastName: ', playerLastName)
    //         console.log('position: ', position)
    //         setError(true)
    //         setMessage('Complete all fields');
    //         setOpenSnackbar(true);
    //     }
    //     else {
    //         setError(false)
    //         setMessage('Player added');
    //         setOpenSnackbar(true);
    //     }

    //     const playerObj = {
    //         playerName,
    //         playerLastName,
    //         height,
    //         weight,
    //         position,
    //         secondaryPosition
    //     }

    //     onAddPlayer(playerObj);

    //     console.log('In CHild: ', playerName, playerLastName, position, secondaryPosition)
    //     // Call the parent component's callback with the selected team
    //     // if (props.onAddPlayer) {
    //     //     props.onAddPlayer(playerName, playerLastName, position, secondaryPosition);
    //     // }
    // };

    const handleCloseSnackbar = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);

        // // Call the parent component's call back with the selected team
        // if (onAddPlayer) {
        //     onAddPlayer(playerName, playerLastName, position, secondaryPosition);
        // }
    };

    const handleClose = () => {
        setReserveReportWindowStatus(false);
        setPlayerName('');
    };



    return (
        <React.Fragment>
            <ThemeProvider theme={defaultTheme}>
                <Dialog open={isOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>
                        <Typography variant="h5" component="div">Add Player</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField

                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={playerName}
                            onChange={(e) => setPlayerName(e.target.value)}
                        />

                        <br />
                        <TextField

                            margin="dense"
                            id="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={playerLastName}
                            onChange={(e) => setPlayerLastName(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />
                        <br />
                        <TextField

                            margin="dense"
                            id="lastName"
                            label="Height"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />
                        <br />
                        <TextField

                            margin="dense"
                            id="lastName"
                            label="Weight"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />

                        <br />
                        <p>Primary Position</p>
                        {
                            isLoading ?
                                <Loader />
                                :
                                <Select
                                    id="demo-simple-select"
                                    value={position || ''}
                                    label="Primary Position"
                                    onChange={(event) => {
                                        setPosition(event.target.value)
                                    }}
                                    sx={{ width: '300px' }}
                                >
                                    {
                                        positions.map((position) => {
                                            return (
                                                <MenuItem key={position.id} value={position.id}>
                                                    {position.name}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                        }

                        <br />
                        <p>Secondary Position</p>
                        {
                            isLoading ?
                                <Loader />
                                :
                                <Select
                                    id="demo-simple-select"
                                    value={secondaryPosition || ''}
                                    label="Secondary Position"
                                    onChange={(event) => {
                                        setSecondaryPosition(event.target.value)
                                    }}
                                    sx={{ width: '300px' }}
                                >
                                    {
                                        positions.map((position) => {
                                            return (
                                                <MenuItem key={position.id} value={position.id}>
                                                    {position.name}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                        }
                        <br />
                        <p>Date of Birth</p>
                        <TextField
                            id="date"
                            // label="Date of Birth"
                            type="date"
                            value={dateOfBirth}
                            onChange={(event) => setDateOfBirth(event.target.value)}

                            sx={{ width: '300px' }}
                        />

                        < br />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { handleClose() }}>Cancel</Button>
                        <Button onClick={() => { handleAddPlayer() }} type='submit'>Submit</Button>
                    </DialogActions>
                </Dialog>

            </ThemeProvider>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    );
}

export default React.memo(AddPlayerModal);
