import { Box, Button, MenuItem, Select } from "@mui/material";
import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from "../dashboard/Content/title";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import StatGrid from "./statGrid";
import { useSelector } from "react-redux";
import { getTeamForActiveTeam } from "../gameday/team";
import { useState } from "react";
import { useEffect } from "react";
import { gamedayStats, playerStats, seasonRequests, seasonStats, sessionRequests, sessionStats, teamRequests, teamStats } from "../../services/api-requests";
import Loader from "../../layouts/loader";
import PrintTrainingStats from "./printTrainingStats";
import ReportGenerator from "./printTrainingStats";
import PrintGameDayStats from "./printGameDayStats";
import PrintSeasonStats from "./printSeasonStats";
import PrintTeamStats from "./printTeamStats";
import PrintPlayerStats from "./printPlayerStats";


function Stats() {

  const defaultTheme = createTheme();
  const activeStatGroup = useSelector((state) => state.stats.activeStatGroup);
  const activeTeam = useSelector((state) => state.gameday.activeTeam);
  const team = getTeamForActiveTeam(activeTeam);
  const [player, setPlayer] = useState('');
  const [fullPlayer, setFullPlayer] = useState('');
  const [splitTypes, setSplitTypes] = useState([]);

  const [gameDayActive, setGameDayActive] = useState(false);
  const [seasonActive, setSeasonActive] = useState(false);
  const [teamActive, setTeamActive] = useState(false);
  const [playerActive, setPlayerActive] = useState(false);

  //Training Stats
  const [sessionActive, setSessionActive] = useState(false);
  const [nrOfSessions, setNrOfSessions] = useState('');
  const [mostSplitsCompleted, setMostSplitsCompleted] = useState('');
  const [leastSplitsCompleted, setLeastSplitsCompleted] = useState('');
  const [minutesTrained, setMinutesTrained] = useState('');
  const [topSessionCategory, setTopSessionCategory] = useState('');
  const [teams, setTeams] = useState([])
  const [backendTeams, setBackendTeams] = useState([])
  const [sessionsSummary, setSessionsSummary] = useState([])
  const [gameDaySummary, setGameDaySummary] = useState([])
  const [teamSummary, setTeamSummary] = useState([])
  const [playerStatSummary, setPlayerStatSummary] = useState([])
  const [seasonStatsSummary, setSeasonStatsSummary] = useState([])
  const [gamesSummary, setGamesSummary] = useState([])
  const [allSplitValues, setAllSplitValues] = useState([])
  const [selectedTeamId, setSelectedTeamId] = useState('')
  const [nrOfSplitsCompleted, setNrOfSplitCompleted] = useState('')
  const [isLoading, setIsLoading] = React.useState(false);
  const [noTeamSelected, setNoTeamSelected] = React.useState(true);
  const [noPlayerSelected, setNoPlayerSelected] = React.useState(true);
  const [selectedSplit, setSelectedSplit] = useState('')
  const [activeStatObject, setActiveStatObject] = useState({})
  const [selectedSplitId, setSelectedSplitId] = useState(0);
  const [fetchSplitsExecuted, setFetchSplitsExecuted] = useState(false);
  const schoolId = parseInt(useSelector((state) => state.user.profile.school))
  const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))

  // useEffect(() => {

  //   fetchTeams();

  // }, [schoolId])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      await fetchTeams().then(() => {
        fetchSplitTypes();
      })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [schoolId]);


  useEffect(() => {
    fetchNumberOfSplitsCompleted()
  }, [selectedSplitId]);

  useEffect(() => {
    if (selectedTeamId) {
      fetchAllSplitsAndTheirNumbers();
    }
  }, [selectedTeamId]);


  useEffect(() => {
    console.log('SelectedTeamID ', selectedTeamId)
  }, [selectedTeamId])

  //Training Stats
  useEffect(() => {

    fetchSessionStatsSummary()
    // fetchNumberOfSessions()
    // fetchMostSplitsCompleted()

    // fetchLeastSplitsCompleted()
    // fetchMinutesTrained()
    // fetchTopSessionCategory()

  }, [sessionActive, selectedTeamId])

  //Game Day Stats
  useEffect(() => {
    fetchGameStatsSummary()
    // fetchWinPercentage()
    // fetchTriesScored()
    // fetchTotalPointsScored()
  }, [gameDayActive, selectedTeamId])

  // Season Stats
  useEffect(() => {
    // fetchHomeGames()
    // fetchAwayGames()
    // fetchHomeGamesWon()
    // fetchAwayGamesWon()
    // fetchInjuries()
    fetchGamesSummary()
    // fetchCleanSheets()
    fetchSeasonStatsSummary()

  }, [seasonActive, selectedTeamId])

  //Team Stats
  useEffect(() => {
    // fetchMostMinutesPlayed()
    // fetchTopScore()
    // fetchMostTriesScored()
    // fetchMostMatchesPlayed()
    // fetchMostCardsReceived()
    // fetchMostManOfTheMatchAwards()
    fetchTeamStatsSummary()
  }, [teamActive, selectedTeamId])

  // Player Stats
  useEffect(() => {
    // fetchMinutesPlayed()
    // fetchPointsScored()
    // fetchPlayerTriesScored()
    // fetchMatchesPlayed()
    // fetchNumberOfCards()
    // fetchNumberOfAwards()
    fetchPlayerStatsSummary()
    fetchPlayers()
  }, [playerActive, player])



  const fetchPlayers = async () => {

    setIsLoading(true);
    try {
      const response = await teamRequests.getAllPlayers(schoolId, ageGroup);

      if (response.status === 200) {
        setBackendTeams(response.data)
        setIsLoading(false)
      }
      else {
        console.log('In the else');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTeams = async () => {

    setIsLoading(true);
    try {
      const response = await teamRequests.getTeams(schoolId, ageGroup);
      // const response = await teamRequests.getTeams(2, 14);

      if (response.status === 200) {

        setTeams(response.data)
        setIsLoading(false)
      }
      else {
        console.log('In the else');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSplitTypes = async () => {

    setIsLoading(true);
    try {
      const response = await sessionRequests.getSplitTypes();

      if (response.status === 200) {

        setSplitTypes(response.data)
        setIsLoading(false)
      }
      else {
        console.log('In the else');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setIsLoading(false);
    }


  };

  // function to get all the splits and their nrs completed
  // for reporting
  const fetchAllSplitsAndTheirNumbers = async () => {

    const allSplitTypeValues = []
    const faultySplitValues = []
    setIsLoading(true);
    splitTypes.forEach(async (split) => {
      try {
        const response = await sessionStats.GetNumberOfSplitsCompleted(selectedTeamId, split.id);
        // const response = await teamRequests.getTeams(2, 14);


        if (response.status === 200) {
          const splitName = splitTypes.find((existingSplit) => existingSplit.id === split.id)
          allSplitTypeValues.push({ split: splitName.type, count: response.data })
        }
        else {
          faultySplitValues.push(response.data)
        }

      } catch (error) {
        console.error('Error fetching nr of splits:', error);
      }
    })
    console.log('All Splits with their values: ', allSplitTypeValues)
    setAllSplitValues(allSplitTypeValues);
    if (faultySplitValues.length > 0) {
      console.log('Faulty values: ', faultySplitValues)
    }
  };

  /////Start of Season Stats/////

  const fetchGamesSummary = async () => {

    setIsLoading(true);
    try {
      const response = await seasonStats.GetGamesSummary(selectedTeamId);

      console.log('Games Stats Summary Received: ', response.data)
      setGamesSummary(response.data)

      if (response.status === 200) {
        setNoTeamSelected(false)
        setGamesSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoTeamSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('Cant get Games Season Stats summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Game Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSeasonStatsSummary = async () => {

    setIsLoading(true);
    try {
      const response = await seasonStats.GetSeasonStatsSummary(selectedTeamId);

      console.log('Season Stats Summary Received: ', response.data)
      setSeasonStatsSummary(response.data)

      if (response.status === 200) {
        setNoTeamSelected(false)
        setSeasonStatsSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoTeamSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('Cant get season Stats summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Season Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //End of season stats

  //Start of Player stats///
  const fetchPlayerStatsSummary = async () => {

    setIsLoading(true);
    try {
      const response = await playerStats.GetPlayerStatsSummary(player);

      console.log('Player Stats Summary Received: ', response.data)
      setPlayerStatSummary(response.data)

      if (response.status === 200) {
        setNoPlayerSelected(false)
        setPlayerStatSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoPlayerSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('Cant get Player stats summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Player Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };
  ///End of Player Stats////

  //Start of Team stats///
  const fetchTeamStatsSummary = async () => {

    setIsLoading(true);
    try {
      const response = await teamStats.GetTeamStatsSummary(selectedTeamId);

      console.log('Team Stats Summary Received: ', response.data)
      setTeamSummary(response.data)

      if (response.status === 200) {
        setNoTeamSelected(false)
        setTeamSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoTeamSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('Cant get teams stats summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Teams Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };
  ///End of Team Stats////

  //Start of GameDay stats///
  const fetchGameStatsSummary = async () => {

    setIsLoading(true);
    try {
      const response = await gamedayStats.GetGameStatsSummary(selectedTeamId);

      console.log('Game day Stats Summary Received: ', response.data)
      setGameDaySummary(response.data)

      if (response.status === 200) {
        setNoTeamSelected(false)
        setGameDaySummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoTeamSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('Cant get gamedayStatus summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Gameday Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  ///End of Gameday Stats////

  //////Start of Training/Session Stats//////
  const fetchSessionStatsSummary = async () => {

    setIsLoading(true);
    try {
      const response = await sessionStats.GetSessionStatsSummary(selectedTeamId);


      if (response.status === 200) {
        setNoTeamSelected(false)
        setSessionsSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setNoTeamSelected(true)
        setIsLoading(false)
      }
      else {
        console.log('In the else');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Session Stats Summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchNumberOfSessions = async () => {

  //   setIsLoading(true);
  //   try {
  //     const response = await sessionStats.GetNumberOfSessions(selectedTeamId);
  //     //   const response = await sessionStats.GetNumberOfSessions(nrOfSessions);

  //     console.log('Number of Sessions received: ', response.data)

  //     if (response.status === 200) {
  //       setNoTeamSelected(false)
  //       setNrOfSessions(response.data)
  //       setIsLoading(false)
  //     }
  //     if (response.status === 404) {
  //       setNoTeamSelected(true)
  //       setNrOfSessions(response.data)
  //       setIsLoading(false)
  //     }
  //     else {
  //       console.log('In the else');
  //       setIsLoading(false)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching Nr of sessions:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const fetchMostSplitsCompleted = async () => {

  //   setIsLoading(true);
  //   try {
  //     const response = await sessionStats.GetMostSplitsCompleted(29);
  //     //   const response = await sessionStats.GetMostSplitsCompleted(nrOfSessions);

  //     console.log('Most splits completed received: ', response.data)

  //     if (response.status === 200) {

  //       setMostSplitsCompleted(response.data)
  //       setIsLoading(false)
  //     }
  //     else {
  //       console.log('In the else');
  //       setIsLoading(false)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching most splits completed:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const fetchLeastSplitsCompleted = async () => {

  //   setIsLoading(true);
  //   try {
  //     const response = await sessionStats.GetLeastSplitsCompleted(29);
  //     // const response = await sessionStats.GetMostSplitsCompleted(nrOfSessions);

  //     console.log('Least splits completed received: ', response.data)

  //     if (response.status === 200) {

  //       setLeastSplitsCompleted(response.data)
  //       setIsLoading(false)
  //     }
  //     else {
  //       console.log('In the else');
  //       setIsLoading(false)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching Least splits completed:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const fetchMinutesTrained = async () => {

  //   setIsLoading(true);
  //   try {
  //     const response = await sessionStats.GetMinutesTrained(29);
  //     //  const response = await sessionStats.GetMinutesTrained(teamId);

  //     console.log('Minutes received: ', response.data)

  //     if (response.status === 200) {

  //       setMinutesTrained(response.data)
  //       setIsLoading(false)
  //     }
  //     else {
  //       console.log('In the else');
  //       setIsLoading(false)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching Minutes:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const fetchTopSessionCategory = async () => {

  //   setIsLoading(true);
  //   try {
  //     const response = await sessionStats.TopSessionCategory(29);
  //     //    const response = await sessionStats.TopSessionCategory(teamId);

  //     console.log('Top Session received: ', response.data)

  //     if (response.status === 200) {

  //       setTopSessionCategory(response.data)
  //       setIsLoading(false)
  //     }
  //     else {
  //       console.log('In the else');
  //       setIsLoading(false)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching top session:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchNumberOfSplitsCompleted = async () => {

    setIsLoading(true);
    try {
      const response = await sessionStats.GetNumberOfSplitsCompleted(selectedTeamId, selectedSplitId);
      // const response = await teamRequests.getTeams(2, 14);

      console.log('nr of Splits received: ', response.data)

      if (response.status === 200) {

        setNrOfSplitCompleted(response.data)
        console.log('nr of Splits received: ', response.data)
        setIsLoading(false)
      }
      else {
        console.log('In the else');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching nr of splits:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //////End of Training/Session Stats//////


  function MySelectButton() {
    return (
      <Select
        id="demo-simple-select"
        value={selectedTeamId}
        placeholder="Select Player"
        onChange={(event) => { setSelectedTeamId(event.target.value) }}
        sx={{ width: '150px', height: '60%', }}
      >
        {
          teams ? (
            teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0} value={0}>
              No Teams Found
            </MenuItem>
          )
        }

      </Select>
    )
  }

  function onSelectSplitType(event) {
    event.preventDefault()
    setSelectedSplitId(event.target.value)

  }

  function preventDefault(event) {
    event.preventDefault();
  }

  function printStats(statGroup, team) {
    console.log('PrintStats clicked')
    console.log('statGroup', statGroup)
    console.log('team', team)
  }

  function addStat() {
    console.log('addStats clicked')
  }

  function handleRendering(activeStatGroup) {

    if (activeStatGroup === 'Game Day Stats' && !gameDayActive) {
      setGameDayActive(true);
    } else if (activeStatGroup === 'Training Stats' && !sessionActive) {
      setSessionActive(true);
    } else if (activeStatGroup === 'Season Stats' && !seasonActive) {
      setSeasonActive(true);
    } else if (activeStatGroup === 'Team Stats' && !teamActive) {
      setTeamActive(true);
    } else if (activeStatGroup === 'Player Stats' && !playerActive) {
      setPlayerActive(true);
    }

    let percentageComplete = 0;
    let percentageInfo2 = "Season not started yet";

    if (!noTeamSelected && gamesSummary && gamesSummary.gamesPlayed !== undefined && gamesSummary.totalGames !== undefined && gamesSummary.totalGames > 0) {
      percentageComplete = Math.round((gamesSummary.gamesPlayed / gamesSummary.totalGames) * 100);

      if (percentageComplete > 0 && percentageComplete <= 100) {
        percentageInfo2 = `${percentageComplete}% complete`;
      }
    }

    switch (activeStatGroup) {
      case 'Game Day Stats':

        return (
          <>
            {/* Metrics and info need to be dynamic */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <MySelectButton />

              {selectedTeamId !== undefined && selectedTeamId !== '' ? (
                <PrintGameDayStats
                  statObject={gameDaySummary}
                  otherObject={gamesSummary}
                  percentageInfo={percentageInfo2}
                  activeStatGroup={activeStatGroup}
                  selectedTeamId={selectedTeamId}
                />
              ) : (
                <></>
              )}



            </Box>
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>
              <StatGrid title="Game Win %"
                metric={noTeamSelected ? "No Team Selected" : gameDaySummary.gamesWon ? gameDaySummary.gamesWon : 0}
                info1="Games won"
                //Change this.
                info2={`${gamesSummary.gamesWon ? gamesSummary.gamesWon : 0} win rate`}
              />

              <StatGrid
                title="Game progress timeline"
                metric={noTeamSelected ? "No Team Selected" : gamesSummary.gamesPlayed !== undefined && gamesSummary.totalGames !== undefined ? `${gamesSummary.gamesPlayed}/${gamesSummary.totalGames}` : 0}
                info1="Games played"
                info2={percentageInfo2}
              />
              <StatGrid
                title="Tries scored"
                metric={noTeamSelected ? "No Team Selected" : gameDaySummary.triesScored ? gameDaySummary.triesScored : 0}
                info1="Tries scored"
                info2={`in ${gamesSummary.gamesPlayed ? gamesSummary.gamesPlayed : 0} games`}
              />
            </Grid>

            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <StatGrid
                title="Points scored"
                metric={noTeamSelected ? "No Team Selected" : gameDaySummary.pointsScored ? gameDaySummary.pointsScored : 0}
                info1="Points scored"
                info2={`in ${gamesSummary.gamesPlayed ? gamesSummary.gamesPlayed : 0} games`}
              />
              <StatGrid
                title="Tries conceded"
                metric={noTeamSelected ? "No Team Selected" : gameDaySummary.triesConceded ? gameDaySummary.triesConceded : 0}
                info1="Tries conceded"
                info2={`in ${gamesSummary.gamesPlayed ? gamesSummary.gamesPlayed : 0} games`}
              />

              <StatGrid
                title="Points conceded"
                metric={noTeamSelected ? "No Team Selected" : gameDaySummary.pointsConceded ? gameDaySummary.pointsConceded : 0}
                info1="Points scored"
                info2={`in ${gamesSummary.gamesPlayed ? gamesSummary.gamesPlayed : 0} games`}
              />
            </Grid>
          </>
        )

      case 'Training Stats':

        return (
          <>
            {/* Metrics and info need to be dynamic */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <MySelectButton />



              {selectedTeamId !== undefined && selectedTeamId !== '' ? (
                <PrintTrainingStats
                  statObject={sessionsSummary}
                  nrOfSplits={allSplitValues}
                  activeStatGroup={activeStatGroup}
                  selectedTeamId={selectedTeamId}
                />
              ) : (
                <></>
              )}

            </Box>
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>
              <StatGrid title="Sessions done"
                metric={noTeamSelected ? "No Team Selected" : sessionsSummary?.numberOfSessions}
                info1="Sessions completed"
              />
              <StatGrid title="Most splits completed"
                metric={noTeamSelected ? "No Team Selected" : sessionsSummary?.mostCommonSplitType?.splitType ? sessionsSummary.mostCommonSplitType.splitType : sessionsSummary.mostCommonSplitType?.category ? sessionsSummary.mostCommonSplitType.category : 0}
                info1={sessionsSummary?.mostCommonSplitType?.count ? sessionsSummary?.mostCommonSplitType.count : "No Metric yet"}
                info2="sessions"
              />

              <StatGrid title="Nr of Splits completed"
                metric="Select Split: "
                info1="Needs to be selected"
                info2={`${nrOfSplitsCompleted} session${nrOfSplitsCompleted !== 1 ? 's' : ''}`}
                splitTypes={splitTypes}
                onSelectSplitType={onSelectSplitType} selectedType={selectedSplitId} />
            </Grid>

            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <StatGrid title="Least Splits completed"
                metric={noTeamSelected ? "No Team Selected" : sessionsSummary?.leastCommonSplitType?.splitType ? sessionsSummary.leastCommonSplitType.splitType : sessionsSummary.leastCommonSplitType?.category ? sessionsSummary.leastCommonSplitType.category : 0}
                info1={sessionsSummary.leastCommonSplitType?.count ? sessionsSummary.leastCommonSplitType?.count : "No Metric yet"}
                info2="sessions"
              />
              <StatGrid title="Minutes Trained"
                metric={noTeamSelected ? "No Team Selected" : sessionsSummary?.totalMinutes}
                info1="minutes in training"
              />
              <StatGrid title="Most Category of sessions"
                metric={noTeamSelected ? "No Team Selected" : sessionsSummary?.topSessionCategory?.splitType ? sessionsSummary.topSessionCategory?.splitType : sessionsSummary.topSessionCategory?.category ? sessionsSummary.topSessionCategory.category : 0}
                info1={sessionsSummary.topSessionCategory?.count ? topSessionCategory?.count : "No Metric yet"}
                info2="sessions"
              />
            </Grid>
          </>
        )
      case 'Season Stats':

        return (
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <MySelectButton />



              {selectedTeamId !== undefined && selectedTeamId !== '' ? (
                <PrintSeasonStats
                  statObject={seasonStatsSummary}
                  activeStatGroup={activeStatGroup}
                  selectedTeamId={selectedTeamId}
                />
              ) : (
                <></>
              )}

            </Box>
            {/* Metrics and info need to be dynamic */}
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>
              <StatGrid
                title="Games Won"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.gamesWon !== 'NaN%' ? `${seasonStatsSummary.gamesWon} ` : 0}
                info1="Games won "
                info2={seasonStatsSummary.gamesWon !== 'NaN%' ? `${seasonStatsSummary.gamesWon} win rate` : ""} />
              <StatGrid
                title="Games lost"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.gamesLost !== 'NaN%' ? `${seasonStatsSummary.gamesLost} ` : 0}
                info1="Games lost"
                info2="" />
              <StatGrid
                title="Games Drawn"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.gamesDrawn !== 'NaN%' ? `${seasonStatsSummary.gamesDrawn} ` : 0}
                info1="Games Drawn" />
            </Grid>

            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <StatGrid
                title="Games Played"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.gamesPlayed}
                info1="games played" />
              <StatGrid
                title="Total Injuries sustained"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.injuries}
                info1="injuries in 3 games" />
              <StatGrid
                title="Clean sheets"
                metric={noTeamSelected ? "No Team Selected" : seasonStatsSummary.cleanSheets}
                info1="Games won without conceding a point" />
            </Grid>
          </>
        )
      case 'Team Stats':
        return (
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <MySelectButton />

              {selectedTeamId !== undefined && selectedTeamId !== '' ? (
                <PrintTeamStats
                  statObject={teamSummary}
                  activeStatGroup={activeStatGroup}
                  selectedTeamId={selectedTeamId}
                />
              ) : (
                <></>
              )}

            </Box>
            {/* Metrics and info need to be dynamic */}
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>
              <StatGrid
                title="Most minutes played"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.gameTimeStat ? `${teamSummary.gameTimeStat.player.firstName} ${teamSummary.gameTimeStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.gameTimeStat ? ` with ${teamSummary.gameTimeStat.gameTime} minutes played` : 'No stats could be found'}
              />
              <StatGrid
                title="Top point scorer"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.pointsStat ? `${teamSummary.pointsStat.player.firstName} ${teamSummary.pointsStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.pointsStat ? ` with ${teamSummary.pointsStat.points} points` : 'No stats could be found'}
              />
              <StatGrid
                title="Top Tries scored"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.triesStat ? `${teamSummary.triesStat.player.firstName} ${teamSummary.triesStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.triesStat ? ` with ${teamSummary.triesStat.triesScored} tries` : 'No stats could be found'}
              />
            </Grid>

            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <StatGrid
                title="Most matches played"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.gamesStat ? `${teamSummary.gamesStat.player.firstName} ${teamSummary.gamesStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.gamesStat ? ` with ${teamSummary.gamesStat.gameCount} ` : 'No stats could be found'}
              />
              <StatGrid
                title="Most Cards received"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.cardsStat ? `${teamSummary.cardsStat.player.firstName} ${teamSummary.cardsStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.cardsStat ? ` with ${teamSummary.cardsStat.cardsReceived} card${teamSummary.cardsStat.cardsReceived > 1 ? 's' : ''}` : 'No stats could be found'}
              />
              <StatGrid
                title="Most MOTM awards"
                metric={noTeamSelected ? "No Team Selected" : teamSummary.manOfTheMatchStat ? `${teamSummary.manOfTheMatchStat.player.firstName} ${teamSummary.manOfTheMatchStat.player.lastName}` : 'No stats could be found'}
                info1={noTeamSelected ? "No Team Selected" : teamSummary.manOfTheMatchStat ? ` with ${teamSummary.manOfTheMatchStat.manOfTheMatchAwards} man of the match Awards` : 'No stats could be found'}
              />
            </Grid>
          </>
        );
      case 'Player Stats':

        return (
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Select
                id="demo-simple-select"
                value={player}
                placeholder="Select Player"
                onChange={(event) => {
                  setNoPlayerSelected(false)
                  setPlayer(event.target.value);
                  const playerIWant = backendTeams?.find((myPlayer) => myPlayer.id === event.target.value);

                  const completePlayer = `${playerIWant.firstName} ${playerIWant.lastName}`
                  setFullPlayer(completePlayer)
                }}
                sx={{ width: '150px', height: '80%', }}
              >
                {backendTeams?.map((player) => (
                  <MenuItem key={player.id} value={player.id}>
                    {player.firstName} {player.lastName}
                  </MenuItem>
                ))}
              </Select>

              {player !== undefined && player !== '' ? (
                <PrintPlayerStats
                  statObject={playerStatSummary}
                  activeStatGroup={activeStatGroup}
                  selectedTeamId={selectedTeamId}
                  player={fullPlayer}
                />
              ) : (
                <></>
              )}


            </Box>

            {/* Metrics and info need to be dynamic */}
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>
              <StatGrid
                title="Total Minutes played"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.minutesPlayed ? playerStatSummary.minutesPlayed : 0}
                info1={` minutes `} />
              <StatGrid
                title="Total Points scored"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.pointsScored ? playerStatSummary.pointsScored : 0}
                info1={` points`} />
              <StatGrid
                title="Total Tries scored"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.triesScored ? playerStatSummary.triesScored : 0}
                info1={` tries scored`} />
            </Grid>

            <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <StatGrid
                title="Total Matches played"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.matchesPlayed ? playerStatSummary.matchesPlayed : 0}
                info1={`  caps`} />
              <StatGrid
                title="Total Cards received"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.numberOfCards ? playerStatSummary.numberOfCards : 0}
                info1={` cards`} />
              <StatGrid
                title="Total MOTM awards"
                metric={noPlayerSelected ? "No Team Selected" : playerStatSummary.numberOfAwards ? playerStatSummary.numberOfAwards : 0}
                info1={` awards`} />
            </Grid>
          </>
        );
      default:
        return (<h3>No Stats are available for this Stat group, please select a tab</h3>)
    }
  }

  return (


    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        {
          isLoading ?
            <Loader />
            :
            <>
              {handleRendering(activeStatGroup)}
            </>
        }
      </Container>
    </ThemeProvider >
  )
}

export default Stats;


