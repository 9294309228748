// import React from 'react'

// function UpdateSeason() {
//     return (
//         <div>UpdateSeason</div>
//     )
// }

// export default UpdateSeason

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';
import { UpdateSeasonGames, setChanged, setSeasonGames, setStateOpenSetupGames } from './seasonSlice';
import { setStateSeasonStarted } from './seasonSlice';
import { setTotalGames } from './seasonSlice';
import { gameRequests, seasonRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../layouts/loader';


export default function UpdateSeason(props) {
    console.log('props in update season: ', props)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [gameFields, setGameFields] = useState([]);
    const [type, setTypeOfGame] = useState('');
    const [seasonStarted, setSeasonStarted] = useState(false);
    const [openSetupGames, setOpenSetupGames] = useState(false);
    const [gameIdCounter, setGameIdCounter] = useState(1); // Initialize the game ID counter
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    // const [changed, setChanged] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [seasonTypes, setSeasonTypes] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [startDateError, setstartDateError] = React.useState(false);
    const [endDateError, setendDateError] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [lastAddedGameIndex, setLastAddedGameIndex] = useState(null);
    const [season, setSeason] = useState('')
    const [teamId, setTeamId] = useState('');
    const [schoolNames, setSchoolNames] = useState([
        "Afrikaanse Hoër Seunskool",
        "Hoërskool Ben Vorster",
        "Boland Landbou",
        "Hoërskool Brandwag",
        "Hoërskool Centurion",
        "Hoërskool Dr. EG Jansen",
        "Hoërskool Eldoraigne",
        "Hoërskool Ermelo",
        "Hoërskool Frikkie Meyer",
        "Grey College",
        "Hoërskool Helpmekaar",
        "Hts John Vorster",
        "Hts Middelburg",
        "Hoërskool Kempton Park",
        "Hoërskool Marais Viljoen",
        "Hoërskool Garsfontein",
        "Hoërskool Framesby",
        "Selborne College",
        "Hoërskool Marlow",
        "Hoërskool Menlo Park",
        "Hoërskool Merensky",
        "Hoërskool Monument Park",
        "Hoërskool Nelspruit",
        "Hoërskool Noordheuwel",
        "Hoërskool Oos Moot",
        "Hoërskool Outeniqua",
        "Paarl Boys High",
        "Paarl Gimnasium",
        "Pietersburg Hoerskool",
        "Hoërskool Piet Retief",
        "Hoërskool Transvalia",
        "Hoërskool Waterkloof",
        "Hoërskool Wonderboom",
        "Hoërskool Rustenburg",
        "Hoërskool Zwartkop"
    ]);


    const [selectedGameType, setSelectedGameType] = useState('');
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const backendSeason = useSelector((state) => state.season.backendSeason)
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const activeSeason = useSelector((state) => state.season.activeSeason);
    console.log('Backend Season received: ', backendSeason)


    const formatDate = (dateString) => {
        const locationOfT = dateString.indexOf('T');
        return dateString.slice(0, locationOfT);
    };

    useEffect(() => {
        //Get Season Types for IDs
        fetchSeasonTypes()
        //Get game types for IDS
        fetchGameTypes()

        const team = availableTeams.find((team) => team.name === activeSeason);
        setTeamId(team.id ? team.id : 0);

    }, [backendSeason, availableTeams])

    useEffect(() => {

        // const initialGameFields = backendSeason.games.map((game) => {
        //     const gameTypeObject = gameTypes.find((gameType) => gameType.type === game.type);
        //     const gameTypeId = gameTypeObject ? gameTypeObject.id : null;
        //     console.log('GameTypeId: ', gameTypeId)

        //     console.log('Game type: ', game.type)
        //     return {
        //         id: game.id,
        //         typeId: gameTypeId,
        //         opponent: game.opponent || '',
        //         date: formatDate(game.date) || '',
        //         venue: game.venue || '',
        //         type: game.type || '',
        //     };
        // });

        // const initialGameFields = backendSeason.games.map((game) => {
        //     const gameTypeObject = gameTypes.find((gameType) => gameType.type === game.type);
        //     const gameTypeId = gameTypeObject ? gameTypeObject.id : null;
        //     return {
        //         id: game.id,
        //         typeId: gameTypeId,
        //         opponent: game.opponent || '',
        //         date: formatDate(game.date) || '',
        //         venue: game.venue || '',
        //         type: game.type || '',
        //     };
        // });

        const initialGameFields = backendSeason.games.map((game) => {

            return {
                id: game.id,
                typeId: game.gameTypeId,
                opponent: game.opponent || '',
                date: formatDate(game.date) || '',
                venue: game.venue || '',
                type: game.type || '',
            };
        });

        setGameFields(initialGameFields);
        setStartDate(formatDate(backendSeason.startDate));
        setEndDate(formatDate(backendSeason.endDate));
    }, [backendSeason, gameTypes]);




    useEffect(() => {
        console.log('Gamefields in UseEffect: ', gameFields)
    }, [gameFields])

    const fetchSeasonTypes = async () => {

        setIsLoading(true);
        try {
            const response = await seasonRequests.getSeasonTypes();
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Season Types received: ', response.data)
            setIsLoading(false)
            if (response.status === 200) {

                setSeasonTypes(response.data)
            }
            else {
                console.log('In the else');
            }

        } catch (error) {
            console.error('Error fetching seasonTypes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGameTypes = async () => {

        setIsLoading(true);
        try {
            const response = await gameRequests.getGameTypes();
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Game Types received: ', response.data)
            setIsLoading(false)
            if (response.status === 200) {

                setGameTypes(response.data)
            }
            else {
                console.log('In the else');
            }

        } catch (error) {
            console.error('Error fetching gametypes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    async function handleConfirmGame() {


        // {
        //     "seasonId": 0,
        //     "schoolId": 0,
        //     "typeId": 0,
        //     "date": "2024-01-03T19:22:44.215Z",
        //     "venue": "string",
        //     "opponent": "string"
        //   }

        if (lastAddedGameIndex !== null) {
            const confirmedGame = gameFields[lastAddedGameIndex];
            console.log('Confirmed Game Data: ', confirmedGame);

            const gameObject = {
                seasonId: backendSeason.id,
                schoolId: schoolId,
                typeId: confirmedGame.typeId,
                date: confirmedGame.date,
                venue: confirmedGame.venue,
                opponent: confirmedGame.opponent
            }

            console.log('Going to be sending over this object: ', gameObject)

            try {
                const response = await gameRequests.AddGameToSeason(teamId, gameObject)
                // const response = await gameRequests.addGame(teamId, gameObject)

                setIsLoading(true)
                if (response.status === 200) {
                    console.log('Response from Add Game: ', response.data)
                    setError(false)
                    setConfirmationMessage('Game Added')
                    setOpenConfirmationSnackbar(true)
                    setIsLoading(false)
                }
                else {
                    console.log('Could not Add Game: ', response)
                    setError(true)
                    setConfirmationMessage('Could not Add Game')
                    setOpenConfirmationSnackbar(true)
                    setIsLoading(false)
                }
            } catch (error) {
                console.log('Could not Add Game: ', error.response)
                setError(true)
                setConfirmationMessage('Could not Add Game')
                setOpenConfirmationSnackbar(true)
                setIsLoading(false)
            } finally {

                setIsLoading(false)
                setSeasonStarted(true)
                //This is supposed to trigger a refresh in season component 
                //because we have a useEffect listening for it.
                dispatch(setChanged(true))
            }

            // Now you can use the data as needed, for example, dispatch it to the store or perform other actions.
        } else {
            console.warn('No game has been added recently.');
        }
    }



    async function updateSeason() {
        setIsLoading(true)
        console.log('Update season has been started')
        //Routing to last component which is the in season component.

        // Check for empty fields in game data
        const isEmptyField = gameFields.some((game) => {
            return (
                game.opponent.trim() === '' ||
                game.date.trim() === '' ||
                game.venue.trim() === '' ||
                (typeof game.type === 'string' && game.type.trim() === '')
            );
        });

        if (isEmptyField) {
            setError(true);
            setConfirmationMessage('Please fill in all game fields');
            setOpenConfirmationSnackbar(true);
            setIsLoading(false);
            return;
        }
        setOpenSetupGames(false)
        setSeasonStarted(true)

        //Dispatch the gameFields to the slice
        console.log("This is game Fields: ", gameFields)

        console.log('GameFields before constructing seasonObj: ', gameFields)


        //GET THE IDS FROM THE STRINGS OF SEASON AND GAMES
        const seasonTypeObject = seasonTypes.find((season) => season.type === backendSeason.type)
        console.log('SeasonTypeObject: ', seasonTypeObject)
        const seasonTypeId = seasonTypeObject.id


        const seasonObj = {
            id: backendSeason.id,
            schoolId: schoolId,
            teamId: teamId,
            typeId: seasonTypeId,
            startDate: startDate,
            endDate: endDate,
            // games: backendSeason.games,
            games: gameFields,
        }


        console.log('Season Object before dispatch and service call: ', seasonObj)
        console.log('Game fields before dispatch and service call: ', gameFields)

        try {
            setIsLoading(true)
            const response = await seasonRequests.updateSeason(seasonObj)

            if (response.status === 200) {
                console.log('Response from Update Season: ', response.data)
                setError(false)
                setConfirmationMessage('Season has been Updated')
                setOpenConfirmationSnackbar(true)
                setSeason(response.data)
                console.log('Snackbar should be open now');
            }
            else {
                console.log('Could not Update season: ', response)
                setError(true)
                setConfirmationMessage('Could not Update season')
                setOpenConfirmationSnackbar(true)
                console.log('Snackbar should be open now');
            }
        } catch (error) {
            console.log('Could not Update season: ', error.response)
            setError(true)
            setConfirmationMessage('Could not Update season')
            setOpenConfirmationSnackbar(true)
            console.log('Snackbar should be open now');
        } finally {
            setIsLoading(false)

        }

    }

    const handleCloseConfirmationSnackbar = async (event, reason) => {
        // try {
        //     // Example asynchronous operation (replace this with your actual asynchronous logic)
        //     await new Promise((resolve) => setTimeout(resolve, 1000));


        //     if (!error) {
        //         dispatch(setChanged(true));
        //         setSeasonStarted(true);
        //         console.log('Season started before dispatch: ', seasonStarted);

        //         // Move navigation inside the block if you want to navigate only when there's no error
        //         navigate('/Season');

        //         // Dispatch actions
        //         dispatch(setStateOpenSetupGames(false));
        //         dispatch(setStateSeasonStarted(true));
        //         dispatch(UpdateSeasonGames(gameFields));
        //     }

        //     // Log and close the snackbar
        //     console.log('Season started before dispatch: ', seasonStarted);


        // } catch (error) {
        //     console.log('Error: ', error)
        // }
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setChanged(true));
        setSeasonStarted(true);
        console.log('Season started before dispatch: ', seasonStarted);

        // Move navigation inside the block if you want to navigate only when there's no error
        navigate('/Season');

        // Dispatch actions
        dispatch(setStateOpenSetupGames(false));
        dispatch(setStateSeasonStarted(true));
        dispatch(UpdateSeasonGames(gameFields));
        setOpenConfirmationSnackbar(false);
    };


    //Handle the changes in Start and End Date
    const handleDateChange = (event, dateType) => {
        const newValue = event.target.value;

        // Update the state based on the dateType
        if (dateType === 'startDate') {
            setStartDate(newValue);
        } else if (dateType === 'endDate') {
            setEndDate(newValue);
        }
    };


    // Update a specific field's value in gameFields
    const handleFieldChange = (event, fieldIndex, fieldName) => {
        console.log('Field Name received: ', fieldName, ' value: ', event.target.value);
        const newValue = event.target.value;

        if (fieldName === 'type') {
            const gameTypeObject = gameTypes.find((gameType) => gameType.type === event.target.value);
            console.log('GameTypeObject: ', gameTypeObject);
            const gameTypeId = gameTypeObject ? gameTypeObject.id : null;
            console.log('GameTypeId: ', gameTypeId);

            // Use the callback form of setGameFields to ensure the correct order of updates
            setGameFields((prevFields) => {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex][fieldName] = newValue;
                updatedFields[fieldIndex]['typeId'] = gameTypeId; // Update the typeId field
                return updatedFields;
            });

            setChanged(true);
            setSelectedGameType(event.target.value);
        } else {
            // For other fields, update directly
            setGameFields((prevFields) => {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex][fieldName] = newValue;
                return updatedFields;
            });
        }
    };



    function addGame() {
        // Check if the last game has any empty fields
        setShowConfirm(true)
        const lastGame = gameFields[gameFields.length - 1];
        console.log('Last Game: ', lastGame)
        const hasEmptyFields = Object.values(lastGame).some(value => value === '');
        console.log('HasEmptyFields? ', hasEmptyFields)

        // if (hasEmptyFields) {
        //     // Display error message in Snackbar
        //     setError(true);
        //     setConfirmationMessage('Please fill in all fields for the previous game before adding a new one.');
        //     setOpenConfirmationSnackbar(true);
        //     return;
        // }

        setGameIdCounter((prevCounter) => prevCounter + 1);

        setGameFields((prevFields) => [
            ...prevFields,
            {
                id: 0,
                typeId: gameIdCounter,
                opponent: '',
                date: '',
                venue: '',
                type: '',
            },
        ]);

        // Set the index of the last added game
        setLastAddedGameIndex(gameFields.length);
    }


    console.log('Gamefields before map: ', gameFields)

    // Render the dynamic game fields
    const renderGameFields = () => {
        return gameFields.map((game, index) => (

            <React.Fragment key={index}>
                <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />
                <p style={{ marginBottom: '2px' }}>Game {index + 1}</p>
                <Grid container spacing={3} justifyContent="center" >
                    <Grid item xs={12} md={3}>
                        {/* <TextField
                            label="Opponent"
                            type="text"
                            value={game.opponent}
                            onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        /> */}
                        <FormControl fullWidth>
                            <InputLabel id={`opponent-label-${index}`}>Opponent</InputLabel>
                            <Select
                                labelId={`opponent-label-${index}`}
                                label="Opponent"
                                id={`opponent-dropdown-${index}`}
                                value={game.opponent}
                                sx={{ width: '100%', marginBottom: '15px' }}
                                onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            >
                                {
                                    schoolNames.map((school) => {
                                        return (
                                            <MenuItem key={school} value={school}>{school}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Date"
                            type="date"
                            value={game.date}
                            onChange={(event) => handleFieldChange(event, index, 'date')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id={`venue-label-${index}`}>Venue</InputLabel>
                            <Select
                                labelId={`venue-label-${index}`}
                                id="demo-simple-select"
                                value={game.venue}
                                label="Venue"
                                onChange={(event) => handleFieldChange(event, index, 'venue')}
                            >
                                <MenuItem value="Home">Home</MenuItem>
                                <MenuItem value="Away">Away</MenuItem>
                                <MenuItem value="Tour">Tour</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id={`type-label-${index}`}>Type Of Game</InputLabel>
                            <Select
                                labelId={`type-label-${index}`}
                                id={`type-dropdown-${index}`}
                                value={game.type}
                                label="Type of Game"
                                onChange={(event) => handleFieldChange(event, index, 'type')}
                            >
                                {gameTypes.map((gametypes) => (
                                    <MenuItem key={gametypes.type} value={gametypes.type}>
                                        {gametypes.type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {lastAddedGameIndex !== null && index === lastAddedGameIndex && (
                    <Button
                        variant="contained"
                        onClick={(event) => handleConfirmGame(event, game.id)}
                        style={{ marginBottom: '10px' }}
                    >
                        Confirm New Game
                    </Button>
                )}
            </React.Fragment >
        ));
    };

    // Render the dynamic fields
    const renderSeasonFields = () => {
        return (
            <React.Fragment>
                <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '20px' }} />
                <Grid container spacing={3} justifyContent="center">

                    <Grid item xs={12} md={3}>
                        <TextField
                            id="date"
                            label="Start Date"
                            type="date"
                            value={startDate} // Use your state variable here, e.g., value={date}
                            // onChange={(event) => {
                            //     setDate(event.target.value)
                            //     setstartDateError(false); // Reset error state
                            // }}
                            onChange={(event) => handleDateChange(event, 'startDate')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '200px', marginBottom: '15px' }}
                            error={startDateError} // Add error prop
                            helperText={startDateError ? 'Please select the start date' : ''}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            id="endDate"
                            label="End Date"
                            type="date"
                            value={endDate} // Use your state variable here, e.g., value={date}
                            // onChange={(event) => {
                            //     setEndDate(event.target.value)
                            //     setendDateError(false); // Reset error state
                            // }}
                            onChange={(event) => handleDateChange(event, 'endDate')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '200px', marginBottom: '15px' }}
                            error={endDateError} // Add error prop
                            helperText={endDateError ? 'Please select an end date' : ''}
                        />
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    };

    return (

        <div style={{ maxWidth: '100%', margin: '0 auto' }}>

            {
                isLoading ?
                    <Loader />
                    :
                    <>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'end',
                                flexDirection: 'column',
                                marginTop: '-20px'
                            }}
                        >
                            <Button variant='contained' onClick={() => addGame()}>Add Game</Button>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant='h5'>Update Season</Typography>
                            <br />
                        </div>

                        {/* Render the season fields */}
                        {renderSeasonFields()}

                        <br />

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant='h5'>Update Games</Typography>
                            <br />
                        </div>

                        {renderGameFields()}

                        <br />
                        <Button variant="contained" onClick={updateSeason} >
                            Update Season
                        </Button>
                        <br />

                        <Button variant="outlined" onClick={() => navigate(-1)} style={{ marginTop: '8px' }}>
                            Back
                        </Button>
                        <Snackbar
                            open={openConfirmationSnackbar}
                            autoHideDuration={4000}
                            onClose={handleCloseConfirmationSnackbar}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={handleCloseConfirmationSnackbar}
                                severity={error ? "error" : "success"}
                            >
                                {confirmationMessage}
                            </MuiAlert>
                        </Snackbar>
                    </>
            }
        </div>


    );
}