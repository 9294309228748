import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';

import { useState } from 'react';
// import { team } from './team';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { pushActions, setKicker } from './gamedaySlice';
import { Add, Clear, Remove } from '@mui/icons-material';


export default function AwayGoalKicker(props) {
    const [open, setOpen] = React.useState(true);
    const [conversionSelected, setConversionSelected] = useState(false);

    const handleClose = () => {
        if (conversionSelected) {
            props.resetOpponentKickerClicked()
            setOpen(false);
        }

    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Opponent Conversion</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                        }}
                    >
                        <br />

                        <Button
                            style={{ marginBottom: '5px' }}
                            data-testid="incrementAway"
                            onClick={() => { props.handleAwayConversionMade() }}
                            variant="outlined"
                            color="primary"
                        >
                            <Add sx={{ color: "green", marginLeft: '-10px' }} />
                            Conversion Over
                        </Button>
                        <br />
                        <Button
                            style={{ marginBottom: '5px' }}
                            data-testid="incrementAway"
                            onClick={() => { props.handleAwayConversionMissed() }}
                            variant="outlined"
                            color="primary"
                        >
                            <Remove sx={{ color: "red" }} />
                            Conversion Missed
                        </Button>
                    </Box>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}
