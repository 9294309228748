import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { setTimer, setIsSecondHalf } from "../../features/gameday/gamedaySlice";
import { useDispatch, useSelector } from 'react-redux';
import { setHalfLength, setRunningTimer } from './timerSlice';
import { setIsSecondHalfinTimer } from '../../features/gameday/timerSlice'

const Timer = () => {
    const dispatch = useDispatch();
    const [isSecondHalf, setIsSecondHalfState] = useState(false);
    const timer = useSelector((state) => state.gameday.timer);
    const runningTimer = useSelector((state) => state.timer.runningTimer);
    const [intervalId, setIntervalId] = useState(null); // Declare intervalId as a state variable
    const [isGameDone, setIsGameDone] = useState(false)
    const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))
    //////////
    //New Code

    const endTimeRef = useRef(null);
    //Handle Half Time according to Age
    let halfTime
    if (ageGroup === 14) {
        halfTime = 25;
        dispatch(setHalfLength(25))
    }
    else if (ageGroup === 15) {
        halfTime = 25;
        dispatch(setHalfLength(25))
    }
    else if (ageGroup === 16) {
        halfTime = 30;
        dispatch(setHalfLength(30))
    }
    else {
        halfTime = 15;
        dispatch(setHalfLength(15))
    }


    const startTimer = () => {
        const startTime = new Date();
        const endTime = new Date(startTime.getTime() + halfTime * 60 * 1000); // Add the half time to the start time
        endTimeRef.current = endTime; // Store the end time in the ref

        console.log('Checking to see if this works: ', 'Start: ', startTime, ' End: ', endTime);

        const updateTimer = () => {
            const currentTime = new Date();
            const remainingTime = endTime - currentTime;
            if (remainingTime > 0) {
                const hours = Math.floor(remainingTime / (60 * 60 * 1000));
                const minutes = Math.floor((remainingTime / (60 * 1000)) % 60);
                const seconds = Math.floor((remainingTime / 1000) % 60);

                dispatch(
                    setRunningTimer(
                        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                    )
                );
            } else {
                // Timer is done, handle the appropriate action here
                dispatch(setRunningTimer('00:00:00'));
                setIsSecondHalfState(true); // Display "Begin Second half" button
                clearInterval(intervalId); // Stop the timer interval when the end time is reached
            }
        };

        // Update the timer every second
        const id = setInterval(updateTimer, 1000);
        setIntervalId(id); // Store the interval ID in the state variable
        updateTimer(); // Call updateTimer immediately to show the correct initial value
    };

    const clearTimer = () => {
        dispatch(setRunningTimer('00:25:00')); // Set timer to initial value of 25 minutes
        if (endTimeRef.current) {
            clearInterval(endTimeRef.current);
            endTimeRef.current = null;
        }
        if (intervalId) {
            clearInterval(intervalId); // Clear the interval using the state variable
            setIntervalId(null); // Reset the interval ID to null
        }
    };

    useEffect(() => {
        dispatch(setRunningTimer(timer));

        if (isSecondHalf) {
            if (timer === '00:00:00') {
                setIsGameDone(true)
            }
        }

        return () => {
            clearTimer(); // Clean up the timer when the component unmounts
        };
    }, [timer, dispatch]);

    const getDeadTime = () => {
        const deadline = new Date();
        if (isSecondHalf) {
            deadline.setMinutes(deadline.getMinutes() + 1);
        } else {
            deadline.setMinutes(deadline.getMinutes() + 1);
        }
        return deadline;
    };

    const onClickStart = () => {
        setIsSecondHalfState(true)
        // dispatch(isSecondHalf); 
        dispatch(setIsSecondHalfinTimer(isSecondHalf))


        clearTimer(); // Clear any existing timers before starting a new one
        startTimer();
    };

    const onClickStop = () => {
        clearTimer();
    };

    const onClickReset = () => {
        setIsSecondHalfState(false)
        // dispatch(isSecondHalf); 
        dispatch(setIsSecondHalfinTimer(isSecondHalf))
        startTimer();
        clearTimer(getDeadTime());
    };

    return (
        <div className="App" style={{ textAlign: 'center', maxWidth: '300px', margin: '0 auto' }}>
            <h2 style={{ color: runningTimer === '00:00:00' ? 'red' : 'inherit', fontSize: '2rem' }}>
                {runningTimer}
            </h2>
            {isGameDone ? <p style={{ fontSize: '1rem' }}>Game done</p> : <></>}
            {isSecondHalf ? <p style={{ fontSize: '1rem' }}>Second Half</p> : <p style={{ fontSize: '1rem' }}>First Half</p>}

            {isSecondHalf ? (
                <Button variant="outlined" onClick={onClickStart} style={{ fontSize: '1rem', margin: '5px' }}>
                    Begin Second Half
                </Button>
            ) : (
                <Button variant="outlined" onClick={onClickReset} style={{ fontSize: '1rem', margin: '5px' }}>
                    Start Timer
                </Button>
            )}

            <Button variant="outlined" onClick={onClickStop} style={{ fontSize: '1rem', margin: '5px' }}>
                Stop Timer
            </Button>
        </div>
    );
};

export default Timer;
