import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, IconButton, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { trelloRequests } from "../services/api-requests";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../layouts/loader';
import { useSelector } from 'react-redux';


const defaultTheme = createTheme();

export default function Popup(props) {
    const { isOpen, setBugReportWindowStatus } = props;
    const [bugName, setBugName] = React.useState('');
    const [bugDescription, setBugDescription] = React.useState('');
    const [isloading, setisLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [additionalMessage, setAdditionalMessage] = React.useState('');
    const coachName = useSelector((state) => state.user.profile.FirstName)
    const coachLastName = useSelector((state) => state.user.profile.LastName)
    const coachSchool = useSelector((state) => state.user.schoolName)



    const submitBugReport = () => {

        const coach = {
            coachName: `${coachName} ${coachLastName}`,
            school: coachSchool
        }

        const bigDescription = `Coach: ${coachName} ${coachLastName} from ${coachSchool}. Description: ${bugDescription}`


        console.log('Going to be sending over the following: ', bugName, bigDescription,  window.location.href)

        setisLoading(true)
        try {
            trelloRequests.submitBug(bugName, bigDescription, window.location.href).then((response) => {
                console.log(response);
                if (response.status === 200) {

                    setMessage('Thank you for reporting the bug');
                    setAdditionalMessage('We will let you know as soon as this is resolved');

                    setOpenSnackbar(true);
                    setError(false)
                }
                else {
                    setMessage('Something went wrong');

                    setOpenSnackbar(true);
                    setError(true)
                }
            });
        }
        catch (error) {
            console.log('Error: ', error)
            setMessage('Something went wrong');

            setOpenSnackbar(true);
            setError(true)
        }
        finally {
            handleClose();
            setisLoading(false)
        }

    }

    const handleClose = () => {
        setOpenSnackbar(false)
        setBugReportWindowStatus(false);
        setBugName('');
        setBugDescription('');
    };

    return (
        <React.Fragment>
            {
                isloading ?
                    <Loader />
                    :
                    <ThemeProvider theme={defaultTheme}>
                        <Dialog open={isOpen} maxWidth="xs" fullWidth={true}>
                            <DialogTitle>
                                <Typography variant="h5" component="div">Report Bug</Typography>
                            </DialogTitle>
                            {/* Close icon might be redundant given that there is a close button at bottom */}
                            {/* <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: "#000000",
                        }}
                    >
                    <CloseIcon />
                    </IconButton> */}
                            <DialogContent dividers>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="What happened?"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={bugName}
                                    onChange={(e) => setBugName(e.target.value)}
                                />
                                <TextField

                                    margin="dense"
                                    id="description"
                                    label="Please Describe in as much detail as possible. "
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    multiline={true}
                                    rows={4}
                                    value={bugDescription}
                                    onChange={(e) => setBugDescription(e.target.value)}
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={submitBugReport}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                    </ThemeProvider>
            }


            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={error ? "error" : "success"}
                >
                    {Message}
                    <br />
                    {additionalMessage}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    )
}