
// import React, { useState, useEffect, useRef } from 'react';
// import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button, TextField, Grid } from '@mui/material';
// import { OutTable, ExcelRenderer } from 'react-excel-renderer';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import { useNavigate } from 'react-router-dom';
// import Taskboard from '../TaskBoard/taskBoard';
// import KanBan from '../TaskBoard/kanbanBoard';


// function TmGameTimeManual() {
//     const navigate = useNavigate()
//     const [fillTeamsSelected, setfillTeamsSelected] = useState(false);
//     const [uploadType, setUploadType] = useState('');
//     const [uploadSelectedError, setUploadSelectedError] = useState(false);
//     const [selectedFile, setSelectedFile] = useState('');
//     const [table, setTable] = useState('');
//     const [nrOfGames, setNrOfGames] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [position, setPosition] = useState('');
//     const [primarySchool, setPrimarySchool] = useState('');
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const [firstName, setFirstName] = useState('');

//     const [firstNameError, setFirstNameError] = useState('');
//     const [nrOfGamesError, setNrOfGamesError] = useState(false);
//     const [lastNameError, setLastNameError] = useState(false);
//     const [positionError, setPositionError] = useState(false);
//     const [primarySchoolError, setPrimarySchoolError] = useState(false);

//     function handleChange(event) {
//         setUploadType(event.target.value);
//         setUploadSelectedError(false); // Reset error state
//         setfillTeamsSelected(true)
//     }

//     function StartGame() {

//     }

//     const handleCloseSnackbar = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpenSnackbar(false);
//     };

//     function handleSubmit(event) {
//         event.preventDefault();
//         console.log('Hit')
//         // Reset error states
//         setPrimarySchoolError(false);
//         setFirstNameError(false);
//         setLastNameError(false);
//         setPositionError(false);


//         // Check if any field is incomplete
//         if (!firstName || !lastName || !position || !primarySchool === '') {
//             setErrorMessage('Please complete all fields');
//             setOpenSnackbar(true);
//             return;
//         }

//         const profile = {
//             'fullName': firstName,
//             'lastname': lastName,
//             'position': position,
//             'primarySchool': primarySchool,
//         }
//         console.log('Player profile added: ', profile)
//     }

//     const listItemStyles = {
//         listStyle: 'decimal',
//         paddingLeft: '20px',
//     };

//     function fillTeams() {
//         console.log('fill teams clicked')
//     }

//     function handleBack() {

//     }
//     return (
//         <div>
//             {/* <Taskboard /> */}
//             <KanBan />
//             <Button onClick={() => { navigate(-1) }} style={{ marginTop: 10 }}>Go back</Button>
//         </div>
//     )
// }

// export default TmGameTimeManual


import React from "react";
import { Container, Typography, Link, AppBar, Toolbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { styled } from "@mui/material/styles"; // Import the styled utility

import DragAndDrop from "../TaskBoard/DragAndDrop";



function TmGameTimeManual() {
    return (
        <div style={{ height: "100%" }}>
            <DragAndDrop />
            {/* <KanbanBoard /> */}
        </div>
    );
}

export default TmGameTimeManual;
