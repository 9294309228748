import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'


import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { devRequests } from '../../services/api-requests';
import { useEffect } from 'react';



import Modal from '@mui/material/Modal';
import Loader from '../loader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ManageManagers = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [deleted, setDeleted] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [approvedSchools, setApprovedSchools] = React.useState([]);
  const [unApprovedschools, setUnApprovedschools] = React.useState([]);
  const [allSchools, setAllSchools] = React.useState([]);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [schoolToBeDeleted, setSchoolToBeDeleted] = React.useState('')

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState();
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);


  const ConfirmationModal = ({ open, onClose }) => {

    const handleNo = () => {
      console.log('Handle No clicked');
      onClose();
    };

    const handleYes = () => {
      console.log('Handle Yes clicked');
      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete school
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {schoolToBeDeleted}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleNo} variant='contained'>No</Button>
              <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
            </div>

          </Box>
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    if (handleDeletedClicked) {
      handleOpen();
    }
  }, [handleDeletedClicked]);


  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await devRequests.getSchoolList();
      console.log('Response received: ', response)
      const approved = [];
      const unApproved = [];

      setIsLoading(true)
      if (response.status === 200) {
        // response.data.forEach((school) => {
        //   const schoolObj = {
        //     name: school.manager.firstName,
        //     lastName: school.manager.lastName,
        //     email: school.manager.email,
        //     school: school.name,
        //     status: school.status,
        //   };

        //   if (school.manager.approved) {
        //     approved.push(schoolObj);
        //   } else {
        //     unApproved.push(schoolObj);
        //   }
        // });
        response.data.forEach((school) => {
          const schoolObj = {
            name: school.manager ? school.manager.firstName : '',
            lastName: school.manager ? school.manager.lastName : '',
            email: school.manager ? school.manager.email : '',
            school: school.name,
            status: school.status,
          };

          if (school.manager && school.manager.approved) {
            approved.push(schoolObj);
          } else {
            unApproved.push(schoolObj);
          }
        });


        setAllSchools(response.data);
        setApprovedSchools(approved);
        setUnApprovedschools(unApproved);
      }
      else {
        setMessage('Something went wrong')
        setDeleted(true)
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  async function handleApprove(schoolName) {

    //API Call to approve the school + manager
    console.log('School received in HandleApprove function: ', schoolName)

    const schoolIwant = allSchools.filter((school) => school.name === schoolName)
    const schoolIDIwant = schoolIwant[0].id

    try {
      const response = await devRequests.approveSchool((schoolIDIwant))

      // Update the state with the updated data
      setAllSchools(response.data);
      fetchData()

      console.log('response: ', response)
      if (response.status === 200) {
        setOpenSnackbar(true);
        setMessage('Coach Approved');
      }
      else {
        setOpenSnackbar(true);
        setMessage('Something went wrong');
      }
    } catch (error) {
      console.log('error: ', error)
    }

  }

  async function handleDeleted(schoolName) {
    setHandleDeletedClicked(true);
    setSchoolToBeDeleted(schoolName)
    handleOpen();
  }

  const handleYesClick = async () => {
    setIsLoading(true)
    try {
      const schoolIwant = allSchools.filter((school) => school.name === schoolToBeDeleted);
      const schoolIDIwant = schoolIwant[0].id;
      console.log('School I want ID: ', schoolIDIwant)

      // Perform the API call to delete the school
      const response = await devRequests.deleteSchool(schoolIDIwant);
      console.log('response: ', response);

      fetchData()
      if (response.status === 200) {

        setOpenSnackbar(true);
        setMessage('School Deleted');
        setIsLoading(false)
      } else {
        setOpenSnackbar(true);
        setMessage('Something went wrong');
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setHandleDeletedClicked(false);
      setIsLoading(false)
    }
  };

  function handleEdit(schoolName) {

    const schoolIwant = allSchools.filter((school) => school.name === schoolName);
    const schoolIDIwant = schoolIwant[0].id;
    console.log('Handle Edit clicked')
    navigate(`/Managers/EditManager/${schoolIDIwant}`)
  }


  return (
    <>
      <TableContainer component={Paper}>
        <h3>Managers awaiting approval: </h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">LastName </TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">School</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {unApprovedschools.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Schools waiting for approval
                </TableCell>
              </TableRow>
            ) : (
              unApprovedschools.map((row) => (
                <TableRow
                  key={row.school}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.lastName}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.school}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align='center'>
                    <Button onClick={() => { handleApprove(row.school) }} variant='contained'>Approve</Button>
                    <Button color='error' onClick={() => handleDeleted(row.school)}>Delete School</Button>
                  </TableCell>
                </TableRow>
              ))
            )}

            {confirmationModalOpen && (
              <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
            )}

          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <TableContainer component={Paper}>
        <h3>Active Managers</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">LastName </TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">School</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              approvedSchools.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Schools have been approved
                  </TableCell>
                </TableRow>
              ) : (
                approvedSchools.map((row) => (
                  <TableRow
                    key={row.email}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.lastName}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.school}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align='center'>
                      <Button onClick={() => { handleEdit(row.school) }}>Edit</Button>
                      <Button color='error' onClick={() => { handleDeleted(row.school); }}>Delete School</Button>
                    </TableCell>

                  </TableRow>
                ))
              )
            }

          </TableBody>
        </Table>
      </TableContainer>

      {
        isLoading ?
          <Loader />
          :
          <></>
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={deleted ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </>
  );

}

export default ManageManagers

