import { useState } from 'react';
import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Icon } from '@mui/material';
import Container from 'react-bootstrap/Container';
import TemplateView from './templates';
import FileView from './files';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Resource() {
  const navigate = useNavigate()

  function navigateToFiles() {
      navigate('/Resource/Files');
  };

  function navigateToTemplates() {
    navigate('/Resource/Templates');
  };

  //  implement for user guides
  function navigateToUserGuides() {
    navigate('/Resource/UserGuides');
  };


  return (
    <Container>
      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Playing Guides</Card.Title>
              <Card.Text>
                Guides for game play.
              </Card.Text>
              <Button variant="contained" onClick={navigateToFiles}>Go to playing guides</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }} >
            <Card.Body>
              <Card.Title>Templates</Card.Title>
              <Card.Text>
                Templates for playing guides.
              </Card.Text>
              <Button variant="contained" onClick={navigateToTemplates}>Go to templates</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }} >
            <Card.Body>
              <Card.Title>User Guides</Card.Title>
              <Card.Text>
                Guides for how to use the system.
              </Card.Text>
              <Button variant="contained"  onClick={navigateToUserGuides}>Go to user guides</Button>
            </Card.Body>
          </Card>
        </Col>

      </Row>

    </Container>
  );
}

export default Resource;


