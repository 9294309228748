import React, { useState, useRef } from 'react'
import { Button, Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { addCard, updatePlayerCardDetails, updatePlayerGameTime } from './gamedaySlice';
import { pushActions } from './gamedaySlice';

function CardDialog({ open, handleClose, stateTeam }) {
    console.log('StateTeam in cardDialog: ', stateTeam)
    const [player, setPlayer] = useState(0);
    const [subbed, setSubbed] = useState();
    const [subClicked, setSubClicked] = useState(false);
    const [cardValue, setValue] = useState();
    const radioGroupRef = useRef(null);
    const time = useSelector((state) => state.gameday.timer)
    const runningTime = useSelector((state) => state.timer.runningTimer)
    const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)
    const halfLength = useSelector((state) => state.timer.halfLength)

    let activeHalf
    isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'
    let timeOfCard

    const options = [
        {
            id: 1,
            type: "Red"
        },
        {
            id: 2,
            type: "Yellow"
        },
        {
            id: 3,
            type: "None"
        }
    ]


    const dispatch = useDispatch();

    const handleChange = (event) => {
        setValue(event.target.value)
        console.log(event, ' was the event')
    };

    const handeSubmit = () => {
        timeOfCard = runningTime

        const [hours, minutes] = timeOfCard.split(':').map(Number);

        const totalMinutes = hours * 60 + minutes;
        console.log('Total minutes in card: ', totalMinutes);

        //Need to find in starting and bench
        // const newPlayer = stateTeam.find((searchPlayer) => searchPlayer.id === player)
        let newPlayer = stateTeam.players.find((searchPlayer) => searchPlayer.id === player)
        if (newPlayer === undefined) {
            newPlayer = stateTeam.reservePlayers.find((searchPlayer) => searchPlayer.id === player)
        }
        const cardIWant = options.find((searchCard) => searchCard.id === parseInt(cardValue))

        // const newAction = `${cardIWant.type} card received by ${newPlayer.firstName} ${newPlayer.lastName} with ${timeOfCard} left in the ${activeHalf}`

        const newAction = `${cardIWant.type} card received by ${newPlayer.firstName} ${newPlayer.lastName} with ${timeOfCard} left in the ${activeHalf}`

        dispatch(updatePlayerCardDetails({ card: cardValue, player: player }))
        dispatch(addCard(newAction))

        let gameTime
        if (activeHalf === '2nd Half') {
            gameTime = halfLength + (halfLength - totalMinutes)
            console.log(newPlayer.id, ' had ', gameTime, ' minutes of gameTime when card was given')

            dispatch(updatePlayerGameTime({
                type: 'card',
                player: newPlayer.id,
                gameTime: gameTime,
                typeOfCard: cardIWant.type,
            }))

        }
        if (activeHalf === '1st Half') {
            console.log('HalfLength: ', halfLength)

            gameTime = halfLength - totalMinutes
            console.log(newPlayer.id, ' had ', gameTime, ' minutes of gameTime when card was given')

            dispatch(updatePlayerGameTime({
                type: 'card',
                player: newPlayer.id,
                gameTime: gameTime,
                typeOfCard: cardIWant.type,
            }))
        }

        handleClose();
    }



    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Cards</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Who received a card?
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel htmlFor="try-scorer">Received by?</InputLabel>
                            <Select

                                value={player}
                                onChange={(event) => setPlayer(event.target.value)}
                                label="sub"
                                inputProps={{
                                    name: 'sub',
                                    id: 'sub',
                                }}
                                sx={{ width: '150px' }}
                            >
                                {/* {
                                    stateTeam.map((teamMember, index) => {
                                        if (teamMember.isReserve) {
                                            return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name}</MenuItem>
                                    })
                                } */}
                                {
                                    stateTeam?.players?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                    })
                                }
                                <Divider />
                                {
                                    stateTeam?.reservePlayers?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                    })
                                }
                            </Select>
                            <RadioGroup
                                ref={radioGroupRef}
                                aria-label="ringtone"
                                name="ringtone"
                                value={cardValue}
                                onChange={handleChange}
                            >
                                {options.map((option) => (
                                    <FormControlLabel
                                        value={option.id}
                                        key={option.id}
                                        control={<Radio />}
                                        label={option.type}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handeSubmit} variant='contained'>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default CardDialog