import Loader from '../../layouts/loader';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { playerRequests, teamRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//Main Components
import UploadTeams from './newTeam/uploadTeams';
import TeamLists from './teamLists';
import SquadList from './squadList';
import InjuredList from './injuredList';
import RTPPlayers from './RTPPlayers';

function Team() {
    //Data Handling
    const [isLoading, setIsLoading] = useState(false);
    const [hasPlayers, setHasPlayers] = useState(false);
    const [teamsData, setTeams] = useState({});
    const [injuredPlayers, setInjuredPlayers] = useState({});
    const [playerStatuses, setPlayerStatuses] = useState([]);
    const [rtpPlayers, setRTPPlayers] = useState([]);
    const [refreshPlayers, setRefreshPlayers] = useState(false);
    const [error, setError] = useState(false);


    const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup))
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const profileAgeGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))

    const activeTeamTab = useSelector((state) => state.team.activeTeamTab)
    console.log('activeTeamTab: ', activeTeamTab)

    //Notification
    const [openNotification, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('')

    const [activeComponent, setActiveComponent] = useState('')
    const [showAddContainerModal, setShowAddContainerModal] = useState(false);
    const [teamsUploaded, setTeamsUploaded] = useState(false)


    // ================================== Data Management ==================================

    useEffect(() => {
        console.log('Getting uploadedList value')
        console.log('Schoolid and ageGroup value: ', schoolId, ' age: ', ageGroup)
        const fetchData = async () => {
            setIsLoading(true);
            const response = await teamRequests.hasUploadedList(schoolId, ageGroup);
            console.log('Got value: ', response.data)
            const hasPlayers = response.data;
            setHasPlayers(hasPlayers);

            if (hasPlayers) {
                await fetchTeams().then(() => {
                    fetchRTPPlayers();
                    getInjuredStatuses();
                    fetchInjuredPlayers();
                    return fetchPlayers();
                })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
            else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [schoolId, profileAgeGroup, refreshPlayers]);

    useEffect(() => {
        console.log('RTP Players: ', rtpPlayers)
    }, [rtpPlayers])


    const getInjuredStatuses = async () => {
        try {
            await playerRequests.getStatuses().then((response) => {
                if (response.status === 200) {
                    console.log('Players Statuses received: ', response.data)
                    setPlayerStatuses(response.data);
                }
            });
        }
        catch (error) {
            console.error('Error fetching Player Statuses:', error);
        }
    };

    const fetchInjuredPlayers = async () => {
        try {
            await teamRequests.getInjuredPlayers(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    console.log('Injured Players received: ', response.data)
                    setInjuredPlayers(response.data);
                }
            });
        }
        catch (error) {
            console.error('Error fetching Injured:', error);
        }
    };

    const fetchTeams = async () => {
        try {
            await teamRequests.getTeamsList(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    console.log('LOOOOOOOK')
                    console.log('Response.data from getTeamLIST: ', response.data)
                    var tempData = teamsData;
                    tempData.teams = response.data;

                    teamsData.teams.push({
                        id: 999,
                        name: 'Available Players',
                        players: []
                    });

                    console.log('TempData received: ', tempData)

                    setTeams(tempData);
                }
            });
        }
        catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const fetchRTPPlayers = async () => {
        try {
            await teamRequests.getRTPPlayers(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    console.log('RTP Players received: ', response.data)
                    setRTPPlayers(response.data)
                }
            });
        }
        catch (error) {
            console.error('Error fetching RTP Players:', error);
        }
    };

    const fetchPlayers = async () => {
        try {
            await teamRequests.getAllPlayers(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    var tempData = teamsData;
                    tempData.players = response.data;
                    tempData.teams = convertTeamsToIds(teamsData.teams);

                    //Sort available players into the available players team
                    tempData.teams[teamsData.teams.length - 1].players = sortPlayers(tempData.players, tempData);
                    setTeams(tempData);
                }
            });
        }
        catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    //if player is not in a team, add to available players
    const sortPlayers = (players, teamsData) => {
        var availablePlayers = [];

        //Loop through all players
        players.forEach((player) => {
            var isPlayerInTeam = false;

            //Check every team to see if player is in team
            teamsData.teams.forEach((team) => {
                if (team.players.find(playerObj => playerObj === player.id)) {
                    isPlayerInTeam = true;
                }
            });

            //If player is not in team, add to available players
            if (!isPlayerInTeam) {
                availablePlayers.push(player.id);
            }
        });
        return availablePlayers;
    };

    //takes the teams and takes each player object and replaces it with the player id so the team is a list of player ids
    const convertTeamsToIds = (teams) => {
        var tempTeams = teams;
        tempTeams.forEach((team) => {
            var tempPlayers = [];
            team.players.forEach((player) => {
                tempPlayers.push(player.id);
            });
            team.players = tempPlayers;
        });
        return tempTeams;
    };


    // ================================== Notification Handling ==================================

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setNotificationOpen(false);
    };

    const PlayerAdded = () => {
        console.log('IN THE PARENT from playerAdded')
        //Changing a state variable a useEffect is listening to, to retrigger the calls
        setRefreshPlayers(!refreshPlayers)

    }

    const refreshSquad = () => {
        console.log('IN THE PARENT from squadList')
        //Changing a state variable a useEffect is listening to, to retrigger the calls
        setRefreshPlayers(!refreshPlayers)
    }

    //Teams are being added async but result is not clear yet, 
    //Show user loader untill result is clear
    const setLoading = (value) => {
        console.log('In the grandparent')
        setIsLoading(value)
    }

    const showNotification = (message, errorValue) => {
        console.log('In the grandparent')
        setError(errorValue)
        setNotificationMessage(message);
        setNotificationOpen(true);
    }

    // ============================= Upload Handling ==================================

    const handleUploadComplete = (amount) => {
        addTeams(amount);
        setRefreshPlayers(!refreshPlayers)
    };

    const addTeams = async (myCalculatedTeams) => {
        setIsLoading(true);

        try {
            for (const team of myCalculatedTeams) {

                //await teamRequests.addTeam(schoolId, ageGroup, newTeamName);
            }
        }
        catch (error) {
            console.error('Error Adding Teams:', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    //Handling active component other than UploadTeams
    function renderActiveComponent() {
        if (activeTeamTab === 'Selection') {
            // setActiveComponent('TeamsList');
            return <TeamLists PlayerAdded={PlayerAdded} initialList={teamsData} showNotification={showNotification} setLoading={setLoading} />;
        } else if (activeTeamTab === 'Squad') {
            // setActiveComponent('SquadList');
            return <SquadList refreshSquad={refreshSquad} players={teamsData} showNotification={showNotification} setLoading={setLoading} statuses={playerStatuses} />;
        }
        else if (activeTeamTab === 'RTP Players') {
            // setActiveComponent('SquadList');
            return <RTPPlayers refreshSquad={refreshSquad} players={rtpPlayers} showNotification={showNotification} setLoading={setLoading} statuses={playerStatuses} />;
        }
        else {
            // setActiveComponent('InjuredList');
            console.log("Sending over injured List: ", injuredPlayers)
            return <InjuredList refreshSquad={refreshSquad} players={injuredPlayers} statuses={playerStatuses} />;
        }
    }


    return (
        <div style={{ height: "100%", }}>
            {
                isLoading ?
                    <Loader />
                    :
                    <>
                        {
                            !hasPlayers ? (
                                <UploadTeams onUploadComplete={handleUploadComplete} />
                            ) : (
                                // Call the renderActiveComponent function by adding ()
                                renderActiveComponent()
                            )
                        }

                    </>
            }

            <Snackbar
                open={openNotification}
                autoHideDuration={4000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {notificationMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default Team;