import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';
import { setSeasonGames, setStateNrOfGames, setStateOpenSetupGames } from './seasonSlice';
import { setStateSeasonStarted } from './seasonSlice';
// import { setTotalGames } from './seasonSlice';
import { seasonRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TeamSeasonModal from './teamSeasonModal';


export function SetupGames(props) {
    const navigate = useNavigate()
    console.log('props: ', props)
    const dispatch = useDispatch();
    const [gameFields, setGameFields] = useState([]);
    const [typeOfGame, setTypeOfGame] = useState('');
    const [seasonStarted, setSeasonStarted] = useState(false);
    const [openSetupGames, setOpenSetupGames] = useState(false);
    const [gameIdCounter, setGameIdCounter] = useState(1); // Initialize the game ID counter
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [seasonDuplicatedFrom, setSeasonDuplicatedFrom] = useState(0);
    const [teamId, setTeamId] = useState('');
    const stateSeasonStarted = useSelector((state) => state.season.seasonStarted);
    const nrOfGames = useSelector((state) => state.season.season[0].totalGames);
    // console.log('New Nr Of games from slice: ', nrOfGames)
    const oldNrOfGames = useSelector((state) => state.season.totalGames);
    // console.log('Old Nr Of games from slice: ', oldNrOfGames)
    const oldSeasonGames = useSelector((state) => state.season.games)
    const seasonGames = useSelector((state) => state.season.season[0].games)
    const stateSeasonObj = useSelector((state) => state.season.season[0])
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const activeSeason = useSelector((state) => state.season.activeSeason);
    const [seasonForTeam, setSeasonForTeam] = useState([]);
    const [duplicateObject, setDuplicateObject] = React.useState({});
    const [isTeamSeasonModal, setIsTeamSeasonModal] = useState(false);
    const stateTeams = useSelector((state) => state.team.availableTeams)
    const activeTeam = useSelector((state) => state.season.activeSeason);
    const correctNrOfGames = parseInt(useSelector((state) => state.season.nrOfGames))



    const [schoolNames, setSchoolNames] = useState([
        "Afrikaanse Hoër Seunskool",
        "Hoërskool Ben Vorster",
        "Boland Landbou",
        "Hoërskool Brandwag",
        "Hoërskool Centurion",
        "Hoërskool Dr. EG Jansen",
        "Hoërskool Eldoraigne",
        "Hoërskool Ermelo",
        "Hoërskool Frikkie Meyer",
        "Grey College",
        "Hoërskool Helpmekaar",
        "Hts John Vorster",
        "Hts Middelburg",
        "Hoërskool Kempton Park",
        "Hoërskool Marais Viljoen",
        "Hoërskool Marlow",
        "Hoërskool Menlo Park",
        "Hoërskool Garsfontein",
        "Hoërskool Framesby",
        "Selborne College",
        "Hoërskool Merensky",
        "Hoërskool Rustenburg",
        "Hoërskool Monument Park",
        "Hoërskool Nelspruit",
        "Hoërskool Noordheuwel",
        "Hoërskool Oos Moot",
        "Hoërskool Outeniqua",
        "Paarl Boys High",
        "Paarl Gimnasium",
        "Pietersburg Hoerskool",
        "Hoërskool Piet Retief",
        "Hoërskool Transvalia",
        "Hoërskool Waterkloof",
        "Hoërskool Wonderboom",
        "Hoërskool Zwartkop"
    ]);

    // console.log('Season object received: ', stateSeasonObj)

    // console.log('NrOfGames received: ', nrOfGames)

    useEffect(() => {
        // console.log('Season games: ', seasonGames)
    }, [seasonGames])


    useEffect(() => {
        setIsLoading(true)
        const teamIWant = availableTeams.find((team) => team.name === activeSeason)

        const localTeamId = teamIWant?.id
        // console.log('TeamID I want: ', localTeamId)
        setTeamId(localTeamId)

        setIsLoading(false)
    }, [stateSeasonStarted, setStateOpenSetupGames, activeSeason])


    //// Initialize gameFields based on nrOfGames
    useEffect(() => {
        const initialGameFields = Array.from({ length: correctNrOfGames }, (_, index) => ({
            //   typeId: gameIdCounter + index, // Assign a unique ID to each game
            typeId: 0,
            opponent: '',
            date: '',
            venue: '',

        }));
        setGameFields(initialGameFields);
        // console.log('Hit')
    }, [props]);

    async function startSeason() {
        // console.log('Start season has been started')

        const seasonObj = {
            schoolId: schoolId,
            teamId: teamId,
            typeId: stateSeasonObj.seasonType,
            startDate: stateSeasonObj.seasonStartDate,
            endDate: stateSeasonObj.seasonEndDate,
            games: gameFields,
        }


        //Identify when we need to duplicate, and when not to

        console.log('Going to Create Season: ', seasonObj)

        // console.log('Are we going to duplicate? ', seasonForTeam.length)
        // console.log('Going to Duplicate Season: ', duplicateObject)

        try {
            setIsLoading(true)
            const response = await seasonRequests.createNewSeason(seasonObj)

            if (response.status === 200) {
                console.log('Response from create Season: ', response.data)
                setError(false)

                setConfirmationMessage('Season has been Created')
                setOpenConfirmationSnackbar(true)

            }
            else {
                console.log('Could not create season: ', response)
                setError(true)
                setConfirmationMessage('Could not create season')
                setOpenConfirmationSnackbar(true)
            }
        } catch (error) {
            console.log('Could not create season: ', error.response)
        } finally {

            //Most probably going to duplicate here:
            if (seasonForTeam.length > 0) {
                try {
                    const response = await seasonRequests.DuplicateSeason(duplicateObject)
                    console.log('Response from duplicate: ', response)
                    if (response.status === 200) {
                        console.log('Response from Duplicate Season: ', response.data)
                        setError(false)
                        setConfirmationMessage('Season has been Duplicated successfully')
                        setOpenConfirmationSnackbar(true)
                    }
                    else {
                        setError(true)
                        setConfirmationMessage('Could not duplicate season')
                        setOpenConfirmationSnackbar(true)
                    }
                } catch (error) {
                    console.log('Error in duplicate: ', error)
                    setError(true)
                    setConfirmationMessage('Could not duplicate season')
                    setOpenConfirmationSnackbar(true)
                }
            }

            //reset nr of games:
            dispatch(setStateNrOfGames(0))

            //This is never used
            setIsLoading(false)
            //This is never used
            setSeasonStarted(true)
        }

    }

    function onTeamsSelected(teams) {
        // console.log('Teams received from modal: ', teams)
        const myTeam = stateTeams.find((team) => team.name === activeTeam).id
        // console.log('My Team in setup: ', myTeam)
        setDuplicateObject(
            {
                teamToDuplicateId: myTeam,
                teamToDuplicateToId: teams[0]
            })
        setSeasonForTeam(teams)
    }

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            dispatch(setStateSeasonStarted(true))
            dispatch(setStateOpenSetupGames(false))
            //trigger callback
            props.refreshSeason()
            return;
        }
        dispatch(setStateSeasonStarted(true))
        dispatch(setStateOpenSetupGames(false))
        //trigger callback
        props.refreshSeason()

        setOpenConfirmationSnackbar(false);
    };

    // Update a specific field's value in gameFields
    const handleFieldChange = (event, fieldIndex, fieldName) => {
        console.log('Value we are working with: ', event.target.value)
        console.log('fieldIndex: ', fieldIndex)
        console.log('fieldName: ', fieldName)
        console.log('Exisiting Game Fields: ', gameFields)
        const newValue = event.target.value;
        setGameFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[fieldIndex][fieldName] = newValue;
            return updatedFields;
        });
    };

    // Render the dynamic fields
    const renderGameFields = () => {
        return gameFields.map((game, index) => (
            <React.Fragment key={index}>
                <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />
                <p>Game {index + 1}</p>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={3}>
                        {/* <TextField
                            label="Opponent"
                            type="text"
                            value={game.opponent}
                            onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        /> */}

                        <FormControl fullWidth>
                            <InputLabel id={`opponent-label-${index}`}>Opponent</InputLabel>
                            <Select
                                labelId={`opponent-label-${index}`}
                                label="Opponent"
                                id={`opponent-dropdown-${index}`}
                                value={game.opponent}
                                sx={{ width: '100%', marginBottom: '15px' }}
                                onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            >
                                {
                                    schoolNames.map((school) => {
                                        return (
                                            <MenuItem key={school} value={school}>{school}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Date"
                            type="date"
                            value={game.date}
                            onChange={(event) => handleFieldChange(event, index, 'date')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TextField
                            label="Venue"
                            type="text"
                            value={game.venue}
                            onChange={(event) => handleFieldChange(event, index, 'venue')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        /> */}
                        <FormControl fullWidth>
                            <InputLabel >Venue</InputLabel>
                            <Select
                                label="Venue"
                                value={game.venue}

                                onChange={(event) => handleFieldChange(event, index, 'venue')}
                                sx={{ width: '100%' }}
                            >
                                <MenuItem key="Home" value="Home">Home </MenuItem>
                                <MenuItem key="Away" value="Away"> Away</MenuItem>
                                <MenuItem key="Tour" value="Tour"> Tour</MenuItem>
                                <MenuItem key="Other" value="Other"> Other  </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel >Type Of Game</InputLabel>
                            <Select
                                label="Type of Game"
                                value={game.typeOfGame}
                                placeholder="Select Type of Game"
                                onChange={(event) => {
                                    handleFieldChange(event, index, 'typeId');
                                   // handleFieldChange(event, index, 'typeOfGame')
                                }
                                }
                                sx={{ width: '100%' }}
                            >
                                <MenuItem key="Friendly" value={1}>
                                    Friendly
                                </MenuItem>
                                <MenuItem key="League" value={2}>
                                    League
                                </MenuItem>
                                <MenuItem key="Derby" value={3}>
                                    Derby
                                </MenuItem>
                                <MenuItem key="TrainingSession" value={4}>
                                    Training Session
                                </MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
            </React.Fragment >
        ));
    };

    return (
        <div style={{ maxWidth: '100%', margin: '0 auto' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography variant='h5'>Set up games</Typography>
                <br />
            </div>

            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                    {/* <p>Duplicate this season</p> */}
                    <Button variant='contained' onClick={() => { setIsTeamSeasonModal(true) }}>
                        Duplicate this season for another team?
                    </Button>
                </div>
            </div>

            {/* Render the dynamic game fields */}
            {renderGameFields()}

            <br />
            <Button variant="contained" onClick={startSeason} style={{ marginBottom: '10px' }}>
                Start Season!
            </Button>
            <br />
            <Button variant="outlined" onClick={() => {
                dispatch(setStateOpenSetupGames(false));
                dispatch(setStateNrOfGames(0));
                navigate('/Season')
            }}>
                Back
            </Button>

            <TeamSeasonModal
                teams={stateTeams}
                isOpen={isTeamSeasonModal}
                setTeamsSessionModalOpen={setIsTeamSeasonModal}
                onTeamsSelected={onTeamsSelected}
            >
            </TeamSeasonModal>

            <Snackbar
                open={openConfirmationSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {confirmationMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}