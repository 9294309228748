import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'


import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useEffect } from 'react';
import { devRequests, managerRequests } from '../../services/api-requests';


import Modal from '@mui/material/Modal';
import Loader from '../../layouts/loader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const ApproveCoaches = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [deleted, setDeleted] = React.useState(false);
    const [approved, setApproved] = React.useState(false);
    const [approvedCoaches, setApprovedCoaches] = React.useState([]);
    const [unApprovedCoaches, setUnapprovedCoaches] = React.useState([]);
    const [allCoaches, setAllCoaches] = React.useState([]);
    const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
    const [coachToBeDeleted, setCoachToBeDeleted] = React.useState({})
    const [coachToBeDeletedFirstName, setCoachToBeDeletedFirstName] = React.useState();
    const [coachToBeDeletedLastName, setCoachToBeDeletedLastName] = React.useState();
    const [isSchoolLoading, setisSchoolLoading] = React.useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [open, setOpen] = React.useState();
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);
    // const schoolId = useSelector((state) => state.user.profile.school)
    const [receivedSchools, setReceivedSchools] = React.useState([]);
    const [endpointCoaches, setEndPointCoaches] = React.useState([]);
    const [selectedSchool, setSelectedSchool] = React.useState('');

    const ConfirmationModal = ({ open, onClose }) => {

        const handleNo = () => {
            console.log('Handle No clicked');
            onClose();
        };

        const handleYes = () => {
            console.log('Handle Yes clicked');
            handleYesClick();
            onClose();
        };

        return (
            <div>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete coach
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete: "{coachToBeDeleted}"
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onClick={handleNo} variant='contained'>No</Button>
                            <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
                        </div>

                    </Box>
                </Modal>
            </div>
        );
    };

    useEffect(() => {
        if (handleDeletedClicked) {
            handleOpen();
        }
    }, [handleDeletedClicked]);

    useEffect(() => {
        // fetchData();
        fetchSchools();
    }, []);

    const fetchSchools = async () => {
        setisSchoolLoading(true);
        try {
            const response = await devRequests.getSchoolList();
            setisSchoolLoading(false);

            if (response.status === 200) {
                setReceivedSchools(response.data);
            } else {
                setMessage('Something went wrong');
                setDeleted(true);
            }
        } catch (error) {
            console.error('Error fetching schools:', error);
        } finally {
            setisSchoolLoading(false);
        }
    };

    useEffect(() => {
        fetchCoaches(selectedSchool)
    }, [selectedSchool])

    const fetchCoaches = async (selectedSchool) => {
        console.log('Selected School: ', selectedSchool)
        const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
        console.log('SchoolIwant: ', schoolIwant)
        console.log('SchoolIwant ID: ', schoolIwant.id)
        setIsLoading(true)
        try {
            const response = await managerRequests.getAllCoaches(schoolIwant.id);
            console.log('Response received from get all Coaches: ', response)
            const approved = [];
            const unApproved = [];

            setIsLoading(true)
            if (response.status === 200) {
                response.data.forEach((coach) => {
                    const coachObj = {
                        name: coach.firstName,
                        lastName: coach.lastName,
                        ageGroup: coach.ageGroup,
                        role: coach.role,
                        level: "3",
                    };

                    if (coach.approved) {
                        approved.push(coachObj);
                    } else {
                        unApproved.push(coachObj);
                    }
                });

                setAllCoaches(response.data);
                setApprovedCoaches(approved);
                setUnapprovedCoaches(unApproved);
            }
            else {
                setMessage('Something went wrong')
                setDeleted(true)
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    async function handleApprove(coachName, coachSurname) {

        //API Call to approve the coach
        console.log('Coach received in HandleApprove function: ', coachName)

        console.log('All Coaches before filter: ', allCoaches)
        const coachIWant = allCoaches.filter((coach) => coach.firstName === coachName && coach.lastName === coachSurname)
        console.log('CoachIWant: ', coachIWant)
        const coachIdIWant = coachIWant[0].id
        console.log('CoachIDIWant: ', coachIdIWant)

        try {
            const response = await managerRequests.approveCoach((coachIdIWant))

            // Update the state with the updated data
            setAllCoaches(response.data);
            fetchCoaches()

            console.log('response: ', response)
            if (response.status === 200) {
                setOpenSnackbar(true);
                setMessage('Coach Approved');
            }
            else {
                setOpenSnackbar(true);
                setMessage('Something went wrong');
            }
        } catch (error) {
            console.log('error: ', error)
        }

    }

    async function handleDeleted(coachName, coachLastName) {
        setHandleDeletedClicked(true);
        setCoachToBeDeletedFirstName(coachName)
        setCoachToBeDeletedLastName(coachLastName)
        setCoachToBeDeleted(coachName)
        handleOpen();
    }


    const handleYesClick = async () => {
        setIsLoading(true)
        try {
            const coachIWant = allCoaches.filter((coach) => coach.firstName === coachToBeDeletedFirstName && coach.lastName === coachToBeDeletedLastName);
            console.log("Coach I Want: ", coachIWant)
            console.log('Coach To be deleted first name: ', coachToBeDeletedFirstName)
            console.log('Coach To be deleted last name: ', coachToBeDeletedLastName)
            const coachIdIWant = coachIWant[0].id;
            console.log('Coach I want ID IN DELETE: ', coachIdIWant)

            // Perform the API call to delete the coach
            const response = await managerRequests.deleteCoach(coachIdIWant);
            console.log('response: ', response);

            fetchCoaches()
            if (response.status === 200) {

                setOpenSnackbar(true);
                setMessage('Coach Deleted');
                setIsLoading(false)
            } else {
                setOpenSnackbar(true);
                setMessage('Something went wrong');
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setHandleDeletedClicked(false);
            setIsLoading(false)
        }
    };

    function handleEdit(coachName, coachSurname) {

        const coachIWant = allCoaches.filter((coach) => coach.firstName === coachName && coach.lastName === coachSurname);
        const coachIdIWant = coachIWant[0].id;
        console.log('Handle Edit clicked')
        //NEED TO SEND SCHOOL ID WITH
        //   navigate(`/Settings/EditProfile/${schoolId}/${coachIdIWant}`)
    }


    return (
        <>
            <h4>Filter by School</h4>
            {
                isSchoolLoading ?
                    <Loader />
                    :
                    <Select
                        id="demo-simple-select"
                        placeholder="Select School"
                        sx={{ width: '150px', height: '10%', }}
                        value={selectedSchool}
                        onChange={(e) => setSelectedSchool(e.target.value)}
                    >
                        {receivedSchools.map((school) => (
                            <MenuItem key={school.name} value={school.name}>
                                {school.name}
                            </MenuItem>
                        ))}
                    </Select>
            }
            <TableContainer component={Paper}>
                <h3>Coaches awaiting approval: </h3>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Surname </TableCell>
                            <TableCell align="right">Age Group</TableCell>
                            <TableCell align="right">Coaching Role</TableCell>
                            <TableCell align="right">Coaching Level</TableCell>
                            <TableCell align="center">Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {unApprovedCoaches.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No Coaches waiting for approval
                                </TableCell>
                            </TableRow>
                        ) : (
                            unApprovedCoaches.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.lastName}</TableCell>
                                    <TableCell align="right">{row.ageGroup}</TableCell>
                                    <TableCell align="right">{row.role}</TableCell>
                                    <TableCell align="right">{row.level}</TableCell>

                                    <TableCell align='center'>
                                        <Button onClick={() => { handleApprove(row.name, row.lastName) }} variant='contained'>Approve</Button>
                                        <Button color='error' onClick={() => handleDeleted(row.name, row.lastName)}>Delete Coach</Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}

                        {confirmationModalOpen && (
                            <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <br />
            <TableContainer component={Paper}>
                <h3>Active Coaches</h3>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Surname </TableCell>
                            <TableCell align="right">Age Group</TableCell>
                            <TableCell align="right">Coaching Role</TableCell>
                            <TableCell align="right">Coaching Level</TableCell>
                            <TableCell align="center">Options</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            approvedCoaches.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No Schools have been approved
                                    </TableCell>
                                </TableRow>
                            ) : (
                                approvedCoaches.map((row) => (
                                    <TableRow
                                        key={row.email}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.lastName}</TableCell>
                                        <TableCell align="right">{row.ageGroup}</TableCell>
                                        <TableCell align="right">{row.role}</TableCell>
                                        <TableCell align="right">{row.level}</TableCell>
                                        <TableCell align='center'>
                                            <Button onClick={() => { handleEdit(row.name, row.lastName) }}>Edit</Button>
                                            <Button color='error' onClick={() => { handleDeleted(row.name, row.lastName); }}>Delete Coach</Button>
                                        </TableCell>

                                    </TableRow>
                                ))
                            )
                        }

                        {confirmationModalOpen && (
                            <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                isLoading ?
                    <Loader />
                    :
                    <></>
            }
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </>
    );

}