import React, { useState, useRef } from 'react'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux';
import CardDialog from './cardDialog';
import AddCardIcon from '@mui/icons-material/AddCard';

function Cards(props) {

    const [open, setOpen] = useState(false);
    const [subClicked, setSubClicked] = useState(false);
    const stateTeam = useSelector((state) => state.gameday.startingTeam)


    const newTeam = props?.team
    console.log('new Team: ', newTeam)

    function handleClick() {
        setSubClicked(true)
        setOpen(true)
        console.log('Handle click has been clicked. ')
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Button variant="contained" onClick={() => handleClick()}><AddCardIcon style={{marginRight: '10px', color: 'yellow'}} />Add Card</Button>
            {
                subClicked && <CardDialog open={open} handleClose={handleClose} stateTeam={newTeam} />
            }
        </div>
    )
}

export default Cards