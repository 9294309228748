import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect } from 'react';
import { managerRequests, playerRequests, teamRequests, userRequests } from '../../services/api-requests';
import Loader from '../../layouts/loader';
import { useState } from 'react';


const EditPlayer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { schoolId, playerId } = useParams();

    const [school, setSchool] = React.useState()

    const [role, setRole] = React.useState()
    const [level, setLevel] = React.useState()
    const [firstName, setFirstName] = React.useState()
    const [lastName, setLastName] = React.useState()
    const [primaryPostion, setPrimaryPosition] = React.useState('')
    const [secondaryPostion, setSecondaryPostion] = React.useState('')
    const [primaryPostionid, setPrimaryPositionid] = React.useState()
    const [secondaryPostionid, setSecondaryPostionid] = React.useState()
    const [playerIWantId, setPlayerIWantId] = React.useState()
    const [weight, setWeight] = React.useState()
    const [height, setHeight] = React.useState()
    const [positionList, setPositionList] = React.useState([])
    const [players, setPlayers] = React.useState([])

    const [position, setPosition] = useState('');
    const [playerObj, setPlayerObj] = useState({});
    const [startDateError, setstartDateError] = React.useState(false);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [allGood, setAllGood] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [schoolError, setschoolError] = React.useState(false);
    const [ageGroupError, setageGroupError] = React.useState(false);
    const [roleError, setroleError] = React.useState(false);
    const [levelError, setLevelError] = React.useState(false)
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCoachingRoleLoading, setisCoachingRoleLoading] = React.useState(false)
    const [isRoleChanged, setIsRoleChanged] = React.useState(false)
    const [isAgeGroupChanged, setIsAgeGroupChanged] = React.useState(false)
    const [positions, setPositions] = useState([
        { id: 1, name: 'Loose Head Prop (1)' },
        { id: 2, name: 'Hooker (2)' },
        { id: 3, name: 'Tight Head Prop (3)' },
        { id: 4, name: 'Lock (4)' },
        { id: 5, name: 'Lock (5)' },
        { id: 6, name: 'Openside Flanker (6)' },
        { id: 7, name: 'Blindside Flanker (7)' },
        { id: 8, name: 'Eight Man (8)' },
        { id: 9, name: 'Scrumhalf (9)' },
        { id: 10, name: 'Flyhalf (10)' },
        { id: 11, name: 'Left Wing (11)' },
        { id: 12, name: 'Inside Center (12)' },
        { id: 13, name: 'Outside Center (13)' },
        { id: 14, name: 'Right Wing (14)' },
        { id: 15, name: 'Fullback (15)' },
    ]);


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            navigate(-1)
            return;
        }
        setOpenSnackbar(false);
        navigate(-1)
    };

    useEffect(() => {
        setIsLoading(true)
        fetchPlayers()
        setIsLoading(false)

    }, [schoolId, playerId])

    useEffect(() => {
        setPlayerObj({
            firstName: firstName,
            lastName: lastName,
            weight: parseInt(weight),
            height: parseInt(height),
            positionList: positionList,
            dateOfBirth: dateOfBirth
        })
        console.log('PlayerObj: ', playerObj)
    }, [firstName, lastName, weight, height, positionList, dateOfBirth])

    //Seperate to clear up console log for debugging
    useEffect(() => {
        console.log('PositionList: ', positionList)

    }, [positionList])

    useEffect(() => {
        // Update positionList when primaryPosition or secondaryPosition changes
        let updatedPositionList = [...positionList]; // Create a copy of the current positionList

        // Update the first position in the positionList
        updatedPositionList[0] = parseInt(primaryPostion);

        // Update the second position in the positionList
        updatedPositionList[1] = parseInt(secondaryPostion);

        setPositionList(updatedPositionList);

        // Get the position numbers and add them to positionList.
        // Create a state variable to send as part of the object
    }, [primaryPostion, secondaryPostion]);

    useEffect(() => {

        console.log('Primary position: ', primaryPostion)

    }, [primaryPostion])

    useEffect(() => {

        console.log('Secondadry position: ', secondaryPostion)
    }, [secondaryPostion,])


    const fetchPlayers = async () => {
        try {
            setIsLoading(true)
            const response = await playerRequests.getPlayers();
            if (response.status === 200) {
                setMessage('Player updated')
                setAllGood(true)
                setIsLoading(false)
                setTimeout(() => console.log("Waited for 1 second"), 1000);
                setPlayers(response.data);
            } else {
                setMessage('Could not update player')
                setAllGood(false)
                console.log('Response not good in players endpoint: ', response);
            }
        } catch (error) {
            setMessage('Could not update player')
            setAllGood(false)
            console.log('error in players endpoint: ', error);
        }
        finally {

            setIsLoading(false)
        }
    }

    useEffect(() => {
        console.log('Players: ', players);
        const playerIWant = players.find((player) => parseInt(player.id) === parseInt(playerId));
        console.log('This player I want: ', playerIWant);

        if (playerIWant) {
            setPlayerIWantId(playerIWant.id)
            setFirstName(playerIWant.firstName);
            setLastName(playerIWant.lastName);
            setWeight(playerIWant.weight)
            setHeight(playerIWant.height)

            const primaryIndex = playerIWant.positionList.indexOf(',')
            const newPrimaryPosition = playerIWant.positionList.slice(0, primaryIndex)
            const newSecondaryPostion = playerIWant.positionList.slice(primaryIndex + 1, playerIWant.positionList.length)

            setPrimaryPosition(newPrimaryPosition);
            setSecondaryPostion(newSecondaryPostion ? newSecondaryPostion : '');

            setPrimaryPositionid(newPrimaryPosition);
            setSecondaryPostionid(newSecondaryPostion ? newSecondaryPostion : '');

            setDateOfBirth(playerIWant.dateOfBirth.slice(0, 10))


            console.log('newPrimaryPistion: ', newPrimaryPosition)
            console.log('newSecondaryPostion: ', newSecondaryPostion)

            console.log('PrimaryIndex: ', primaryIndex)

            console.log('dateOfBirth: ', playerIWant.dateOfBirth.slice(0, 10))


        }
    }, [players]);

    function handlePositionChange(type, position) {
        let updatedPositionList = [...positionList]; // Create a copy of the current positionList


        if (type === 'primary') {
            setPrimaryPosition(position);
            console.log('Changed: ', type, ' to: ', position);
            // Update the first position in the positionList
            updatedPositionList[0] = position;
        } else {
            setSecondaryPostion(position);
            console.log('Changed: ', type, ' to: ', position);
            // Update the second position in the positionList
            updatedPositionList[1] = position;
        }

        setPositionList(updatedPositionList);

        // Get the position numbers and add them to positionList.
        // Create a state variable to send as part of the object
    }



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (firstName == '' || lastName === '' || weight === '' || height === '' || primaryPostion === '' || dateOfBirth === '') {
            setAllGood(false);
            setMessage('Cannot be empty');
            setOpenSnackbar(true);
            return;
        }

        setIsLoading(true)
        console.log('playerid right before service call:', playerId)
        try {
            console.log('Player object before sending over to service call: ', playerObj)
            const response = await playerRequests.updatePlayer(parseInt(playerId), playerObj)
            console.log('Response from update: ', response)
            if (response.status === 200) {
                setAllGood(true);
                setMessage('Updated');
                setOpenSnackbar(true);
            }

        } catch (error) {
            console.log('Error: ', error)
        } finally {
            setIsLoading(false)
        }
        navigate('/Teams')
    }

    return (

        <Grid container sx={{}} >
            <CssBaseline />

            <Grid item xs={12} sm={8} md={5} lg={12} >
                <Box
                    sx={{
                        my: 2,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />

                    <Typography component="h1" variant="h5">
                        Edit Player Profile
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>


                        <p>First Name</p>
                        <TextField

                            autoComplete="given-name"
                            name="firstName"
                            required
                            sx={{ width: '300px' }}
                            value={`${firstName}`}
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <br />

                        <p>Last Name</p>
                        <TextField

                            autoComplete="given-name"
                            name="lastName"
                            required
                            sx={{ width: '300px' }}
                            value={`${lastName}`}
                            id="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <br />

                        <p>Weight</p>
                        <TextField

                            autoComplete="given-name"
                            name="weight"
                            required
                            sx={{ width: '300px' }}
                            value={weight}
                            id="weight"
                            onChange={(e) => setWeight(e.target.value)}
                        />

                        <br />
                        <p>Height</p>
                        <TextField

                            autoComplete="given-name"
                            name="height"
                            required
                            sx={{ width: '300px' }}
                            value={height}
                            id="height"
                            onChange={(e) => setHeight(e.target.value)}
                        />
                        <br />

                        <p>Primary Position</p>
                        <Select
                            id="pimaryposition"
                            value={primaryPostion}
                            placeholder="Position"
                            onChange={(event) => {
                                // dispatch(setSecondaryPostion(event.target.value))
                                handlePositionChange('primary', event.target.value);
                                // setPosition(event.target.value)
                                // setPositionError(false)
                            }}
                            sx={{ width: '300px', marginBottom: '15px' }}
                        // error={positionError} // Add error prop
                        // helperText={positionError ? 'Please complete this field' : ''}
                        >
                            {
                                positions.map((position) => {
                                    return (
                                        <MenuItem key={position.id} value={position.id}>
                                            {position.name}
                                        </MenuItem>
                                    )
                                })
                            }

                        </Select>

                        <p>Secondary Postion</p>
                        <Select
                            id="secondaryposition"
                            value={secondaryPostion}
                            placeholder="Position"
                            onChange={(event) => {
                                // dispatch(setSecondaryPostion(event.target.value))
                                handlePositionChange('secondary', event.target.value);
                                // setPosition(event.target.value)
                                // setPositionError(false)
                            }}
                            sx={{ width: '300px', marginBottom: '15px' }}
                        // error={positionError} // Add error prop
                        // helperText={positionError ? 'Please complete this field' : ''}
                        >
                            {
                                positions.map((position) => {
                                    return (
                                        <MenuItem key={position.id} value={position.id}>
                                            {position.name}
                                        </MenuItem>
                                    )
                                })
                            }

                        </Select>

                        <br />
                        <p>Date of Birth</p>
                        <TextField
                            id="date"
                            // label="Date of Birth"
                            type="date"
                            value={dateOfBirth}
                            defaultValue={dateOfBirth}
                            onChange={(event) => setDateOfBirth(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                defaultValue: dateOfBirth, // Add this line
                            }}
                            sx={{ width: '300px', marginBottom: '15px' }}
                            error={startDateError} // Add error prop
                            helperText={startDateError ? 'Please select date of birth' : ''}
                        />

                        < br />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3 }}
                        >Submit
                        </Button>
                        <br />
                        <Button
                            type='button'
                            variant="text"
                            sx={{ mt: 3 }}
                            onClick={() => navigate(-1)}
                        >Back
                        </Button>
                    </Box>
                </Box>
                {
                    isLoading ?
                        <Loader />
                        :
                        <></>
                }
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allGood ? "success" : "error"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </Grid>

    );
};


export default EditPlayer;
