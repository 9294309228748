import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import { useRef } from 'react';
import { useState } from 'react';
import { Card, CardContent, CardMedia, Input, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setApproved, setProfile, setProfileCreated, setUserToken } from '../user/userSlice';
import { setUserLoggedIn } from '../gameday/gamedaySlice';
import { useDispatch } from 'react-redux';
import { base64 } from 'base-64'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { userRequests } from '../../services/api-requests';
import { jwtDecode } from 'jwt-decode';
import { FormControl, Select, MenuItem, } from '@mui/material'
import Loader from '../../layouts/loader';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://squadcontrol.co.za/">
                Squad Control
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function RegisterSchool() {


    const [uploadedFileName, setUploadedFileName] = useState('');
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [previewUrl, setPreviewUrl] = useState(null);
    const [encodedSchoolLogo, setEncodedSchoolLogo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [schoolNames, setSchoolNames] = useState([
        "Afrikaanse Hoër Seunskool",
        "Hoërskool Ben Vorster",
        "Boland Landbou",
        "Hoërskool Brandwag",
        "Hoërskool Centurion",
        "Hoërskool Dr. EG Jansen",
        "Hoërskool Eldoraigne",
        "Hoërskool Ermelo",
        "Hoërskool Frikkie Meyer",
        "Grey College",
        "Hoërskool Helpmekaar",
        "Hts John Vorster",
        "Hts Middelburg",
        "Hoërskool Kempton Park",
        "Hoërskool Marais Viljoen",
        "Hoërskool Marlow",
        "Hoërskool Menlo Park",
        "Hoërskool Garsfontein",
        "Hoërskool Merensky",
        "Hoërskool Monument Park",
        "Hoërskool Nelspruit",
        "Hoërskool Noordheuwel",
        "Hoërskool Rustenburg",
        "Hoërskool Oos Moot",
        "Hoërskool Outeniqua",
        "Paarl Boys High",
        "Paarl Gimnasium",
        "Pietersburg Hoerskool",
        "Hoërskool Piet Retief",
        "Hoërskool Transvalia",
        "Hoërskool Waterkloof",
        "Hoërskool Wonderboom",
        "Hoërskool Zwartkop"
    ]);


    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setSelectedImage(file);
            const imageUrl = URL.createObjectURL(file);
            setPreviewUrl(imageUrl);

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const base64Data = reader.result.split(',')[1]; // Get the base64 data from the result
                setEncodedSchoolLogo(base64Data);
            };
        } else {
            setSelectedImage(null);
            setPreviewUrl(null);
            setEncodedSchoolLogo(null);
        }
    };


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (e) => {

        let globalError = false


        e.preventDefault();
        const data = new FormData(e.currentTarget);

        const firstName = data.get('firstName')
        const lastName = data.get('lastName')
        const email = data.get('email')
        const password = data.get('password')
        const schoolName = data.get('schoolName')
        const schoolLogo = encodedSchoolLogo

        if (firstName !== '' && lastName !== '' && email !== '' && password !== '' && schoolName !== '') {
            if (selectedImage) {

                try {
                    const response = await userRequests.registerSchool(firstName, lastName, email, password, schoolName, schoolLogo)

                    //Still needs to be approved
                    if (response.status === 200) {
                        dispatch(setUserLoggedIn(true))
                        const decodedToken = jwtDecode(response.data);
                        dispatch(setProfile(decodedToken))
                        globalError = false;
                    }
                    else {
                        console.log(`Response status: ${response.status}`)
                        globalError = true
                    }
                } catch (error) {
                    setErrorMessage('Could not register school');
                    setOpenSnackbar(true);
                    console.log('Error in registering School: ', error)
                    globalError = true
                } finally {
                    if (!globalError) {
                        setIsLoading(false)
                        navigate("/dashboard");
                    }
                }


            } else {
                console.log('No image selected');
            }
        }
        else {
            setErrorMessage('Incorrect details');
            setOpenSnackbar(true);
            setEmailError(true);
            setPasswordError(true);
        }
    };


    const fileInput = useRef(null);

    function handleSchoolNameChange() {

    }

    const openFileBrowser = () => {
        fileInput.current.click();
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            {
                isLoading ?
                    <Loader />
                    :
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >

                            <img style={{ borderRadius: '10px', marginBottom: '20px', height: '180px' }} src={SquadControlLogo} alt='Squad Control Logo' />

                            <Typography component="h1" variant="h5">
                                Register your school
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="lastName"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            autoFocus
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* <TextField
                                    required
                                    fullWidth
                                    id="schoolName"
                                    label="School Name"
                                    name="schoolName"
                                    autoComplete="family-name"
                                    onChange={handleSchoolNameChange}
                                /> */}

                                        <FormControl fullWidth>
                                            <InputLabel >School Name</InputLabel>
                                            <Select
                                                required
                                                id="schoolName"
                                                label="School Name"
                                                name="schoolName"

                                                sx={{ width: '100%', marginBottom: '15px' }}
                                                onChange={handleSchoolNameChange}                                    >
                                                {
                                                    schoolNames.map((school) => {
                                                        return (
                                                            <MenuItem key={school} value={school}>{school}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputLabel htmlFor="schoolLogo">Upload School Logo</InputLabel>
                                        <Input
                                            type="file"
                                            id="schoolLogo"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid></Grid>
                                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '110px' }}>
                                            {previewUrl && (
                                                <Card>
                                                    <CardMedia component="img" alt="Preview" height="140" image={previewUrl} />
                                                    <CardContent>
                                                        <p>Logo Preview</p>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Grid>
                                        <Grid></Grid>
                                    </Grid>




                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/" variant="body2">
                                            School already registered? Sign in
                                        </Link>
                                    </Grid>
                                </Grid>

                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                >
                                    <MuiAlert
                                        elevation={6}
                                        variant="filled"
                                        onClose={handleCloseSnackbar}
                                        severity="error"
                                    >
                                        {errorMessage}
                                    </MuiAlert>
                                </Snackbar>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 5 }} />
                    </Container>
            }

        </ThemeProvider>
    );
}