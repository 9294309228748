import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import SquadControlLogo from '../assets/Squad Control Logo New.jpeg'
import { useDispatch } from 'react-redux';
import { setProfile } from '../features/user/userSlice';


const SubscriptionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleGoBack() {
        localStorage.setItem('token', '')
        dispatch(setProfile(''))
        navigate('/')
    }


    return (
        <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '100px', }}>
            <img
                style={{
                    marginLeft: '37%',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    height: '180px',
                    display: 'block',
                    marginX: 'auto',
                }}
                src={SquadControlLogo}
                alt="Squad Control Logo"
            />


            <Typography variant="h2">Your Subscription has ended</Typography>
            <br />
            <Typography variant="h5">Please contact your school Manager</Typography>
            <br />
            <Typography variant="body1">We can't wait to have you back on board.</Typography>
            <Button onClick={() => handleGoBack()} style={{marginTop: '20px'}} variant='contained'>Go to Home</Button>
        </Container>
    );
};

export default SubscriptionPage;
