import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

const categories = [
    {

        children: [
            { id: 'SetUpProfile', name: 'Set Up Profile', icon: <DnsRoundedIcon />, active: true, },
            { id: 'UploadTeams', name: 'Upload Teams', icon: <DnsRoundedIcon />, active: false, },
            { id: 'GameTime', name: 'Game Time', icon: <PermMediaOutlinedIcon />, active: false, },
            { id: 'Report', name: 'Report', icon: <PublicIcon />, active: false, },

        ],
    },
    {

        children: [
            { id: 'Settings', name: 'Settings', icon: <SettingsIcon />, active: false, },
            { id: 'LogOut', name: 'Log Out', icon: <LogoutIcon />, active: false }
        ],
    },
];

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};


export default function TrialNavigator(props) {
    const navigate = useNavigate()
    const { ...other } = props;

    function handleSubmit(childId, children, name) {

        sessionStorage.setItem('activePage', name);
        window.dispatchEvent(new Event("storage"));
        if (childId === 'LogOut') {
            navigate('/tmSignIn')
        }
        else {
            navigate(`/trialManager/${childId}`)
        }


        const foundChild = children.filter((child) => { return child.id === childId })
        const notFoundChild = children.filter((child) => { return child.id !== childId })
        notFoundChild.forEach(child => { child.active = false; });
        foundChild[0].active = true;



    }


    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
                    Squad Control
                </ListItem>
                <ListItem sx={{ ...item, ...itemCategory }}>
                    <ListItemText>Simplify. Organize. Succeed</ListItemText>
                </ListItem>
                {categories.map(({ id, children }) => (
                    <Box key={id} sx={{ bgcolor: '#101F33' }}>
                        <ListItem sx={{ py: 2, px: 3 }} >
                            <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                        </ListItem>
                        {children.map(({ id: childId, icon, active, name }) => (
                            <ListItem disablePadding key={childId} >
                                <ListItemButton onClick={() => handleSubmit(childId, children, name)} sx={item}>
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText>{name}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </List>
        </Drawer>
    );
}