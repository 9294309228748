import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, IconButton, TextField, FormGroup, FormControlLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Loader from '../../layouts/loader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { sessionRequests } from '../../services/api-requests';
import { useState } from 'react';
import { sessionRequests } from '../../services/api-requests';
import { useDispatch, useSelector } from 'react-redux';
import { setStateWeeklyFocusPoint } from './seasonSlice';
import { CheckBox } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

const defaultTheme = createTheme();

export default function TeamSeasonModal(props) {
    const { isOpen, setTeamsSessionModalOpen, teams, onTeamsSelected } = props;

    // console.log('Teams received in Modal: ', teams)

    const dispatch = useDispatch()

    const [teamsSelected, setTeamsSelected] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [duplicateObject, setDuplicateObject] = React.useState({});


    const activeTeam = useSelector((state) => state.season.activeSeason);
    const [selectedTeams, setSelectedTeams] = useState([]);
    // console.log('Active Season in Modal: ', activeTeam)


    const handleCheckboxChange = (event) => {
        const teamId = parseInt(event.target.value, 10); // Convert the value to an integer

        if (teamId === 0) {
            // Handle "All" checkbox separately - this will not trigger because "all" is taken out
            setSelectedTeams(event.target.checked ? teams.map((team) => team.id) : []);
        } else {
            // Handle individual team checkboxes
            setSelectedTeams((prevSelectedTeams) => {
                if (prevSelectedTeams.includes(teamId)) {
                    return prevSelectedTeams.filter((id) => id !== teamId);
                } else {
                    return [...prevSelectedTeams, teamId];
                }
            });
        }
    };

    const submitTeamsSessions = async () => {
        if (selectedTeams.length === 0) {
            setError(true);
            setMessage('Select at least one team or click cancel');
            setOpenSnackbar(true);
        } else {
            // Perform your logic with the selectedTeams array
            setTeamsSessionModalOpen(false)

            onTeamsSelected(selectedTeams)
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleClose = () => {
        setTeamsSessionModalOpen(false);
        setTeamsSelected('');
    };

    function handleSubmit() {
        console.log('Submit')
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={defaultTheme}>
                <Dialog open={isOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>
                        <Typography variant="h5" component="div">Select the team you want to duplicate this season to</Typography>
                    </DialogTitle>

                    <DialogContent dividers>
                        <FormGroup onSubmit={handleSubmit}>
                            {/* <FormControlLabel
                                value={0}
                                control={<Checkbox checked={selectedTeams.length === teams.length} />}
                                label={'All'}
                                onChange={handleCheckboxChange}
                            /> */}

                            {teams.map((team) => (
                                <FormControlLabel
                                    key={team.id}
                                    value={team.id}
                                    control={
                                        <Checkbox
                                            checked={selectedTeams.includes(team.id)}
                                            disabled={team.seasonId !== -1 || team.name === activeTeam} // Disable the checkbox if seasonId is not -1
                                        />
                                    }
                                    label={team.seasonId !== -1 ? `${team.name} - already has season` : team.name}
                                    onChange={handleCheckboxChange}
                                />
                            ))}


                        </FormGroup>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={submitTeamsSessions}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    )
}