// import React, { useState, useEffect, useRef } from 'react';
// import { Draggable } from 'react-beautiful-dnd';
// import { Button } from '@mui/material';
// import ReservePopup from './ReservePopup';
// import { useSelector } from 'react-redux';
// import { teamRequests } from '../../services/api-requests';
// import ImportExportIcon from '@mui/icons-material/ImportExport';

// export function Player(props) {
//   //user data
//   const schoolId = useSelector((state) => parseInt(state.user.profile.school))
//   const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup))

//   //Data Handling
//   const [isReservePopupOpen, setIsReservePopupOpen] = useState(false);
//   const [activePlayerId, setActivePlayerId] = useState(0);
//   const [activePlayerName, setActivePlayerName] = useState('');
//   const [teamsList, setBackendTeams] = useState([]);

//   const handleReserveButtonClicked = async (player) => {
//     await fetchTeams().then(() => {
//       setActivePlayerId(player.id)
//       setActivePlayerName(player.firstName + ' ' + player.lastName)
//       setIsReservePopupOpen(true)
//     });
//   }

//   const fetchTeams = async () => {
//     try {
//       await teamRequests.getTeams(schoolId, ageGroup).then((response) => {
//         setBackendTeams(response.data);
//       });
//     }
//     catch (error) {
//       console.log('error in teams endpoint: ', error);
//     }
//   }

//   return (
//     <div>
//       <Draggable draggableId={String(props.playerData.id)} index={props.index}>
//         {(provided, snapshot) => (
//           <div className="player-container"
//             ref={provided.innerRef}
//             {...provided.draggableProps}
//             {...provided.dragHandleProps}
//             isdragging={snapshot.isDragging.toString()}>
//             {props.playerData.firstName + ' ' + props.playerData.lastName}

//             <Button variant='ghost' className={props.playerData.isReserve ? 'player-reserve-active' : 'player-no-reserve'}
//               onClick={() => handleReserveButtonClicked(props.playerData)}>R</Button>
//           </div>)
//         }
//       </Draggable>

//       <ReservePopup
//         name={activePlayerName}
//         isOpen={isReservePopupOpen}
//         setReserveReportWindowStatus={setIsReservePopupOpen}
//         teams={teamsList}
//         playerId={activePlayerId}>
//       </ReservePopup>
//     </div>
//   );
// }



import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button, TextField, Box } from '@mui/material';
import ReservePopup from './ReservePopup';
import { useSelector } from 'react-redux';
import { teamRequests } from '../../services/api-requests';
import ImportExportIcon from '@mui/icons-material/ImportExport';

export function Player(props) {
  // user data
  const schoolId = useSelector((state) => parseInt(state.user.profile.school));
  const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup));

  // Data Handling
  const [isReservePopupOpen, setIsReservePopupOpen] = useState(false);
  const [activePlayerId, setActivePlayerId] = useState(0);
  const [activePlayerName, setActivePlayerName] = useState('');
  const [teamsList, setBackendTeams] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);

  const handleReserveButtonClicked = async (player) => {
    await fetchTeams().then(() => {
      setActivePlayerId(player.id);
      setActivePlayerName(player.firstName + ' ' + player.lastName);
      setIsReservePopupOpen(true);
    });
  };

  const togglePosition = () => {
    setCurrentPositionIndex((prevIndex) => (prevIndex + 1) % props.playerData.positionList.length);
  };

  const fetchTeams = async () => {
    try {
      await teamRequests.getTeams(schoolId, ageGroup).then((response) => {
        setBackendTeams(response.data);
      });
    } catch (error) {
      console.log('error in teams endpoint: ', error);
    }
  };

  const extractPositionNumber = (position) => {
    const matches = position.match(/\(([^)]+)\)/);
    return matches ? matches[1] : position;
  };

  return (
    <div>
      <Draggable draggableId={String(props.playerData.id)} index={props.index}>
        {(provided, snapshot) => (
          <div
            className="player-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isdragging={snapshot.isDragging.toString()}
          >
            <Box display="flex" alignItems="center">
              <TextField
                variant="standard"
                //  variant="outlined"
                size="small"
                style={{
                  width: '45px',
                  pointerEvents: 'none',
                  marginRight: '-10px',
                  border: 'none', // Hide border
                }}
                value={extractPositionNumber(props.playerData.positionList[currentPositionIndex])}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true, // <== added this
                  style: { border: 'none !important' }, // Hide border
                }}
              />
              <ImportExportIcon fontSize='small' onClick={togglePosition} />
            </Box>

            {props.playerData.firstName + ' ' + props.playerData.lastName}

            <Button
              variant="ghost"
              className={props.playerData.isReserve ? 'player-reserve-active' : 'player-no-reserve'}
              onClick={() => handleReserveButtonClicked(props.playerData)}
            >
              R
            </Button>
          </div>
        )}
      </Draggable>

      <ReservePopup
        name={activePlayerName}
        isOpen={isReservePopupOpen}
        setReserveReportWindowStatus={setIsReservePopupOpen}
        teams={teamsList}
        playerId={activePlayerId}
      ></ReservePopup>
    </div>
  );
}

