import React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Content/title.js';
import { useSelector } from 'react-redux';
import { TodayRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PrintSessions from './printSessions.js';
import { Button } from '@mui/material';
import { Box, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { sessionRequests } from '../../services/api-requests.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../layouts/loader.js';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';


const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function TodaySessions(props) {

  const [sessionToBeDeleted, setSessionToBeDeleted] = React.useState('')
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [activeSessionArray, setActiveSessionArray] = React.useState([]);
  const [updatedSessionsArray, setUpdatedSessionsArray] = React.useState([]);
  const [deleted, setDeleted] = React.useState(false);

  console.log('Props received in Today: ', props)
  console.log('props.allSessions right after normal props: ', props.sessions)
  console.log('props.todaySessions right after normal props: ', props.todaySessions)

  const navigate = useNavigate();

  useEffect(() => {
    if (handleDeletedClicked) {
      handleOpen();
    }
  }, [handleDeletedClicked]);

  useEffect(() => {

  }, [sessionToBeDeleted]);

  useEffect(() => {

    //Lets format the props.sessions so we can always have the same type of array going down to print
    const placeHolderArray = props.sessions.map((session) => {
      const { name, teamSessions: { todaySessions } } = session;

      // Check if todaySessions is not empty
      if (todaySessions && todaySessions.length > 0) {
        // Flatten todaySessions and include name in the object
        const flattenedSessions = todaySessions.map((todaySession) => ({
          name,
          ...todaySession,
        }));

        return flattenedSessions;
      }

      // If todaySessions is empty, return an empty array
      return [];
    }).flat();

    setUpdatedSessionsArray(placeHolderArray)


    props.sessions.length > 0 ? setActiveSessionArray(placeHolderArray) : setActiveSessionArray(props.todaySessions)
  }, [props])

  const todaySessions = useSelector((state) => state.session.todaysSession);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    // Trigger the callback function to refresh team sessions
    props.refreshTeamSessions();
  };

  function EditTodaySessions(event) {
    event.preventDefault();
    navigate('/Sessions/editTodaySessions');
  }

  function handleAddSessionForToday() {
    // setCreateSession(true)
    navigate('/Sessions/addSession');
  }

  const ConfirmationModal = ({ open, onClose }) => {

    const handleNo = () => {
      onClose();
    };

    const handleYes = () => {

      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Session
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {sessionToBeDeleted.category} session @ {sessionToBeDeleted.time} for {sessionToBeDeleted.name}?
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleNo} variant='contained'>No</Button>
              <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
            </div>

          </Box>
        </Modal>
      </div>
    );
  };

  async function handleClearOption(session) {

    setHandleDeletedClicked(true);
    setSessionToBeDeleted(session)
    handleOpen();
  }

  const handleYesClick = async () => {
    setIsLoading(true)

    try {
      // Perform the API call to delete the Session
      const response = await sessionRequests.DeleteSession(sessionToBeDeleted.id);
      console.log('Response from delete: ', response);

      if (response.status === 200) {

        setOpenSnackbar(true);
        setMessage('Session Deleted');
        setIsLoading(false)

      } else {
        setOpenSnackbar(true);
        setMessage('Something went wrong');
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setHandleDeletedClicked(false);
      setIsLoading(false)
    }
  };





  // Check if props.futureSessions is defined and is an array
  const todaySessionsArray = Array.isArray(props.todaySessions) ? props.todaySessions : [];
  console.log('Props.TodaySessions: ', props.todaySessions)
  console.log('TodaySessionsArray in TodaySessions: ', todaySessionsArray)

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button variant="contained" onClick={handleAddSessionForToday}>
          Add Session For Today
        </Button>

        <PrintSessions
          weeklyFocusPoint={props.weeklyFocusPoint}
          type={"Today"}
          sessions={activeSessionArray}
          activeArray={activeSessionArray === props.todaySessions ? 'Team' : 'All'}
        />
      </Box>

      <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>

        <Title>Today session</Title>
        <div style={{ overflowX: 'auto' }}>

          {
            activeSessionArray === props.todaySessions ?
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Team</TableCell>
                    <TableCell>Session Type</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    {Array.from({ length: todaySessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0) }, (_, index) => (
                      <TableCell key={index}>{`Split ${index + 1}`}</TableCell>
                    ))}
                    {/* Add an empty TableCell in the header row that spans the remaining columns */}
                    <TableCell colSpan={todaySessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
                    {/* Additional TableCell for the clear icon */}
                    <TableCell style={{ textAlign: 'right' }}>Option</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {todaySessionsArray.length !== 0 ? (
                    todaySessionsArray.map((session, sessionIndex) => {
                      return (
                        <TableRow key={sessionIndex}>

                          <TableCell>{props.team}</TableCell>
                          <TableCell>{session.category}</TableCell>
                          <TableCell>{session.time}</TableCell>
                          <TableCell>{session.duration}</TableCell>
                          {session.splits.map((splitValue, splitIndex) => (
                            <TableCell key={splitIndex} >
                              {splitValue.type ? `${splitValue.type} - ${splitValue.duration} min` : ''}
                            </TableCell>

                          ))}
                          {/* Add an empty TableCell that spans the remaining columns */}
                          <TableCell colSpan={todaySessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>

                          <TableCell colSpan={todaySessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => handleClearOption(session)} style={{ color: 'red' }}>
                              <ClearIcon />
                            </IconButton>
                          </TableCell>


                        </TableRow>


                      );
                    })
                  ) : (
                    <TableRow>
                      {/* Set colSpan to match the total number of columns */}
                      <TableCell colSpan={3 + todaySessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}>No Sessions</TableCell>
                    </TableRow>
                  )}

                  {confirmationModalOpen && (
                    <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                  )}


                </TableBody>
              </Table>

              :

              // This is for all Sessions
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Team</TableCell>
                    <TableCell>Session Type</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    {Array.from({ length: updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0) }, (_, index) => (
                      <TableCell key={index}>{`Split ${index + 1}`}</TableCell>
                    ))}
                    {/* Add an empty TableCell in the header row that spans the remaining columns */}
                    <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
                    {/* Additional TableCell for the clear icon */}
                    <TableCell style={{ textAlign: 'right' }}>Option</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {updatedSessionsArray.length !== 0 ? (
                    updatedSessionsArray.map((session, sessionIndex) => {
                      return (
                        <TableRow key={sessionIndex}>

                          <TableCell>{session.name}</TableCell>
                          <TableCell>{session.category}</TableCell>
                          <TableCell>{session.time}</TableCell>
                          <TableCell>{session.duration}</TableCell>
                          {session.splits.map((splitValue, splitIndex) => (
                            <TableCell key={splitIndex} >
                              {splitValue.type ? `${splitValue.type} - ${splitValue.duration} min` : ''}
                            </TableCell>

                          ))}
                          {/* Add an empty TableCell that spans the remaining columns */}
                          <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>

                          <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => handleClearOption(session)} style={{ color: 'red' }}>
                              <ClearIcon />
                            </IconButton>
                          </TableCell>


                        </TableRow>


                      );
                    })
                  ) : (
                    <TableRow>
                      {/* Set colSpan to match the total number of columns */}
                      <TableCell colSpan={3 + updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}>No Sessions</TableCell>
                    </TableRow>
                  )}

                  {confirmationModalOpen && (
                    <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                  )}


                </TableBody>
              </Table>
          }

        </div>
        <Link
          color="primary"
          href="#"
          onClick={EditTodaySessions}
          sx={{ mt: 2, display: 'block' }}
        >
          Change session
        </Link>
      </Paper>
      {
        isLoading ?
          <Loader />
          :
          <></>
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={deleted ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>

    </React.Fragment>


  );
}




