import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
// import { team } from './team';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { pushActions, setKicker, setStateManOfTheMatch } from './gamedaySlice';
import { Add, Clear, Remove } from '@mui/icons-material';
import { Divider } from '@mui/material';


export default function ManOfTheMatchModal(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const [conversionSelected, setConversionSelected] = useState(false);
    const [manOfTheMatch, setManOfTheMatch] = useState('');

    const newTeam = props.team


    const handleClose = () => {
        setOpen(false);

    };

    const handeSubmit = () => {

        
        if (manOfTheMatch) {
            // const manOfTheMatchObject = props.team.find((player) => player.id === manOfTheMatch)
            // console.log('manOfTheMatchObject', manOfTheMatchObject)

            props.onManOfTheMatchSelected(manOfTheMatch);

        }
        setOpen(false);
    };

    function handleChange(playerId) {
        setManOfTheMatch(playerId)
        const manOfTheMatchObject = props.team.find((player) => player.id === playerId)
        console.log('Man Of the match Object in modal: ', manOfTheMatchObject)
        dispatch(setStateManOfTheMatch(manOfTheMatchObject));
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Who was your man of the match?</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                        }}
                    >
                        <br />

                        <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel htmlFor="converter">MOTM</InputLabel>
                            <Select
                                autoFocus
                                value={manOfTheMatch}
                                onChange={(event) => { handleChange(event.target.value) }}
                                label="converter"
                                inputProps={{
                                    name: 'converter',
                                    id: 'converter',
                                }}
                            >
                                {/* {
                                    newTeam.map((teamMember, index) => {
                                        if (teamMember.isReserve) {
                                            return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                        }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.firstName} {teamMember.lastName}</MenuItem>
                                    })
                                } */}
                                {
                                    props?.lineUp?.players?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                    })
                                }
                                <Divider />
                                {
                                    props.lineUp?.reservePlayers?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handeSubmit} variant='contained'>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
