import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'
import LogoSelector from '../../services/logoSelector';
import { useEffect } from 'react';
import { useState } from 'react';

function PrintTrainingStats(props) {
    console.log('All props: ', props)
    console.log('StatObject props: ', props.statObject)
    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)
    const availableTeams = useSelector((state) => state.team.availableTeams)

    const teamObject = availableTeams.filter((team) => team.id === props.selectedTeamId)

    const teamIwant = teamObject[0]?.name




    async function printStats() {
        console.log('All props: ', props)
        console.log('StatObject props: ', props.statObject)
        var doc = new jsPDF();

        // Define the position and size of the logo
        const logoX = 10;
        const logoY = 10;
        const logoWidth = 50;
        const logoHeight = 50;

        const helperX = 78;
        const headerX = 73;
        const headerY = logoY + logoHeight + 10;

        const SCLogoWidth = 70;
        const SCLogoHeight = 80;

        const mySchoolLogo = await LogoSelector(schoolName);
        doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);
        doc.text(`${teamIwant}`, helperX + 17, 50);

        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

        doc.setFontSize(15);
        doc.text(`${props.activeStatGroup}`, headerX + 12, 65);

        var pageNumber = doc.internal.getNumberOfPages();
        console.log('Page nr: ', pageNumber);

        let currentX = 22; // Initial X-coordinate for the first column
        let spaceBetweenColumns = 95; // Adjust as needed
        let spaceBetweenRows = 65; // Adjust as needed

        console.log('Stat Object: ', props.statObject)
        let sessionsDone
        if (props.statObject !== undefined) {
            sessionsDone = props.statObject.numberOfSessions

        }


        // Iterate over the stats and render them in 2 columns
        const stats = [
            { title: "Sessions done", metric: sessionsDone, info1: "Sessions completed", info2: "" },
            { title: "Most splits completed", metric: props.statObject.mostCommonSplitType.splitType ? props.statObject.mostCommonSplitType.splitType : props.statObject.mostCommonSplitType.category, info1: props.statObject.mostCommonSplitType?.count ? props.statObject.mostCommonSplitType.count : "No Metric yet", info2: "sessions" },
            // {
            //     title: "Nr of Splits completed", metric: "Select Split: ", info1: "Needs to be selected", info2: `${props.nrOfSplits} session${props.nrOfSplits !== 1 ? 's' : ''}`
            // },
            {
                title: "Nr of Splits completed", metric: props?.nrOfSplits, info1: "", info2: ""
            },
            { title: "Least Splits completed", metric: props.statObject.leastCommonSplitType.splitType ? props.statObject.leastCommonSplitType.splitType : props.statObject.leastCommonSplitType.category, info1: props.statObject.leastCommonSplitType?.count ? props.statObject.leastCommonSplitType.count : "No Metric yet", info2: "sessions" },
            { title: "Minutes Trained", metric: props.statObject.totalMinutes, info1: "minutes in training", info2: "" },
            { title: "Most Category of sessions", metric: props.statObject.topSessionCategory?.category ? props.statObject.topSessionCategory?.category : "", info1: props.statObject ? props.statObject.topSessionCategory?.count : "No Metric yet", info2: "sessions" },
        ];

        let currentY = headerY + 5; // Adjust the starting Y-coordinate as needed

        stats.forEach((stat, index) => {
            // renderStatGrid(doc, stat, currentX, currentY);
            // currentX += spaceBetweenColumns;

            // // Move to the next row after rendering two stats in a row
            // if ((index + 1) % 2 === 0) {
            //     currentX = 22;
            //     currentY += spaceBetweenRows;
            // }

            // Check if the stat is one of the specified ones
            const isSpecialStat = ['Nr of Splits completed', 'Least Splits completed'].includes(stat.title);

            // Check if the stat is one of the specified ones
            const isAfterSpecialStat = ['Minutes Trained', 'Most Category of sessions'].includes(stat.title);

            // Adjust rectHeight for special stats
            const rectHeight = isSpecialStat ? 65 : 60;

            // Add 5 to rectHeight for the specified stats
            renderStatGrid(doc, stat, currentX, currentY, isSpecialStat, isAfterSpecialStat, rectHeight + (isSpecialStat ? 5 : 0));
            currentX += spaceBetweenColumns;

            // Move to the next row after rendering two stats in a row
            if ((index + 1) % 2 === 0) {
                currentX = 22;
                currentY += isSpecialStat ? spaceBetweenRows + 5 : spaceBetweenRows;
            }

        });


        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Copyright © Squad Control`, headerX + 10, doc.internal.pageSize.height - 10);

        const date = new Date();
        doc.save(`Training Stats ${teamIwant} ${date.toDateString()}.pdf`);
    }




    function renderStatGrid(doc, stat, x, y, isSpecialStat, isAfterSpecialStat) {
        const { title, metric, info1, info2 } = stat;

        console.log(title, ' is ', isAfterSpecialStat)

        doc.setDrawColor(0);

        if (typeof x !== 'number' || typeof y !== 'number') {
            console.error('Invalid coordinates:', x, y);
            return;
        }

        if (typeof title !== 'string' || typeof info2 !== 'string') {
            console.error('Invalid text values:', title, info2);
            return;
        }

        const rectWidth = 70;
        const rectHeight = isSpecialStat ? 60 + 10 : 60; // Increased height to accommodate 'sessions'

        // Add a light grey background
        doc.setFillColor(240, 240, 240); // Adjust color as needed
        doc.rect(x, isAfterSpecialStat ? y + 5 : y, rectWidth, rectHeight, 'F');

        // Add a slight elevation effect
        doc.setDrawColor(180, 180, 180); // Adjust color as needed
        doc.setLineWidth(1);
        doc.line(x, isAfterSpecialStat ? y + rectHeight + 5 : y + rectHeight, x + rectWidth, isAfterSpecialStat ? y + rectHeight + 5 : y + rectHeight);

        // Render title in blue
        doc.setFontSize(18);
        doc.setTextColor(100, 149, 237); // Set text color to blue

        //Handle the case for all the splits
        if (title === 'Nr of Splits completed') {
            doc.text(title, x + 4, y + 8)
            doc.setFontSize(8);
            doc.setTextColor(0); // Set text color to black

            console.log('Metric is: ', metric)
            const startingY = y + 13;
            if (metric.length > 0) {
                metric.forEach((split, index) => {
                    doc.text(split.split.toString(), x + 10, startingY + (index * 5));
                    doc.text(`${split.count.toString()} sessions`, x + 38, startingY + (index * 5));
                })
            }

            // Render info1 based on type
            doc.setFontSize(12);
            doc.setTextColor(0); // Set text color to black


            // If info1 is a number, set position to x + 32
            doc.text(info1.toString(), x + 32, y + 30);

            // If info1 is a string, set position based on the title
            doc.text(info1, x + 5, y + 30);

            // Render info2
            doc.text(info2, x + 25, y + 40);
        }
        else {
            doc.text(title === 'Most Category of sessions' ? 'Most Category of' : title,
                title === 'Sessions done' || title === 'Minutes Trained' ? x + 13
                    : title === 'Most Category of sessions' ? x + 8 : x + 3,
                isAfterSpecialStat ? y + 13 : y + 8);
            doc.text(title === 'Most Category of sessions' ? 'sessions' : '', x + 22, isAfterSpecialStat ? y + 20 : y + 15);

            // Render metric based on type
            doc.setFontSize(16);
            doc.setTextColor(0); // Set text color to black

            if (typeof metric === 'number') {
                // If metric is a number, set position to x + 30
                doc.text(metric.toString(), x + 30, isAfterSpecialStat ? y + 25 : y + 20);
            } else if (typeof metric === 'string') {
                // If metric is a string, set position based on the title
                doc.text(metric,
                    title === 'Most Category of sessions' ? x + 25 : metric === 'Speed' || metric === 'Attack' ? x + 25 : x + 21,
                    title === 'Most Category of sessions' ? y + 30 : y + 25
                    );
            }

            // Render info1 based on type
            doc.setFontSize(12);
            doc.setTextColor(0); // Set text color to black

            if (typeof info1 === 'number') {
                // If info1 is a number, set position to x + 32
                doc.text(info1.toString(), x + 32, isAfterSpecialStat ? y + 35 : y + 30);
            } else if (typeof info1 === 'string') {
                // If info1 is a string, set position based on the title
                doc.text(info1, title === 'Most Category of sessions' ? x + 5 : info1 === 'Sessions completed' ? x + 15 : x + 18, isAfterSpecialStat ? y + 35 : y + 35);
            }

            // Render info2
            doc.text(info2, x + 25, isAfterSpecialStat ? y + 45 : y + 40);
        }


    }


    return (

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="contained" onClick={() => { printStats() }} style={{ margin: '5px' }}>
                Print Team Stats
            </Button>


        </div>
    )
}

export default PrintTrainingStats



