import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    error: null,
    userLoggedIn: false,
    profileCreated: false,
    devProfile: false,
    manager: false,
    admin: false,
    schoolName: '',
    profile: {},
    token: '',
    approved: false,
    role: "",
    playersUploaded: false,
}

let response

// Basic Crud functionality
export const fetchSites = createAsyncThunk('site/endpointName', async () => {
    // const response = await client.get('/api/endpointIamLookingFor', null)
    const response = {};
    return response.data
})

export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
    const response = {};
    return response.data
})


export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
    const response = {};
    return extraData.body
})


export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
    const response = {};
    return response.data
})


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.admin = action.payload
        },
        setSchoolName: (state, action) => {
            state.schoolName = action.payload
        },
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setUserToken: (state, action) => {
            state.token = action.payload
        },
        setUserLoggedIn: (state, action) => {
            state.userLoggedIn = action.payload
        },
        setProfileCreated: (state, action) => {
            state.profileCreated = action.payload
        },
        setApproved: (state, action) => {
            state.approved = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
        setDevProfile: (state, action) => {
            state.devProfile = action.payload
        },
        setManager: (state, action) => {
            state.manager = action.payload;
        },
        setPlayersUploaded: (state, action) => {
            state.playersUploaded = true
        },

    }
})

//Selectors come below:
export const { setAdmin, setSchoolName, setPlayersUploaded, setRole, setUserToken, setManager, setDevProfile, setApproved, setProfile, setProfileCreated, setUserLoggedIn,
    decrement, incrementByAmount } = userSlice.actions

export default userSlice.reducer