import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import SquadControlLogo from '../assets/Squad Control Logo New.jpeg'


const NotFoundPage = () => {
    return (
        <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '100px', }}>
            <img
                style={{
                    marginLeft: '37%',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    height: '180px',
                    display: 'block', 
                    marginX: 'auto', 
                }}
                src={SquadControlLogo}
                alt="Squad Control Logo"
            />


            <Typography variant="h2">404</Typography>
            <Typography variant="h5">Page not found</Typography>
            <Typography variant="body1">You may have been logged out.</Typography>
            <Button component={Link} to="/" variant="contained" sx={{ marginTop: '20px' }}>
                Go to Home
            </Button>
        </Container>
    );
};

export default NotFoundPage;
