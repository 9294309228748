import * as React from 'react';
import { useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { team } from './team';
import { pushActions, setOpponent, updateTryScorer } from './gamedaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { Divider } from '@mui/material';

export default function TryScorer(props) {
    const [open, setOpen] = React.useState(true);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [tryScorer, setTryScorer] = React.useState();
    const dispatch = useDispatch();
    const time = useSelector((state) => state.gameday.timer)
    const runningTime = useSelector((state) => state.timer.runningTimer)
    const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)
    const [isSubmitDisabled, setSubmitDisabled] = React.useState(true); // New state


    // console.log('This is team in tryscorer: ', props.team.team)

    let activeHalf
    isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'

    console.log('Props received in TryScorer component: ', props)
    const newTeam = props.team.team
    const startingLineUp = props.team.team.players
    const bench = props.team.team.reservePlayers

    console.log('Starting lineup is: ', startingLineUp)
    console.log('Bench lineup is: ', bench)

    const handleClose = () => {
        if (!tryScorer) {
            alert("Select the try scorer")
        }
        else {
            setOpen(false);
        }
        // Add any additional logic or alerts here if needed
    };

    let timeOfTryScored

    const handleChange = (event) => {
        console.log(event, ' was the event');
        setTryScorer(event.target.value);
        setSubmitDisabled(false); // Enable the "Submit" button when a try scorer is selected
    };


    const handeSubmit = () => {

        if (!tryScorer) {
            alert("Select the try scorer")
        }
        else {
            let playerObjectInStartingLineUp
            let playerObjectInBench
            playerObjectInStartingLineUp = props.team.team.players.find((searchPlayer) => searchPlayer.id === tryScorer)
            playerObjectInBench = props.team.team.reservePlayers.find((searchPlayer) => searchPlayer.id === tryScorer)

            let newAction = ""
            timeOfTryScored = runningTime
            if (playerObjectInStartingLineUp !== undefined) {
                console.log('New Player found in tryscorer submit, playerObjectInStartingLineUp: ', playerObjectInStartingLineUp)
                newAction = `Try Scored by ${playerObjectInStartingLineUp.firstName} ${playerObjectInStartingLineUp.lastName} with ${timeOfTryScored} minutes left in the ${activeHalf}`;
            }
            else {
                console.log('New Player found in tryscorer submit, playerObjectInBench: ', playerObjectInBench)
                newAction = `Try Scored by ${playerObjectInStartingLineUp.firstName} ${playerObjectInStartingLineUp.lastName} with ${timeOfTryScored} minutes left in the ${activeHalf}`;
            }

            //  const playerObject = props.team.team.find((searchPlayer) => searchPlayer.id === tryScorer)


            console.log('Try Scorer: ', tryScorer)


            console.log('Action with time added: ', newAction)
            dispatch(pushActions(newAction))

            ///////
            //Add a dispatch here that will add and update the player stats
            console.log('All Players before dispatch: ', props.team.team)

            console.log('Try Scorer before dispatch: ', tryScorer)
            dispatch(updateTryScorer(tryScorer))

            setOpen(false);


            //Used like this:
            //Dispatch
            // dispatch(addSite({ body: newSiteValues })).unwrap().then((result) => {
            //     dispatch(siteAdded(result))
            // })


            console.log(actions)

            props.tryScorerSubmitted(true)
        }

    }


    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Try Scorer</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel htmlFor="try-scorer">Scored by</InputLabel>
                            <Select
                                autoFocus
                                value={tryScorer}
                                onChange={(event) => setTryScorer(event.target.value)}
                                label="try-scorer"
                                inputProps={{
                                    name: 'try-scorer',
                                    id: 'try-scorer',
                                }}
                            >
                                {
                                    startingLineUp?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                    })
                                }
                                <Divider />
                                {
                                    bench?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handeSubmit} variant='contained'>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
