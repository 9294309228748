import React, { useState } from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import { useTheme } from '@mui/material';
import Stats from './features/stats/stats.js';
import Aside from './components/Aside.js';
import Session from './features/session/session.js';
import Calendar from './features/calendar/calendar.js';
import Settings from './features/settings/settings.js';
// import Dashboard from './features/dashboard/dashboard.js';
import Paperbase from './features/dashboard/Paperbase.js';
import SignInSide from './features/signIn/signIn.js';
import SignUp from './features/signUp/signUp.js';
// import Dashboard from './features/dashboard/index.js';
import Dashboard from './features/dashboard/dashboard.js';
import { useSelector } from 'react-redux'
import NotFoundPage from './layouts/notFoundPage'
import GameDay from './features/gameday/gameday.js';
import AddTodaySession from './features/session/addTodaySession.js';
import AddFutureSession from './features/session/addFutureSession.js';
import EditTodaySesions from './features/session/editTodaySession.js';
import EditFutureSessions from './features/session/editFutureSessions.js';
import Season from './features/season/season.js';
import TrialManager from './layouts/trialManager/trialManager.js';
import TrialPaperbase from './layouts/trialManager/trialPaperBase.js';

import TmSignIn from './layouts/trialManager/tmSignInPage.js';
import TmSetupProfile from './layouts/trialManager/tmSetupProfile.js';
import TmUploadTeams from './layouts/trialManager/tmUploadTeams.js';
import TmGameTime from './layouts/trialManager/GameTime/tmGameTime.js';
import TmReport from './layouts/trialManager/tmReport.js';
import TmUploadTeamsAuto from './layouts/trialManager/tmUploadTeamsAuto.js';
import TmUploadTeamsManual from './layouts/trialManager/tmUploadTeamsManual.js';
import TmGameTimeAuto from './layouts/trialManager/GameTime/tmGameTimeAuto.js';
import TmGameTimeManual from './layouts/trialManager/GameTime/tmGameTimeManual.js';
import RegisterSchool from './features/registerSchool/registerSchool.js';
import AdminDashboard from './layouts/adminDetails/adminDashboard.js';
import Managers from './layouts/adminDetails/managers.js';
import Schools from './layouts/adminDetails/schools.js';
import Coaches from './layouts/adminDetails/coaches.js';
import Players from './layouts/adminDetails/players.js';
import ManageSCTeam from './layouts/adminDetails/manageSCTeam.js';
import DeveloperSettings from './layouts/adminDetails/developerSettings.js';
import EditCoach from './features/settings/editCoach.js';
import EditManager from './layouts/adminDetails/editMangers.js';
import { ManageCoaches } from './features/settings/manageCoaches.js';
import EditPlayer from './layouts/adminDetails/editPlayer.js';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { setAdmin, setProfile } from './features/user/userSlice.js';
import { useDispatch } from 'react-redux';
import Team from './features/team/team.js';
import UpdateSeason from './features/season/updateSeason.js';
// import { setUserLoggedIn } from './features/gameday/gamedaySlice.js';
import Resource from './features/resources/resources.js';
import FileView from './features/resources/files.js';
import TemplateView from './features/resources/templates.js';
import { ApproveCoaches } from './layouts/adminDetails/approveCoaches.js';
import SubscriptionPage from './layouts/subscriptionPage.js';
import UserGuides from './features/resources/userGuides.js';

export const appBarHeight = "4em"

function Layout({ setLocale }) {
    const dispatch = useDispatch();
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    // const userLoggedIn = useSelector((state) => state.gameday.userLoggedIn)
    const token = localStorage.getItem('token')

    let decodedToken
    if (token !== '' && token !== null) {
        decodedToken = jwtDecode(token)
    }
    else {
        decodedToken = ''
    }

    const tmUserLoggedIn = useSelector((state) => state.tmUser.tmUserLoggedIn);

    const subscriptionStatus = useSelector((state) => state.user.profile?.subscriptionStatus);

    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(false);
    const [toggled, setToggled] = useState(false);
    const theme = useTheme()
    // const devProfile = useSelector((state) => state.user.profile.role)
    // const isManager = useSelector((state) => state.user.profile.role)
    const userProfile = useSelector((state) => state.user.profile)
    const profile = useSelector((state) => state.user.profile)
    let role
    profile !== undefined ? role = profile.role : role = ''

    let subscriptionStatusResult

    if (subscriptionStatus !== undefined) {
        subscriptionStatusResult = subscriptionStatus
    } else {
        subscriptionStatusResult = undefined
    }

    // const role = useSelector((state) => state.user.profile.role ? state.user.profile.role : '')



    const isTokenExpired = () => {
        if (!decodedToken || !decodedToken.exp) {
            // If there is no expiration time in the token, consider it expired
            return true;
        }

        // Convert the expiration time to a Date object
        const expirationDate = new Date(decodedToken.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds

        // Compare the expiration date with the current date
        return expirationDate <= new Date();
    };
    let admin
    let manager

    useEffect(() => {
        if (decodedToken) {
            dispatch(setProfile(decodedToken));

            if (isTokenExpired()) {
                setUserLoggedIn(false);
            } else {
                setUserLoggedIn(true);
            }
        } else {
            setUserLoggedIn(false);
        }
        console.log('SubscriptionStatus in useEffect: ', subscriptionStatus)
    }, [token, subscriptionStatus]);

    // Usage


    if (role === 'Admin') {
        dispatch(setAdmin(true))
        admin = true
        console.log('Admin is true')
    }
    else if (role === 'Manager') {
        dispatch(setAdmin(false))
        manager = true;
        console.log('Manager is true')
    }
    else {
        dispatch(setAdmin(false))
        console.log('Manager is true')
    }



    function AuthenticatedRoutes() {
        // ... (copy all the routes that should be displayed when the user is logged in)
        // Example:
        return (
            <>

                <Routes >

                    {/* Dev routes */}
                    <>
                        {
                            admin ?
                                <>
                                    <Route path="/AdminDashboard" element={<AdminDashboard />} />
                                    <Route path="/Managers" element={<Managers />} />
                                    <Route path="/Managers/EditManager/:id" element={<EditManager />} />
                                    <Route path="/Managers/EditCoach/:schoolId/:coachId" element={<EditCoach />} />
                                    <Route path="/Managers/EditPlayer/:schoolId/:playerId" element={<EditPlayer />} />

                                    <Route path="/Schools" element={<Schools />} />
                                    <Route path="/Coaches" element={<Coaches />} />
                                    <Route path="/ApproveCoaches" element={<ApproveCoaches />} />
                                    <Route path="/Players" element={<Players />} />
                                    <Route path="/ManageSCTeam" element={<ManageSCTeam />} />
                                    <Route path="/DeveloperSettings" element={<DeveloperSettings />} />
                                    <Route path="/Resource" element={<Resource />} />
                                    <Route path="/Resource/Files" element={<FileView />} />
                                    <Route path="/Resource/Templates" element={<TemplateView />} />
                                    <Route path="/Resource/UserGuides" element={<UserGuides />} />

                                </>
                                :
                                <>
                                    {/* <Route path="/AdminDashboard" element={<NotFoundPage />} />
                                    <Route path="/Managers" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditManager/:id" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditCoach/:schoolId/:coachId" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditPlayer/:schoolId/:playerId" element={<NotFoundPage />} />

                                    <Route path="/Schools" element={<NotFoundPage />} />
                                    <Route path="/Coaches" element={<NotFoundPage />} />
                                    <Route path="/ApproveCoaches" element={<NotFoundPage />} />
                                    <Route path="/Players" element={<NotFoundPage />} />
                                    <Route path="/ManageSCTeam" element={<NotFoundPage />} />
                                    <Route path="/DeveloperSettings" element={<NotFoundPage />} />
                                    <Route path="/Resource" element={<NotFoundPage />} />
                                    <Route path="/Resource/Files" element={<NotFoundPage />} />
                                    <Route path="/Resource/Templates" element={<NotFoundPage />} /> */}

                                </>
                        }
                    </>

                    <Route path="/" element={<Outlet />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* Teams */}
                    {/* <Route path="/Teams" element={<Team />} /> */}
                    <Route path="/Teams" element={<Team />} />
                    <Route path="/EditPlayer/:schoolId/:playerId" element={<EditPlayer />} />
                    {/* Stats */}
                    <Route path="/Stats" element={<Stats />} />
                    {/* <Route path="/EasterEgg" element={<NotFoundPage />} /> */}


                    {/* Sessions */}
                    <Route path="/Sessions" element={<Session />} />
                    <Route path="/Sessions/addSession" element={<AddTodaySession />} />
                    <Route path="/Sessions/addFutureSession" element={<AddFutureSession />} />
                    <Route path="/Sessions/editTodaySessions" element={<EditTodaySesions />} />
                    <Route path="/Sessions/editFutureSessions" element={<EditFutureSessions />} />

                    {/* Calendar */}
                    <Route path="/Calendar" element={<Calendar />} />

                    {/* Settings */}

                    <>
                        {
                            manager ?
                                <>
                                    <Route path="/Settings" element={<ManageCoaches />} />
                                    <Route path="/Settings/EditProfile/:schoolId/:coachId" element={<EditCoach />} />
                                </>
                                :
                                <>
                                    <Route path="/Settings/:schoolId/:coachId" element={<Settings />} />
                                </>
                        }
                    </>
                    <Route path="/Settings" element={<Settings />} />

                    {/* Game Day */}
                    <Route path="/GameDay" element={<GameDay />} />

                    {/* Season */}
                    <Route path="/season" element={<Season />} />
                    <Route path="/Season/UpdateSeason" element={<UpdateSeason />} />


                    {/* Resources */}
                    <Route path="/Resource" element={<Resource />} />
                    <Route path="/Resource/Files" element={<FileView />} />
                    <Route path="/Resource/Templates" element={<TemplateView />} />
                    <Route path="/Resource/UserGuides" element={<UserGuides />} />

                </Routes>

            </>
        );
    }



    return (
        <>
            <Router>
                <div>
                    {userLoggedIn ? (
                        <>
                            {console.log("subscriptionStatus:", subscriptionStatus)}
                            {subscriptionStatusResult !== 'Disbabled' ? (
                                <Paperbase>
                                    <AuthenticatedRoutes />
                                </Paperbase>
                            ) : (
                                <SubscriptionPage />
                            )}
                        </>
                    ) : (
                        <>
                            <Routes >
                                {/* <Route path="/trialManager" element={<Navigate to="/tmSignIn" />} /> */}
                                <Route path="*" element={<SignInSide />} />
                                <Route path="/" element={<SignInSide />} />
                                {/* <Route path="/tmSignIn" element={<TmSignIn />} /> */}
                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/registerSchool" element={<RegisterSchool />} />
                                <Route path="/paper" element={<NotFoundPage />} />
                                <Route path="/dashboard" element={<NotFoundPage />} />
                                <Route path="/GameDay" element={<NotFoundPage />} />
                                <Route path="/Teams" element={<NotFoundPage />} />
                                <Route path="/Stats" element={<NotFoundPage />} />
                                <Route path="/Sessions" element={<NotFoundPage />} />
                                <Route path="/Sessions/addSession" element={<NotFoundPage />} />
                                <Route path="/Sessions/addFutureSession" element={<NotFoundPage />} />
                                <Route path="/Sessions/editTodaySessions" element={<NotFoundPage />} />
                                <Route path="/Sessions/editFutureSessions" element={<NotFoundPage />} />
                                <Route path="/Calendar" element={<NotFoundPage />} />
                                <Route path="/Settings" element={<NotFoundPage />} />
                                <Route path="/season" element={<NotFoundPage />} />
                                <Route path="/Resource" element={<NotFoundPage />} />
                                <Route path="/Resource/Files" element={<NotFoundPage />} />
                                <Route path="/Resource/Templates" element={<NotFoundPage />} />

                            </Routes>
                        </>
                    )}
                </div>
            </Router>
        </>
    );
}

export default Layout;