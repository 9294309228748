import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'
import LogoSelector from '../../services/logoSelector';
import { useEffect } from 'react';
import { useState } from 'react';

function PrintSeasonStats(props) {
    console.log('All props: ', props)
    console.log('StatObject props: ', props.statObject)
    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)
    const availableTeams = useSelector((state) => state.team.availableTeams)

    const teamObject = availableTeams.filter((team) => team.id === props.selectedTeamId)

    const teamIwant = teamObject[0]?.name


    //For one team

    async function printStats() {
        console.log('All props: ', props)
        console.log('StatObject props: ', props.statObject)
        var doc = new jsPDF();

        // Define the position and size of the logo
        const logoX = 10;
        const logoY = 10;
        const logoWidth = 50;
        const logoHeight = 50;

        const helperX = 78;
        const headerX = 73;
        const headerY = logoY + logoHeight + 10;

        const SCLogoWidth = 70;
        const SCLogoHeight = 80;

        const mySchoolLogo = await LogoSelector(schoolName);
        doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);
        doc.text(`${teamIwant}`, helperX + 17, 50);

        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

        doc.setFontSize(15);
        doc.text(`${props.activeStatGroup}`, headerX + 12, 65);

        var pageNumber = doc.internal.getNumberOfPages();
        console.log('Page nr: ', pageNumber);

        let currentX = 22; // Initial X-coordinate for the first column
        let spaceBetweenColumns = 95; // Adjust as needed
        let spaceBetweenRows = 65; // Adjust as needed

        console.log('Stat Object: ', props.statObject)
        let sessionsDone
        if (props.statObject !== undefined) {
            sessionsDone = props.statObject.numberOfSessions

        }


        // Iterate over the stats and render them in 2 columns
        const stats = [
            {
                title: "Games Won",
                metric: props.statObject.gamesWon ? props.statObject.gamesWon : 0,
                info1: "Games won ",
                info2: "100% win rate",
            },
            {
                title: "Games lost",
                metric: props.statObject.gamesLost ? props.statObject.gamesLost : 0,
                info1: "Games lost",
                info2: "",
            },
            {
                title: "Games Drawn",
                metric: props.statObject.gamesDrawn ? props.statObject.gamesDrawn : 0,
                info1: "Games Drawn",
                info2: "",
            },
            {
                title: "Games Played",
                metric: props.statObject.gamesPlayed ? props.statObject.gamesPlayed : 0,
                info1: "games played",
                info2: "",
            },
            {
                title: "Total Injuries sustained",
                metric: props.statObject.injuries ? props.statObject.injuries : 0,
                info1: "injuries in 3 games",
                info2: "",
            },
            {
                title: "Clean sheets",
                metric: props.statObject.cleanSheets ? props.statObject.cleanSheets : 0,
                info1: "Games won without conceding a point",
                info2: "",
            }]

        let currentY = headerY + 5; // Adjust the starting Y-coordinate as needed

        stats.forEach((stat, index) => {
            renderStatGrid(doc, stat, currentX, currentY);
            currentX += spaceBetweenColumns;

            // Move to the next row after rendering two stats in a row
            if ((index + 1) % 2 === 0) {
                currentX = 22;
                currentY += spaceBetweenRows;
            }
        });


        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Copyright © Squad Control`, headerX + 10, doc.internal.pageSize.height - 10);

        const date = new Date();
        doc.save(`Season Stats ${teamIwant} ${date.toDateString()}.pdf`);
    }

    //With Styling
    function renderStatGrid(doc, stat, x, y) {
        const { title, metric, info1, info2 } = stat;

        doc.setDrawColor(0);

        if (typeof x !== 'number' || typeof y !== 'number') {
            console.error('Invalid coordinates:', x, y);
            return;
        }

        if (typeof title !== 'string' || typeof info2 !== 'string') {
            console.error('Invalid text values:', title, info2);
            return;
        }

        const rectWidth = 70;
        const rectHeight = 60; // Increased height to accommodate 'sessions'

        // Add a light grey background
        doc.setFillColor(240, 240, 240); // Adjust color as needed
        doc.rect(x, y, rectWidth, rectHeight, 'F');

        // Add a slight elevation effect
        doc.setDrawColor(180, 180, 180); // Adjust color as needed
        doc.setLineWidth(1);
        doc.line(x, y + rectHeight, x + rectWidth, y + rectHeight);

        // Render title in blue
        doc.setFontSize(18);
        doc.setTextColor(100, 149, 237); // Set text color to blue

        //Handle the case for all the splits
        if (title === 'Total Injuries sustained') {
            doc.text(title, x + 2, y + 8)

            doc.setFontSize(18);
            doc.setTextColor(0); // Set text color to black
            doc.text(metric.toString(), x + 31, y + 20);
            // Render info1 based on type

            doc.setFontSize(14);
            doc.setTextColor(0); // Set text color to black
            // If info1 is a string, set position based on the title
            doc.text(info1, x + 15, y + 30);

            // Render info2
            doc.text(info2, x + 20, y + 40);
        }
        else {
            doc.text(title, x + 15, y + 8);
            // doc.text(title === 'Most Category of sessions' ? 'sessions' : '', x + 22, y + 15);

            // Render metric based on type
            doc.setFontSize(16);
            doc.setTextColor(0); // Set text color to black

            if (typeof metric === 'number') {
                // If metric is a number, set position to x + 30
                doc.text(metric.toString(), x + 30, y + 20);
            } else if (typeof metric === 'string') {
                // If metric is a string, set position based on the title
                doc.text(metric, x + 27 ,  y + 20);
            }

            // Render info1 based on type
            doc.setFontSize(12);
            doc.setTextColor(0); // Set text color to black

            if (typeof info1 === 'number') {
                doc.text(info1.toString(), x + 32, y + 30);
            } else if (typeof info1 === 'string') {
                if (info1 === 'Games won without conceding a point') {
                    doc.text("Games won without", x + 18, y + 30);
                    doc.text("conceding a point", x + 18, y + 37);
                }
                else {
                    doc.text(info1, x + 22, y + 30);
                }

            }


            // Render info2
            doc.text(info2, x + 21, y + 40);
        }

    }

    return (

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="contained" onClick={() => { printStats() }} style={{ margin: '5px' }}>
                Print Team Stats
            </Button>
        </div>
    )
}

export default PrintSeasonStats



