import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg';
import Copyright from '../../layouts/copyRight';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProfile } from '../user/userSlice';

const theme = createTheme();

const Approval = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    function handleGoback() {
        localStorage.setItem('token', '')
        dispatch(setProfile(''))
        navigate('/')
    }

    return (
        <Grid container sx={{ height: '100vh', marginTop: '50px' }}>
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} lg={12}>
                <Box
                    sx={{
                        my: 4,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <img style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />

                    <Typography variant="h3" component="h1" mb={2}>
                        Welcome to Squad Control
                    </Typography>
                    <Typography variant="h5" color="textSecondary" mb={4}>
                        Thank you for signing up. You are currently awaiting approval from your school manager.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        You will gain access to Squad Control as soon as your manager approves your sign-up.
                    </Typography>
                    <Typography variant="body1" mb={4}>
                        Please contact your school manager for further assistance.
                    </Typography>
                    <Button onClick={() => handleGoback()}>Go Back</Button>
                </Box>
                <Box mt={4}>
                    <Copyright />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Approval;

