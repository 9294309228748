import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { setProfile } from '../user/userSlice';
import ArticleIcon from '@mui/icons-material/Article';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SchoolIcon from '@mui/icons-material/School';
import SportsIcon from '@mui/icons-material/Sports';
import CheckIcon from '@mui/icons-material/Check';
import Diversity2Icon from '@mui/icons-material/Diversity2';

const devCategories = [
  {
    children: [
      { id: 'Admin Dashboard', name: 'AdminDashboard', icon: <DashboardIcon />, active: true, },
      { id: 'School Managers', name: 'Managers', icon: <AssignmentIndIcon />, active: false, },
      { id: 'Schools', icon: <SchoolIcon />, active: false, },
      { id: 'Coaches', icon: <SportsIcon />, active: false, },
      { id: 'Approve Coaches',name: 'ApproveCoaches', icon: <CheckIcon />, active: false, },
      { id: 'Players', icon: <PeopleIcon />, active: false, },
      { id: 'Manage SC Team', name: 'ManageSCTeam', icon: <Diversity2Icon />, active: false, },
      {id: 'Resource', icon: <ArticleIcon />, active: false},
      { id: 'Developer Settings', name: 'DeveloperSettings', icon: <SettingsIcon />, active: false, },
      { id: 'Log Out', name: 'LogOut', icon: <LogoutIcon />, active: false }
    ],
  },
];

const categories = [
  {

    children: [
      {
        id: 'Dashboard',
        icon: <DashboardIcon />,
        active: true,
      },
      { id: 'GameDay', name: 'GameDay', icon: <SportsRugbyIcon />, active: false, },
      { id: 'Teams', icon: <PeopleIcon />, active: false, },
      { id: 'Stats', icon: <QueryStatsIcon />, active: false, },
      { id: 'Calendar', icon: <CalendarMonthIcon />, active: false, },
      {
        id: 'Sessions', icon: <DirectionsRunIcon />, active: false,
      },
      {
        id: 'Season', icon: <EmojiEventsIcon />, active: false,
      },
    ],
  },
  {
    children: [
      {id: 'Resource', icon: <ArticleIcon />, active: false},
      { id: 'Settings', icon: <SettingsIcon />, active: false, },
      { id: 'Log Out', name: 'LogOut', icon: <LogoutIcon />, active: false }
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};


export default function Navigator(props) {
  const dispatch = useDispatch()
  const devProfile = useSelector((state) => state.user.devProfile)
  const schoolId = useSelector((state) => state.user.profile.school)
  const userId = useSelector((state) => state.user.profile.id)
  const role = useSelector((state) => state.user.profile.role)
  let admin
  role === 'Admin' ? admin = true : admin = false;

  const navigate = useNavigate()
  const { ...other } = props;

  function handleSubmit(childId, children) {

    sessionStorage.setItem('activePage', childId);
    window.dispatchEvent(new Event("storage"));
    // navigate(`/${childId}`)

    const foundChild = children.filter((child) => { return child.id === childId })

    const notFoundChild = children.filter((child) => { return child.id !== childId })
    notFoundChild.forEach(child => { child.active = false; });
    foundChild[0].active = true;
    const navigateToName = foundChild[0].name

    if (navigateToName === 'LogOut') {
      localStorage.setItem('token', '')
      dispatch(setProfile())
      navigate('')
    }

    else if (navigateToName === 'Settings' && role !== "Manager") {


    }
    else if (navigateToName !== undefined) {

      navigate(`/${navigateToName}`)
    }
    else {
      if (childId === 'Settings' && role !== "Manager") {
        navigate(`/Settings/${schoolId}/${userId}`)
      }
      else {
        navigate(`/${childId}`)
      }

    }
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Squad Control
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemText>Simplify. Organize. Succeed</ListItemText>
        </ListItem>
        {
          admin ?
            // If a developer, use dev UI
            <div>
              {devCategories.map(({ id, children }) => {
                console.log('ID in navigator: ', id)
                return (
                  <Box key={id} sx={{ bgcolor: '#101F33' }}>
                    <ListItem sx={{ py: 2, px: 3 }} >
                      <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                    </ListItem>
                    {children.map(({ id: childId, icon, active }) => (
                      <ListItem disablePadding key={childId} >
                        <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{childId === 'GameDay' ? 'Game Day' : childId}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <Divider sx={{ mt: 2 }} />
                  </Box>
                )
              })}
            </div>
            :
            // If not a developer, use normal UI
            <div>
              {categories.map(({ id, children }) => {

                console.log('children in navigator: ', children)
                console.log('key in navigator: ', children)
                return (


                  <Box key={children} sx={{ bgcolor: '#101F33' }}>
                    <ListItem key={children} sx={{ py: 2, px: 3 }} >
                      <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                    </ListItem>
                    {children.map(({ id: childId, icon, active }) => (
                      <ListItem disablePadding key={childId} >
                        <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{childId === 'GameDay' ? 'Game Day' : childId}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <Divider sx={{ mt: 2 }} />
                  </Box>
                )
              })}
            </div>
        }

      </List>
    </Drawer>
  );
}