import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';

const buttonVariants = {
    default: 'bg-gray-900 text-white hover:bg-gray-800',
    destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
    outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
    secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
    ghost: 'hover:bg-gray-100',
    link: 'text-primary underline-offset-4 hover:underline',
};

const sizeVariants = {
    default: 'h-10 px-4 py-2',
    sm: 'h-9 rounded-md px-3',
    lg: 'h-11 rounded-md px-8',
    icon: 'h-10 w-10',
};

const MyButton = React.forwardRef((props, ref) => {
    const { className, variant = 'default', size = 'default', asChild = false, ...restProps } = props;
    const Comp = asChild ? Slot : 'button';

    return (
        <Comp
            className={clsx(
                'flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                buttonVariants[variant],
                sizeVariants[size],
                className
            )}
            ref={ref}
            {...restProps}
        />
    );
});

MyButton.displayName = 'MyButton';

export { MyButton, buttonVariants };
