import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from "../dashboard/Content/title";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import waterkloofLogo from '../../assets/waterkloof logo.png';
import kloof from '../../assets/kloof.jpeg';
import monnasLogo from '../../assets/Monnas Logo.jpeg';
import affiesLogo from '../../assets/Affies_wapen.jpg';
import egJansenLogo from '../../assets/EGJansenLogo.png';
import menloLogo from '../../assets/menlo.jpeg'
import maraisviljoenlogo from '../../assets/maraisviljoen.jpeg'
import { useNavigate } from 'react-router-dom';
import LogoSelector from '../../services/logoSelector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'
import mySchoolLogo from '../../assets/EGJansenLogo.png'

function NextGame({ title, metric, date, venue, type }) {
    const [schoolImage, setSchoolImage] = React.useState(null);
    const [opponentImage, setOpponentImage] = React.useState(null);

    const schoolName = useSelector((state) => state.user.schoolName);
    // console.log('SchoolName: ', schoolName)

    useEffect(() => {
        getImage();
        getOpponentImage(metric);
    }, [title, metric]);


    const getImage = async () => {
        const image = await LogoSelector(schoolName);
        // console.log('image received: ', image)
        setSchoolImage(image);
    };

    const getOpponentImage = async (opponent) => {
        const image = await LogoSelector(opponent);
        setOpponentImage(image);
    };


    const locationOfT = date.indexOf('T')
    // console.log('Location of T in Date: ', locationOfT)
    const newDate = date.slice(0, locationOfT)
    // console.log('NewDate: ', newDate)

    const navigate = useNavigate()

    function UpdateSeason(event) {
        event.preventDefault();
        navigate('/Season/UpdateSeason')
    }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <img src={metric === 'No Opponent' ? '' : schoolImage} style={{ height: '80%', width: '80%', maxWidth: '100%', alignItems: "flex-start", marginLeft: '15px' }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <React.Fragment>
                            <Title sx={{ textDecoration: 'underline' }} variant="h4">{title}</Title>

                            <Typography component="p" variant="h5">
                                {metric}
                            </Typography>

                            <Typography color="text.secondary" sx={{ flex: 1, marginTop: 2 }}>
                                Date: <br />
                                {locationOfT > -1 ? newDate : date}
                            </Typography>

                            <Typography color="text.secondary" sx={{ flex: 1, marginTop: 4 }}>
                                Venue: <br />
                                {venue}
                            </Typography>

                            <Typography color="text.secondary" sx={{ flex: 1 }}>
                                {type}
                            </Typography>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <img src={metric === 'EG Jansen' ? egJansenLogo : affiesLogo || metric === 'No Opponent' ? '' : affiesLogo} style={{ height: '80%', width: 'auto', maxWidth: '100%', alignItems: "flex-start" }} /> */}
                        <img src={opponentImage || SquadControlLogoWhite} style={{ height: '80%', width: '80%', maxWidth: '100%', alignItems: "flex-start" }} />

                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item>
                        <div>
                            <Link color="primary" href="#" onClick={UpdateSeason}>
                                Change Details
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default NextGame;





//With Opponent, metric and all the rest next to each other like in the designs
// import * as React from 'react';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
// import Title from "../dashboard/Content/title";
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';

// function NextGame({ title, metric, date, venue, type }) {
//     function preventDefault(event) {
//         event.preventDefault();
//     }

//     return (
//         <Grid item xs={12} md={12} lg={12}>
//             <Paper
//                 sx={{
//                     p: 2,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: 240,
//                 }}
//             >
//                 <React.Fragment>
//                     <Title sx={{ textDecoration: 'underline', display: 'flex', flexDirection: 'row' }} variant="h4">
//                         {title}
//                     </Title>
//                     <Grid container alignItems="center">
//                         <Typography component="p" variant="h5" sx={{ flex: 1 }}>
//                             {metric}
//                         </Typography>

//                         <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center' }}>
//                             {date}
//                         </Typography>

//                         <Typography color="text.secondary" variant="h6" sx={{ flex: 1, textAlign: 'right',  }}>
//                             {venue}
//                         </Typography>
//                     </Grid>

//                     <Typography color="text.secondary" sx={{ flex: 1, marginTop: 5 }}>
//                         {type}
//                     </Typography>
//                     <div>
//                         <Link color="primary" href="#" onClick={preventDefault}>
//                             Change Details
//                         </Link>
//                     </div>
//                 </React.Fragment>
//             </Paper>
//         </Grid>
//     )
// }

// export default NextGame
