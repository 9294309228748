import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

const initialState = {
    files: [],
}

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setFiles: (state, action) => {
            state.files = action.payload
        },

    }
})

//Selectors come below:
export const { setFiles, } = resourcesSlice.actions

export default resourcesSlice.reducer