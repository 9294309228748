import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../../features/dashboard/Content/chart.js'
import Deposits from '../../features/dashboard/Content/deposit.js'
import Orders from '../../features/dashboard/Content/Order.js';
import StatGrid from '../../features/stats/statGrid'
import { useDispatch, useSelector } from 'react-redux';
import FutureSessions from '../../features/session/FutureSessions.js';
import NextGame from '../../features/season/nextGame.js';


const drawerWidth = 240;


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AdminDashboard() {
  const futureSessions = useSelector((state) => state.session.sessions);
  const seasonGames = useSelector((state) => state.season.games)
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        {/* <AppBar position="absolute" open={open}>
        </AppBar> */}
        <Box
          component="main"
          sx={{
            backgroundColor: {
              // height: '100vh',
              overflow: 'auto',
            },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>

            <Grid container spacing={3}>
              <StatGrid size="3" category="Calendar" title="Next Activity" metric="Meeting" info1="28 August 2023" info2="Forward meeting" />
              <StatGrid size="3" category="Season" title="Game progress timeline" metric="3/12" info1="Games played" info2=" 25% complete" />

              {/* Chart */}
              <Grid item xs={12} md={8} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              {/* <Grid item xs={12} md={4} lg={3}> */}

              {/* </Grid> */}
              {/* Recent Orders */}
              <Grid item xs={12}>
                {/* <FutureSessions futureSessions={futureSessions} type='dashboard' /> */}
                <br />

                <NextGame title="Next Opponent: "
                  metric={seasonGames.opponent !== undefined ? seasonGames.opponent : 'No Opponent'}
                  date={seasonGames.date !== undefined ? seasonGames.date : 'No date'}
                  venue={seasonGames.venue !== undefined ? seasonGames.venue : 'No Venue'}
                  type={seasonGames.type !== undefined ? seasonGames.type : 'No Type'} />

              </Grid>
            </Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}