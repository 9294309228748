import * as React from 'react';
import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './Navigator';
import Users from './Users';
import Header from './Header';
import Dashboard from './dashboard'
import Team from '../team/team';
import Session from '../session/session';
import Settings from '../settings/settings'
import Calendar from '../calendar/calendar'
import Stats from '../stats/stats'
import AddTeam from '../team/addteam'
import theme from './theme';
import GameDay from '../gameday/gameday';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import Dashboard from './features/dashboard/dashboard.js';
import SignInSide from '../signIn/signIn';
import SignUp from '../signUp/signUp';
// import Dashboard from './features/dashboard/index.js';

import NotFoundPage from '../../layouts/notFoundPage';
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom';
import CreateCoachingProfile from '../../layouts/createCoachingProfile';
import Approval from '../userApproval/approval';
import AdminDashboard from '../../layouts/adminDetails/adminDashboard';
import Copyright from '../../layouts/copyRight';



const drawerWidth = 256;

export default function Paperbase({ children }) {
  // const approved = useSelector((state) => state.user.approved)
  const profile = useSelector((state) => state.user.profile)
  let approved
  profile !== undefined ? approved = profile.approved : approved = ''
  console.log('Approved is: ', approved)
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    console.log('Approved in useEffect is: ', approved)
  }, [approved])


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      {
        approved === 'True' ?

          <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
              <CssBaseline />
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                {isSmUp ? null : (
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                )}

                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  sx={{ display: { sm: 'block', xs: 'none' } }}
                />
              </Box>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Header onDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>

                  {children}
                </Box>
                <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
                  <Copyright />
                </Box>
              </Box>
            </Box>
          </ThemeProvider>

          :

          <>


            <Approval />
          </>
      }

    </div>

    // <div>


    //   <ThemeProvider theme={theme}>
    //     <Box sx={{ display: 'flex', minHeight: '100vh' }}>
    //       <CssBaseline />
    //       <Box
    //         component="nav"
    //         sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    //       >
    //         {isSmUp ? null : (
    //           <Navigator
    //             PaperProps={{ style: { width: drawerWidth } }}
    //             variant="temporary"
    //             open={mobileOpen}
    //             onClose={handleDrawerToggle}
    //           />
    //         )}

    //         <Navigator
    //           PaperProps={{ style: { width: drawerWidth } }}
    //           sx={{ display: { sm: 'block', xs: 'none' } }}
    //         />
    //       </Box>
    //       <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
    //         <Header onDrawerToggle={handleDrawerToggle} />
    //         <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>

    //           {children}
    //         </Box>
    //         <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
    //           <Copyright />
    //         </Box>
    //       </Box>
    //     </Box>
    //   </ThemeProvider>




    // </div>


  );
}