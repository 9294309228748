import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';


import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'


import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const ManageSCTeam = () => {

    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [deleted, setDeleted] = React.useState(false);
    const [approved, setApproved] = React.useState(false);

    function createData(name, surname, ageGroup, coachingRole, coachingLevel) {
        return { name, surname, ageGroup, coachingRole, coachingLevel };
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    function handleApprove() {

        setOpenSnackbar(true);
        setMessage('Coach Approved');
        setApproved(true);
    }

    function handleDeleted() {

        setOpenSnackbar(true);
        setMessage('Coach Deleted');
        setDeleted(true);
    }

    function handleEdit() {
        console.log('Handle Edit clicked')
        navigate(`/Settings/EditProfile`)
    }

    const approvedCoaches = [
        createData('Franco', 'Enslin', 'francoenslin@egjansen.com', 'Owner', 'Admin'),
        createData('Lukas', 'Holtzhauzen', 'lukas@klofies.co.za', 'Marketing', "Read"),
        createData('Dirk', 'Odendaal', 'dirk@menlo.co.za', 'Development', 'Dev'),
        createData('SJ', 'Naude', 'sj@klofies.co.za', 'Sats', "Query"),
        createData('Stefan', 'Coetzer', 'stefan@affies.co.za', 'Sales', "Read"),
    ];



    return (
        <>

            <TableContainer component={Paper}>
                <h3>Squad Control Team</h3>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Surname</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Department</TableCell>
                            <TableCell align="right">Roles</TableCell>
                            <TableCell align="center">Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {approvedCoaches.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.surname}</TableCell>
                                <TableCell align="right">{row.ageGroup}</TableCell>
                                <TableCell align="right">{row.coachingRole}</TableCell>
                                <TableCell align="right">{row.coachingLevel}</TableCell>
                                <TableCell align='center'>
                                    <Button onClick={() => { handleEdit() }}>Edit</Button>
                                    <Button color='error' onClick={() => handleDeleted()}>Delete</Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </>
    );

}

export default ManageSCTeam