
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';
import { SetupGames } from './setupGames';
import { InSeasonComponent } from './inSeason';
import { addSeason, setStateNrOfGames, setStateOpenSetupGames } from './seasonSlice';
import { setStateSeasonStarted } from './seasonSlice';
import { setTotalGames } from './seasonSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { seasonRequests } from '../../services/api-requests';
import Loader from '../../layouts/loader';

export const CreateSeason = (props) => {
    console.log('props received: ', props.seasonTypes)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [time, setTime] = useState('');
    const [age, setAge] = useState('');
    const [duration, setDuration] = useState('');
    const [stateSessionSplit, setStateSessionSplit] = useState(1);
    const [seasonType, setSeasonType] = useState('');
    const [createSession, setCreateSession] = useState(false);
    const [excludeWarmup, setExcludeWarmup] = useState(false)
    const [seasonStarted, setSeasonStarted] = useState(false);
    const [date, setDate] = useState();
    const [endDate, setEndDate] = useState();
    const [nrOfGames, setNrOfGames] = useState(0);
    const [openSetupGames, setOpenSetupGames] = useState(false);
    const [opponent, setOpponent] = useState('')
    const [venue, setVenue] = useState('')
    // const [typeOfGame, setTypeOfGame] = useState('')
    const [gameFields, setGameFields] = useState([]);
    const [typeOfGame, setTypeOfGame] = useState('');
    const [activeComponent, setActiveComponent] = useState('')

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [typeError, setTypeError] = React.useState(false);
    const [startDateError, setstartDateError] = React.useState(false);
    const [endDateError, setendDateError] = React.useState(false);
    const [nrOfGamesErrors, setnrOfGamesErrors] = React.useState(false);
    const stateSeasonStarted = useSelector((state) => state.season.seasonStarted)
    const activeSeason = useSelector((state) => state.season.activeSeason)

    let seasonObj
    useEffect(() => {
        console.log('Open Setup games: ', openSetupGames)

        seasonObj = {
            seasonType: seasonType,
            seasonStartDate: date,
            seasonEndDate: endDate,
            totalGames: nrOfGames,
            games: [],
        }
    }, [seasonType, date, endDate, nrOfGames, openSetupGames])


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    function handleSubmit(event) {
        event.preventDefault()

        if (!seasonType) {
            setTypeError(true);
        }
        if (!date) {
            setstartDateError(true);
        }
        if (!endDate) {
            setendDateError(true);
        }
        // if (!nrOfGamesErrors) {
        //     setnrOfGamesErrors(true);
        // }

        //|| !nrOfGamesErrors
        if (!seasonType || !date || !endDate) {
            setErrorMessage('Please complete all fields');
            setOpenSnackbar(true);
            return;
        }

        if (nrOfGames > 0) {
            setOpenSetupGames(true)
            dispatch(setStateNrOfGames(nrOfGames))
            console.log('OpenSetup games is: ', openSetupGames)
        }
        // dispatch(setTotalGames(nrOfGames))
        dispatch(setStateOpenSetupGames(true))
        // dispatch(setStateSeasonStarted(true))
        console.log('Season Object right before dispatch: ', seasonObj)
        dispatch(addSeason(seasonObj))
    }

    return (

        <div className='App' style={{ width: '20', marginTop: '-20px' }} variant="outlined">


            <form onSubmit={(customEvent) => handleSubmit(customEvent)} style={{ width: '20', alignContent: 'center' }}>
                <Title>Create Season</Title>
                <br />
                <p>Type of Season</p>

                <Select
                    id="seasonType"
                    value={seasonType}
                    placeholder={seasonType}
                    onChange={(event) => {
                        setSeasonType(event.target.value)
                        setTypeError(false); // Reset error state
                    }}
                    sx={{ width: '300px' }}
                    error={typeError} // Add error prop
                    helperText={typeError ? 'Please select a session type' : ''}
                >
                    {
                        props.seasonTypes.length > 0 ? props.seasonTypes.map((seasonType) => {
                            return (
                                <MenuItem key={seasonType.id} value={seasonType.id}>{seasonType.type}</MenuItem>
                            )
                        }
                        )
                            :
                            <MenuItem key={seasonType.id} value={seasonType.id}>{seasonType.type}</MenuItem>
                    }
                </Select>
                <br />
                <br />

                <p>Start Date</p>

                <TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={date} // Use your state variable here, e.g., value={date}
                    onChange={(event) => {
                        setDate(event.target.value)
                        setstartDateError(false); // Reset error state
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ width: '300px', marginBottom: '15px' }}
                    error={startDateError} // Add error prop
                    helperText={startDateError ? 'Please select the start date' : ''}
                />

                <br />
                <br />

                <p>End Date</p>

                <TextField
                    id="endDate"
                    label="Date"
                    type="date"
                    value={endDate} // Use your state variable here, e.g., value={date}
                    onChange={(event) => {
                        setEndDate(event.target.value)
                        setendDateError(false); // Reset error state
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ width: '300px', marginBottom: '15px' }}
                    error={endDateError} // Add error prop
                    helperText={endDateError ? 'Please select an end date' : ''}
                />

                <br />
                <br />

                <p>Amount of Games</p>

                <TextField
                    id='nrOfGames'
                    type="number"
                    value={nrOfGames}
                    sx={{ width: '300px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    onChange={(event) => {
                        setNrOfGames(event.target.value)
                        setnrOfGamesErrors(false); // Reset error state
                    }}
                    error={nrOfGamesErrors} // Add error prop
                    helperText={nrOfGamesErrors ? 'Please select the number of games' : ''}
                />

                <br />
                <br />
                <Button variant="contained" type='submit'>Start!</Button>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity="error"
                >
                    {errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>

    )
}

