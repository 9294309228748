import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
  availableTeams: [],
  sites: [],
  status: 'idle',
  error: null,
  players: [],
  activeTeamTab: "Selection",
}

let response

// Basic Crud functionality
export const fetchSites = createAsyncThunk('site/endpointName', async () => {
  // const response = await client.get('/api/endpointIamLookingFor', null)
  //Delete the below response intizialision
  const response = {};
  return response.data
})

export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
  //Delete the below response intizialision
  const response = {};
  return response.data
})


export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
  //Delete the below response intizialision
  const response = {};

  return extraData.body
})


export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
  //Delete the below response intizialision
  const response = {};
  return response.data
})



export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setActiveTeamTab: (state, action) => {
      state.activeTeamTab = action.payload
    },
    setAvailableTeams: (state, action) => {
      state.availableTeams = action.payload

    },
    setStatePlayers: (state, action) => {
      state.players = action.payload
      console.log("Payload received: ", action.payload)
      console.log('Players after upload: ', state.players)
    },
    increment: state => {
      state.value += 1
    },
    decrement: state => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    }
  }
})

//Selectors come below:
export const { setActiveTeamTab, setAvailableTeams, setStatePlayers, increment, decrement, incrementByAmount } = teamSlice.actions

export default teamSlice.reducer