import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';

import { setStateSeasonStarted } from './seasonSlice';
import Loader from '../../layouts/loader';
import { seasonRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import TeamSeasonModal from './teamSeasonModal';
import PrintSeasons from './printSeasons';



const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export const InSeasonComponent = (props) => {
    console.log("Season prop received: ", props.season);
    const defaultTheme = createTheme();
    const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
    const [seasonToBeDeleted, setSeasonToBeDeleted] = React.useState('')
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [seasonForTeam, setSeasonForTeam] = useState([]);
    const [isTeamSeasonModal, setIsTeamSeasonModal] = useState(false);
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const stateTeams = useSelector((state) => state.team.availableTeams)


    //Get the active season
    const activeTeam = useSelector((state) => state.season.activeSeason);

    //Get all the season Data
    const [isLoading, setIsLoading] = useState(false);
    const [seasonLoading, setSeasonLoading] = useState(true);
    const [season, setSeason] = useState('');
    console.log("ActiveSeason ", activeTeam);


    useEffect(() => {

        props.season === undefined || props.season === '' || props.season === null
            ? setSeasonLoading(true)
            : setSeasonLoading(false)

    }, [props])


    const ConfirmationModal = ({ open, onClose }) => {

        const handleNo = () => {
            onClose();
        };

        const handleYes = () => {

            handleYesClick();
            onClose();
        };

        return (
            <div>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Season
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete {props.season.type} for {activeTeam}?
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onClick={handleNo} variant='contained'>No</Button>
                            <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
                        </div>

                    </Box>
                </Modal>
            </div>
        );
    };

    async function handleClearOption() {

        setHandleDeletedClicked(true);
        setSeasonToBeDeleted(props.season)
        handleOpen();
    }

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            //trigger callback
            props.refreshSeason()
            return;
        }
        //trigger callback
        props.refreshSeason()
        setOpenConfirmationSnackbar(false);
    };


    async function onTeamsSelected(teams) {
        console.log('Teams received from modal: ', teams)
        const myTeam = stateTeams.find((team) => team.name === activeTeam).id
        const duplicateObject = {
            teamToDuplicateId: myTeam,
            teamToDuplicateToId: teams[0]
        }

        setSeasonForTeam(teams)
        //Most probably going to duplicate here:
        if (teams.length > 0) {
            try {
                const response = await seasonRequests.DuplicateSeason(duplicateObject)
                console.log('Response from duplicate IN SEASON: ', response)
                if (response.status === 200) {
                    console.log('Response from Duplicate Season IN SEASON: ', response.data)
                    setError(false)
                    setConfirmationMessage('Season has been Duplicated successfully')
                    setOpenConfirmationSnackbar(true)
                }
                else {
                    setError(true)
                    setConfirmationMessage('Could not duplicate season')
                    setOpenConfirmationSnackbar(true)
                }
            } catch (error) {
                console.log('Error in duplicate: ', error)
                setError(true)
                setConfirmationMessage('Could not duplicate season')
                setOpenConfirmationSnackbar(true)
            }
            finally {

            }
        }
    }

    const handleYesClick = async () => {
        setIsLoading(true)

        console.log('Going to be deleting: ', seasonToBeDeleted.id)

        try {
            // Perform the API call to delete the Season
            const response = await seasonRequests.deleteSeason(seasonToBeDeleted.id);
            console.log('Response from delete: ', response);

            if (response.status === 200) {

                setOpenSnackbar(true);
                setMessage('Season Deleted');
                setIsLoading(false)

            } else {
                setOpenSnackbar(true);
                setMessage('Something went wrong');
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setHandleDeletedClicked(false);
            setIsLoading(false)
            //trigger callback
            props.refreshSeason()
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    // Filter games that are not played and unique
    const notPlayedGames = props.season?.games?.filter((game) => !game.played);
    const uniqueGameIds = new Set();

    return (
        <>
            {isLoading || seasonLoading ? (
                <Loader />
            ) : (
                <div style={{ maxWidth: '100%', margin: '0 auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'column', // Stack elements vertically on small screens
                            marginTop: '-30px'
                        }}
                    >
                        <Title style={{ textAlign: 'center', marginBottom: '10px', marginTop: '-20px' }}>
                            {props.season?.type ? props.season?.type : 'Season'}
                        </Title>

                        <Title style={{ textAlign: 'center', marginBottom: '10px' }}>Upcoming games</Title>
                    </div>

                    <ThemeProvider theme={defaultTheme}>
                        <CssBaseline />
                        <Container
                            maxWidth="lg"
                            sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}
                        >

                            {/* Buttons Section */}
                            {/* <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', }}>

                                <Box sx={{width: '30%'}}>
                                    <Button  variant="outlined"  onClick={() => handleClearOption()}>
                                        Duplicate this Season
                                    </Button>
                                </Box>

                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" onClick={() => console.log('Print Season')}>
                                        Print Season
                                    </Button>
                                    <Button variant="text" style={{ color: 'red' }} onClick={() => handleClearOption()}>
                                        Delete Season
                                    </Button>
                                </Box>

                            </Box> */}

                            <Grid container>
                                {/* Buttons Section */}
                                <Grid container item xs={12} md={12}>
                                    {/* Duplicate this Season Button */}
                                    <Grid item xs={12} md={3}>
                                        <Button variant='outlined' onClick={() => { setIsTeamSeasonModal(true) }}>
                                            Duplicate this season?
                                        </Button>
                                    </Grid>

                                    {/* Other Buttons on the right */}
                                    <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {/* <Button variant="contained" onClick={() => console.log('Print Season')}>
                                            Print Season
                                        </Button> */}
                                        {
                                            seasonLoading ?
                                                <>
                                                </>
                                                :
                                                <PrintSeasons
                                                    seasonType={props.season?.type}
                                                    activeTeam={activeTeam}
                                                    season={props.season}
                                                />
                                        }


                                        <Button variant="text" style={{ color: 'red' }} onClick={() => handleClearOption()}>
                                            Delete Season
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>


                            {/* <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                {props.season?.games && Array.isArray(props.season?.games)
                                    ? props.season?.games.map((game, index) => {
                                        if (index + 1 < 4) {
                                            return (
                                                <NextGame
                                                    key={index}
                                                    title="Next Opponent: "
                                                    metric={game.opponent}
                                                    date={game.date}
                                                    venue={game.venue}
                                                    type={game.type}
                                                />
                                            );
                                        }
                                    })
                                    :
                                    null
                                }

                                {confirmationModalOpen && (
                                    <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                                )}

                            </Grid> */}

                            {
                                props.season !== null ?
                                    <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                        {(notPlayedGames?.length > 0 ? notPlayedGames.slice(0, 3) : props?.season?.games?.slice(-3)).map((game, index) => (
                                            <NextGame
                                                key={index}
                                                title="Next Opponent: "
                                                metric={game.opponent}
                                                date={game.date}
                                                venue={game.venue}
                                                type={game.type}
                                            />
                                        ))}
                                    </Grid> :
                                    <>Loading...</>
                            }




                            {confirmationModalOpen && (
                                <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                            )}
                        </Container>
                    </ThemeProvider>

                    <TeamSeasonModal
                        teams={stateTeams}
                        isOpen={isTeamSeasonModal}
                        setTeamsSessionModalOpen={setIsTeamSeasonModal}
                        onTeamsSelected={onTeamsSelected}
                    >
                    </TeamSeasonModal>

                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleCloseSnackbar}
                            severity={deleted ? "error" : "success"}
                        >
                            {Message}
                        </MuiAlert>
                    </Snackbar>

                    <Snackbar
                        open={openConfirmationSnackbar}
                        autoHideDuration={4000}
                        onClose={handleCloseConfirmationSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleCloseConfirmationSnackbar}
                            severity={error ? "error" : "success"}
                        >
                            {confirmationMessage}
                        </MuiAlert>
                    </Snackbar>
                </div>
            )}
        </>
    );
};

export default InSeasonComponent
