// import { Box, Button, Container, Paper } from '@material-ui/core';
import { Add, Clear, Remove } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import './Score.css';
import { Button, Box, Container, Paper, useStepContext, Typography, useMediaQuery } from '@mui/material';
import TryScorer from './tryScorer';
import GoalKicker from './goalkicker';
import { team } from './team';
import { pushActions, setOpponent, setOurScore, setOpponentScore, setGameDayStarted, updateKickerConversion } from './gamedaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { selectKicker } from './gamedaySlice';
import { setRunningTimer } from './timerSlice';
import GameReportGenerator from './reports/generateGameReport';
import AwayGoalKicker from './awayGoalKicker';


function Score(props) {
   // console.log('Props received in score: ', props)
    const [ourStateScore, setOurStateScore] = useState(0);
    const [opponentStateScore, setOppononetStateScore] = useState(0);
    const [ourTryClicked, setOurTryClicked] = useState(false)
    const [opponentTryClicked, setOpponentTryClicked] = useState(false)
    const [conversionClicked, setConversionClicked] = useState(false)
    const [penaltyClicked, setPenaltyClicked] = useState(false)
    const [tryscored, setTryScored] = useState(false)
    const [goalKickerUsed, setGoalKickerUsed] = useState(false)
    const [fromPenalty, setFromPenalty] = useState(false)
    const [openKicker, setOpenKicker] = useState(false)
    const [kicker, setKicker] = useState()
    const [kickerClicked, setKickerClicked] = useState(false);
    const dispatch = useDispatch()
    const [createdAction, setCreatedAction] = useState();
    const [stateKickerName, setStateKickerName] = useState('');
    const stateKicker = useSelector((state) => state.gameday.kicker)
    const time = useSelector((state) => state.gameday.timer)
    const runningTime = useSelector((state) => state.timer.runningTimer)
    const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)
    const ourScore = useSelector((state) => state.gameday.ourScore)
    const opponentScore = useSelector((state) => state.gameday.opponentScore)
    const opponent = useSelector((state) => state.gameday.opponent)
    const gameObject = useSelector((state) => state.gameday.GameObject)
    // console.log('GameObject in score component: ', gameObject)


    const isMobile = useMediaQuery('(max-width: 600px)');

    let activeHalf
    isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'
    let timeOfPenalty
    let timeOfAction

    useEffect(() => {
        if (stateKicker) {
            console.log('Props yo: ', props)
           // const newPlayer = props.team.find((searchPlayer) => searchPlayer.id === stateKicker)
         //   console.log('New Player found in score useEffect: ', newPlayer)

            //FIND THE NEW PLAYER
            let playerObjectInStartingLineUp
            let playerObjectInBench
            playerObjectInStartingLineUp = props?.team?.players?.find((searchPlayer) => searchPlayer.id === stateKicker)
            playerObjectInBench = props?.team?.reservePlayers?.find((searchPlayer) => searchPlayer.id === stateKicker)

            let name = ""
            if (playerObjectInStartingLineUp !== undefined) {
                name = `${playerObjectInStartingLineUp?.firstName} ${playerObjectInStartingLineUp?.lastName}`
                console.log('New Player found in score useEffect in starting LineUp: ', name)
                setStateKickerName(name)
            }
            else {
                console.log('New Player found in tryscorer submit, playerObjectInBench: ', playerObjectInBench)
                name = `${playerObjectInBench?.firstName} ${playerObjectInBench?.lastName}`
                console.log('New Player found in score useEffect in bench LineUp: ', name)
                setStateKickerName(name)
            }

            // setStateKickerName(`${newPlayer.firstName} ${newPlayer.lastName}`)


        }


    }, [stateKicker])


    useEffect(() => {
        if (stateKicker !== null || stateKicker !== '') {
            setKicker(stateKicker)
        }
        else {
            console.log('No Kicker yet')
        }
    }, [])



    function handleHomePenalty() {
        setKickerClicked(true)
        setFromPenalty(true)

        //Need to wait for kicker selection



        console.log('Reset the kicker')

    }

    function handleAwayScore() {
        timeOfAction = runningTime
        dispatch(setOpponentScore({ type: 'try', action: opponentScore + 5 }));
        setOpponentTryClicked(true)
        const newAction = `${opponent} Scored with ${timeOfAction} minutes left in the ${activeHalf}`;
        dispatch(pushActions(newAction));

    }

    function handleAwayConversionMissed() {
        const newAction = `${opponent} Conversion Missed`;
        dispatch(pushActions(newAction));


        setOpponentTryClicked(false)
    }

    function handleAwayConversionMade() {
        timeOfAction = runningTime
        const newAction = `${opponent} Conversion Made with ${timeOfAction} minutes left in the ${activeHalf}`;
        dispatch(pushActions(newAction));


        dispatch(setOpponentScore({ type: 'conversion', action: opponentScore + 2 }));
        setOpponentTryClicked(false)
    }

    function handleAwayPenalty() {
        timeOfAction = runningTime
        const newAction = `${opponent} Penalty Kicked with ${timeOfAction} minutes left in the ${activeHalf}`;
        dispatch(pushActions(newAction));

        dispatch(setOpponentScore({ type: 'penalty', action: opponentScore + 3 }));
    }

    function handleHomeScore() {
        setFromPenalty(false)
        dispatch(setOurScore(ourScore + 5));
        setOurTryClicked(true);
    }

    function handleHomeConversionOver(kicker) {
        dispatch(updateKickerConversion({ result: 'over', player: kicker }))
        timeOfAction = runningTime
        setConversionClicked(true);
        dispatch(setOurScore(ourScore + 2));
        setOurTryClicked(false);
        setKickerClicked(false)

        const newAction = `Try Converted by ${stateKickerName}`;
        dispatch(pushActions(newAction));

    }

    function handleHomeConversionMissed(kicker) {
        dispatch(updateKickerConversion({ result: 'missed', player: kicker }))
        setConversionClicked(true);
        setOurTryClicked(false);
        setKickerClicked(false)

        const newAction = `Try Conversion Missed by ${stateKickerName}`;
        dispatch(pushActions(newAction));

    }

    function handleKicker() {
        setKickerClicked(true);
    }

    function handleEndGame() {
        dispatch(setOpponentScore({ type: 'end', action: 0 }));
        dispatch(setOurScore(0));
        dispatch(setRunningTimer('00:00:00'))
        dispatch(setGameDayStarted(false))
    }

    function handleTryScoreSubmitted() {
        setOpenKicker(true)
        setKickerClicked(true);
        setOurTryClicked(false)
    }

    function resetKickerClicked() {
        setKickerClicked(false)
        if (stateKicker) {
            timeOfPenalty = runningTime
            setPenaltyClicked(true);
            dispatch(setOurScore(ourScore + 3));
            setPenaltyClicked(false);
            const newAction = `Penalty Kicked by ${stateKickerName} with ${timeOfPenalty} minutes left in the ${activeHalf}`;
            dispatch(pushActions(newAction));
        }
        console.log('No Kicker found')

    }

    function resetOpponentKickerClicked() {
        opponentTryClicked(false)
    }


    return (
        <Container>
            <Box p={2}>
                {/* Use flexbox to adjust layout */}
                {
                    isMobile ?
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: isMobile ? 'column' : 'row', // Stack elements vertically on smaller screens
                            }}
                        >
                            <Box>
                                <Button
                                    style={{ marginRight: '3px' }}
                                    data-testid="incrementHome"
                                    onClick={() => { handleHomeScore(); }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Add />
                                    5
                                </Button>

                                <Button
                                    data-testid="incrementHome"
                                    onClick={() => { handleHomePenalty(); }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Add />
                                    3
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', my: isMobile ? 1 : 2 }}>
                                {/* Actual ScoreCard: */}
                                <Typography variant="h4" component="div" sx={{ mx: 1 }}>
                                    {ourScore}
                                </Typography>
                                <Typography variant="h4" component="div" sx={{ mx: 1 }}>
                                    :
                                </Typography>
                                <Typography variant="h4" component="div" sx={{ mx: 1 }}>
                                    {opponentScore}
                                </Typography>
                            </Box>

                            <Box>
                                <Button
                                    style={{ marginRight: '3px' }}
                                    data-testid="incrementAway"
                                    onClick={() => { handleAwayScore() }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Add />
                                    5
                                </Button>
                                <Button
                                    data-testid="incrementHome"
                                    onClick={() => { handleAwayPenalty() }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Add />
                                    3
                                </Button>
                                <br />
                            </Box>
                        </Box>
                        :
                        <div>
                            <Box className="flex-container">
                                <Box mr={1}>

                                </Box>
                                <Button style={{ marginRight: '3px' }} data-testid="incrementHome" onClick={() => { handleHomeScore(); }} variant="contained" color="primary">
                                    <Add />
                                    5
                                </Button>
                                <br />
                                <Button data-testid="incrementHome" onClick={() => { handleHomePenalty(); }} variant="contained" color="primary">
                                    <Add />
                                    3
                                </Button>

                                {/* Actual ScoreCard: */}
                                <Paper className="p-x-1 m-x-1 big-font" square>{ourScore}</Paper>
                                <Box className="big-font">:</Box>
                                <Paper className="p-x-1 m-x-1 big-font" square>{opponentScore}</Paper>

                                <Button style={{ marginRight: '3px' }} data-testid="incrementAway" onClick={() => { handleAwayScore() }} variant="contained" color="primary">
                                    <Add />
                                    5
                                </Button>
                                <Button data-testid="incrementHome" onClick={() => { handleAwayPenalty() }} variant="contained" color="primary">
                                    <Add />
                                    3
                                </Button>
                                <br />

                                <Box ml={1}>
                                </Box>
                            </Box>

                        </div>

                }

            </Box>
            <Box pt={2} className="center">
                {/* {ourTryClicked ? (
                    <div>
                        <TryScorer team={props} tryScorerSubmitted={handleTryScoreSubmitted} />

                        {kickerClicked ? <GoalKicker team={props}
                            type={fromPenalty ? 'penalty' : 'try'}
                            handleHomeConversionOver={handleHomeConversionOver}
                            handleHomeConversionMissed={handleHomeConversionMissed}
                            resetKickerClicked={resetKickerClicked}
                        /> : <></>}

                    </div>
                ) : (
                    <></>
                )} */}

                {ourTryClicked ? (
                    <div>
                        <TryScorer
                            team={props}
                            tryScorerSubmitted={handleTryScoreSubmitted}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {kickerClicked ? (
                    <GoalKicker
                        team={props}
                        type={fromPenalty ? 'penalty' : 'try'}
                        handleHomeConversionOver={handleHomeConversionOver}
                        handleHomeConversionMissed={handleHomeConversionMissed}
                        resetKickerClicked={resetKickerClicked}
                    />
                ) : (
                    <></>
                )}


                {opponentTryClicked ? (
                    <div>
                        <AwayGoalKicker
                            handleAwayConversionMade={handleAwayConversionMade}
                            handleAwayConversionMissed={handleAwayConversionMissed}
                            resetOpponentKickerClicked={resetOpponentKickerClicked}
                        />

                    </div>
                ) : (
                    <></>
                )}

            </Box>
        </Container >
    );
}

export default Score;


