import React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Content/title.js';
import { useSelector } from 'react-redux';
import { TodayRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { Box, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../layouts/loader.js';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HealingIcon from '@mui/icons-material/Healing';
import { playerRequests } from '../../services/api-requests.js';
import PrintSquadList from './printSquadList.js';
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function SquadList(props) {
    console.log('Props received in squadList: ', props.players.players)
    console.log('Props received in squadList: ', props)
    const [playerToBeDeleted, setPlayerToBeDeleted] = React.useState('')
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openInjuredSnackbar, setOpenInjuredSnackbar] = React.useState(false);
    const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [injuredMessage, setInjuredMessage] = React.useState('');
    const [injuredError, setInjuredError] = React.useState('');
    const [activeSessionArray, setActiveSessionArray] = React.useState([]);
    const [deleted, setDeleted] = React.useState(false);
    const [error, setError] = React.useState(false);
    const schoolId = parseInt(useSelector((state) => state.user.profile.school))

    const navigate = useNavigate()

    async function handleClearOption(player) {

        setHandleDeletedClicked(true);
        setPlayerToBeDeleted(player)
        console.log('Player to be deleted: ', player)
        handleOpen();
    }

    async function handleSetPlayerAsInjured(player) {
        console.log('Player in injured: ', player)
        try {
            const playerIWant = props.players.players.filter((session) => session.type === playerToBeDeleted.type);
            const playerIWantID = playerIWant[0].id;

            const statusIWant = props.statuses.find((status) => status.status === "Injured")
            console.log('Status I Want: ', statusIWant)
            const statusId = statusIWant.id
            console.log('StatusID I want: ', statusId)

            // props.setLoading(true)
            // Perform the API call to delete the Session
            // const response = await playerRequests.UpdateStatus(player.id, statusId);
             const response = await playerRequests.setInjured(player.id);

            // const response = await playerRequests.setInjured(player.id);

            if (response.status === 200) {
                // props.showNotification('Player Deleted', false)
                // props.setLoading(false)
                console.log('Response from injured: ', response);
                setInjuredError(false)
                setInjuredMessage('Player set to Injured. Click to refresh');
                setOpenInjuredSnackbar(true)
            } else {
                // props.showNotification('Something went wrong', true)
                // props.setLoading(false)
                setInjuredMessage('Something went wrong');
                setInjuredError(true)
                setOpenInjuredSnackbar(true)
            }
        } catch (error) {
            console.log('error: ', error);
            // props.showNotification('Something went wrong', true)
            // props.setLoading(false)
            setInjuredError(true)
            setOpenInjuredSnackbar(true)
            setInjuredMessage('Something went wrong');
        } finally {

        }

    }

    const ConfirmationModal = ({ open, onClose }) => {

        const handleNo = () => {
            // console.log('Handle No clicked');
            onClose();
        };

        const handleYes = () => {
            // console.log('Handle Yes clicked');
            handleYesClick();
            onClose();
        };

        return (
            <div>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Player
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                            Are you sure you want to delete {playerToBeDeleted.firstName} {playerToBeDeleted.lastName}?
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onClick={handleNo} variant='contained'>No</Button>
                            <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
                        </div>

                    </Box>
                </Modal>
            </div>
        );
    };

    const handleYesClick = async () => {
        // setIsLoading(true)
        try {
            const playerIWant = props.players.players.filter((session) => session.type === playerToBeDeleted.type);
            const playerIWantID = playerIWant[0].id;
            // props.setLoading(true)
            // Perform the API call to delete the Session
            const response = await playerRequests.deletePlayer(playerToBeDeleted.id);

            if (response.status === 200) {
                // props.showNotification('Player Deleted', false)
                // props.setLoading(false)
                console.log('Response from delete: ', response);
                setError(false)
                setMessage('Player Deleted. Click to refresh');
                setOpenSnackbar(true);
            } else {
                // props.showNotification('Something went wrong', true)
                // props.setLoading(false)
                setMessage('Something went wrong');
                setError(true)
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.log('error: ', error);
            // props.showNotification('Something went wrong', true)
            // props.setLoading(false)
            setError(true)
            setOpenSnackbar(true);
            setMessage('Something went wrong');
        } finally {
            setHandleDeletedClicked(false);
            // setIsLoading(false)
            //Add a refresh method to get the teams again
            // props.refreshSquad()
        }
    };

    const handleCloseInjuredSnackbar = (event, reason) => {

        if (reason === 'clickaway') {
            //Add a refresh method to get the teams again
            props.refreshSquad()
            return;
        }
        setOpenSnackbar(false);
        props.refreshSquad()

    };

    const handleCloseSnackbar = (event, reason) => {

        if (reason === 'clickaway') {
            //Add a refresh method to get the teams again
            props.refreshSquad()
            return;
        }
        setOpenSnackbar(false);
        props.refreshSquad()

    };


    return (
        <React.Fragment>
            <PrintSquadList squadList={props.players.players} />
            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>

                <Title>Squad</Title>
                <div style={{ overflowX: 'auto' }}>

                    {
                        isLoading ?
                            <Loader />

                            :
                            <>
                                {/* Need to be able to set a player to injured from here as well */}
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Height</TableCell>
                                            <TableCell>Weight</TableCell>
                                            {Array.from({ length: props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0) }, (_, index) => (
                                                <TableCell key={index}>{`Position ${index + 1}`}</TableCell>
                                            ))}
                                            {/* Add an empty TableCell in the header row that spans the remaining columns */}
                                            <TableCell colSpan={props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)}></TableCell>
                                            {/* Additional TableCell for the clear icon */}
                                            <TableCell style={{ textAlign: 'left' }} >Date of Birth</TableCell>

                                            <TableCell style={{ textAlign: 'center' }}>Options</TableCell>
                                            
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {props.players.players.length !== 0 ? (
                                            props.players.players.map((player, playerIndex) => {
                                                return (
                                                    <TableRow key={playerIndex}>
                                                        <TableCell>{playerIndex + 1}</TableCell>
                                                        <TableCell>{player.firstName}</TableCell>
                                                        <TableCell>{player.lastName}</TableCell>
                                                        <TableCell>{player.height}</TableCell>
                                                        <TableCell>{player.weight}</TableCell>
                                                        {player.positionList.map((position, splitIndex) => (
                                                            <TableCell key={splitIndex} >
                                                                {position}
                                                            </TableCell>

                                                        ))}
                                                        {/* Add an empty TableCell that spans the remaining columns */}
                                                        <TableCell colSpan={props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)}></TableCell>
                                                        <TableCell >{player.dateOfBirth}</TableCell>
                                                        <TableCell colSpan={props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)} style={{ textAlign: 'right' }}>


                                                            <Tooltip title="Edit Player Details"  >
                                                                <IconButton onClick={() => navigate(`/EditPlayer/${schoolId}/${player.id}`)} style={{ color: '#87CEFA' }}>
                                                                    <EditIcon></EditIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Set Player as Injured">
                                                                <IconButton onClick={() => handleSetPlayerAsInjured(player)} style={{}}>
                                                                    <HealingIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Player">
                                                                <IconButton onClick={() => handleClearOption(player)} style={{ color: 'red' }}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Tooltip>


                                                        </TableCell>



                                                    </TableRow>

                                                );
                                            })
                                        ) : (
                                            <TableRow>
                                                {/* Set colSpan to match the total number of columns */}
                                                <TableCell colSpan={3 + props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)}>No players</TableCell>
                                            </TableRow>
                                        )}

                                        {confirmationModalOpen && (
                                            <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                                        )}


                                    </TableBody>
                                </Table>
                            </>
                    }


                    <Snackbar
                        open={openInjuredSnackbar}
                        // autoHideDuration={3000}
                        onClose={handleCloseInjuredSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleCloseInjuredSnackbar}
                            severity={injuredError ? "error" : "success"}
                        >
                            {injuredMessage}
                        </MuiAlert>
                    </Snackbar>

                    <Snackbar
                        open={openSnackbar}
                        // autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleCloseSnackbar}
                            severity={error ? "error" : "success"}
                        >
                            {Message}
                        </MuiAlert>
                    </Snackbar>
                </div>

            </Paper>
        </React.Fragment>
    )
}

export default SquadList

{/* <TableRow key={playerIndex}>
<TableCell>{playerIndex + 1}</TableCell>
<TableCell>{player.firstName}</TableCell>
<TableCell>{player.lastName}</TableCell>
<TableCell>{player.height}</TableCell>
<TableCell>{player.weight}</TableCell>
{player.positionList.map((position, splitIndex) => (
  <TableCell key={splitIndex}>{position}</TableCell>
))}

<TableCell colSpan={props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)}></TableCell>
<TableCell style={{ textAlign: 'center' }}>{new Date(player.dateOfBirth).toLocaleDateString()}</TableCell>
<TableCell colSpan={props.players.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)} style={{ textAlign: 'right' }}>
  <Tooltip title="Edit Player Details">
    <IconButton onClick={() => navigate(`/EditPlayer/${schoolId}/${player.id}`)} style={{ color: '#87CEFA' }}>
      <EditIcon></EditIcon>
    </IconButton>
  </Tooltip>
  <Tooltip title="Set Player as Injured">
    <IconButton onClick={() => handleSetPlayerAsInjured(player)} style={{}}>
      <HealingIcon />
    </IconButton>
  </Tooltip>
  <Tooltip title="Delete Player">
    <IconButton onClick={() => handleClearOption(player)} style={{ color: 'red' }}>
      <ClearIcon />
    </IconButton>
  </Tooltip>
</TableCell>
</TableRow> */}