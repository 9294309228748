import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  events: [
    {
      id: 1,
      session: 'Today Session',
      description: 'Testing session 1',
      startTime: 'Tue Sep 12 2023 13:00:00',
      endTime: 'Tue Sep 12 2023 14:30:00',
      isAllDay: false,
    },
  ],
  todayEvents: [],
  futureEvents: [],
  status: 'idle',
  error: null,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents: (state, action) => {
      return state.events;
    },
    addEvents: (state, action) => {
      state.events.push(action.payload);
      console.log('Calendar Events in Slice: ', state.events);
    },
    filterEvents: (state) => {
      const today = new Date();
      const newTodayEvents = [];
      const newFutureEvents = [];

      state.events.forEach((event) => {
        const eventStartTime = new Date(event.startTime);

        //Format the start and endTime. 

        const startTime = eventStartTime.toString().substring(0,25);
        const sessionObject = {
          id: event.id,
          sessionType: event.session || 'Needs further editing',
          DateTime: startTime,
          splitValues: [],
          duration: 0,
        };

        if (eventStartTime.toDateString() === today.toDateString()) {
          newTodayEvents.push(sessionObject);
        } else if (eventStartTime > today) {
          newFutureEvents.push(sessionObject);
        }
      });

      state.todayEvents = newTodayEvents;
      state.futureEvents = newFutureEvents;
      // console.log('Today events: ', state.todayEvents);
      // console.log('Future events: ', state.futureEvents);
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

export const { filterEvents, getEvents, addEvents, increment, decrement, incrementByAmount } = calendarSlice.actions;

export default calendarSlice.reducer;
