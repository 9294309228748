import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import React from "react";
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { resourceRequests } from '../../services/api-requests';
import { useEffect } from 'react';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import Loader from '../../layouts/loader';
import { useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function FileView() {

    const [numPages, setNumPages] = useState();
    const [pdf, setPdf] = useState();
    const [show, setShow] = useState(false);
    const [fileInfo, setFileInfo] = useState();
    const [fileName, setFileName] = useState();
    const [files, setFiles] = useState([]);
    const [fileId, setFileId] = useState([]);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [deleted, setDeleted] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [fileUploadLoading, setIsFileUploadLoading] = useState(false)
    const [wholeSchool, setWholeSchool] = useState(false)
    const [onlyMyAgeGroup, setOnlyMyAgeGroup] = useState(false)
    const [isError, setIsError] = useState(false)

    const ageGroup = parseInt(useSelector((state) => parseInt(state.user.profile.ageGroup)))
    const schoolId = parseInt(useSelector((state) => parseInt(state.user.profile.school)))


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate()


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Get the Base64 string after the comma
            setFileInfo(base64String);
        };
        // Read the file as a data URL (which includes Base64 encoding)
        reader.readAsDataURL(file);
        setPdf(file);
    };


    async function uploadFile() {

        if (fileInfo) {

            const uniqueId = Math.floor(Math.random() * 1000000);

            const lastItem = files[files.length - 1];

            // console.log(lastItem.id)

            if (fileInfo && fileName && (onlyMyAgeGroup || !onlyMyAgeGroup)) {

                const newFile = {
                    // id: lastItem.id + 1,
                    id: uniqueId,
                    name: fileName,
                    data: fileInfo,
                    type: "pdf",
                    schoolId: schoolId,
                    ageGroup: onlyMyAgeGroup ? ageGroup : -1
                };



                console.log('Going to be sending over this file: ', newFile)
                try {
                    setIsFileUploadLoading(true)
                    const response = await resourceRequests.uploadNewFile(newFile);
                    // const response = 201
                    console.log(response.status)

                    if (response.status === 200) {

                        fetchFiles();
                        //   fetchSchoolFiles()
                        handleClose();
                        setIsError(false)
                    } else {
                        setIsError(true)
                        console.log("not working")
                    }
                } catch (error) {
                    console.log('Error')
                }
                finally {
                    setIsFileUploadLoading(false)
                }

            }
            else {
                console.log("NO FILE");
                alert('Please ensure you have selected a file')
            }
        } else {
            setDeleted(true)
            setMessage('Please fill out all fields before submitting')
            setOpenSnackbar(true)
        }

    };

    // file download
    const handleDownload = () => {
        saveAs(pdf, 'downloaded_file.pdf');
    };

    //delete file 
    async function deleteFile() {

        const response = await resourceRequests.deleteFile(fileId);

        try {
            if (response.status === 200) {
                fetchFiles();

                setPdf();
                setMessage('File successfully deleted');
                setOpenSnackbar(true);
            } else {
                // setMessage('File could not be deleted');
                // setDeleted(true);
                // setOpenSnackbar(true);
            }
        }
        catch (error) {
            console.log("something went wrong")
        }
    }

    // fetch file by id 
    // const fetchFileById = async () => {
    //   try {
    //     const response = await resourceRequests.getFileByID(7);

    //     if (response.status === 200) {
    //       console.log(response.data)

    //       const currentFile = {
    //         id: response.data.id,
    //         fileType: response.data.filetype,
    //         fileName: response.data.filename,
    //         fileData: response.data.filedata
    //       }
    //     }
    //   }
    //   catch
    //   {

    //   }
    // };

    const fetchFiles = async () => {

        const myFiles = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getFileList();

            if (response.status === 200) {
                response.data.forEach((file) => {
                    const fileObj = {
                        id: file.id,
                        fileType: file.fileType,
                        fileName: file.fileName,
                        fileData: file.fileData
                    };
                    myFiles.push(fileObj);
                });

                setFiles(myFiles);

                console.log('Files received: ', myFiles)

            } else {
                console.log("not working")
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };


    const fetchSchoolFiles = async () => {

        const myFiles = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getSchoolFiles(schoolId);

            if (response.status === 200) {
                response.data.forEach((file) => {
                    const fileObj = {
                        id: file.id,
                        fileType: file.fileType,
                        fileName: file.fileName,
                        fileData: file.fileData
                    };
                    myFiles.push(fileObj);
                });

                setFiles(myFiles);

            } else {
                console.log("not working")
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const fetchAgeGroupFiles = async () => {

        const myFiles = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getAgeGroupFiles(schoolId, ageGroup);

            if (response.status === 200) {
                response.data.forEach((file) => {
                    const fileObj = {
                        id: file.id,
                        fileType: file.fileType,
                        fileName: file.fileName,
                        fileData: file.fileData
                    };
                    myFiles.push(fileObj);
                });

                setFiles(myFiles);

            } else {
                console.log("not working")
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    //display selected pdf file 
    const loadPdf = (id) => {
        setFileId(id);

        console.log(id)
        var loadedFile = files.find(file => file.id === id)
        console.log(loadedFile);

        if (!loadedFile) {
            setMessage('File could not be loaded');
            setDeleted(true);
            setOpenSnackbar(true);
        }
        else {
            var selectedFile = "data:application/pdf;base64," + loadedFile.fileData;
            setPdf(selectedFile)
        }

    };

    useEffect(() => {
        // fetchFiles()
        // // fetchSchoolFiles();
        // //fetchAgeGroupFiles();
    }, [])

    function navigateBack() {
        navigate('/Resource');
    }

    function handleViewChange(view) {
        console.log('View received in function: ', view)
        switch (view) {
            case 'sc':
                fetchFiles();
                break;
            case 'school':
                fetchSchoolFiles();
                break;
            case 'agegroup':
                fetchAgeGroupFiles();
                break;
            default:
                break;
        }
    }

    return (

        <Container>


            <Row>
                <Col xs={2} md={2}>
                    <Button variant='outlined' startIcon={<ArrowBackIcon />} onClick={navigateBack} fullWidth >Back</Button>
                </Col>

                <Col xs={2} md={2} lg={5}>
                    <Box sx={{ display: 'flex', justifyContent: 'start', mb: 1, mt: -2, ml: 5 }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Display resources</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="sc" control={<Radio />} label="SC Resources" onChange={() => { handleViewChange("sc") }} />
                                <FormControlLabel value="school" control={<Radio />} label="School" onChange={() => { handleViewChange("school") }} />
                                <FormControlLabel value="agegroup" control={<Radio />} label="Age Group" onChange={() => { handleViewChange("agegroup") }} />

                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Col>

                <Col xs={2} md={2} lg={5}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                        <Button startIcon={<UploadIcon />} sx={{ marginRight: '5px' }} variant="contained" onClick={handleShow}> New File</Button>
                        <Button startIcon={<DownloadIcon />} sx={{ marginRight: '5px' }} variant="contained" onClick={handleDownload} > Download </Button>
                        <Button startIcon={<DeleteIcon />} variant="contained" onClick={deleteFile}> Delete</Button>
                    </Box>
                </Col>

            </Row>

            <Row>
                <Col xs={3} md={2}>
                    {
                        isLoading ? (
                            <Loader />
                        ) : (
                            isError ? (
                                <p>Could not retrieve files</p>
                            ) : (
                                files.length === 0 ? (
                                    <p>No files found</p>
                                ) : (
                                    <ListGroup>
                                        {files.map((file) => (
                                            <ListGroup.Item key={file.id} action onClick={() => loadPdf(file.id)}>
                                                {file.fileName}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )
                            )
                        )
                    }

                </Col>

                <Col xs={2} md={2} lg={6} xl={8}>

                    {/* pdf viewer */}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, width: '700px', height: '600px' }}>
                        <div className='pdf-div'>
                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
                                {Array.apply(null, Array(numPages))
                                    .map((x, i) => i + 1)
                                    .map((page) => {
                                        return (<Page pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false} />)
                                    })}
                            </Document>
                        </div>
                    </Box>



                </Col>
            </Row>

            {/* modal for uploading and renaming file */}
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Upload new file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select file to upload</Form.Label>
                            <Form.Control
                                type="file"
                                autoFocus
                                onChange={handleFileInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>File Name</Form.Label>
                            <Form.Control type="text" rows={3} onChange={(e) => setFileName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicRadio">
                            <Form.Check
                                type="radio"
                                label="This resource is for the whole school"
                                name="resourceType"
                                onChange={(e) => {
                                    // Handle radio button logic here
                                    setWholeSchool(true);
                                    setOnlyMyAgeGroup(false);
                                    console.log('wholeSchool: ', true);
                                    console.log('only age group: ', false)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicRadio">
                            <Form.Check
                                type="radio"
                                label="This resource is for my age group only"
                                name="resourceType"
                                onChange={(e) => {
                                    // Handle radio button logic here
                                    // Handle radio button logic here
                                    setOnlyMyAgeGroup(true);
                                    setWholeSchool(false);
                                    console.log('myAgeGroup: ', true);
                                    console.log('only school ', false)
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={uploadFile}>
                        Upload File
                    </Button>
                </Modal.Footer>
            </Modal>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? 'error' : 'success'}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>

        </Container >

    );
}
