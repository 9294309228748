import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
  sites: [],
  status: 'idle',
  error: null
}

let response

// Basic Crud functionality
export const fetchSites = createAsyncThunk('site/endpointName', async () => {
  // const response = await client.get('/api/endpointIamLookingFor', null)
  return response.data
})

export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
  return response.data
})


export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
  return extraData.body
})


export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
  // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
  return response.data
})



export const substitutionSlice = createSlice({
  name: 'substitution',
  initialState,
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: state => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    }
  }
})

//Selectors come below:
export const { increment, decrement, incrementByAmount } = substitutionSlice.actions

export default substitutionSlice.reducer