// import React, { useState, useEffect, useRef } from 'react'
// import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'
// import Timer from './timer';
// import Score from './score';
// import Substitutions from './substitutions';
// import './Score.css'
// import Cards from './card'
// import { useDispatch } from 'react-redux';
// import { setOpponent, setAgeGroup, setKickoffTime, selectActions, setTechnicalTable } from './gamedaySlice';
// import { selectOpponent, selectAgeGroup, selectKickoffTime } from './gamedaySlice';
// import { useSelector } from 'react-redux';
// import { selectTeam } from './gamedaySlice';
// import { team } from './team';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import GenerateGameReport from '../gameday/reports/generateGameReport'
// import { setGameDayStarted } from './gamedaySlice';
// import { getTeamForActiveTeam } from './team';
// import { gameRequests, seasonRequests } from '../../services/api-requests';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import Loader from '../../layouts/loader';



// function GameDay() {
//     const dispatch = useDispatch();
//     const [age, setAge] = useState('');
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const [opponentError, setOpponentError] = useState(false);
//     const [ageGroupError, setAgeGroupError] = useState(false);
//     const [kickOffTimeError, setKickOffTimeError] = useState(false);
//     const [techTableError, setTechTableError] = useState(false);
//     const [openNotification, setNotificationOpen] = useState(false);
//     const [notificationMessage, setNotificationMessage] = useState('')
//     const [isLoading, setIsLoading] = useState(false);
//     const [closestGame, setClosestGame] = useState('');
//     const [teamId, setTeamId] = useState('');
//     const [error, setError] = useState(false);
//     const [hasSeason, setHasSeason] = useState(false);

//     // const [gameDayStarted, setgameDayStarted] = useState(false);

//     //Selectors
//     const stateOpponent = useSelector((state) => state.gameday.opponent)
//     const stateKickOffTime = useSelector((state) => state.gameday.kickoffTime);
//     const stateAgeGroup = useSelector((state) => state.gameday.ageGroup);
//     const gameDayStarted = useSelector((state) => state.gameday.gameDayStarted)
//     const activeTeam = useSelector((state) => state.gameday.activeTeam)
//     const techTable = useSelector((state) => state.gameday.IsTechnical)
//     const availableTeams = useSelector((state) => state.team.availableTeams)
//     console.log('activeTeam: ', activeTeam)
//     console.log('AvailableTeams: ', availableTeams)

//     let allGood = false


//     // useEffect(() => {
//     //     const team = availableTeams.find((team) => team.name === activeTeam)
//     //     setTeamId(team.id)
//     //     console.log('Team id set to: ', team.id)

//     //     fetchClosestGame();
//     //     fetchSeason();
//     // }, [activeTeam])


//     useEffect(() => {
//         let hasSeason
//         const team = availableTeams.find((team) => team.name === activeTeam)

//         if (team !== undefined) {
//             setTeamId(team.id)
//             const localTeamID = team.id

//             team.seasonId === -1 ? hasSeason = false : hasSeason = true
//             console.log('Team id set to: ', team.id)

//             if (hasSeason) {
//                 const fetchData = async () => {
//                     setIsLoading(true);

//                     try {
//                         await fetchSeason().then(() => {
//                             fetchClosestGame();
//                         })
//                             .finally(() => {
//                                 setIsLoading(false);
//                             });
//                     } catch (error) {
//                         console.log('Error trying to get Data')
//                     }

//                     fetchData();
//                 };
//             }
//             else {
//                 setError(true)
//                 setNotificationMessage('No Season found for this team. Please create one in the seasons tab')
//                 setNotificationOpen(true)
//             }
//         }

//     }, [activeTeam]);


//     const handleCloseSnackbar = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpenSnackbar(false);
//     };

//     const handleCloseConfirmationSnackbar = (event, reason) => {
//         if (reason === 'clickaway') return;
//         setNotificationOpen(false);
//     };

//     const fetchClosestGame = async () => {
//         console.log('Getting Game')
//         try {
//             await gameRequests.getClosestGame(teamId).then((response) => {
//                 if (response.status === 200) {
//                     setClosestGame(response.data)
//                     console.log('Closest Game Received: ', response.data)
//                 }
//                 if (response.status === 404) {
//                     setNotificationMessage('No Season found for this team. Please create one in the seasons tab')
//                     setError(true)
//                     setNotificationOpen(true)
//                     setErrorMessage('No Season found for this team. Please create one in the seasons tab')
//                     setOpenSnackbar(true)
//                 }
//             });
//         }
//         catch (error) {
//             console.error('Error fetching teams:', error);
//         }
//     };

//     const fetchSeason = async () => {
//         try {
//             await seasonRequests.getSeason(teamId).then((response) => {
//                 if (response.status === 200) {
//                     setClosestGame(response.data)
//                     console.log('Closest Game Received: ', response.data)
//                 }
//                 if (response.status === 404) {
//                     setNotificationMessage('No Season found for this team. Please create one in the seasons tab')
//                     setError(true)
//                     setNotificationOpen(true)
//                 }
//             });
//         }
//         catch (error) {
//             console.error('Error fetching teams:', error);
//         }
//     };

//     const handleChange = (event) => {
//         setAge(event.target.value);
//     };

//     function handleSubmit(event) {
//         event.preventDefault();

//         // Reset error states
//         setOpponentError(false);
//         setAgeGroupError(false);
//         setKickOffTimeError(false);
//         setTechTableError(false);

//         // Check if any field is incomplete
//         if (!stateOpponent || !stateAgeGroup || !stateKickOffTime || techTable === '') {
//             setErrorMessage('Please complete all fields');
//             setOpenSnackbar(true);
//             return;
//         }

//         // This needs to be added to the store
//         dispatch(setGameDayStarted(true));
//         console.log('Game day started');
//         console.log('Opponent: ', stateOpponent);
//         console.log('Kickoff: ', stateKickOffTime);
//         console.log('Age group: ', stateAgeGroup);

//         //Used like this:
//         //Dispatch
//         // dispatch(addSite({ body: newSiteValues })).unwrap().then((result) => {
//         //     dispatch(siteAdded(result))
//         // })
//     }


//     const renderActiveComponent = () => {

//         if (isLoading) {
//             <Loader />
//         }
//         else if (gameDayStarted) {
//             return <InGameComponent />
//         }
//         else {
//             return (
//                 <div className='App' style={{ width: '20' }} variant="outlined">


//                     <form onSubmit={handleSubmit} style={{ width: '20', alignContent: 'center' }}>
//                         <p>Who is your opponent for today?</p>

//                         {/* <Autocomplete
//                             disablePortal
//                             id="combo-box-demo"
//                             options={top100Films}
//                             sx={{ width: 300 }}
//                             renderInput={(params) => <TextField {...params} label="Movie" />}
//                         /> */}

//                         <Select
//                             // labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             required
//                             value={stateOpponent || ''}
//                             label="Opponent"
//                             onChange={(event) => {
//                                 dispatch(setOpponent(event.target.value));
//                                 setOpponentError(false); // Reset error state
//                             }}
//                             sx={{ width: '300px', marginBottom: '15px' }}
//                             error={opponentError} // Add error prop
//                             helperText={opponentError ? 'Incorrect email or password' : ''}
//                         >
//                             <MenuItem key={'Dr EG Jansen'} value={'Dr EG Jansen'}>Dr EG Jansen</MenuItem>
//                             <MenuItem key={'Waterkloof'} value={'Waterkloof'}>Waterkloof</MenuItem>
//                             <MenuItem key={'Affies'} value={'Affies'}>Affies</MenuItem>
//                             <MenuItem key={'Monnas'} value={'Monnas'}>Monnas</MenuItem>
//                             <MenuItem key={'Menlo'} value={'Menlo'}>Menlo</MenuItem>
//                             <MenuItem key={'Noordheuwel'} value={'Noordheuwel'}>Noordheuwel</MenuItem>
//                         </Select>

//                         <br />
//                         <p>Age group?</p>
//                         <Select
//                             // labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={stateAgeGroup}
//                             placeholder="Age Group"
//                             onChange={(event) => {
//                                 dispatch(setAgeGroup(event.target.value))
//                                 setAgeGroupError(false); // Reset error state
//                             }}
//                             sx={{ width: '300px' }}
//                             error={ageGroupError} // Add error prop
//                             helperText={ageGroupError ? 'Please complete this field' : ''}
//                         >
//                             <MenuItem value={'u14'}>u14</MenuItem>
//                             <MenuItem value={'u15'}>u15</MenuItem>
//                             <MenuItem value={'u16'}>u16</MenuItem>
//                             <MenuItem value={'u19'}>u19</MenuItem>
//                         </Select>
//                         <br />

//                         <p>Time of kickoff?</p>

//                         <Select
//                             // labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={stateKickOffTime}
//                             // label="Kickoff Time"
//                             onChange={(event) => {
//                                 dispatch(setKickoffTime(event.target.value))
//                                 setKickOffTimeError(false)
//                             }}
//                             sx={{ width: '300px', marginBottom: '15px' }}
//                             error={kickOffTimeError} // Add error prop
//                             helperText={kickOffTimeError ? 'Please complete this field' : ''}
//                         >
//                             <MenuItem value={'08:00'}>08:00</MenuItem>
//                             <MenuItem value={'09:00'}>09:00</MenuItem>
//                             <MenuItem value={'10:00'}>10:00</MenuItem>
//                             <MenuItem value={'11:00'}>11:00</MenuItem>
//                             <MenuItem value={'12:00'}>12:00</MenuItem>
//                             <MenuItem value={'13:00'}>13:00</MenuItem>
//                             <MenuItem value={'14:00'}>14:00</MenuItem>
//                         </Select>

//                         <br />

//                         <p>Track Extra Stats?</p>

//                         <Select
//                             // labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={techTable}
//                             // label="Kickoff Time"
//                             onChange={(event) => {
//                                 dispatch(setTechnicalTable(event.target.value))
//                                 setTechTableError(false)
//                             }}
//                             sx={{ width: '300px', marginBottom: '15px' }}
//                             error={techTableError} // Add error prop
//                             helperText={techTableError ? 'Please complete this field' : ''}
//                         >
//                             <MenuItem value={true}>Yes</MenuItem>
//                             <MenuItem value={false}>No</MenuItem>
//                         </Select>

//                         <br />
//                         <Button variant="text" type='submit' onClick={handleSubmit}>Start!</Button>
//                     </form>

//                     {/* Snackbar for error message */}
//                     <Snackbar
//                         open={openSnackbar}
//                         autoHideDuration={6000}
//                         onClose={handleCloseSnackbar}
//                         anchorOrigin={{ vertical: 'bo   ttom', horizontal: 'center' }}
//                     >
//                         <MuiAlert
//                             elevation={6}
//                             variant="filled"
//                             onClose={handleCloseSnackbar}
//                             severity="error"
//                         >
//                             {errorMessage}
//                         </MuiAlert>
//                     </Snackbar>

//                 </div>
//             )
//         }
//     };

//     const InGameComponent = () => {

// return (
//     <div style={{ maxWidth: '100%', margin: '0 auto' }}>
//         <div
//             style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 flexDirection: 'column', // Stack elements vertically on small screens
//             }}
//         >
//             <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
//                 Waterkloof vs {stateOpponent ? stateOpponent : 'No Team Selected'}
//             </h3>
//             <GenerateGameReport />
//         </div>

//         {/* Timer component needs to run here: */}
//         <Timer />

//         {/* Score component needs to run here */}
//         <Score team={getTeamForActiveTeam(activeTeam)} />

//         <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />

//         {/* Subs component needs to run here */}
//         <Substitutions team={getTeamForActiveTeam(activeTeam)} />

//         <Divider
//             style={{ margin: '0 auto', width: '80%', marginBottom: '10px', marginTop: '10px' }}
//         />

//         {/* Cards component needs to run here */}
//         <Cards team={getTeamForActiveTeam(activeTeam)} />
//     </div>
// )
//     }

//     <Snackbar
//         open={openNotification}
//         autoHideDuration={4000}
//         onClose={handleCloseConfirmationSnackbar}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//     >
//         <MuiAlert
//             elevation={6}
//             variant="filled"
//             onClose={handleCloseConfirmationSnackbar}
//             severity={error ? "error" : "success"}
//         >
//             {notificationMessage}
//         </MuiAlert>
//     </Snackbar>


//     return renderActiveComponent()


// }

// export default GameDay


import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem, Divider, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setOpponent, setAgeGroup, setKickoffTime, setTechnicalTable, setGameDayStarted, setGameObject, initializeExtraStats } from './gamedaySlice';
import { selectOpponent, selectAgeGroup, selectKickoffTime } from './gamedaySlice';
import { selectTeam } from './gamedaySlice';
import { team } from './team';
import { gameRequests, seasonRequests, teamRequests } from '../../services/api-requests';
import { getTeamForActiveTeam } from './team';
import Loader from '../../layouts/loader';
import Timer from './timer';
import Score from './score';
import Substitutions from './substitutions';
import Cards from './card';
import GenerateGameReport from '../gameday/reports/generateGameReport';
import { useNavigate } from 'react-router-dom';
import ExtraStats from './extraStats';
import { useRef } from 'react';

function GameDay() {
    const dispatch = useDispatch();
    const [age, setAge] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [opponentError, setOpponentError] = useState(false);
    const [ageGroupError, setAgeGroupError] = useState(false);
    const [kickOffTimeError, setKickOffTimeError] = useState(false);
    const [techTableError, setTechTableError] = useState(false);
    const [openNotification, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [additionalMessage, setAdditionalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [closestGame, setClosestGame] = useState('');
    const [season, setSeason] = useState([]);
    const [team, setTeam] = useState([]);
    const [combinedListPlayers, setCombinedListPlayers] = useState([]);

    const [oldTeam, setOldTeam] = useState([]);
    const [teamId, setTeamId] = useState('');
    const [selectedGame, setSelectedGame] = useState('');
    const [gameId, setGameId] = useState(0);
    const [error, setError] = useState(false);
    const [hasSeason, setHasSeason] = useState(false);
    const [typeOfGame, setTypeOfGame] = useState('');
    const [teamSeasonId, setTeamSeasonID] = useState(-1);
    const [stateGameObject, setStateGameObject] = useState({});
    const [venue, setVenue] = useState({});
    const [availableGameTypes, setAvailableGameTypes] = useState([
        {
            "id": 1,
            "type": "Friendly"
        },
        {
            "id": 2,
            "type": "League"
        },
        {
            "id": 3,
            "type": "Derby"
        },
        {
            "id": 4,
            "type": "Training Session"
        }
    ]);
    const [selectedTypeOfGame, setSelectedTypeOfGame] = useState('');
    const [selectedKickOffTime, setSelectedKickOffTime] = useState('');

    const stateOpponent = useSelector((state) => state.gameday.opponent);
    const stateKickOffTime = useSelector((state) => state.gameday.kickoffTime);
    const stateAgeGroup = useSelector((state) => state.gameday.ageGroup);
    const gameDayStarted = useSelector((state) => state.gameday.gameDayStarted);
    const activeTeam = useSelector((state) => state.gameday.activeTeam);
    const techTable = useSelector((state) => state.gameday.IsTechnical);
    const availableTeams = useSelector((state) => state.team.availableTeams);
    const profileAgeGroup = useSelector((state) => state.user.profile.ageGroup)
    const schoolName = useSelector((state) => state.user.schoolName)
    const schoolId = parseInt(useSelector((state) => state.user.profile.school))
    const displayedAgeGroup = `u/${parseInt(profileAgeGroup)}`
    console.log(displayedAgeGroup)
    const navigate = useNavigate()

    console.log('Active Team: ', activeTeam)

    let allGood = false;

    useEffect(() => {
        console.log('team received in useEffect: ', team)
    }, [team])

    // //This one should run on the first component mount:
    // useEffect(() => {
    //     let hasSeason;
    //     const team = availableTeams[0];
    //     setTeamSeasonID(team ? team.seasonId : 'No Season ID')
    //     console.log('Team in useEffect: ', team)
    //     if (team !== undefined) {
    //         setTeamId(team.id);
    //         const localTeamID = team.id;

    //         team.seasonId === -1 ? (hasSeason = false) : (hasSeason = true);

    //         if (hasSeason) {
    //             const fetchData = async () => {
    //                 setIsLoading(true);

    //                 try {
    //                     await fetchSeason(localTeamID).then(() => {
    //                         fetchClosestGame(localTeamID);
    //                         fetchTeam(localTeamID);
    //                         fetchTeamLists(schoolId, parseInt(profileAgeGroup), localTeamID)
    //                     })
    //                         .finally(() => {
    //                             setIsLoading(false);
    //                         });
    //                 } catch (error) {
    //                     console.log('Error trying to get Data');
    //                 }
    //             };

    //             fetchData();
    //         } else {
    //             setError(true);
    //             setNotificationMessage('No Season found for this team');
    //             setAdditionalMessage('Please create one in the seasons tab')
    //             setNotificationOpen(true);
    //         }
    //     }
    // }, []);
    ///////    

    useEffect(() => {
        let hasSeason;
        const team = availableTeams.find((team) => team.name === activeTeam);
        setTeamSeasonID(team ? team.seasonId : 'No Season ID')
        console.log('Team in useEffect: ', team)
        if (team !== undefined) {
            setTeamId(team.id);
            const localTeamID = team.id;

            team.seasonId === -1 ? (hasSeason = false) : (hasSeason = true);

            if (hasSeason) {
                const fetchData = async () => {
                    setIsLoading(true);

                    try {
                        await fetchSeason(localTeamID).then(() => {
                            fetchClosestGame(localTeamID);
                           // fetchTeam(localTeamID);
                            fetchTeamLists(schoolId, parseInt(profileAgeGroup), localTeamID)
                        })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    } catch (error) {
                        console.log('Error trying to get Data');
                    }
                };

                fetchData();
            } else {
                setError(true);
                setNotificationMessage('No Season found for this team');
                setAdditionalMessage('Please create one in the seasons tab')
                setNotificationOpen(true);
            }
        }
    }, [activeTeam]);

    useEffect(() => {
        console.log('SelectedGame: ', selectedGame)
        if (selectedGame) setGameId(selectedGame)
        const gameIWant = season.games?.find((game) => game.id === selectedGame)

        if (gameIWant) {
            setVenue(gameIWant.venue)
            console.log('Game I want: ', gameIWant)
            setOpponent(gameIWant.opponent); dispatch(setOpponent(gameIWant.opponent));
            const gameTypeIWant = availableGameTypes.find((gameType) => gameType.type === gameIWant.type)
            const gameTypeId = gameTypeIWant.id
            setTypeOfGame(gameTypeId)

        }

        const preGameObject = {
            "Opponent": gameIWant?.opponent,
            "Type": gameIWant?.type
        }

        console.log('PreGame Object: ', preGameObject)
    }, [selectedGame])




    const effectCounterRef = useRef(0);

    useEffect(() => {
        console.log('State Game Object: ', stateGameObject)

        if (techTable && effectCounterRef.current === 0) {
            setStateGameObject(prevState => ({
                ...prevState,
                extraStats: {
                    "knockOns": 0,
                    "lineoutsWon": 0,
                    "lineoutsLost": 0,
                    "scrumsWon": 0,
                    "scrumsLost": 0
                }
            }));

            effectCounterRef.current++;
        }
    }, [stateGameObject]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') { return };
        setNotificationOpen(false);
    };

    const fetchClosestGame = async (teamIdToUse) => {
        console.log('TeamID right before call: ', teamId)
        try {
            await gameRequests.getClosestGame(teamIdToUse).then((response) => {
                if (response.status === 200) {
                    console.log('Closest game received: ', response.data)
                    setClosestGame(response.data);
                    setError(false);
                }
                if (response.status === 404) {
                    setNotificationMessage('No Season found for this team');
                    setAdditionalMessage('Please create one in the seasons tab')
                    setError(true);
                    setNotificationOpen(true);

                    setNotificationMessage('No Season found for this team');
                    setAdditionalMessage('Please create one in the seasons tab')
                    setOpenSnackbar(true);
                }
            });
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const fetchSeason = async (teamIdToUse) => {
        try {
            await seasonRequests.getSeason(teamIdToUse).then((response) => {
                if (response.status === 200) {
                    console.log('Season received: ', response.data)
                    setSeason(response.data);
                    setError(false)
                }
                if (response.status === 404) {
                    setNotificationMessage('No Season found for this team');
                    setAdditionalMessage('Please create one in the seasons tab')
                    setError(true);
                    setNotificationOpen(true);
                }
            });
        } catch (error) {
            console.error('Error fetching Season:', error);
        }
    };

    const fetchTeamLists = async (schoolId, ageGroup, localTeamID) => {
        try {
            await teamRequests.getTeamsList(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    console.log('Response.data in the one i wnat: ', response.data)
                    const results = response.data.filter((team) => team.name === activeTeam)
                    //Within results we have players, and reservePlayers
                    console.log('Results variable is: ', results[0])
                    if (results) {
                        setTeam(results[0]);
                        const combinedPlayers = [...results[0].players, ...results[0].reservePlayers];
                        setCombinedListPlayers(combinedPlayers)
                    }
                    else {
                        setTeam("");
                    }
                }
            });
        }
        catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    //Use this instead to get the players in their position already:
    const fetchTeams = async () => {
        try {
            await teamRequests.getTeamsList(schoolId, profileAgeGroup).then((response) => {
                if (response.status === 200) {
                    console.log('Response.data: ', response.data)
                    setTeam(response.data);
                }
            });
        }
        catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    // const fetchTeam = async (teamIdToUse) => {
    //     try {
    //         await teamRequests.getPlayerList(teamIdToUse).then((response) => {
    //             if (response.status === 200) {
    //                 console.log('Team List received: ', response.data)
    //                 //  setTeam(response.data);
    //                 setOldTeam(response.data)
    //                 setError(false)
    //             }
    //             if (response.status === 404) {
    //                 setNotificationMessage('No Team found');
    //                 setAdditionalMessage('')
    //                 setError(true);
    //                 setNotificationOpen(true);
    //             }
    //         });
    //     } catch (error) {
    //         console.error('Error fetching Team:', error);
    //     }
    // };

    async function handleSubmit(event) {
        event.preventDefault();

        setOpponentError(false);
        setAgeGroupError(false);
        setKickOffTimeError(false);
        setTechTableError(false);

        if (!selectedGame || !displayedAgeGroup || !selectedKickOffTime || techTable === '') {
            setErrorMessage('Please complete all fields');
            setOpenSnackbar(true);
            return;
        }

        try {
            console.log('TeamID: ', teamId, ' + GameID: ', gameId)
            setIsLoading(true)
            const response = await gameRequests.startGame(teamId, gameId)
            if (response.status === 200) {
                console.log('Game Object received: ', response.data)
                setStateGameObject(response.data)
                dispatch(setGameObject(response.data))
                dispatch(setGameDayStarted(true));
            }
            else {
                console.log('Error trying to get game object: ', response.data)
                setError(true)
                setNotificationMessage('Could not get Game Object');
                setNotificationOpen(true);
            }
        } catch (error) {
            console.log('Error: ', error)
            setError(true)
            setNotificationMessage('Could not get Game Object');
            setNotificationOpen(true);
        }
        finally {
            if (techTable) {
                dispatch(initializeExtraStats({
                    "knockOns": 0,
                    "lineoutsWon": 0,
                    "lineoutsLost": 0,
                    "scrumsWon": 0,
                    "scrumsLost": 0
                }))
            }
            setIsLoading(false)
        }
    }

    function handleSelectedGameChange(value) {
        setSelectedGame(value);
        setOpponentError(false);
    }

    const renderActiveComponent = () => {
        if (isLoading) {
            return <Loader />;
        } else if (gameDayStarted && !error) {
            return <InGameComponent />;
        } else if (teamSeasonId === -1) {
            return <Button onClick={() => navigate('/Season')} variant='contained'>Create Season for {activeTeam}</Button>;
        }

        else {
            return (
                <div className='App' style={{ width: '20' }} variant="outlined">
                    <form onSubmit={handleSubmit} style={{ width: '20', alignContent: 'center' }}>
                        <p>Who is your opponent for today?</p>

                        {/* <Autocomplete
                             disablePortal
                             id="combo-box-demo"
                             options={top100Films}
                             sx={{ width: 300 }}
                             renderInput={(params) => <TextField {...params} label="Movie" />}
                         /> */}

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            required
                            value={selectedGame || ''}
                            label="Opponent"
                            onChange={(event) => {
                                handleSelectedGameChange(event.target.value)
                                // Reset error state
                            }}
                            sx={{ width: '300px', marginBottom: '20px' }}
                            error={opponentError} //Add error prop
                            helperText={opponentError ? 'Incorrect email or password' : ''}
                        >
                            {
                                season?.games?.map((game) => {
                                    return (
                                        <MenuItem disabled={game.played ? true : false} key={game.id} value={game.id}>{game.played ? `${game.opponent} - Played` : game.opponent}</MenuItem>

                                    )
                                })
                            }

                        </Select>
                        <br />

                        <p>Age group?</p>

                        <TextField disabled placeholder={displayedAgeGroup} style={{ width: '300px' }} />
                        <br />

                        <br />
                        <p>Type of Game?</p>
                        <Select
                            disabled
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeOfGame}
                            placeholder="Type of Game"
                            onChange={(event) => {
                                setTypeOfGame(event.target.value)

                            }}
                            sx={{ width: '300px' }}
                        >
                            {
                                availableGameTypes.map((gameType) => {
                                    return (<MenuItem value={gameType.id}>{gameType.type}</MenuItem>)

                                })
                            }
                        </Select>
                        <br />
                        <br />
                        <p>Time of kickoff?</p>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedKickOffTime}
                            label="Kickoff Time"
                            onChange={(event) => {
                                setSelectedKickOffTime(event.target.value)
                                dispatch(setKickoffTime(event.target.value))
                                setKickOffTimeError(false)
                            }}
                            sx={{ width: '300px', marginBottom: '15px' }}
                            error={kickOffTimeError}// Add error prop
                            helperText={kickOffTimeError ? 'Please complete this field' : ''}
                        >
                            <MenuItem key={'08:00'} value={'08:00'}>08:00</MenuItem>
                            <MenuItem key={'08:30'} id="half" value={'08:30'}>08:30</MenuItem>
                            <MenuItem key={'09:00'} value={'09:00'}>09:00</MenuItem>
                            <MenuItem key={'09:30'} id="half" value={'09:30'}>09:30</MenuItem>
                            <MenuItem key={'10:00'} value={'10:00'}>10:00</MenuItem>
                            <MenuItem key={'10:30'} id="half" value={'10:30'}>10:30</MenuItem>
                            <MenuItem key={'11:00'} value={'11:00'}>11:00</MenuItem>
                            <MenuItem key={'11:30'} id="half" value={'11:30'}>11:30</MenuItem>
                            <MenuItem key={'12:00'} value={'12:00'}>12:00</MenuItem>
                            <MenuItem key={'12:30'} id="half" value={'12:30'}>12:30</MenuItem>
                            <MenuItem key={'13:00'} value={'13:00'}>13:00</MenuItem>
                            <MenuItem key={'13:30'} id="half" value={'13:30'}>13:30</MenuItem>
                            <MenuItem key={'14:00'} value={'14:00'}>14:00</MenuItem>
                            <MenuItem key={'14:30'} id="half" value={'14:30'}>14:30</MenuItem>
                            <MenuItem key={'15:00'} value={'15:00'}>15:00</MenuItem>
                            <MenuItem key={'15:30'} id="half" value={'15:30'}>15:30</MenuItem>
                            <MenuItem key={'16:00'} value={'16:00'}>16:00</MenuItem>
                            <MenuItem key={'16:30'} id="half" value={'16:30'}>16:30</MenuItem>
                            <MenuItem key={'17:00'} value={'17:00'}>17:00</MenuItem>
                            <MenuItem key={'17:30'} id="half" value={'17:30'}>17:30</MenuItem>
                            <MenuItem key={'18:00'} value={'18:00'}>18:00</MenuItem>
                            <MenuItem key={'18:30'} id="half" value={'18:30'}>18:30</MenuItem>
                            <MenuItem key={'19:00'} value={'19:00'}>19:00</MenuItem>
                            <MenuItem key={'19:30'} id="half" value={'19:30'}>19:30</MenuItem>
                            <MenuItem key={'20:00'} value={'20:00'}>20:00</MenuItem>
                            <MenuItem key={'20:30'} id="half" value={'20:30'}>20:30</MenuItem>
                            <MenuItem key={'21:00'} value={'21:00'}>21:00</MenuItem>

                        </Select>

                        <br />
                        <br />
                        <p>Track Extra Stats?</p>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={techTable}
                            label="Kickoff Time"
                            onChange={(event) => {
                                dispatch(setTechnicalTable(event.target.value))
                                setTechTableError(false)
                            }}
                            sx={{ width: '300px', marginBottom: '15px' }}
                            error={techTableError} //Add error prop
                            helperText={techTableError ? 'Please complete this field' : ''}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>

                        <br />

                        <Button variant="contained" type='submit' onClick={handleSubmit}>Start Game</Button>
                    </form>

                    {/* Snackbar for error message */}
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={handleCloseSnackbar}
                            severity="error"
                        >
                            {errorMessage}
                        </MuiAlert>
                    </Snackbar>
                </div>
            );
        }
    };

    const InGameComponent = () => {

        return (
            <div style={{ maxWidth: '100%', margin: '0 auto' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column', // Stack elements vertically on small screens
                    }}
                >
                    <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '10px' }}>
                        {schoolName} vs {stateOpponent ? stateOpponent : 'No Team Selected'}
                        <br />
                        {displayedAgeGroup}
                    </Typography >

                </div>

                {/* Timer component needs to run here: */}
                <Timer />

                {/* Score component needs to run here */}
                <Score team={team ? team : getTeamForActiveTeam(activeTeam)} />


                {/* Extra stats needs to come here */}
                {techTable ?
                    <>
                        <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />
                        <ExtraStats />
                    </>
                    :
                    <></>
                }

                <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />

                {/* Subs component needs tnpmo run here */}
                <Substitutions team={team ? team : getTeamForActiveTeam(activeTeam)} />

                <Divider
                    style={{ margin: '0 auto', width: '80%', marginBottom: '10px', marginTop: '10px' }}
                />

                {/* Cards component needs to run here */}
                <Cards team={team ? team : getTeamForActiveTeam(activeTeam)} />

                <Divider
                    style={{ margin: '0 auto', width: '80%', marginBottom: '10px', marginTop: '10px' }}
                />

                <GenerateGameReport teamId={teamId} lineUp={team} team={combinedListPlayers} venue={venue ? venue : ''} extraStats={techTable} />
            </div>
        )
    };

    return (
        <>
            {renderActiveComponent()}

            <Snackbar
                open={openNotification}
                autoHideDuration={2000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? 'error' : 'success'}
                >
                    {notificationMessage}
                    <br />
                    {additionalMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default GameDay;
