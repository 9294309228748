import * as React from 'react';
import { useEffect } from 'react';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { managerRequests, devRequests, playerRequests, teamRequests } from '../../services/api-requests';
import Loader from '../loader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Players = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSchoolLoading, setisSchoolLoading] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [deleted, setDeleted] = React.useState(false);
    const [allPlayers, setAllPlayers] = React.useState([]);
    const [coachesInSchool, setCoachesInSchool] = React.useState(0);
    const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [playerToBeDeleted, setPlayerToBeDeleted] = React.useState('');
    const [selectedSchool, setSelectedSchool] = React.useState('');
    const [receivedSchools, setReceivedSchools] = React.useState([]);
    const [endpointPlayers, setEndpointPlayers] = React.useState([]);
    const [receivedTeams, setReceivedTeams] = React.useState([]);
    const [schoolId, setSchoolId] = React.useState();
    const [ageGroup, setAgeGroup] = React.useState();
    const [team, setTeam] = React.useState();
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);


    const ConfirmationModal = ({ open, onClose }) => {
        const handleNo = () => {
            onClose();
        };

        const handleYes = () => {
            handleYesClick();
            onClose();
        };

        return (
            <div>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete player
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete {playerToBeDeleted}?
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onClick={handleNo} variant="contained">
                                No
                            </Button>
                            <Button onClick={handleYes} variant="contained" color="error">
                                Yes
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        );
    };

    useEffect(() => {
        handleFilter();
        fetchPlayers();
        fetchSchools();
    }, []);

    useEffect(() => {
        fetchPlayers();
    }, [selectedSchool], schoolId);

    useEffect(() => {
        fetchTeams();
    }, [ageGroup]);


    const fetchSchools = async () => {
        setisSchoolLoading(true);
        try {
            const response = await devRequests.getSchoolList();
            setisSchoolLoading(false);

            if (response.status === 200) {
                setReceivedSchools(response.data);
            } else {
                setMessage('Something went wrong');
                setDeleted(true);
            }
        } catch (error) {
            console.error('Error fetching schools:', error);
        } finally {
            setisSchoolLoading(false);
        }
    };

    const fetchTeams = async () => {
        // setisSchoolLoading(true);
        // try {
        //     console.log('Schoold id and ageGroup right before request: ', schoolId, ' ', ageGroup)
        //     const response = await teamRequests.getTeam(schoolId, ageGroup);
        //     setisSchoolLoading(false);
        //     console.log('Teams received: ', response.data)
        //     if (response.status === 200) {
        //         setReceivedTeams(response.data);
        //     } else {
        //         setMessage('Something went wrong');
        //         setDeleted(true);
        //     }
        // } catch (error) {
        //     console.error('Error fetching teams:', error);
        // } finally {
        //     setisSchoolLoading(false);
        // }
    };

    const fetchPlayers = async () => {
        setIsLoading(true);
        const myPlayers = [];

        try {
            const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
            if (schoolIwant) {
                const response = await playerRequests.getPlayers();
                setIsLoading(false);
                setEndpointPlayers(response.data)

                if (response.status === 200) {
                    response.data.forEach((player) => {
                        const playerObj = {
                            name: player.firstName,
                            surname: player.lastName,
                            positionList: player.positionList,
                            caps: player.caps,
                            height: player.height,
                            weight: player.weight,
                        };

                        myPlayers.push(playerObj);
                    });

                    setAllPlayers(myPlayers);
                } else {
                    setMessage('Something went wrong');
                    setDeleted(true);
                }
            } else {
                // No school selected
                setAllPlayers([]);
            }
        } catch (error) {
            console.error('Error fetching coaches:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleYesClick = async () => {
        setIsLoading(true);
        try {
            const playerIWant = allPlayers.find((player) => player.firstName === playerToBeDeleted);

            if (playerIWant) {
                const playerIdIWant = playerIWant.id; // Add your logic for getting the player ID

                // Perform the API call to delete the player
                const response = await playerRequests.deletePlayer(playerIdIWant);
                console.log('Response from delete: ', response);

                fetchPlayers();
                if (response.status === 200) {
                    setOpenSnackbar(true);
                    setMessage('Player Deleted');
                } else {
                    setOpenSnackbar(true);
                    setMessage('Something went wrong');
                }
            } else {
                setMessage('Selected player not found');
                setDeleted(true);
            }
        } catch (error) {
            console.error('Error deleting player:', error);
        } finally {
            setHandleDeletedClicked(false);
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleEdit = (playerName, schoolId) => {

        //IF the user does not select a school, obv selectedSchool will be 0
        //Find the school of the user through the user.

        const playerIWant = allPlayers.find((player) => player.firstName === playerName);
        const playerIdIWant = playerIWant.id;



        if (selectedSchool !== '') {
            const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
            const schoolIdIWant = schoolIwant.id
            navigate(`/Managers/EditPlayer/${schoolIdIWant}/${playerIdIWant}`)

        }
        else {
            navigate(`/Managers/EditPlayer/${schoolId}/${playerIdIWant}`)
        }


        // navigate(`/Managers/EditPlayer/${schoolIdIWant}/${playerIdIWant}`)
    };

    const handleDeleted = (playerName) => {
        setHandleDeletedClicked(true);
        setPlayerToBeDeleted(playerName);
        handleOpen();
    };


    async function handleFilter(event) {

        let params = new Map();
        params.set('schoolId', schoolId);
        params.set('ageGroup', ageGroup);
        //We want to send over the team ID. Get all teams, find the matching one, return his id
        params.set('team', team);

        // if (!schoolId) {
        //     setMessage('Need to select a school')
        //     setOpenSnackbar(true)
        //     return;
        // }

        try {
            const response = await playerRequests.playersSearch(params);
            if (response.status === 200) {
                console.log('Response from filter: ', response);
                setAllPlayers(response.data)
            } else {
                setOpenSnackbar(true);
                setMessage('Something went wrong');
            }
        }
        catch (error) {
            console.log('Error: ', error)
        }
        finally {
            setIsLoading(false)
        }

    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h4>Filter by School</h4>
                   
                   
                    {
                        isSchoolLoading ?
                            <Loader />
                            :
                            <Select
                                id="schoolId"
                                name='schoolId'
                                placeholder="Select School"
                                sx={{ width: '150px', height: '80%',  }}
                                value={schoolId}
                                onChange={(e) => { setSchoolId(e.target.value) }}
                            >
                                {receivedSchools.map((school) => (
                                    <MenuItem key={school.name} value={school.id}>
                                        {school.name}
                                    </MenuItem>
                                ))}
                            </Select>
                    }
                </div>

                <div>
                    <h4>Filter by Age Group</h4>
                    <Select
                        id="ageGroup"
                        name='ageGroup'
                        placeholder="Select Split"
                        value={ageGroup}
                        onChange={(e) => setAgeGroup(e.target.value)}
                        sx={{ width: '150px', height: '80%',  }}
                    >
                        <MenuItem key={'14'} value={'14'}>u14</MenuItem>
                        <MenuItem key={'15'} value={'15'}> u15 </MenuItem>
                        <MenuItem key={'16'} value={'16'}> u16</MenuItem>
                        <MenuItem key={'17'} value={'17'}> u17</MenuItem>
                        <MenuItem key={'19'} value={'19'}> u19</MenuItem>
                    </Select>
                </div>


                <div>
                    <h4>Filter by Team</h4>
                    <Select
                        id="team"
                        name='team'
                        placeholder="Select Split"
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                        sx={{ width: '150px', height: '80%', }}
                    >
                        <MenuItem key={'A Team'} value={'A Team'}>A Team</MenuItem>
                        <MenuItem key={'B Team'} value={'B Team'}>B Team </MenuItem>
                        <MenuItem key={'C Team'} value={'C Team'}>C Team</MenuItem>
                        <MenuItem key={'D Team'} value={'D Team'}>D Team</MenuItem>
                        {
                            selectedSchool !== 'Dr EG Jansen' || selectedSchool !== 'Menlo Park' || selectedSchool !== 'Waterkloof' ?
                                <div>
                                    <MenuItem key={'E Team'} value={'E Team'}>E Team</MenuItem>
                                    <MenuItem key={'F Team'} value={'F Team'}>F Team </MenuItem>
                                    <MenuItem key={'G Team'} value={'G Team'}>G Team</MenuItem>
                                    <MenuItem key={'H Team'} value={'H Team'}>H Team</MenuItem>
                                </div>
                                :
                                <div>
                                    <MenuItem key={'No Team'} value={'No Team'}>No Team</MenuItem>

                                </div>
                        }
                    </Select>
                </div>
                <Button variant="contained" onClick={(event) => handleFilter(event)}>
                    Filter
                </Button>
            </div>
            <br />
            <TableContainer component={Paper}>
                <h3>Players</h3>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Surname</TableCell>
                            <TableCell align="right">Position List</TableCell>
                            <TableCell align="right">Caps</TableCell>
                            <TableCell align="right">Height</TableCell>
                            <TableCell align="center">Weight</TableCell>
                            <TableCell align="center">Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPlayers.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    {/* {selectedSchool !== undefined && ageGroup !== undefined ? 'No Players found' : "No filters have been selected"} */}
                                </TableCell>
                            </TableRow>
                        ) : (
                            allPlayers.map((row) => (
                                <TableRow
                                    key={row.firstName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.firstName}
                                    </TableCell>
                                    <TableCell align="right">{row.lastName}</TableCell>
                                    <TableCell align="right">{row.positionList}</TableCell>
                                    <TableCell align="right">{row.caps}</TableCell>
                                    <TableCell align="right">{row.height}</TableCell>
                                    <TableCell align="right">{row.weight}</TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleEdit(row.firstName, row.schoolId)} variant="text">
                                            Edit
                                        </Button>
                                        <Button color="error" onClick={() => handleDeleted(row.firstName)}>
                                            Delete Player
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}

                        {confirmationModalOpen && (
                            <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <br />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? 'error' : 'success'}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </div>
    );

}

export default Players


