import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
    fileObj: {},
    fileName: '',
    players: [],
}

// let response

// // Basic Crud functionality
// export const getTries = createAsyncThunk('site/endpointName', async () => {
//   // const response = await client.get('/api/endpointIamLookingFor', null)
//   return response.data
// })

// export const addTry = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data
// })


// export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
//   return extraData.body 
// })


// export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data 
// })


export const trialManagerSlice = createSlice({
    name: 'trialManager',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
            state.profileCreated = true
        },
        setTMUserLoggedIn: (state, action) => {
            state.tmUserLoggedIn = action.payload
        },
        setFileObj: (state, action) => {
            state.fileObj = action.payload;
            state.fileName = state.fileObj.name
        },

        setPlayers: (state, action) => {
            state.players.push(action.payload)
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        }
    }
})

//Selectors come below:
export const { setProfile, setTMUserLoggedIn, incrementByAmount,
    setFileObj, setPlayers,
} = trialManagerSlice.actions

export default trialManagerSlice.reducer