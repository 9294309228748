import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'
import LogoSelector from '../../services/logoSelector';

function PrintRTPPlayers(props) {
    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)

    async function printTeams() {

        const doc = new jsPDF();

        // Define the position and size of the logo
        const logoX = 10; // X-coordinate of the logo
        const logoY = 10; // Y-coordinate of the logo
        const logoWidth = 50; // Width of the logo (adjust as needed)
        const logoHeight = 50; // Height of the logo (adjust as needed)

        const helperX = 78; // X-coordinate of the header and table
        const headerX = 72; // X-coordinate of the header and table
        const headerY = logoY + logoHeight + 10; // Y-coordinate of the header (below the logo)
        const tableY = headerY + 20; // Y-coordinate of the table (below the header)

        const SCLogoWidth = 70
        const SCLogoHeight = 80
        // Add the logo to the PDF
        // doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        const mySchoolLogo = await LogoSelector(schoolName)
        doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`List of RTP Players`, helperX + 8, 20);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);


        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

        let currentY = logoY + logoHeight + 5; // Initial Y-coordinate for the first table

        // Add the date as a header
        doc.setFontSize(16);
        doc.text(`Return-to-Play Player List`, headerX, currentY);

        const table = [];
        const header = ['#', 'First Name', 'Last Name', 'Height', 'Weight', 'Position 1', 'Position 2'];
        table.push(header);

        props?.rtpList.forEach((player, index) => {
            const row = [
                index + 1, // Index starts from 0, so add 1 to start from 1
                player.firstName,
                player.lastName,
                player.height,
                player.weight,
                player.positionList[0] || '', // Use the first position, if available
                player.positionList[1] || '', // Use the second position, if available
            ];

            table.push(row);
        });

        // Generate the table using autoTable
        doc.autoTable({
            head: [table[0]],
            body: table.slice(1),
            startY: currentY + 10, // Set the Y-coordinate for the table with some space
        });
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Copyright © Squad Control`, helperX + 5, doc.internal.pageSize.height - 10);

        const date = new Date();
        doc.save(`RTP List ${date.toDateString()}.pdf`);
    }

    //Still need to finish - work with props
    return (

        <Button sx={{ marginTop: '-20px', marginBottom: '5px', p: 1, display: 'flex', flexDirection: 'row', alignContent: 'start' }}
            variant='contained' onClick={() => printTeams()}>
            Print List
        </Button>

    )
}

export default PrintRTPPlayers