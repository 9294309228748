import React, { useEffect, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';

function EventView({...props}) {
  const [contextMenu, setContextMenu] = useState(false);

  const handleEdit = () => {
    props.schedulerRef.triggerDialog(true, structureData());
    props.onClose();
  }

  const handleDelete = () => {
    props.onDelete(props.event_id);
    props.onClose();
  }

  const structureData = () =>{
    return {
      allDay: props.allDay,
      deletable: props.deletable,
      disabled: props.disabled,
      draggable: props.draggable,
      editable: props.editable,
      end: props.end,
      event_id: props.event_id,
      start: props.start,
      title: props.title,
    }
  }
    
  return (
    <div className="event-popper">
      <div className='event-popper-header'>
        <p>{props.title}</p>
        <button className='context-icon' onClick={
          (e) => {
            e.stopPropagation();
            setContextMenu(!contextMenu);
          }
        }>
          <MoreHorizIcon className='more-icon'/>
        </button>
      </div>
      <div className='event-popper-body'>
        <div className='event-popper-value'>
          <CalendarMonthIcon className='icon'/>
          <span>{props.start.toLocaleDateString()}</span>
        </div>
        <div className='event-popper-value'>
          <AccessTimeIcon className='icon' />
          <span>{props.start.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})} - {props.end.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}</span>
        </div>
      </div>

      <div className={`event-popper-context-menu ${contextMenu ? '' : 'invisible'}`}>
          <button className='context-item' onClick={handleEdit}>
            <EditIcon className='edit-icon'/>
            <span className='item-text'>Edit</span>
          </button>
          <button className='context-item' onClick={handleDelete}>
            <DeleteIcon className='delete-icon'/>
            <span className='item-text'>Delete</span>
          </button>
        </div>
    </div>
  );
}

export default EventView;
