import React from 'react'

function DeveloperSettings() {
    return (
        <div>
            Dev settings working
        </div>
    )
}

export default DeveloperSettings
