import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';

function EditView({...props}) {
  const [title, setTitle] = useState(props.scheduler.edited !== undefined ? props.scheduler.edited.title : "New Event");
  const [event_id, setId] = useState(props.scheduler.edited !== undefined ? props.scheduler.edited.event_id : -1);
  const [date, setDate] = useState(props.scheduler.edited !== undefined ? props.scheduler.edited.start : props.scheduler.state.start.value);
  const [startTime, setStartTime] = useState(props.scheduler.edited !== undefined ? props.scheduler.edited.start : props.scheduler.state.start.value);
  //TODO add duration value prop
  const [endTime, setEndTime] = useState(props.scheduler.edited !== undefined ? props.scheduler.edited.start : props.scheduler.state.start.value);

  const structureData = () =>{
    return {
      // end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime.getHours(), endTime.getMinutes()),
      //will be in dayjs format combine day with end time to get
      end: new Date(dayjs(date).year(), dayjs(date).month(), dayjs(date).date(), dayjs(endTime).hour(), dayjs(endTime).minute()),
      event_id: event_id,
      start: new Date(dayjs(date).year(), dayjs(date).month(), dayjs(date).date(), dayjs(startTime).hour(), dayjs(startTime).minute()),
      title: title,
    }
  }

  const handleSubmit = () => {
    var data = structureData();

    if(data.end < data.start) {
      alert("End time must be after start time");
      return;
    }

    props.onConfirm(data, props.scheduler.edited === undefined ? "create" : "edit");
    props.scheduler.close();
  }

  const calculateDuration = (start, end) => {
    //convert bot to day and find the difference in minutes
    var start = new Date(props.scheduler.state.start.value);
    var end = new Date(props.scheduler.state.end.value);

    var diff = (end.getTime() - start.getTime()) / 60000;
    return diff;
  }

  return (
    <div className='event-create'>
      {console.log(props.scheduler)}
      <div className='event-create-header'>
        <p>{props.scheduler.edited === undefined ? "Create Event" : "Edit Event"}</p>
      </div>
      <div className='event-create-body'>
        <div className='event-name'>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            defaultValue={dayjs(date)}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className='time-input'>
            <TimePicker
              label="Start Time"
              defaultValue={dayjs(startTime)}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            /> 
            <div className='spanner'></div>
            <TimePicker
              label="End Time"
              defaultValue={dayjs(startTime).add(props.scheduler.edited !== undefined ? calculateDuration(startTime, endTime) : 60, 'minute')}
              onChange={(newValue) => {
                setEndTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
        <div className='event-create-footer'>
          <Button variant='ghost' style={{marginRight: '6px'}} onClick={props.scheduler.close}>Close</Button>
          <Button variant='contained' color="primary" onClick={handleSubmit}>
            {props.scheduler.edited === undefined ? "Create" : "Edit"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditView;
