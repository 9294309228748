// import React from 'react'
// // import { Box, Button, Container, Paper } from '@material-ui/core';
// import { Add, Clear, Remove } from '@mui/icons-material';
// import { useEffect, useState } from 'react';
// import './Score.css';
// import { Button, Box, Container, Paper, useStepContext, Typography, useMediaQuery } from '@mui/material';
// import TryScorer from './tryScorer';
// import GoalKicker from './goalkicker';
// import { team } from './team';
// import { pushActions, setOpponent, setOurScore, setOpponentScore, setGameDayStarted } from './gamedaySlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { actions } from './actions';
// import { selectKicker } from './gamedaySlice';
// import { setRunningTimer } from './timerSlice';
// import GameReportGenerator from './reports/generateGameReport';

// function ExtraStats() {

//     const [ourStateScore, setOurStateScore] = useState(0);
//     const [opponentStateScore, setOppononetStateScore] = useState(0);
//     const [ourTryClicked, setOurTryClicked] = useState(false)
//     const [opponentTryClicked, setOpponentTryClicked] = useState(false)
//     const [conversionClicked, setConversionClicked] = useState(false)
//     const [penaltyClicked, setPenaltyClicked] = useState(false)
//     const [tryscored, setTryScored] = useState(false)
//     const [goalKickerUsed, setGoalKickerUsed] = useState(false)
//     const [kicker, setKicker] = useState()
//     const [kickerClicked, setKickerClicked] = useState();
//     const dispatch = useDispatch()
//     const [createdAction, setCreatedAction] = useState();
//     const stateKicker = useSelector((state) => state.gameday.kicker)
//     const time = useSelector((state) => state.gameday.timer)
//     const runningTime = useSelector((state) => state.timer.runningTimer)
//     const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)
//     const ourScore = useSelector((state) => state.gameday.ourScore)
//     const opponentScore = useSelector((state) => state.gameday.opponentScore)
//     const opponent = useSelector((state) => state.gameday.opponent)

//     const isMobile = useMediaQuery('(max-width: 600px)');


//     function handleHomeScore() {
//         // dispatch(setOurScore(ourScore + 5));
//         // setOurTryClicked(true);
//     }

//     function handleHomePenalty() {
//         // timeOfPenalty = runningTime
//         // setPenaltyClicked(true);
//         // dispatch(setOurScore(ourScore + 3));
//         // setPenaltyClicked(false);
//         // const newAction = `Penalty Kicked by ${stateKicker} with ${timeOfPenalty} minutes left in the ${activeHalf}`;
//         // dispatch(pushActions(newAction));
//     }

//     return (

//         <Box
//             sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 flexDirection: isMobile ? 'column' : 'row', // Stack elements vertically on smaller screens
//             }}
//         >
//             <Box>
//                 <Button
//                     style={{ marginRight: '3px' }}
//                     data-testid="incrementHome"
//                     onClick={() => { handleHomeScore(); }}
//                     variant="contained"
//                     color="primary"
//                 >
//                     <Add />
//                     Lineout Won
//                 </Button>

//                 <Button
//                     data-testid="incrementHome"
//                     onClick={() => { handleHomePenalty(); }}
//                     variant="contained"
//                     color="primary"
//                 >
//                      <Remove />
//                     Lineout Lost
//                 </Button>
//             </Box>
//             <Box>
//                 <Button
//                     style={{ marginRight: '3px' }}
//                     data-testid="incrementHome"
//                     onClick={() => { handleHomeScore(); }}
//                     variant="contained"
//                     color="primary"
//                 >
//                     <Add />
//                     Scrum Won
//                 </Button>

//                 <Button
//                     data-testid="incrementHome"
//                     onClick={() => { handleHomePenalty(); }}
//                     variant="contained"
//                     color="primary"
//                 >
//                     <Remove />
//                     Scrum Lost
//                 </Button>
//             </Box>
//         </Box>

//     )
// }

// export default ExtraStats


import React from 'react'
// import { Box, Button, Container, Paper } from '@material-ui/core';
import { Add, Clear, Remove } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import './Score.css';
import { Button, Box, Container, Paper, useStepContext, Typography, useMediaQuery, Grid } from '@mui/material';
import TryScorer from './tryScorer';
import GoalKicker from './goalkicker';
import { team } from './team';
import { pushActions, setOpponent, setOurScore, setOpponentScore, setGameDayStarted, IncreaseLineoutWon, IncreaseLineoutLost, IncreaseScrumWon, IncreaseScrumLost, IncreaseKnockOnn, pushExtraStatsAction } from './gamedaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { selectKicker } from './gamedaySlice';
import { setRunningTimer } from './timerSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import GameReportGenerator from './reports/generateGameReport';

function ExtraStats() {
    const [ourStateScore, setOurStateScore] = useState(0);
    const [opponentStateScore, setOppononetStateScore] = useState(0);
    const [ourTryClicked, setOurTryClicked] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [allGood, setAllGood] = React.useState(false);
    const [opponentTryClicked, setOpponentTryClicked] = useState(false);
    const [conversionClicked, setConversionClicked] = useState(false);
    const [penaltyClicked, setPenaltyClicked] = useState(false);
    const [tryscored, setTryScored] = useState(false);
    const [goalKickerUsed, setGoalKickerUsed] = useState(false);
    const [kicker, setKicker] = useState();
    const [kickerClicked, setKickerClicked] = useState();
    const dispatch = useDispatch();
    const [createdAction, setCreatedAction] = useState();
    const stateKicker = useSelector((state) => state.gameday.kicker);
    const time = useSelector((state) => state.gameday.timer);

    const ourScore = useSelector((state) => state.gameday.ourScore);
    const opponentScore = useSelector((state) => state.gameday.opponentScore);
    const opponent = useSelector((state) => state.gameday.opponent);
    const isMobile = useMediaQuery('(max-width: 600px)');

    const runningTime = useSelector((state) => state.timer.runningTimer)
    const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)

    let activeHalf
    isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'
    let timeofAction


    function handleLineOutWon() {
        timeofAction = runningTime
        const action = `Lineout won with ${timeofAction} left in the ${activeHalf}`
        dispatch(pushExtraStatsAction(action))
        dispatch(IncreaseLineoutWon())
        setMessage('Received')
        setAllGood(true)
        setOpenSnackbar(true)
    }

    function handleLineoutLost() {
        timeofAction = runningTime
        const action = `Lineout lost with ${timeofAction} left in the ${activeHalf}`
        dispatch(pushExtraStatsAction(action))
        dispatch(IncreaseLineoutLost())
        setMessage('Received')
        setAllGood(true)
        setOpenSnackbar(true)
    }

    function handleScrumWon() {
        timeofAction = runningTime
        const action = `Scrum won with ${timeofAction} left in the ${activeHalf}`
        dispatch(pushExtraStatsAction(action))
        dispatch(IncreaseScrumWon())
        setMessage('Received')
        setAllGood(true)
        setOpenSnackbar(true)

    }

    function handleScrumLost() {
        timeofAction = runningTime
        const action = `Scrum lost with ${timeofAction} left in the ${activeHalf}`
        dispatch(pushExtraStatsAction(action))
        dispatch(IncreaseScrumLost())
        setMessage('Received')
        setAllGood(true)
        setOpenSnackbar(true)
    }

    function handleKnockOn() {
        timeofAction = runningTime
        const action = `Knock-on with ${timeofAction} left in the ${activeHalf}`
        dispatch(pushExtraStatsAction(action))
        dispatch(IncreaseKnockOnn())
        setMessage('Received')
        setAllGood(true)
        setOpenSnackbar(true)
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid container spacing={1} sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'end',
                    alignItems: isMobile ? 'center' : 'end',
                    flexDirection: isMobile ? 'column' : 'column',

                }} >

                    <p style={{
                        textDecoration: "underline", marginTop: '10px',
                        marginRight: isMobile ? '0' : '38px'
                    }}>Lineouts</p>
                    <Grid item xs={6}>
                        <Button
                            style={{ marginRight: '3px' }}
                            data-testid="incrementHome"
                            onClick={() => { handleLineOutWon(); }}
                            variant="outlined"
                            color="primary"
                        >
                            <Add style={{ color: 'green' }} />
                            Lineout Won
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            data-testid="incrementHome"
                            onClick={() => { handleLineoutLost(); }}
                            variant="outlined"
                            color="primary"
                        >
                            <Remove style={{ color: 'red', marginRight: '3px' }} />
                            Lineout Lost
                        </Button>
                    </Grid>
                </Grid>

                <Box sx={{ margin: isMobile ? '10px 0' : '0 10px' }} />

                <Grid container spacing={1} sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'start',
                    alignItems: isMobile ? 'center' : 'start',
                    flexDirection: isMobile ? 'column' : 'column',

                }}>

                    <p style={{
                        textDecoration: "underline",
                        marginTop: '10px', marginLeft: isMobile ? '0' : '45px'
                    }}>Scrums</p>
                    <Grid item xs={6}>
                        <Button
                            style={{ marginRight: '3px' }}
                            data-testid="incrementHome"
                            onClick={() => { handleScrumWon(); }}
                            variant="outlined"
                            color="primary"
                        >
                            <Add style={{ color: 'green' }} />
                            Scrum Won
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            data-testid="incrementHome"
                            onClick={() => { handleScrumLost(); }}
                            variant="outlined"
                            color="primary"
                        >
                            <Remove style={{ color: 'red' }} />
                            Scrum Lost
                        </Button>
                    </Grid>
                </Grid>


            </Box>

            <br />
            <Grid item xs={6} style={{ marginBottom: '10px' }}>
                <Button
                    data-testid="incrementHome"
                    onClick={() => { handleKnockOn(); }}
                    variant="outlined"
                    color="primary"
                >
                    <Add style={{ color: 'red' }} />
                    Knock-on
                </Button>
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allGood ? "success" : "error"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>

        </div>



    );
}

export default ExtraStats;
