import { LteMobiledataOutlined } from "@mui/icons-material";
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
  todaysSession: [],
  allSessions: [],
  splitValues: [],
  activeSelectedTeam: "All Teams",
  weeklyFocusPoint: "",
  sessions: [
    // {
    //   id: 0,
    //   sessionType: 'Install',
    //   DateTime: '9 Sep 11:00',
    //   duration: '30',
    //   splitValues: [
    //     'Defence',
    //     'Rucking',
    //   ]
    // },
  ],
  //I don't know what to do yet with these arrays:
  finalFutureSessions: [],
  finalTodaySessions: [],
  futureSessions: [],
  status: 'idle',
  error: null
}


// let response

// // Basic Crud functionality
// export const fetchSites = createAsyncThunk('site/endpointName', async () => {
//   // const response = await client.get('/api/endpointIamLookingFor', null)
//   return response.data
// })

// export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data
// })


// export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
//   return extraData.body
// })


// export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data
// })



export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setFutureSessions: (state, action) => {
      state.futureSessions = action.payload

    },
    setActiveTabTeam: (state, action) => {
      state.activeSelectedTeam = action.payload

    },
    setStateWeeklyFocusPoint: (state, action) => {
      state.weeklyFocusPoint = action.payload;
    },
    setSplitValues: (state, action) => {
      state.splitValues = action.payload;
    },
    setAllSessions: (state, action) => {
      state.allSessions = action.payload;
    },
    addFinalFutureSessions: (state, action) => {
      state.finalFutureSessions.push(action.payload);
    },
    addFinalTodaySessions: (state, action) => {

      // state.finalTodaySessions.push(action.payload);
      state.finalTodaySessions = [...state.finalTodaySessions, action.payload]; // Create a new array

    },
    createTodaySession: (state, action) => {
      state.todaysSession.push(action.payload);
    },

    createSession: (state, action) => {
      state.sessions.push(action.payload);
    },
    //OLD WAY
    editTodaySession: (state, action) => {
      const { sessionType, sessionObject } = action.payload;

      // Find the session to edit based on sessionType
      const sessionToEdit = state.todaysSession.find(
        (session) => session.sessionType === sessionType
      );

      if (sessionToEdit) {
        // Update the session with the new data
        sessionToEdit.sessionType = sessionObject.sessionType;
        sessionToEdit.DateTime = sessionObject.time; // Update with your datetime property
        sessionToEdit.splitValues = sessionObject.splitValues;
      }
    },
    // editTodaySession: (state, action) => {
    //   const { sessionType, sessionObject } = action.payload;
    //   console.log('Payload Received', action.payload);
    //   console.log('finalTodaySessions at this moment', current(state.finalTodaySessions[0]));

    //   // Check if finalTodaySessions[0] is an array
    //   if (Array.isArray(state.finalTodaySessions[0])) {
    //     // Find the index of the session to edit based on sessionType
    //     const sessionToEditIndex = state.finalTodaySessions[0].findIndex(
    //       (session) => session.sessionType === sessionType
    //     );
    //     console.log('Found in slice index: ', sessionToEditIndex);

    //     if (sessionToEditIndex !== -1) {
    //       // Update the session with the new data
    //       const updatedSession = {
    //         ...state.finalTodaySessions[0][sessionToEditIndex],
    //         sessionType: sessionObject.sessionType,
    //         DateTime: sessionObject.time,
    //         splitValues: sessionObject.splitValues,
    //       };
    //       console.log('Updated: ', updatedSession);

    //       // Update the session within the array at the found index
    //       state.finalTodaySessions[0][sessionToEditIndex] = updatedSession;
    //     } else {
    //       console.log('Could not find session in slice to edit');
    //     }
    //   } else {
    //     console.log('finalTodaySessions[0] is not an array');
    //   }
    // },
    //OLD
    editFutureSession: (state, action) => {
      const { sessionType, sessionObject } = action.payload;

      // Find the session to edit based on sessionType
      const sessionToEdit = state.sessions.find(
        (session) => session.sessionType === sessionType
      );

      if (sessionToEdit) {
        // Create a new session object with the updated data
        const updatedSession = {
          ...sessionToEdit,
          sessionType: sessionObject.sessionType,
          DateTime: sessionObject.DateTime,
          splitValues: sessionObject.splitValues,
        };

        // Replace the old session with the updated one in the array
        state.sessions = state.sessions.map((session) =>
          session.sessionType === sessionType ? updatedSession : session
        );
      }
    },


    //NEW
    // editFutureSession: (state, action) => {
    //   const { sessionType, sessionObject } = action.payload;

    //   // Find the session to edit based on sessionType
    //   const sessionToEdit = state.sessions.find(
    //     (session) => session.sessionType === sessionType
    //   );

    //   if (sessionToEdit) {
    //     // Create a new session object with the updated data
    //     const updatedSession = {
    //       ...sessionToEdit,
    //       sessionType: sessionObject.sessionType,
    //       DateTime: sessionObject.DateTime,
    //       splitValues: sessionObject.splitValues,
    //     };

    //     // Replace the old session with the updated one in the array
    //     state.sessions = state.sessions.map((session) =>
    //       session.sessionType === sessionType ? updatedSession : session
    //     );
    //   }
    // },
    increment: state => {
      state.value += 1
    },
    decrement: state => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    }
  }
})

//Selectors come below:
export const { setFutureSessions, setActiveTabTeam, setStateWeeklyFocusPoint, setSplitValues, setAllSessions, addFinalTodaySessions, addFinalFutureSessions, editFutureSession, editTodaySession, createTodaySession, createSession, increment, decrement, incrementByAmount } = sessionSlice.actions

export default sessionSlice.reducer