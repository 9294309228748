export const getTeamForActiveTeam = (activeTeam) => {
  switch (activeTeam) {
    case 'A Team':
      return [
        {
          name: 'Jacques Neuhoff',
          nr: 1,
        },
        {
          name: 'Reinhard Duvenhage',
          nr: 2,
        },
        {
          name: 'Jannes Erasmus',
          nr: 3,
        },
        {
          name: 'Hugo Louw',
          nr: 4,
        },
        {
          name: 'Zuan Schaafsma',
          nr: 5,
        },
        {
          name: 'Carlo Brits',
          nr: 6,
        },
        {
          name: 'Gerhard Viviers ',
          nr: 7,
        },
        {
          name: 'Juantes Fourie',
          nr: 8,
        },
        {
          name: 'Jean-Jacques Thom',
          nr: 9,
        },
        {
          name: 'Liam Schutte',
          nr: 10,
        },
        {
          name: 'Kyle Finlay',
          nr: 11,
        },
        {
          name: 'Eghardt Pitzer',
          nr: 12,
        },
        {
          name: 'Alexander Griesel',
          nr: 13,
        },
        {
          name: 'Tobie de Klerk',
          nr: 14,
        },
        {
          name: 'Mieder Erasmus',
          nr: 15,
        },
        {
          name: 'Wian Hetzel',
          nr: 17,
        },
        {
          name: 'Albert van der Nest ',
          nr: 16,
        },
        {
          name: 'Logan Sanders',
          nr: 18,
        },
        {
          name: 'Marius Venter',
          nr: 19,
        },
        {
          name: 'Wynand',
          nr: 20,
        },
        {
          name: 'Heinz Meissner',
          nr: 21,
        },
        {
          name: 'MJ Grimbeek',
          nr: 22,
        },
        {
          name: 'Teagan Eckard',
          nr: 23,
        },
        {
          name: 'Rudi van der Merwe',
          nr: 24,
        },
        {
          name: 'Jason Drysdale',
          nr: 24,
        },
      ];
    case 'B Team':
      return [
        {
          name: 'Wian Hetzel',
          nr: 1,
        },
        {
          name: 'Albert van der Nest',
          nr: 2,
        },
        {
          name: 'Logan Sanders',
          nr: 3,
        },
        {
          name: 'Marius Venter',
          nr: 4,
        },
        {
          name: 'JP Muller',
          nr: 5,
        },
        {
          name: 'Heinz Meissner',
          nr: 6,
        },
        {
          name: 'MJ Grimbeek',
          nr: 7,
        },
        {
          name: 'Lucien Hoffman',
          nr: 8,
        },
        {
          name: 'Connor Rossouw',
          nr: 9,
        },
        {
          name: 'Wynand Gouws',
          nr: 10,
        },
        {
          name: 'Ruan Stassen',
          nr: 10,
        },
        {
          name: 'Teagan Eckardt',
          nr: 11,
        },
        {
          name: 'Rudi van der Merwe',
          nr: 12,
        },
        {
          name: 'Jason Drysdale',
          nr: 13,
        },
        {
          name: 'Eckard van Staden',
          nr: 14,
        },
        {
          name: 'Luan van Wyk',
          nr: 15,
        },
        {
          name: 'Joshua Hartslief',
          nr: 17,
        },
        {
          name: 'Ethan Keller',
          nr: 16,
        },
        {
          name: 'Dylan Nel',
          nr: 18,
        },
        {
          name: 'André Els',
          nr: 19,
        },
        {
          name: 'Lian Claassen',
          nr: 20,
        },
        {
          name: 'Tyron Brandon',
          nr: 21,
        },
        {
          name: 'Declan Barnard',
          nr: 22,
        },
        {
          name: 'Joshua  Joubert',
          nr: 23,
        },
      ];
    case 'C Team':
      return [
        {
          name: 'Joshua Hartslief',
          nr: 1,
        },
        {
          name: 'Ethan Keller',
          nr: 2,
        },
        {
          name: 'Dylan Nel',
          nr: 3,
        },
        {
          name: 'Alexander Mitchell',
          nr: 4,
        },
        {
          name: 'Andre Els',
          nr: 5,
        },
        {
          name: 'Lian Claassen',
          nr: 6,
        },
        {
          name: 'Ben du Toit',
          nr: 7,
        },
        {
          name: 'Ignus Botha',
          nr: 8,
        },
        {
          name: 'Wynand Gouws',
          nr: 9,
        },
        {
          name: 'Tyron Brandon',
          nr: 10,
        },
        {
          name: 'Chayse Topper',
          nr: 11,
        },
        {
          name: 'Joshua  Joubert',
          nr: 12,
        },
        {
          name: 'Hendré van Zyl',
          nr: 13,
        },
        {
          name: 'Anru Vellema',
          nr: 14,
        },
        {
          name: 'Declan Barnard',
          nr: 15,
        },
        {
          name: 'Gerhard',
          nr: 17,
        },
        {
          name: 'PW Bester',
          nr: 17,
        },
        {
          name: 'Logan Muller',
          nr: 16,
        },
        {
          name: 'Yannick Malherbe',
          nr: 18,
        },
        {
          name: 'Damian Roux',
          nr: 19,
        },
      ];
    // Add more cases for other teams here

    default:
      return [];
  }
};

// In your component, you can use the getTeamForActiveTeam function
// const activeTeam = useSelector((state) => state.gameday.activeTeam);
// const currentTeam = getTeamForActiveTeam(activeTeam);