import * as React from 'react';
import { Button } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from 'react-bootstrap/Table';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { resourceRequests } from '../../services/api-requests';
import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Loader from '../../layouts/loader';
import { useSelector } from 'react-redux';

export default function TemplateView() {
    const [show, setShow] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [deleted, setDeleted] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [templateData, setTemplateData] = useState(null);
    const [templateName, setTemplateName] = useState();
    const [templates, setTemplates] = useState([]);
    const [currentId, setCurrentId] = useState();
    const [wholeSchool, setWholeSchool] = useState(false)
    const [onlyMyAgeGroup, setOnlyMyAgeGroup] = useState(false)


    const ageGroup = parseInt(useSelector((state) => parseInt(state.user.profile.ageGroup)))
    const schoolId = parseInt(useSelector((state) => parseInt(state.user.profile.school)))

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const navigate = useNavigate()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    function navigateBack() {
        navigate('/Resource');
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            console.log(reader.result)
            const base64Data = reader.result.split(',')[1];
            setTemplateData(base64Data);
        };

        reader.readAsDataURL(file);
    };


    const handleDownloadClick = (id) => {

        var loadedTemplate = templates.find(template => template.id === id)
        var selectedTemplate = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + loadedTemplate.templateData;

        const base64Data = selectedTemplate;

        if (base64Data) {
            const downloadLink = document.createElement('a');
            downloadLink.href = base64Data;
            downloadLink.download = loadedTemplate.templateName + ".xlsx";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setMessage('Template successfuly downloaded');
            setOpenSnackbar(true);
        }
    };


    async function uploadFile() {
        if (templates.length == 0) {
            setCurrentId(1);
        }
        else {
            const lastItem = templates[templates.length - 1];
            setCurrentId(lastItem.id + 1);
        }

        const uniqueId = Math.floor(Math.random() * 1000000);


        if (templateName && templateData && (onlyMyAgeGroup || !onlyMyAgeGroup)) {
            const newTemplate = {
                id: uniqueId,
                name: templateName,
                data: templateData,
                schoolId: schoolId,
                ageGroup: onlyMyAgeGroup ? ageGroup : 0
            };

            try {
                setIsLoading(true)
                const response = await resourceRequests.uploadNewTemplate(newTemplate);
                if (response.status === 200) {
                    fetchTemplates();
                    handleClose();
                    setMessage('Template successfully uploaded');
                    setOpenSnackbar(true);
                } else {
                    setMessage('Template could not be uploaded');
                    setDeleted(true);
                    setOpenSnackbar(true);
                }
            }
            catch (error) {
                setMessage('Template could not be uploaded');
                setDeleted(true);
                setOpenSnackbar(true);
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            console.log("NO FILE");
            alert('Please ensure you have selected a file and completed all fields')
        }
    };

    const fetchTemplates = async () => {

        const myTemplates = [];

        try {
            const response = await resourceRequests.getTemplateList();

            if (response.status === 200) {
                response.data.forEach((template) => {
                    const templateObj = {
                        id: template.id,
                        templateName: template.templateName,
                        templateData: template.templateData
                    };
                    myTemplates.push(templateObj);
                });

                setTemplates(myTemplates);
                setIsError(false)
            }
            else {
                setIsError(true)
                console.log("Not Working")
            }
        }
        catch (error) {
            setIsError(true)
            console.error('Error fetching data:', error);
        }

    };

    const fetchSchoolTemplates = async () => {

        const myTemplates = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getSchoolTemplates(schoolId);

            if (response.status === 200) {
                response.data.forEach((template) => {
                    const templateObj = {
                        id: template.id,
                        templateName: template.templateName,
                        templateData: template.templateData
                    };
                    myTemplates.push(templateObj);
                });

                setTemplates(myTemplates);
                setIsError(false)
            } else {
                setIsError(true)
                console.log("not working")
            }

        } catch (error) {
            setIsError(true)
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const fetchAgeGroupTemplates = async () => {

        const myTemplates = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getAgeGroupTemplates(schoolId, ageGroup);

            if (response.status === 200) {
                response.data.forEach((template) => {
                    const templateObj = {
                        id: template.id,
                        templateName: template.templateName,
                        templateData: template.templateData
                    };
                    myTemplates.push(templateObj);
                });

                setTemplates(myTemplates);

            } else {
                console.log("not working")
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    function handleViewChange(view) {
        console.log('View received in function: ', view)
        switch (view) {
            case 'sc':
                fetchTemplates();
                break;
            case 'school':
                fetchSchoolTemplates();
                break;
            case 'agegroup':
                fetchAgeGroupTemplates();
                break;
            default:
                break;
        }
    }

    //delete file 
    const deleteTemplate = (templateId) => {
        const response = resourceRequests.deleteTemplate(templateId);


        try {
            if (response.status === 200) {
                fetchTemplates();
                setMessage('Template successfully deleted');
                setOpenSnackbar(true);
            } else {
                setMessage('Template could not be deleted');
                setDeleted(true);
                setOpenSnackbar(true);

                console.log("something went wrong")

            }
        }
        catch (error) {
            console.log("something went wrong")
        }
    }


    // useEffect(() => {
    //     fetchTemplates()
    // }, [])


    return (

        <Container>
            <Row>
                <Col xs={3} md={2}>
                    <Button variant='outlined' startIcon={<ArrowBackIcon />} onClick={navigateBack} fullWidth>
                        Back
                    </Button>
                </Col>

                <Col xs={2} md={2} lg={5}>
                    <Box sx={{ display: 'flex', justifyContent: 'start', mb: 1, mt: -2, ml: 5 }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Display templates</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="sc" control={<Radio />} label="SC Templates" onChange={() => { handleViewChange("sc") }} />
                                <FormControlLabel value="school" control={<Radio />} label="School" onChange={() => { handleViewChange("school") }} />
                                <FormControlLabel value="agegroup" control={<Radio />} label="Age Group" onChange={() => { handleViewChange("agegroup") }} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Col>

                <Col xs={2} md={2} lg={5}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                        <Button startIcon={<UploadIcon />} sx={{ marginRight: '5px' }} variant="contained" onClick={handleShow}>
                            New Template
                        </Button>
                    </Box>
                </Col>
            </Row>


            <Row>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 5 }}>
                    {
                        isLoading ? (
                            <Loader />
                        ) : (
                            isError ? (
                                <p>Could not retrieve templates</p>
                            ) : (
                                templates.length === 0 ? (
                                    <p>No templates found</p>
                                ) : (
                                    <Table striped hover bordered size="lg" >
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}></th>
                                                <th style={{ width: '75%' }}>Template Name</th>
                                                <th style={{ width: '10%' }}>Download</th>
                                                <th style={{ width: '10%' }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {templates.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.templateName}</td>
                                                    <td onClick={(e) => handleDownloadClick(item.id)}>{<DownloadIcon />}</td>
                                                    <td onClick={(e) => deleteTemplate(item.id)}>{<DeleteIcon />}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )
                            )
                        )
                    }


                    {/* {
                        isLoading ?
                            <Loader />
                            :
                            <Table striped hover bordered size="lg" >
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%' }}></th>
                                        <th style={{ width: '75%' }}>Template Name</th>
                                        <th style={{ width: '10%' }}>Download</th>
                                        <th style={{ width: '10%' }}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.templateName}</td>
                                            <td onClick={(e) => handleDownloadClick(item.id)}>{<DownloadIcon />}</td>
                                            <td onClick={(e) => deleteTemplate(item.id)}>{<DeleteIcon />}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                    } */}


                </Box>


            </Row>
            {/* modal for uploading and renaming template */}
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Upload new template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select file to upload</Form.Label>
                            <div>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileInputChange}
                                />
                            </div>

                            <div>
                            </div>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                            onChange={(e) => setTemplateName(e.target.value)}
                        >
                            <Form.Label>Template Name</Form.Label>
                            <Form.Control type="text" rows={3} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicRadio">
                            <Form.Check
                                type="radio"
                                label="This resource is for the whole school"
                                name="resourceType"
                                onChange={(e) => {
                                    // Handle radio button logic here
                                    setWholeSchool(true);
                                    setOnlyMyAgeGroup(false);
                                    console.log('wholeSchool: ', true);
                                    console.log('only age group: ', false)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicRadio">
                            <Form.Check
                                type="radio"
                                label="This resource is for my age group only"
                                name="resourceType"
                                onChange={(e) => {
                                    // Handle radio button logic here
                                    // Handle radio button logic here
                                    setOnlyMyAgeGroup(true);
                                    setWholeSchool(false);
                                    console.log('myAgeGroup: ', true);
                                    console.log('only school ', false)
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={uploadFile}>
                        Upload File
                    </Button>
                </Modal.Footer>
            </Modal>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? 'error' : 'success'}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </Container>

    );


}