import React, { useState, useEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Player } from "./player"
import { Button } from '@mui/material';
import AddPlayer, { AddPlayerModal } from './newTeam/addPlayer'
import { useSelector } from 'react-redux';
import { teamRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export function TeamList(props) {
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [isAddPlayerPopupOpen, setIsAddPlayerPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup));
  const schoolId = parseInt(useSelector((state) => state.user.profile.school))

  const addPlayerClick = (item) => {
    setShowAddPlayerModal(true)
    console.log('addPlayerClick');
    console.log(props.handleReserveSubmit);
  }

  async function onAddPlayer(playerObject) {
    let positionList = [0, 0];
    console.log('Properties of player received', playerObject)
    console.log('Object values: ', Object.values(playerObject))
    //Convert positions into positionList with IDs
    if (Object.values(playerObject).length !== 0 && playerObject.playerName !== '') {
      console.log('Working with this: ', playerObject)
      //Handle the postions 
      positionList[0] = playerObject.position;
      positionList[1] = playerObject.secondaryPosition;

      console.log('PlayerList: ', positionList)

      //Craft object to send over for service call
      const ReadyPlayerObject = {
        FirstName: playerObject.playerName,
        LastName: playerObject.playerLastName,
        Weight: parseInt(playerObject.weight),
        Height: parseInt(playerObject.height),
        PositionList: positionList,
        dateOfBirth: playerObject.dateOfBirth
      }


      console.log('Going to be sending  with this object: ', ReadyPlayerObject)
      try {
        props.setLoading(true)
        const response = await teamRequests.addPlayers(schoolId, ageGroup, [ReadyPlayerObject])
        if (response.status === 200) {
          console.log('Player Added ', response.data);
          props.showNotification('Player Added', false)
          props.setLoading(false)

          setError(false)
          setMessage('Player(s) added');
          setOpenSnackbar(true);

        }
      } catch (error) {
        console.log('Error trying to add Players: ', error)
        props.showNotification('Something went wrong', true)
        props.setLoading(false)
        setError(true)
        setMessage('Could not add Player(s)');
        setOpenSnackbar(true);
      }
      finally {
        setIsLoading(false)
        //Add a refresh method to get the teams again
        props.handleAddPlayer()
      }

    } else {
      console.log('PlayerObject not good')
    }
    //Add Height and Weight to the playerObject
  }

  const handleCloseSnackbar = (event, reason) => {

    if (reason === 'clickaway') {
      // //Add a refresh method to get the teams again
      // props.handleAddPlayer()
      return;
    }
    setOpenSnackbar(false);

    // //Add a refresh method to get the teams again
    // props.handleAddPlayer()
  };

  return (
    <div className="team-container">
      <div className='team-header'>
        <h2 className='team-title'>{props.teamData.name}</h2>
        {
          props.teamData.id !== 999 ?
            <Button variant="contained" color="error" className='team-delete-button' onClick={() => props.deleteTeam(props.teamData.id)}>X</Button>
            :
            <></>
        }
      </div>

      <Droppable key={props.index} droppableId={String(props.teamData.id)}>
        {(provided, snapshot) => (
          <div className='team-list' ref={provided.innerRef} {...provided.droppableProps} isdraggingover={snapshot.isDraggingOver.toString}>
            {
              props.players.length !== 0 && props.players !== undefined ?

                props?.players?.map((player, index) => {
                  console.log('player id: ', player.id)
                  return (
                    <Player key={player.id} playerData={player} index={index} />
                  )
                }
                )

                :
                <></>
            }

            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <AddPlayer

        isOpen={showAddPlayerModal}
        setReserveReportWindowStatus={setShowAddPlayerModal}
        onAddPlayer={onAddPlayer}
      >
      </AddPlayer>
      {
        // Only available players can have an option to Add Players
        props.teamData.id === 999 ?
          <Button variant='ghost' onClick={addPlayerClick}>Add Player</Button>
          :
          <></>
      }

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={error ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}