import React from 'react'

function TrialManager() {
    return (
        <div>
            <h3>Trial Manager</h3>
        </div>
    )
}

export default TrialManager
