import axios from "axios";
import api from "./api-url";

//TRELLO BUG REQUESTS
//Saving these in the file instead of in a .env file, mainly because trello won't have any sensitive data so if the key is exposed it's not a big deal.
const trelloAppKey = "4a5b42b8be5dfa79fd7ee75b61e7f72f"
const trelloToken = "ATTA4cc3c5894b4868688c4ae58a62856784e38aeaf5a7ca79433816f3aac9c9c46c2CBC1568";

/**
 * @brief Creates a new bug in the Trello board
 * @param name - The title of the bug card
 * @param description - The description of the bug
 * @returns - The API call
 */
const submitBug = (name, description, url) => {
    var newCard = {
        key: trelloAppKey,
        token: trelloToken,
        name: name,
        desc: description,
        urlSource: url,
        pos: "bottom",
        idList: "6548f06d363d6cc747b42e2b"
    };

    return axios.post('https://api.trello.com/1/cards', newCard, {
        headers: {
            'Content-Type': 'application/json'

        }
    });
}


export const trelloRequests = {
    submitBug
}
////////


const headers = {
    'Content-Type': 'application/json'
}
/**
 * @brief Sends a login request to the API
 * @param username - The username of the user
 * @param password - The password of the user
 * @returns - The API call
 */
// const loginUser = (username, password) => {
//     return api.post("/Login", {
//         username: username,
//         password: password
//     });
// }


/////USER REQUESTS
const loginUser = async (Email, Password) => {
    try {
        const response = await api.post("/Users/Login", {
            Email: Email,
            Password: Password
        });
        return response;
    } catch (error) {
        throw error;
    }
}

const registerUser = async (FirstName, LastName, Email, Password, SchoolId, AgeGroup, marketing,) => {
    try {
        const response = await api.post("/Users/Register", {
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            Password: Password,
            marketing: true,
            SchoolId: SchoolId,
            AgeGroup: AgeGroup,
        });
        return response
    }
    catch (error) {
        throw error;
    }

}

const registerSchool = async (FirstName, LastName, Email, Password, SchoolName, schoolLogo) => {
    try {
        const response = api.post("/Manager/RegisterSchool", {
            firstName: FirstName,
            lastName: LastName,
            email: Email,
            password: Password,
            schoolName: SchoolName,
            schoolLogo: schoolLogo,
            marketing: true
        });

        return response
    }
    catch (error) {
        throw error;
    }

}


const updateCoachingData = async (userId, ageGroup, roleId,) => {
    try {
        console.log('Data received in endpoint: ', 'userId: ', userId, 'Age group: ', ageGroup, 'RoleID: ', roleId)
        const response = api.post("/Users/UpdateCoachingData", {
            userId: userId,
            ageGroup: ageGroup,
            roleId: roleId,
        });
        return response
    }
    catch (error) {
        throw error;
    }

}

const updateProfile = async (userId, email, password, newPassword) => {
    try {
        const response = api.put("/Users/UpdateCoachingData", {
            userId: userId,
            email: email,
            password: password,
            newPassword: newPassword,
        });
        return response
    }
    catch (error) {
        throw error;
    }

}


const getCoachingRoles = async () => {
    const response = await api.get("/Users/GetAllCoachingRoles");
    return response
}

const getAllSchools = async () => {
    const response = await api.get("/Users/GetAllSchools");
    return response
}

export const userRequests = {
    loginUser,
    registerUser,
    registerSchool,
    getCoachingRoles,
    updateCoachingData,
    getAllSchools,
    updateProfile
}
/////////




//DEV/ADMIN REQUESTS
const approveSchool = async (schoolId) => {
    console.log('SchoolID Received before approval: ', schoolId)
    try { return api.post(`/Dev/ApproveSchool/${schoolId}`); }
    catch (error) { throw error; }
}

const deleteSchool = async (schoolId) => {
    console.log('SchoolID Received before approval: ', schoolId)
    try { return api.delete(`/Dev/DeleteSchool/${schoolId}`); }
    catch (error) { throw error; }
}


//get request example
const getSchoolList = async () => {
    const response = await api.get("/Dev/GetAllSchools");
    return response
}

const getSchoolByID = async (schoolId) => {
    try { return await api.get(`/Dev/GetSchool/${schoolId}`); }
    catch (error) { throw error; }
}

const getSubscriptionStatus = async () => {
    try { return await api.get(`/Dev/GetSubscriptionStatuses`); }
    catch (error) { throw error; }
}






const updateManager = async (schoolId, newManagerId) => {
    try {
        const response = api.put(`/Dev/UpdateManager/`, {
            schoolId: schoolId,
            newManagerId: newManagerId,
        });

        return response
    }
    catch (error) {
        throw error;
    }
}

const setSubscriptionStatus = async (schoolId, statusId) => {
    try {
        const response = api.put(`/Dev/SetSubscriptionStatus/`, {
            schoolId: schoolId,
            statusId: statusId,
        });

        return response
    }
    catch (error) {
        throw error;
    }
}

export const devRequests = {
    getSchoolList,
    approveSchool,
    deleteSchool,
    getSchoolByID,
    updateManager,
    getSubscriptionStatus,
    setSubscriptionStatus,
}
/////////


///Resource requests

const getFileList = async () => {
    try { return await api.get(`/Resource/GetFiles`); }
    catch (error) { throw error; }
}

const getSchoolFiles = async (schoolId) => {
    try { return await api.get(`/Resource/GetSchoolFiles/${schoolId}`); }
    catch (error) { throw error; }
}

const getAgeGroupFiles = async (schoolId, ageGroup) => {
    try { return await api.get(`/Resource/GetAgeGroupFiles/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getFileByID = async (fileId) => {
    try { return await api.get(`/Resource/GetFile/${fileId}`); }
    catch (error) { throw error; }
}

const uploadNewFile = async (fileData) => {
    try {
        return await api.post(`/Resource/UploadFile`, {
            id: fileData.id,
            fileName: fileData.name,
            fielType: fileData.type,
            fileData: fileData.data,
            schoolId: fileData.schoolId,
            ageGroup: fileData.ageGroup

        });
    }
    catch (error) { throw error; }
}

const deleteFile = async (fileId) => {
    try { return api.delete(`/Resource/DeleteFile/${fileId}`); }
    catch (error) { throw error; }
}


////Templates

const getTemplateList = async () => {
    try { return await api.get(`/Resource/GetTemplates`); }
    catch (error) { throw error; }
}

const getAgeGroupTemplates = async (schoolId, ageGroup) => {
    try { return await api.get(`/Resource/GetAgeGroupTemplates/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getSchoolTemplates = async (schoolId) => {
    try { return await api.get(`/Resource/GetSchoolTemplates/${schoolId}`); }
    catch (error) { throw error; }
}

const uploadNewTemplate = async (templateData) => {

    try {
        return await api.post(`/Resource/UploadTemplate`, {
            id: templateData.id,
            fileName: templateData.name,
            fileData: templateData.data,
            schoolId: templateData.schoolId,
            ageGroup: templateData.ageGroup
        });
    }
    catch (error) { throw error; }
}


const deleteTemplate = async (templateId) => {
    try { return api.delete(`/Resource/DeleteTemplate/${templateId}`); }
    catch (error) { throw error; }
}


///////User guides
const getUserGuides = async () => {
    try { return await api.get(`/Resource/GetUserGuides`); }
    catch (error) { throw error; }
}

const uploadNewUserGuide = async (userGuideData) => {
    console.log('User Guide data: ', userGuideData)
    try {
        return await api.post(`/Resource/UploadUserGuide`, {
            id: userGuideData.id,
            userGuideData: userGuideData.name,
            guideData: userGuideData.data,

        });
    }
    catch (error) { throw error; }
}

const deleteUserGuides = async (userGuideId) => {
    try { return api.delete(`/Resource/DeleteUserGuide/${userGuideId}`); }
    catch (error) { throw error; }
}


export const resourceRequests = {

    getFileList,
    getFileByID,
    uploadNewFile,
    deleteFile,
    getTemplateList,
    uploadNewTemplate,
    deleteTemplate,
    getSchoolFiles,
    getAgeGroupFiles,
    getAgeGroupTemplates,
    getSchoolTemplates,
    getUserGuides,
    uploadNewUserGuide,
    deleteUserGuides

}


//MANAGER REQUESTS
const getPlayers = async () => {

    try { return await api.get(`/Players/GetPlayers/`); }
    catch (error) { throw error; }
}

const playersSearch = (data) => {


    let url = "/Players/GetPlayers?";
    if (data.get("schoolId") !== undefined) url += "schoolId=" + data.get("schoolId") + "&";
    if (data.get("ageGroup") !== undefined) url += "ageGroup=" + data.get("ageGroup") + "&";
    if (data.get("team") !== undefined) url += "team=" + data.get("team") + "&";

    //Remove die laaste & van die url
    url = url.slice(0, -1);
    return api.get(url);
}


// const updatePlayer = async (playerId) => {
//     console.log('CoachID Received before approval: ', playerId)
//     try { return api.put(`/Players/UpdatePlayer/${playerId}`); }
//     catch (error) { throw error; }
// }

const updatePlayer = async (playerId, playerObj) => {
    console.log('playerId received: ', playerId)
    console.log('playerObj received: ', playerObj)
    try {
        const response = api.put(`/Players/UpdatePlayer/${playerId}`, playerObj
            //  {
            //     firstName: playerObj.firstName,
            //     lastName: playerObj.lastName,
            //     weight: playerObj.weight,
            //     height: playerObj.height,
            //     positionList: playerObj.positionList
            // }
        );

        return response
    }
    catch (error) {
        throw error;
    }
}

const deletePlayer = async (playerId) => {
    console.log('playerId Received before delete: ', playerId)
    try { return api.delete(`/Players/DeletePlayer/${playerId}`); }
    catch (error) { throw error; }
}

const setInjured = async (playerId) => {
    console.log('playerId before setting injured: ', playerId)
    try { return api.post(`/Players/SetInjured/${playerId}`); }
    catch (error) { throw error; }
}

const setReserveTeam = async (body) => {

    try { return api.post(`/Players/SetReserveTeam/`, body); }
    catch (error) { throw error; }
}

const getStatuses = async () => {

    try { return await api.get(`/Players/GetStatuses/`); }
    catch (error) { throw error; }
}


const UpdateStatus = async (playerId, statusId) => {
    console.log('playerId before updating status: ', playerId)
    try {
        return api.post(`/Players/UpdateStatus/`, {
            playerId: playerId,
            statusId: statusId
        });
    }
    catch (error) { throw error; }
}

export const playerRequests = {
    getPlayers,
    playersSearch,
    updatePlayer,
    deletePlayer,
    setInjured,
    getStatuses,
    UpdateStatus,
    setReserveTeam
}
//////////


//MANAGER REQUESTS
const getAllCoaches = async (schoolId) => {
    try { return await api.get(`/Manager/GetAllCoaches/${schoolId}`); }
    catch (error) { throw error; }
}

const approveCoach = async (coachId) => {
    console.log('CoachID Received before approval: ', coachId)
    try { return api.post(`/Manager/ApproveCoach/${coachId}`); }
    catch (error) { throw error; }
}

const deleteCoach = async (coachId) => {
    console.log('CoachID Received before deleting: ', coachId)
    try { return api.post(`/Manager/DeleteCoach/${coachId}`); }
    catch (error) { throw error; }
}

const resetPassword = async (coachId) => {
    try {
        console.log('Updating this users password: ', 'coachId: ', coachId,)
        const response = api.put("/Manager/ResetUserPassword", coachId);
        return response
    }
    catch (error) {
        throw error;
    }

}

export const managerRequests = {
    getAllCoaches,
    approveCoach,
    deleteCoach,
    resetPassword
}
///////

//TEAM REQUESTS
const getTeams = async (schoolId, ageGroup) => {
    console.log('SchoolID Received: ', schoolId, ' Age Group: ', ageGroup)
    try { return await api.get(`/Teams/GetTeams/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const hasUploadedList = async (schoolId, ageGroup) => {
    try { return await api.get(`/Teams/UploadedPlayersList/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getAvailablePlayers = async (schoolId, ageGroup) => {
    console.log('Getting available players right before service call: ', schoolId, ' ', ageGroup)
    try { return await api.get(`/Teams/GetAvailablePlayers/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getInjuredPlayers = async (schoolId, ageGroup) => {
    try { return await api.get(`/Teams/GetInjuredPlayers/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getAllPlayers = async (schoolId, ageGroup) => {
    try { return await api.get(`/Teams/GetAllPlayers/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getRTPPlayers = async (schoolId, ageGroup) => {

    try { return await api.get(`/Teams/GetRTPPlayers/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const addPlayers = async (schoolId, ageGroup, body) => {
    console.log("Before adding players: ", schoolId, ' ', ageGroup, ' ', body)



    //Look at arrays [] objects {} etc

    const playerObj = {
        schoolId: schoolId,
        ageGroup: ageGroup,
        players: [body],
    }

    console.log('===============')
    console.log('SchoolID: ', schoolId)
    console.log('ageGroup: ', ageGroup)
    console.log('body: ', body)
    console.log('Entire player obj: ', playerObj)
    console.log('===============')


    try {
        const response = await api.post("/Teams/AddPlayers", {
            schoolId: schoolId,
            ageGroup: ageGroup,
            players: body,
            // playerObj
        });
        return response
    }
    catch (error) {
        throw error;
    }
}

const addTeam = async (schoolId, ageGroup, name) => {
    console.log("Adding Team: ", schoolId, ' ', ageGroup, ' ', name)
    try {
        const response = await api.post("/Teams/AddTeam", {
            schoolId: schoolId,
            name: name,
            ageGroup: ageGroup,
        });
        return response
    }
    catch (error) {
        throw error;
    }
}

const updateTeams = async (body) => {
    try {
        //Check the array right after path if errors show up
        const response = await api.put("/Teams/UpdateTeams", body);
        return response
    }
    catch (error) {
        throw error;
    }
}
// Upload Teams need:
// [
//     {
//       "schoolId": 0,
//       "teamId": 0,
//       "teamName": "string",
//       "players": [
//         0
//       ],
//       "reserves": [
//         0
//       ]
//     }
//   ]

const getTeamsList = async (schoolId, ageGroup) => {
    try { return await api.get(`/Teams/GetTeamsList/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const getPlayerList = async (teamId) => {
    try { return await api.get(`/Teams/GetPlayerList/${teamId}`); }
    catch (error) { throw error; }
}

const deleteTeam = async (teamId) => {
    try { return api.delete(`/Teams/DeleteTeam/${teamId}`); }
    catch (error) { throw error; }
}


export const teamRequests = {
    getTeams,
    getAvailablePlayers,
    getInjuredPlayers,
    getAllPlayers,
    addPlayers,
    addTeam,
    getTeamsList,
    updateTeams,
    hasUploadedList,
    deleteTeam,
    getRTPPlayers,
    getPlayerList
}
///////



///////
//Season REQUESTS
const getSeasonTypes = async () => {
    try { return await api.get(`/Seasons/GetTypes/`); }
    catch (error) { throw error; }
}

const getSeason = async (teamId) => {
    try { return await api.get(`/Seasons/GetSeason/${teamId}`); }
    catch (error) { throw error; }
}

const hasActiveSeason = async (teamId) => {
    console.log('TeamID received in endpoint: ', teamId)
    try { return await api.get(`/Seasons/HasActiveSeason/${teamId}`); }
    catch (error) { throw error; }
}

const createNewSeason = async (body) => {
    console.log("Creating new Season: ", body)
    try {
        const response = await api.post("/Seasons/CreateNewSeason/",
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const updateSeason = async (body) => {
    console.log("Updating season: ", body)
    try {
        const response = await api.put(`/Seasons/UpdateSeason/`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const deleteSeason = async (seasonId) => {
    console.log('CoachID Received before deleting: ', seasonId)
    try { return api.delete(`/Seasons/DeleteSeason/${seasonId}`); }
    catch (error) { throw error; }
}

const DuplicateSeason = async (seasonObject) => {
    console.log("Duplicating Season: ", seasonObject)
    try {
        const response = await api.post(`/Seasons/DuplicateSeason/`,
            seasonObject
        );
        return response
    }
    catch (error) {
        throw error;
    }
}


//NOT USED
const AddBulkSeason = async (body) => {
    console.log("Adding bulk Season: ", body)
    try {
        const response = await api.post(`/Seasons/Duplicate/`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

export const seasonRequests = {
    getSeasonTypes,
    getSeason,
    createNewSeason,
    updateSeason,
    deleteSeason,
    hasActiveSeason,
    AddBulkSeason,
    DuplicateSeason
}
/////


//Game REQUESTS
const getGameTypes = async () => {
    try { return await api.get(`/Games/GetGameTypes/`); }
    catch (error) { throw error; }
}

const getClosestGame = async (teamId) => {
    try { return await api.get(`/Games/GetClosestGame/${teamId}`); }
    catch (error) { throw error; }
}



const AddGameToSeason = async (teamId, body) => {
    console.log("Creating new Game in season: ", teamId, ' ', body)
    try {
        const response = await api.post(`/Games/AddGameToSeason/${teamId}`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const startGame = async (teamId, gameId) => {
    try { return await api.get(`/Games/StartGame/${teamId}/${gameId}`); }
    catch (error) { throw error; }
}

const submitGameData = async (teamId, gameData) => {
    console.log('Gamedata received: ', gameData)
    try { return await api.post(`/Games/SubmitGameData/${teamId}`, gameData); }
    catch (error) { throw error; }
}

export const gameRequests = {
    getGameTypes,
    AddGameToSeason,
    getClosestGame,
    startGame,
    submitGameData
}

////


//Session REQUESTS
const getCategories = async () => {
    try { return await api.get(`/Sessions/GetCategories/`); }
    catch (error) { throw error; }
}

const getSplitTypes = async () => {
    try { return await api.get(`/Sessions/GetSplitTypes/`); }
    catch (error) { throw error; }
}

const getTeamSessions = async (teamId) => {
    try { return await api.get(`/Sessions/GetTeamSessions/${teamId}`); }
    catch (error) { throw error; }
}

const getAllSessions = async (schoolId, ageGroup) => {
    try { return await api.get(`/Sessions/GetAllSessions/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}


const AddBulkSessions = async (body) => {
    console.log("Adding bulk Sessions: ", body)
    try {
        const response = await api.post(`/Sessions/AddBulkSessions/`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const AddSession = async (body) => {
    console.log("Adding session: ", body)
    try {
        const response = await api.post(`/Sessions/AddSession/`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const DeleteSession = async (sessionId) => {
    console.log('sessionId Received before delete: ', sessionId)
    try { return api.delete(`/Sessions/DeleteSession/${sessionId}`); }
    catch (error) { throw error; }
}

const updateSession = async (sessionId, body) => {
    console.log("Updating session: ", sessionId, ' ', 'with: ', body)
    try {
        const response = await api.put(`/Sessions/UpdateSession/${sessionId}`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const SetWeeklyFocusPoint = async (teamId, focusPoint) => {
    console.log("Setting Weekly focus for team: ", teamId, 'focus: ', focusPoint)
    try {
        const response = await api.post(`/Sessions/${teamId}/SetWeeklyFocusPoint?value=${focusPoint}/`);
        return response
    }
    catch (error) {
        throw error;
    }
}

// /api/Sessions/{teamId}/SetWeeklyFocusPoint
// Sessions/{teamId}/SetWeeklyFocusPoint?value=Test
// Sessions/${teamId}/SetWeeklyFocusPoint?value=${focus point value}

export const sessionRequests = {
    getCategories,
    getSplitTypes,
    getTeamSessions,
    getAllSessions,
    AddBulkSessions,
    AddSession,
    DeleteSession,
    updateSession,
    SetWeeklyFocusPoint
}

//Stats for Session REQUESTS
const GetNumberOfSessions = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetNumberOfSessions/`); }
    catch (error) { throw error; }
}

const GetMostSplitsCompleted = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetMostSplitsCompleted/`); }
    catch (error) { throw error; }
}

const GetLeastSplitsCompleted = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetLeastSplitsCompleted`); }
    catch (error) { throw error; }
}

const GetMinutesTrained = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetMinutesTrained`); }
    catch (error) { throw error; }
}

const TopSessionCategory = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/TopSessionCategory`); }
    catch (error) { throw error; }
}

const GetNumberOfSplitsCompleted = async (teamId, splitTypeId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetNumberOfSplitsCompleted?splitType=${splitTypeId}`); }
    catch (error) { throw error; }
}

const GetSessionStatsSummary = async (teamId) => {
    try { return await api.get(`/Stats/Sessions/${teamId}/GetSessionStatsSummary`); }
    catch (error) { throw error; }
}

// Stats/Sessions/${teamId}/GetNumberOfSplitsCompleted?splitType=${splitTypeId}


export const sessionStats = {
    GetNumberOfSessions,
    GetMostSplitsCompleted,
    GetLeastSplitsCompleted,
    GetMinutesTrained,
    TopSessionCategory,
    GetNumberOfSplitsCompleted,
    GetSessionStatsSummary,

}

//Stats for Gameday REQUESTS
const GetWinPercentage = async (teamId) => {
    try { return await api.get(`/Stats/Games/${teamId}/GetWinPercentage/`); }
    catch (error) { throw error; }
}

const GetGameStatsSummary = async (teamId) => {
    try { return await api.get(`/Stats/Games/${teamId}/GetGameStatsSummary/`); }
    catch (error) { throw error; }
}

const GetTriesScored = async (teamId) => {
    try { return await api.get(`/Stats/Games/${teamId}/GetTriesScored`); }
    catch (error) { throw error; }
}

const GetTotalPointsScored = async (teamId) => {
    try { return await api.get(`/Stats/Games/${teamId}/GetTotalPointsScored`); }
    catch (error) { throw error; }
}


export const gamedayStats = {
    GetWinPercentage,
    GetGameStatsSummary,
    GetTriesScored,
    GetTotalPointsScored,
}

//Stats for Player REQUESTS
const GetMinutesPlayed = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetMinutesPlayed/`); }
    catch (error) { throw error; }
}

const GetPointsScored = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetPointsScored/`); }
    catch (error) { throw error; }
}

const GetPlayerTriesScored = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetTriesScored`); }
    catch (error) { throw error; }
}

const GetMatchesPlayed = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetMatchesPlayed`); }
    catch (error) { throw error; }
}

const GetNumberOfCards = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetNumberOfCards`); }
    catch (error) { throw error; }
}

const GetNumberOfAwards = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetNumberOfAwards`); }
    catch (error) { throw error; }
}

const GetPlayerStatsSummary = async (playerId) => {
    try { return await api.get(`/Stats/Players/${playerId}/GetPlayerStatsSummary`); }
    catch (error) { throw error; }
}


export const playerStats = {
    GetMinutesPlayed,
    GetPointsScored,
    GetPlayerTriesScored,
    GetMatchesPlayed,
    GetNumberOfCards,
    GetNumberOfAwards,
    GetPlayerStatsSummary,
}

//Stats for Season REQUESTS
const GetHomeGames = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetHomeGames/`); }
    catch (error) { throw error; }
}

const GetAwayGames = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetAwayGames/`); }
    catch (error) { throw error; }
}

const GetHomeGamesWon = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetHomeGamesWon`); }
    catch (error) { throw error; }
}

const GetAwayGamesWon = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetAwayGamesWon`); }
    catch (error) { throw error; }
}

const GetInjuries = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetInjuries`); }
    catch (error) { throw error; }
}

const GetGamesSummary = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetGamesSummary`); }
    catch (error) { throw error; }
}

const GetCleanSheets = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetCleanSheets`); }
    catch (error) { throw error; }
}

const GetSeasonStatsSummary = async (teamId) => {
    try { return await api.get(`/Stats/Seasons/${teamId}/GetSeasonStatsSummary`); }
    catch (error) { throw error; }
}


export const seasonStats = {
    GetHomeGames,
    GetAwayGames,
    GetHomeGamesWon,
    GetAwayGamesWon,
    GetInjuries,
    GetGamesSummary,
    GetCleanSheets,
    GetSeasonStatsSummary,
}


//Stats for Teams REQUESTS
const GetMostMinutesPlayed = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetMostMinutesPlayed/`); }
    catch (error) { throw error; }
}

const GetTopScore = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetTopScore/`); }
    catch (error) { throw error; }
}

const GetMostTriesScored = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetMostTriesScored`); }
    catch (error) { throw error; }
}

const GetMostMatchesPlayed = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetMostMatchesPlayed`); }
    catch (error) { throw error; }
}

const GetMostCardsReceived = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetMostCardsReceived`); }
    catch (error) { throw error; }
}

const GetMostManOfTheMatchAwards = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetMostManOfTheMatchAwards`); }
    catch (error) { throw error; }
}

const GetTeamStatsSummary = async (teamId) => {
    try { return await api.get(`/Stats/Teams/${teamId}/GetTeamStatsSummary`); }
    catch (error) { throw error; }
}


export const teamStats = {
    GetMostMinutesPlayed,
    GetTopScore,
    GetMostTriesScored,
    GetMostMatchesPlayed,
    GetMostCardsReceived,
    GetMostManOfTheMatchAwards,
    GetTeamStatsSummary,
}

//Calendar REQUESTS
const GetEvents = async (schoolId, ageGroup) => {
    try { return await api.get(`/Calendar/GetEvents/${schoolId}/${ageGroup}`); }
    catch (error) { throw error; }
}

const AddEvent = async (body) => {
    try {
        const response = await api.post(`/Calendar/AddEvent`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const UpdateEvent = async (body) => {
    try {
        const response = await api.put(`/Calendar/UpdateEvent`,
            body
        );
        return response
    }
    catch (error) {
        throw error;
    }
}

const DeleteEvent = async (eventId) => {
    try { return api.delete(`/Calendar/DeleteEvent/${eventId}`); }
    catch (error) { throw error; }
}


export const calendarRequests = {
    GetEvents,
    AddEvent,
    UpdateEvent,
    DeleteEvent
}
