import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Avatar, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, SwipeableDrawer, useMediaQuery, Zoom, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import InsertEmoticonRoundedIcon from '@mui/icons-material/InsertEmoticonRounded';
import SensorsIcon from '@mui/icons-material/Sensors';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import InterestsRoundedIcon from '@mui/icons-material/InterestsRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import StadiumIcon from '@mui/icons-material/Stadium';



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: 'flex-end',
}));

const drawerWidth = 250;


const Aside = ({ image, collapsed, setCollapsed, toggled
    // , handleToggleSidebar
}) => {
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Home");

    const navigate = useNavigate();
    function togglePage(to) {
        navigate(to);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const menuItems = [
        {
            name: "Dashboard",
            icon: <FlagCircleRoundedIcon />,
            to: "/home",
            subMenu: []
        },
        {
            name: "Sessions",
            icon: <MapRoundedIcon />,
            to: "/sessions",
            subMenu: []
        },
        {
            name: "Team",
            icon: <GroupRoundedIcon />,
            to: "/team",
            subMenu: []
        },
        {
            name: "Stats",
            icon: <InsertEmoticonRoundedIcon />,
            to: "/stats",
            subMenu: []

        },
        {
            name: "Calendar",
            icon: <GroupsRoundedIcon />,
            to: "/calendar",
            subMenu: []

        },
        {
            name: "Settings",
            icon: <InterestsRoundedIcon />,
            to: "/settings",
            subMenu: []

        },

    ]


    const [state, setState] = React.useState(false);

    const logout = () => {
        console.log("Logging Out!")
        // setCookies("access_token", "")
        navigate('/');

    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };

    function ResponsiveDrawer({ children }) {
        const mobile = useMediaQuery('(min-width:700px)')

        return !mobile ?
            <SwipeableDrawer
                PaperProps={{
                    style: {
                        // backgroundColor: theme.palette.primary.dark,
                        borderTopRightRadius: "0.5em",
                    }
                }}
                sx={{
                    width: !collapsed ? drawerWidth : 0,
                    // backgroundColor: theme.palette.background.default,

                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                anchor={"left"}
                open={state}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
            >
                {children}
            </SwipeableDrawer >

            :

            <Drawer
                PaperProps={{
                    style: {
                        backgroundColor: 'grey',
                        borderTopRightRadius: "0.5em",
                    }
                }}
                sx={{
                    width: !collapsed ? drawerWidth : 0,
                    // backgroundColor: 'blue',

                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                anchor={"left"}
                variant="permanent"
            >
                {children}
            </Drawer >


    }

    return (
        <ResponsiveDrawer>
            <DrawerHeader>

                <Grid container justifyContent={"space-between"} alignItems="center">

                    <Grid item>
                        <Tooltip title={`Toggle dark mode`}>
                            <Avatar sx={{}}>
                                <IconButton onClick={() => { console.log('clicked') }}>
                                    <Brightness2Icon />
                                </IconButton>
                            </Avatar>
                        </Tooltip>
                    </Grid>
                    <Grid item>

                        <Tooltip title="Logout">
                            <Avatar sx={{}}>
                                <IconButton
                                    type='submit'

                                    variant='text'
                                    sx={{ mt: 1, mb: 1 }}
                                    onClick={() => {

                                        console.log('clicked')
                                    }}
                                >
                                    <LogoutIcon />

                                </IconButton>
                            </Avatar>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DrawerHeader>
            <Paper variant='elevation' elevation={3} sx={{ height: "100%", margin: "5px", padding: "5px" }} >
                <List>

                    {menuItems.map((item) => (
                        <Paper key={item.name} variant='elevation' elevation={selectedItem === item.name ? 4 : 0} sx={{ borderRadius: selectedItem === item.name ? "0.5em" : "1.5em", }}>
                            <ListItem

                                sx={{
                                    // backgroundColor: selectedItem === item.name ?
                                    //     theme.palette.mode === "light" ? theme.palette.secondary.main : theme.palette.secondary.dark
                                    //     : theme.palette.background.paper,
                                    marginTop: "5px",

                                    height: "3em",
                                    borderRadius: selectedItem === item.name ? "0.5em" : "1.5em",
                                    border: selectedItem === item.name ? "1px solid" : "",


                                    "&:hover": {
                                        // backgroundColor: theme.palette.mode === "light" ? theme.palette.secondary.light : theme.palette.secondary.main,
                                        border: "1px solid",
                                    }
                                }}
                                button onClick={() => {
                                    togglePage(item.to)
                                    setSelectedItem(item.name)
                                }} key={item.name}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>

                        </Paper>

                    ))}

                </List>

            </Paper>
            <Grid container direction={"column"} justifyItems="center" >
                <Grid item>
                    <Paper sx={{ margin: "4px", padding: "4px" }}>
                        <Grid container justifyContent={"center"}>
                            {/* Insert Project LOGO Here */}
                            {/* <img height={"40px"} src="" alt="project logo" />
              <img height={"40px"} src="" alt="project logo" /> */}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper sx={{ margin: "4px", padding: "4px" }}>
                        <Grid container justifyContent={"center"}>
                            <img height={"50px"} src={image} alt="react logo" />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <div
                        className="sidebar-btn-wrapper"
                        style={{
                            padding: '20px 24px',
                        }}
                    >
                        <a
                            href="#"
                            target="_blank"
                            className="sidebar-btn"
                            rel="noopener noreferrer"
                        >

                            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>

                            </span>
                        </a>
                    </div>
                </Grid>

            </Grid>

        </ResponsiveDrawer >
    )
};

export default Aside;



