import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect } from 'react';
import { managerRequests, userRequests } from '../../services/api-requests';
import Loader from '../../layouts/loader';


const Settings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { schoolId, coachId } = useParams();
  console.log('schoolId: ', schoolId);
  console.log('coachId: ', coachId);

  const [school, setSchool] = React.useState()
  const [ageGroup, setAgeGroup] = React.useState()
  const [role, setRole] = React.useState()
  const [level, setLevel] = React.useState()
  const [firstName, setFirstName] = React.useState()
  const [lastName, setLastName] = React.useState()
  const [email, setEmail] = React.useState()
  const [coachIWantId, setCoachIWantID] = React.useState()
  const [coaches, setCoaches] = React.useState([])
  const [coachingRoles, setCoachingRoles] = React.useState([]);
  // State for the second form

  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [allGood, setAllGood] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [schoolError, setschoolError] = React.useState(false);
  const [ageGroupError, setageGroupError] = React.useState(false);
  const [roleError, setroleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false)
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCoachingRoleLoading, setisCoachingRoleLoading] = React.useState(false)
  const [isRoleChanged, setIsRoleChanged] = React.useState(false)
  const [isAgeGroupChanged, setIsAgeGroupChanged] = React.useState(false)


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      navigate(-1)
      return;
    }
    setOpenSnackbar(false);
    navigate(-1)
  };

  useEffect(() => {
    fetchCoaches()
    fetchCoachingRoles()
  }, [schoolId, coachId])



  const fetchCoaches = async () => {
    try {
      setIsLoading(true)
      const response = await managerRequests.getAllCoaches(schoolId);
      console.log('response from coaches: ', response)
      if (response.status === 200) {
        setIsLoading(false)
        setCoaches(response.data);
      } else {
        console.log('Response not good in coaches endpoint: ', response);
      }
    } catch (error) {
      console.log('error in coaches endpoint: ', error);
    }
    finally {

      setIsLoading(false)
    }
  }

  const fetchCoachingRoles = async () => {
    try {
      setisCoachingRoleLoading(true)
      const response = await userRequests.getCoachingRoles();
      console.log('response from coaching roles: ', response)
      if (response.status === 200) {
        setisCoachingRoleLoading(false)
        setCoachingRoles(response.data);
      } else {
        console.log('Response not good in coaching roles endpoint: ', response);
      }
    } catch (error) {
      console.log('error in coaching roles endpoint: ', error);
    }
    finally {

      setisCoachingRoleLoading(false)
    }
  }

  useEffect(() => {
    console.log('Coaches: ', coaches);
    const coachIWant = coaches.find((coach) => coach.id === parseInt(coachId));
    console.log('This coach I want: ', coachIWant);

    if (coachIWant) {
      setCoachIWantID(coachIWant.id)
      setFirstName(coachIWant.firstName);
      setLastName(coachIWant.lastName);
      setEmail(coachIWant.email);
      setAgeGroup(coachIWant.ageGroup);

      //Before we set the role, just get the ID of that role
      const roleIWant = coachingRoles.find((role) => role.role === coachIWant.role)
      // console.log('RoleIWant in set: ', roleIWant.id)
      console.log('roleIWant: ', roleIWant)
      roleIWant !== null && roleIWant !== undefined ? setRole(roleIWant.id) : setRole(coachIWant.role)
      // setRole(coachIWant.role);

    }
  }, [coaches, coachId, coachingRoles]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isAgeGroupChanged && !isRoleChanged) {
      setAllGood(false);
      setMessage('Nothing has changed');
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true)
    console.log("ROLE WE ARE LOOKING FOR NOW: ", role)
    try {
      const response = await userRequests.updateCoachingData(coachIWantId, parseInt(ageGroup), role)
      console.log('Response from update: ', response)
      if (response.status === 200) {
        setAllGood(true);
        setMessage('Updated');
        setOpenSnackbar(true);

      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }



  }

  const handleSubmitSecondForm = async (event) => {
    event.preventDefault();

    if (!email && !password && !newPassword) {
      setAllGood(false);
      setMessage('Nothing has changed');
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true)
    console.log("ROLE WE ARE LOOKING FOR NOW: ", role)
    try {
      const response = await userRequests.updateProfile(coachIWantId, email, password, newPassword)
      console.log('Response from update: ', response)
      if (response.status === 200) {
        setAllGood(true);
        setMessage('Updated');
        setOpenSnackbar(true);

      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }



  }


  return (


    <Grid container spacing={2} >
      <CssBaseline />


      {/* Centered Image */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '-40px' }}>
        <img style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />
      </Grid>


      {/* Left side - Edit Coaching Profile */}
      < Grid item xs={12} sm={8} md={5} lg={6} >
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isCoachingRoleLoading || isLoading ?
            <Loader />
            :
            <>
              <Typography component="h1" variant="h5">
                Edit Coaching Profile
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                <p>Edit Coaching Data for:</p>

                <TextField
                  disabled="true"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  sx={{ width: '300px' }}
                  value={`${firstName} ${lastName}`}
                  id="firstName"
                />

                <p>Age Group</p>
                <Select
                  id="demo-simple-select"
                  value={ageGroup || ''}
                  label="Age Group"
                  onChange={(event) => {
                    setAgeGroup(event.target.value)
                    setIsAgeGroupChanged(true)
                  }}
                  sx={{ width: '300px' }}
                  error={ageGroupError} // Add error prop
                  helperText={ageGroupError ? 'Please select an age group' : ''}
                >
                  <MenuItem value={'14'}>14</MenuItem>
                  <MenuItem value={'15'}>15</MenuItem>
                  <MenuItem value={'16'}>16</MenuItem>
                  <MenuItem value={'17'}>17</MenuItem>
                  <MenuItem value={'19'}>19</MenuItem>
                </Select>


                <p>Coaching Role</p>
                <Select
                  id="demo-simple-select"
                  value={role || ''}
                  label="Coaching Role"
                  onChange={(event) => {
                    setRole(event.target.value);
                    setIsRoleChanged(true)
                  }}
                  sx={{ width: '300px' }}
                  error={roleError} // Add error prop
                  helperText={roleError ? 'Please select a role' : ''}
                >
                  {coachingRoles
                    .filter((role) => role.role !== 'Manager') // Exclude 'Manager' role
                    .map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.role}
                      </MenuItem>
                    ))}

                </Select>

                < br />
                <Button
                  type="submit"

                  variant="contained"
                  sx={{ mt: 3 }}
                >Submit Coaching Profile
                </Button>
                <br />

              </Box>
            </>
          }
        </Box>
      </Grid >


      {/* Right side - Edit Coaching Details */}
      <Grid item xs={12} sm={8} md={5} lg={6}>
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Second form inputs */}
          <Typography component="h1" variant="h5">
            Edit Coaching Details
          </Typography>

          <p style={{ marginTop: '10px' }}>Edit Profile details for:</p>
          <p style={{ marginBottom: '1px' }}>  Email</p>
          <TextField

            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{ width: '300px', mt: 0 }}
          />
          <p style={{ marginBottom: '1px' }}>  Password</p>
          <TextField

            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ width: '300px', mt: 0 }}
          />
          <p style={{ marginBottom: '1px' }}>  New Password</p>
          <TextField

            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            sx={{ width: '300px', mt: 0 }}
          />
          <br />

          {/* Button to submit the second form */}
          <Button
            type="button"
            variant="contained"
            onClick={handleSubmitSecondForm}
            sx={{ mt: 3 }}
          >
            Submit Coaching Details
          </Button>
        </Box>

      </Grid>
      <br />

      {/* Centered Image */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '-40px' }}>
        <Button
          type="submit"
          variant="text"
          sx={{ mt: 3 }}
          onClick={() => navigate(-1)}
        >Back
        </Button>
      </Grid>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={allGood ? "success" : "error"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </Grid>

  );
};


export default Settings;
