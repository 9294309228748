import React, { useState, useRef, useEffect } from 'react'
import { Button, Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useSelector } from 'react-redux';
import { pushActions, selectActions, selectStateSubs, selectTeam, updatePlayerGameTime } from './gamedaySlice';
import { addSub } from './gamedaySlice';
import { useDispatch } from 'react-redux';
import { playerRequests } from '../../services/api-requests';


function SubDialog({ open, handleClose, stateTeam }) {
    console.log('Stateteam in subDialog: ', stateTeam)
    const [sub, setSub] = React.useState();
    const [subbed, setSubbed] = React.useState();
    const halfLength = useSelector((state) => state.timer.halfLength)


    const [value, setValue] = React.useState();
    const radioGroupRef = useRef(null);

    const dispatch = useDispatch();



    const time = useSelector((state) => state.timer.runningTimer)
    const isSecondHalf = useSelector((state) => state.timer.isSecondHalf)

    let activeHalf
    isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'
    let timeOfSub


    useEffect(() => {
        isSecondHalf ? activeHalf = '2nd Half' : activeHalf = '1st Half'
    }, [])


    const handleChange = (event) => {
        setValue(event.target.value)
    };

    const handleSubmit = async () => {
        timeOfSub = time

        const [hours, minutes] = timeOfSub.split(':').map(Number);

        const totalMinutes = hours * 60 + minutes;
        console.log('Total minutes in sub: ', totalMinutes);

        //Need to find in starting and bench
        let subbedName

        //OLD -  subbedName = stateTeam.find((player) => player.id === subbed)
        subbedName = stateTeam.players.find((player) => player.id === subbed)
        if (subbedName === undefined) {
            subbedName = stateTeam.reservePlayers.find((player) => player.id === subbed)
        }
        console.log('SubbedName: ', subbedName)

        let subName
        subName = stateTeam.players.find((player) => player.id === sub)
        if (subName === undefined) {
            subName = stateTeam.reservePlayers.find((player) => player.id === sub)
        }
        //OLD - subName = stateTeam.find((player) => player.id === sub)
        console.log('Subname: ', subName)

        // const newAction = `Subbed ${subbedName.firstName} ${subbedName.lastName} for ${subName.firstName} ${subName.lastName} for ${value} with ${timeOfSub} minutes left in the ${activeHalf}`

        const newAction = `Subbed ${subbedName.firstName} ${subbedName.lastName} for ${subName.firstName} ${subName.lastName} for ${value} with ${timeOfSub} minutes left in the ${activeHalf}`

        console.log('newAction: ', newAction)
        dispatch(addSub(newAction))
        let gameTime
        if (activeHalf === '2nd Half') {
            gameTime = halfLength + (halfLength - totalMinutes)
            console.log(subbed, ' had ', gameTime, ' minutes of gameTime')

            dispatch(updatePlayerGameTime({
                type: 'sub',
                player: subbed,
                gameTime: gameTime,
                subbedWith: sub,
            }))

        }
        if (activeHalf === '1st Half') {
            console.log('HalfLength: ', halfLength)

            gameTime = halfLength - totalMinutes
            console.log(subbed, ' had ', gameTime, ' minutes of gameTime')

            dispatch(updatePlayerGameTime({
                type: 'sub',
                player: subbed,
                gameTime: gameTime,
                subbedWith: sub,
            }))
        }

        //If player is injured, set status to Injured
        if (value === 'Injury') {
            try {
                const response = await playerRequests.setInjured(subbedName.id);
                if (response.status === 200) {
                    console.log('Player set to Injured')
                }
                else {
                    console.log('Could not set player to Injured')
                }

            } catch (error) {
                console.log('Error in setting player to injured: ', error)
            }

        }
        handleClose();
    }

    const options = [
        'Tactical',
        'Blood',
        'Injury',
        'Game time',
    ]


    return (

        <Dialog
            fullWidth={false}
            widt='400px'
            maxWidth='400px'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Substitution</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Who are you subbing and with who?
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel htmlFor="try-scorer">Sub</InputLabel>
                        <Select
                            autoFocus
                            value={subbed}
                            onChange={(event) => setSubbed(event.target.value)}
                            label="sub"
                            inputProps={{
                                name: 'sub',
                                id: 'sub',
                            }}
                        >
                            {/* {
                                stateTeam.map((teamMember, index) => {
                                    if (teamMember.isReserve) {
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name}</MenuItem>
                                })
                            } */}

                            {
                                stateTeam?.players?.map((teamMember, index) => {
                                    // if (teamMember.isReserve) {
                                    //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    // }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                })
                            }
                            <Divider />
                            {
                                stateTeam?.reservePlayers?.map((teamMember, index) => {
                                    // if (teamMember.isReserve) {
                                    //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    // }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                })
                            }

                        </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel htmlFor="try-scorer">With who?</InputLabel>
                        <Select

                            value={sub}
                            onChange={(event) => setSub(event.target.value)}
                            label="sub"
                            inputProps={{
                                name: 'sub',
                                id: 'sub',
                            }}
                        >
                            {/* {
                                stateTeam.map((teamMember, index) => {
                                    if (teamMember.isReserve) {
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name}</MenuItem>
                                })
                            } */}

                            {
                                stateTeam?.players?.map((teamMember, index) => {
                                    // if (teamMember.isReserve) {
                                    //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    // }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                })
                            }
                            <Divider />
                            {
                                stateTeam?.reservePlayers?.map((teamMember, index) => {
                                    // if (teamMember.isReserve) {
                                    //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                    // }
                                    return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                })
                            }
                        </Select>
                        <RadioGroup
                            ref={radioGroupRef}
                            aria-label="ringtone"
                            name="ringtone"
                            value={value}
                            onChange={handleChange}
                        >
                            {options.map((option) => (
                                <FormControlLabel
                                    value={option}
                                    key={option}
                                    control={<Radio />}
                                    label={option}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleSubmit} variant='contained'>Submit</Button>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>

    )
}

export default SubDialog

