import { Button, CssBaseline, Grid, Typography } from '@mui/material';
import React from 'react';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SeasonComingUp(props) {
    const navigate = useNavigate();
    const activeSeason = useSelector((state) => state.season.activeSeason);
    const startDate = props?.season?.startDate;
    
    // Check if startDate is defined before using indexOf
    const locationOfT = startDate ? startDate.indexOf('T') : -1;
    
    // Check if locationOfT is not -1 before using slice
    const newDate = locationOfT !== -1 ? startDate.slice(0, locationOfT) : null;

    return (
        <Grid container justifyContent="center" alignItems="center">
            <CssBaseline />

            {/* Centered Image */}
            {/* <Grid item xs={12} sx={{  }}>
                <img
                    style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }}
                    src={SquadControlLogo}
                    alt='Squad Control Logo'
                />
            </Grid> */}
            {/* Centered Image */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                <img style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />
            </Grid>

            <Grid item xs={12} sm={8} md={5} lg={6}>
                <Box
                    sx={{
                        my: 2,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant='h6'>
                        Your:
                    </Typography>
                    <br />
                    <p>{props.season ? `${props.season.type} for ${activeSeason} is starting on:` : 'No Season found'}</p>

                    <Typography variant='h6' component='strong'>
                        {props.season ? <>{newDate}</> : 'No Season found'}
                    </Typography>
                    <br />
                    <Typography>
                        You will not be able to create any Sessions with a season not active yet.
                    </Typography>
                    <br />
                    <Typography>
                        You can change the start date of your season to start creating sessions.
                    </Typography>
                    <br />
                    <Button variant='contained' onClick={() => navigate('UpdateSeason')}>
                        Update Season
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SeasonComingUp;
