import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
    tmUserLoggedIn: false,
    status: 'idle',
    error: null,
    profileCreated: false,
    profile: [],
    teamsUploaded: false,
    fileCols: [],
    fileRows: [],
    fileName: '',
    players: [],
    amountOfGames: 0,
}

// let response

// // Basic Crud functionality
// export const getTries = createAsyncThunk('site/endpointName', async () => {
//   // const response = await client.get('/api/endpointIamLookingFor', null)
//   return response.data
// })

// export const addTry = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data
// })


// export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
//   return extraData.body 
// })


// export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data 
// })


export const tmUserSlice = createSlice({
    name: 'tmUser',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
            state.profileCreated = true
        },
        setTMUserLoggedIn: (state, action) => {
            state.tmUserLoggedIn = action.payload
        },

        setFileCols: (state, action) => {
            state.fileCols = action.payload
        },
        setFileRows: (state, action) => {
            state.fileRows = action.payload
        },
        setStatePlayers: (state, action) => {
            state.players.push(action.payload)
            state.teamsUploaded = true
            console.log("Payload received: ", action.payload)
        },
        setAmountOfGames: (state, action) => {
            state.amountOfGames = action.payload
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        }
    }
})

//Selectors come below:
export const { setProfile, setTMUserLoggedIn, incrementByAmount,
    setStatePlayers, setFileCols, setFileRows, setAmountOfGames
} = tmUserSlice.actions

export default tmUserSlice.reducer