import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
// import { team } from './team';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { pushActions, setKicker, updateKickerConversion, updateKickerPenalty } from './gamedaySlice';
import { Add, Clear, Remove } from '@mui/icons-material';
import { Divider } from '@mui/material';


export default function GoalKicker(props) {
    const [open, setOpen] = React.useState(true);
    const [conversionSelected, setConversionSelected] = useState(false);
    const [converter, setConverter] = React.useState('')
    const [stateKicker, setStateKicker] = useState('')
    const dispatch = useDispatch()
    const storeKicker = useSelector((state) => state.gameday.kicker)

    const newTeam = props.team.team
    const startingLineUp = props.team.team.players
    const bench = props.team.team.reservePlayers

    const handleClose = () => {
        if (!conversionSelected) {
            alert("What happened to the conversion?")
            // setOpen(true);
        }
        else {
            props.resetKickerClicked()
            setOpen(false);
        }
        // Add any additional logic or alerts here if needed
    };

    const handleChange = (event) => {
        console.log(event, ' was the event')
    };

    const handleSubmit = () => {
        console.log('Props is: ', props.type)
        // localStorage.setItem('Kicker', kicker)

        // dispatch(setKicker(kicker))
        const newAction = { payload: `Converted by: ${stateKicker}` };
        // dispatch(pushActions(newAction));
        // actions.push(newAction)

        if (!storeKicker) {
            console.log('storeKicker: ', storeKicker)
            alert("Please Select a kicker")
        }
        if (!conversionSelected && props.type === 'try') {
            alert("What happened to the conversion?")
            // setOpen(true);
        }

        else {
            if (props.type === 'penalty') {
                console.log('Dispatching penalty kick to store: ', storeKicker)
                dispatch(updateKickerPenalty(storeKicker))
            }

            props.resetKickerClicked()
            setOpen(false);
        }


        console.log(actions)
    };

    function handleKickerChange(kickerId) {

        dispatch(setKicker(kickerId));
        setStateKicker(kickerId)
    }


    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Goal Kicker</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                            // m: 'auto',
                            // width: 'fit-content',
                        }}
                    >


                        <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel htmlFor="converter">Who is the kicker?</InputLabel>
                            <Select
                                autoFocus
                                value={storeKicker ? storeKicker : stateKicker}
                                onChange={(event) => { handleKickerChange(event.target.value) }}
                                label="converter"
                                inputProps={{
                                    name: 'converter',
                                    id: 'converter',
                                }}
                            >
                                {/* {
                                    newTeam.map((teamMember, index) => {
                                        if (teamMember.isReserve) {
                                            return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name}</MenuItem>
                                    })
                                } */}
                                {
                                    startingLineUp?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName}</MenuItem>
                                    })
                                }
                                <Divider />
                                {
                                    bench?.map((teamMember, index) => {
                                        // if (teamMember.isReserve) {
                                        //     return <MenuItem key={teamMember.id} value={teamMember.id}>{teamMember.name} (R)</MenuItem>
                                        // }
                                        return <MenuItem key={teamMember.id} value={teamMember.id}>{index + 1}. {teamMember.firstName} {teamMember.lastName} (R)</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <br />
                        {
                            props.type === 'try' ?
                                <>
                                    <Button
                                        style={{ marginTop: '10px', marginBottom: '10px', }}
                                        data-testid="incrementHome"
                                        onClick={() => { props.handleHomeConversionOver(storeKicker); setConversionSelected(true) }}
                                        color="primary"
                                        variant='outlined'

                                    >
                                        <Add sx={{ color: "green", marginLeft: '-10px' }} />
                                        Conversion Over
                                    </Button>

                                    <Button
                                        style={{ marginBottom: '5px', }}
                                        data-testid="incrementHome"
                                        onClick={() => { props.handleHomeConversionMissed(storeKicker); setConversionSelected(true) }}
                                        color="primary"
                                        variant='outlined'
                                    >
                                        <Remove sx={{ color: "red" }} />
                                        Conversion Missed
                                    </Button>
                                </>

                                :
                                <></>
                        }

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} variant='contained'>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
