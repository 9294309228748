import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import { createTodaySession, editTodaySession } from './sessionSlice';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { managerRequests, sessionRequests } from '../../services/api-requests';
import WeeklyFocusPoint from './weeklyFocusPoint';
import TeamSessionsModal from './teamSessionsModal';
import Loader from '../../layouts/loader';
import EditIcon from '@mui/icons-material/Edit';


function EditTodaySesions() {


    const todaySessions = useSelector((state) => state.session.allSessions)
    console.log('AllSesions: ', todaySessions) //change variable to allSessions
    const [readyToEdit, setReadyToEdit] = useState(false);

    console.log('TodaySessions from State ', todaySessions)


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState('');
    const [warmup, setWarmup] = useState(true)
    const [warmupChecked, setWarmupChecked] = useState(false)
    const [stateSessionSplit, setStateSessionSplit] = useState(1);
    const [sessionType, setsessionType] = useState('');
    const [teamName, setTeamName] = useState('');
    const [sessionToEdit, setSessionToEdit] = useState('');
    const [createSession, setCreateSession] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [sessionId, setSessionId] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [typeError, setTypeError] = React.useState(false);
    const [durationError, setDurationError] = React.useState(false);
    const [timeError, setTimeError] = React.useState(false);
    const [backendSplitValues, setBackendSplitValues] = useState([]);
    const [categories, setCategories] = useState([]);
    const [aimOfSession, setAimOfSession] = useState([])
    const [equipmentNeeded, setEquipmentNeeded] = useState([])
    const [sessionFocusPoint, setFocusPoint] = useState([])
    const [weeklyFocusPoint, setWeeklyFocusPoint] = useState('')
    const [extraNotes, setExtraNotes] = useState('')
    const [coachAssignments, setCoachAssignments] = useState([-1]);
    const [coaches, setCoaches] = useState([]);
    const schoolId = parseInt(useSelector((state) => state.user.profile.school))
    const [teamId, setTeamId] = useState([]);
    const [isWeeklyFocusPopupOpen, setIsWeeklyFocusPopupOpen] = useState(false);
    const [isTeamSessionModal, setIsTeamSessionModal] = useState(false);
    const [sessionForTeam, setSessionForTeam] = useState([]);
    const [initialTeamId, setInitialTeamId] = useState(null);

    //Selectors
    const [splitValues, setSplitValues] = useState([]);
    const [newArrayValueIWant, setNewArrayValueIWant] = useState([]);

    const seasonId = useSelector((state) => state.season.backendSeason.id)
    const availableTeams = useSelector((state) => state.team.availableTeams)


    useEffect(() => {
        fetchCategories();
        fetchSplitTypes();
        fetchCoaches();
    }, [])


    useEffect(() => {
        // Update readyToEdit when todaySessions changes
        if (todaySessions && todaySessions.length > 0) {
            setReadyToEdit(true);
        }
    }, [todaySessions, sessionToEdit]);


    // Assuming 'allSessions' is your array of sessions
    const updatedSessionsArray = todaySessions.map((session) => {
        const { name, teamSessions: { todaySessions } } = session;

        // Check if todaySessions is not empty
        if (todaySessions && todaySessions.length > 0) {
            // Flatten todaySessions and include name in the object
            const flattenedSessions = todaySessions.map((todaySession) => ({
                name,
                ...todaySession,
            }));

            return flattenedSessions;
        }

        // If todaySessions is empty, return an empty array
        return [];
    }).flat();

    //Service Calls
    const fetchCoaches = async () => {

        setIsLoading(true);
        try {
            const response = await managerRequests.getAllCoaches(schoolId);
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Coaches received: ', response.data)

            if (response.status === 200) {

                setCoaches(response.data)
                setIsLoading(false)
            }
            else {
                console.log('In the else');
                setIsLoading(false)
            }

        } catch (error) {
            console.error('Error fetching coaches:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCategories = async () => {

        setIsLoading(true);
        try {
            const response = await sessionRequests.getCategories();
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Categories received: ', response.data)

            if (response.status === 200) {

                setCategories(response.data)
                setIsLoading(false)
            }
            else {
                console.log('In the else');
                setIsLoading(false)
            }

        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSplitTypes = async () => {

        setIsLoading(true);
        try {
            const response = await sessionRequests.getSplitTypes();
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Split Types received: ', response.data)

            if (response.status === 200) {

                setBackendSplitValues(response.data)
                setIsLoading(false)
            }
            else {
                console.log('In the else');
                setIsLoading(false)
            }

        } catch (error) {
            console.error('Error fetching split types:', error);
        } finally {
            setIsLoading(false);
        }
    };
    //////


    useEffect(() => {
        // Update the splitValues array when stateSessionSplit changes

        setSplitValues(new Array(stateSessionSplit).fill(''));

    }, [stateSessionSplit]);




    let sessionObject = []
    useEffect(() => {
        sessionObject = {
            sessionType,
            time,
            warmup,
            duration,
            splitValues,

        };

    }, [sessionType, time, splitValues, warmup]);



    async function handleSubmit(event) {
        event.preventDefault();

        if (!sessionType) {
            setTypeError(true);
        }
        if (!time) {
            setTimeError(true);
        }
        if (!duration) {
            setDurationError(true);
        }

        if (!sessionType || !time || !duration) {
            setErrorMessage('Please complete all fields');
            setOpenSnackbar(true);
            return;
        }

        // Ensure splitValues is not undefined before mapping
        const splitTypeList = Array.isArray(sessionObject.splitValues)
            ? sessionObject.splitValues.map((splitValue) => {
                const splitValueIWant = backendSplitValues.find((value) => value.type === splitValue);
                let idIWant;
                splitValueIWant !== undefined ? (idIWant = splitValueIWant.id) : (idIWant = null);
                return idIWant;
            })
            : [];


        //Need to convert sessionType to sesseionCategory ID
        const categoryIWant = categories.find((category) => category.category === sessionType)
        const catID = categoryIWant.id

        const splitList = newArrayValueIWant.map((element, index) => {
            const splitTypeId = splitValues[index] ? backendSplitValues.find((value) => value.type === splitValues[index])?.id || null : null;
            const coachId = coachAssignments[index] ? coachAssignments[index] === 'None' ? -1 : coachAssignments[index] : -1;

            return {
                splitTypeId,
                coachId,
            };
        });

        // //Convert Date and time in future session to ISO format
        // let dateAndTime = new Date(`${date} ${time}:00`).toISOString();

        //Convert Date and time in today session to ISO format
        let dateAndTime = new Date(`${new Date().toISOString().slice(0, 10)} ${time}:00`).toISOString();




        const newSessionObject = {
            sessionCategoryId: catID,
            teamId: teamId,
            seasonId: seasonId,
            date: dateAndTime,
            duration: duration,
            splitList: splitList,
            warmUp: warmup,
            focusPoints: sessionFocusPoint,
            aims: aimOfSession,
            equipment: equipmentNeeded,
            extraNotes: extraNotes,
        }



        //Check later if the teams array is more than one. Then use 
        try {
            const response = await sessionRequests.updateSession(sessionId, newSessionObject)
            // const response = await gameRequests.addGame(teamId, gameObject)

            setIsLoading(true)
            if (response.status === 200) {
                console.log('Response from Update Session: ', response.data)
                setError(false)
                setConfirmationMessage('Session Updated')
                setOpenSnackbar(true)
                setIsLoading(false)
            }
            else {
                console.log('Could not Update Session: ', response)
                setError(true)
                setConfirmationMessage('Could not Update Session')
                setOpenSnackbar(true)
                setIsLoading(false)
            }
        } catch (error) {
            console.log('Could not Update Session: ', error.response)
            setError(true)
            setConfirmationMessage('Could not Update Session')
            setOpenSnackbar(true)
            setIsLoading(false)
        } finally {
            setIsLoading(false)
            // setCreateSession(false);
            // dispatch(createTodaySession(sessionObject))
        }

        // setCreateSession(false);
        // console.log('Session object right before dispatch: ', sessionObject)
        // dispatch(createTodaySession(sessionObject))
        // navigate('/Sessions')
    }


    const handleCoachAssignmentChange = (index, coach) => {
        const newCoachAssignments = [...coachAssignments];
        newCoachAssignments[index] = coach;
        setCoachAssignments(newCoachAssignments);
    };


    function handleSessionSplitChange(value) {
        setStateSessionSplit(value);
        const newArray = new Array(parseInt(value, 10)).fill('');
        setNewArrayValueIWant(newArray);
        setSplitValues(newArray);
    }

    // Define a function to handle session selection
    function handleSessionSelection(event) {


        const [selectedCategory, selectedTime, selectedName] = event.target.value.split("_");


        const selectedSession = updatedSessionsArray.find(
            (session) =>
                session.category === selectedCategory &&
                session.time === selectedTime &&
                session.name === selectedName
        );


        const teamIWant = availableTeams.filter((team) => team.name === selectedSession.name)

        //Its still in array format so just select the first one
        const teamIdIWant = teamIWant[0].id

        if (selectedSession) {
            setTeamName(selectedSession.name)
            setTeamId(teamIdIWant)
            setSessionId(selectedSession.id)
            setsessionType(selectedSession.category);
            setTime(selectedSession.time);
            setDuration(selectedSession.duration);
            setAimOfSession(selectedSession.aims)
            setEquipmentNeeded(selectedSession.equipment)
            setExtraNotes(selectedSession.extraNotes)
            setFocusPoint(selectedSession.focusPoint)
            setSplitValues(selectedSession.splits);
            setWarmup(selectedSession.warmUp)
        }
        setSessionToEdit(event.target.value);
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (!error) {
            setOpenSnackbar(false);
            navigate('/Sessions')
        }
        setOpenSnackbar(false);
    };

    function goBack() {
        navigate('/Sessions/addSession');
    }

    function onTeamsSelected(teams) {
        setSessionForTeam(teams)

    }


    return (
        <div className='App' variant="outlined">

            {
                isLoading ?
                    <Loader />
                    :
                    <div style={{ marginTop: '-25px' }}>
                        {/* Set Focus Point for the week */}
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '-10px' }}>

                        </div>
                        {/* FORM COMES HERE */}
                        <form onSubmit={(customEvent) => handleSubmit(customEvent)} style={{ width: '20', alignContent: 'center' }}>
                            <Typography variant='h5'>Edit Today Session(s)</Typography>
                            <br />

                            {
                                readyToEdit && todaySessions ?
                                    (

                                        <>
                                            <p>Select Session to edit</p>

                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sessionToEdit}
                                                placeholder="Session To Edit"
                                                onChange={handleSessionSelection}
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                            >
                                                {
                                                    updatedSessionsArray.map((session, index) => {
                                                        const uniqueKey = `${session.category}_${session.time}_${session.name}`;
                                                        console.log('uniqueKey? ', uniqueKey)
                                                        return (
                                                            <MenuItem key={uniqueKey} value={uniqueKey}>{`${session.category} @ ${session.time} for ${session.name}`}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            {/*  Rest of coding here: */}
                                            <br />

                                            <p>Type of session</p>
                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sessionType}
                                                placeholder="Age Group"
                                                onChange={(event) => { setsessionType(event.target.value) }}
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                            >
                                                <MenuItem key={'Install'} value={'Install'}>Install</MenuItem>
                                                <MenuItem key={'Speed'} value={'Speed'}>Speed</MenuItem>
                                                <MenuItem key={'Intensity'} value={'Intensity'}>Intensity</MenuItem>
                                                <MenuItem key={'Man Up'} value={'Man Up'}>Man Up</MenuItem>
                                                <MenuItem key={'Recovery'} value={'Recovery'}>Recovery</MenuItem>
                                            </Select>
                                            <br />

                                            <p>Date and Time</p>
                                            {/* Needs to be imported from Calendar */}

                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={time}
                                                label="Time"
                                                onChange={(event) => { setTime(event.target.value) }}
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                            >
                                                <MenuItem key={'06:00'} value={'06:00'}>06:00</MenuItem>
                                                <MenuItem key={'06:30'} id="half" value={'06:30'}>06:30</MenuItem>
                                                <MenuItem key={'07:00'} value={'07:00'}>07:00</MenuItem>
                                                <MenuItem key={'07:30'} id="half" value={'07:30'}>07:30</MenuItem>

                                                <MenuItem key={'08:00'} value={'08:00'}>08:00</MenuItem>
                                                <MenuItem key={'08:30'} id="half" value={'08:30'}>08:30</MenuItem>
                                                <MenuItem key={'09:00'} value={'09:00'}>09:00</MenuItem>
                                                <MenuItem key={'09:30'} id="half" value={'09:30'}>09:30</MenuItem>
                                                <MenuItem key={'10:00'} value={'10:00'}>10:00</MenuItem>
                                                <MenuItem key={'10:30'} id="half" value={'10:30'}>10:30</MenuItem>
                                                <MenuItem key={'11:00'} value={'11:00'}>11:00</MenuItem>
                                                <MenuItem key={'11:30'} id="half" value={'11:30'}>11:30</MenuItem>
                                                <MenuItem key={'12:00'} value={'12:00'}>12:00</MenuItem>
                                                <MenuItem key={'12:30'} id="half" value={'12:30'}>12:30</MenuItem>
                                                <MenuItem key={'13:00'} value={'13:00'}>13:00</MenuItem>
                                                <MenuItem key={'13:30'} id="half" value={'13:30'}>13:30</MenuItem>
                                                <MenuItem key={'14:00'} value={'14:00'}>14:00</MenuItem>
                                                <MenuItem key={'14:30'} id="half" value={'14:30'}>14:30</MenuItem>
                                                <MenuItem key={'15:00'} value={'15:00'}>15:00</MenuItem>
                                                <MenuItem key={'15:30'} id="half" value={'15:30'}>15:30</MenuItem>
                                                <MenuItem key={'16:00'} value={'16:00'}>16:00</MenuItem>
                                                <MenuItem key={'16:30'} id="half" value={'16:30'}>16:30</MenuItem>
                                                <MenuItem key={'17:00'} value={'17:00'}>17:00</MenuItem>
                                                <MenuItem key={'17:30'} id="half" value={'17:30'}>17:30</MenuItem>
                                                <MenuItem key={'18:00'} value={'18:00'}>18:00</MenuItem>
                                                <MenuItem key={'18:30'} id="half" value={'18:30'}>18:30</MenuItem>
                                                <MenuItem key={'19:00'} value={'19:00'}>19:00</MenuItem>
                                                <MenuItem key={'21:00'} value={'21:00'}>21:00</MenuItem>
                                            </Select>

                                            <br />

                                            <p>Session Duration</p>
                                            {/* Needs to be imported from Calendar */}

                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={duration}
                                                label="Duration"
                                                onChange={(event) => { setDuration(event.target.value) }}
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                            >
                                                <MenuItem key={15} value={15}>15 Minutes</MenuItem>
                                                <MenuItem key={30} value={30}>30 Minutes</MenuItem>
                                                <MenuItem key={60} value={60}>60 Minutes</MenuItem>
                                                <MenuItem key={90} value={90}>90 Minutes</MenuItem>
                                                <MenuItem key={120} value={120}>120 Minutes</MenuItem>
                                                <MenuItem key={150} value={150}>150 Minutes</MenuItem>
                                                <MenuItem key={180} value={180}>180 Minutes</MenuItem>

                                            </Select>

                                            <br />

                                            <p>Aim Of Session</p>

                                            <TextField

                                                autoComplete="given-name"
                                                name="aimOfSession"

                                                sx={{ width: '300px', marginBottom: '15px' }}
                                                value={aimOfSession}
                                                id="aimOfSession"
                                                onChange={(event) => setAimOfSession([event.target.value])}
                                            />

                                            <br />

                                            <p>Equipment Needed</p>

                                            <TextField

                                                autoComplete="equipment"
                                                name="equipmentNeeded"

                                                sx={{ width: '300px', marginBottom: '15px' }}
                                                value={equipmentNeeded}
                                                id="equipmentNeeded"
                                                onChange={(event) => setEquipmentNeeded([event.target.value])}
                                            />

                                            <br />

                                            <p>Focus Point(s) for the session</p>

                                            <TextField

                                                autoComplete="focusPoint"
                                                name="focusPoint"
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                                value={sessionFocusPoint}
                                                id="focusPoint"
                                                onChange={(event) => setFocusPoint([event.target.value])}
                                            />

                                            <br />

                                            <p>Extra Notes</p>

                                            <TextField

                                                autoComplete="extraNotes"
                                                name="extraNotes"
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                                value={extraNotes}
                                                id="extraNotes"
                                                onChange={(event) => setExtraNotes(event.target.value)}
                                            />
                                            <br />


                                            <p>Session split</p>

                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={stateSessionSplit}
                                                // label="Kickoff Time"
                                                onChange={(event) => { handleSessionSplitChange(event.target.value) }}
                                                sx={{ width: '300px', marginBottom: '15px' }}
                                            >
                                                <MenuItem key={'1'} value={'1'}>1</MenuItem>
                                                <MenuItem key={'2'} value={'2'}>2</MenuItem>
                                                <MenuItem key={'3'} value={'3'}>3</MenuItem>
                                                <MenuItem key={'4'} value={'4'}>4</MenuItem>
                                                <MenuItem key={'5'} value={'5'}>5</MenuItem>
                                                <MenuItem key={'6'} value={'6'}>6</MenuItem>
                                                <MenuItem key={'7'} value={'7'}>7</MenuItem>
                                            </Select>

                                            {/*  Depending on the amount of splits chosen, the amount must be rendered */}
                                            {newArrayValueIWant.map((element, index) => (
                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '15px', alignItems: 'center' }}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <p>Split {index + 1}*</p>
                                                        <Select
                                                            key={`demo-simple-select-${index}`}
                                                            value={splitValues[index]}
                                                            onChange={(event) => {
                                                                const newSplitValues = [...splitValues];
                                                                newSplitValues[index] = event.target.value;
                                                                setSplitValues(newSplitValues);
                                                            }}
                                                            sx={{ width: '300px' }}
                                                        >
                                                            <MenuItem key={'Lineouts'} value={'Lineouts'}>Lineouts</MenuItem>
                                                            <MenuItem key={'Scrums'} value={'Scrums'}> Scrums </MenuItem>
                                                            <MenuItem key={'Rucking'} value={'Rucking'}> Rucking</MenuItem>
                                                            <MenuItem key={'Attack'} value={'Attack'}> Attack</MenuItem>
                                                            <MenuItem key={'Defence'} value={'Defence'}>Defence </MenuItem>
                                                            <MenuItem key={'Kickoffs'} value={'Kickoffs'}> Kickoffs </MenuItem>
                                                            <MenuItem key={'Skills'} value={'Skills'}> Skills </MenuItem>
                                                            <MenuItem key={'Conditioning'} value={'Conditioning'}>  Conditioning </MenuItem>
                                                            <MenuItem key={'Speed Work'} value={'Speed Work'}> Speed Work</MenuItem>
                                                            <MenuItem key={'Recovery'} value={'Recovery'}> Recovery</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <p>Assign Coach</p>
                                                        <Select
                                                            key={`coach-select-${index}`}
                                                            value={coachAssignments[index] || ''}

                                                            onChange={(event) => { console.log('event', event); handleCoachAssignmentChange(index, event.target.value) }}
                                                            sx={{ width: '300px' }}
                                                        >
                                                            {
                                                                coaches && !isLoading ? (
                                                                    coaches.map((coach) => {
                                                                        return (
                                                                            <MenuItem key={coach.id || 'none'} value={coach.id || 'none'}>
                                                                                {coach.firstName} {coach.lastName}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <MenuItem key={0} value={0}>
                                                                        None
                                                                    </MenuItem>
                                                                )
                                                            }

                                                        </Select>
                                                    </div>
                                                </div>
                                            ))}

                                            <FormGroup style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={warmupChecked}
                                                            onChange={() => {
                                                                setWarmupChecked(!warmupChecked);
                                                                setWarmup(!warmup)
                                                            }}
                                                        />
                                                    }
                                                    label="We are not going to warm up"
                                                />
                                            </FormGroup>

                                            <br />
                                            <Button variant="contained" onClick={(event) => handleSubmit(event)}>Edit Session</Button>
                                            <br />
                                            <Button variant="text" type='submit' style={{ marginTop: '5px' }} onClick={() => { navigate('/Sessions') }}>Back</Button>

                                        </>
                                    )
                                    :
                                    <>
                                        <p>No Sessions For Today</p>
                                        <br />

                                        <Button variant="contained" onClick={goBack}>Create Session</Button>
                                        <br />
                                        <Button variant="text" type='submit' style={{ marginTop: '5px' }} onClick={() => { navigate('/Sessions') }}>Back</Button>

                                    </>

                            }

                        </form>
                    </div>
            }



            {/* Snackbar for error message */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {confirmationMessage}
                </MuiAlert>
            </Snackbar>

        </div>
    )
}

export default EditTodaySesions

