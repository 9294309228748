import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux'
import { store } from './store';
import Layout from './routes';
import { useState } from 'react'
import Paperbase from './features/dashboard/Paperbase';
import { pdfjs } from 'react-pdf';
import 'bootstrap/dist/css/bootstrap.min.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


function App() {

  const [locale, setLocale] = useState('en');


  return (

       <div className="App">
        {/* <Paperbase /> */}
        <Layout setLocale={setLocale}/>
      </div>

  );
}

export default App;
