import React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Content/title.js';
import { useDispatch, useSelector } from 'react-redux';
import { TodayRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PrintSessions from './printSessions.js';
import { Button } from '@mui/material';
import { Box, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { sessionRequests } from '../../services/api-requests.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../layouts/loader.js';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { setFutureSessions } from './sessionSlice.js';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function FutureSessions(props) {
  const dispatch = useDispatch()
  const [sessionToBeDeleted, setSessionToBeDeleted] = React.useState('')
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [deleted, setDeleted] = React.useState(false);
  const [activeSessionArray, setActiveSessionArray] = React.useState([]);
  const [updatedSessionsArray, setUpdatedSessionsArray] = React.useState([]);
  // const allProps = { type, props }
  ////console.log('All props: ', allProps)
  const navigate = useNavigate();
  // console.log('Props future sessions: ', props)
  const futureSessions = useSelector((state) => state.session.sessions);
  // console.log('Future Sessions from store: ', futureSessions)


  useEffect(() => {
    if (handleDeletedClicked) {
      handleOpen();
    }
  }, [handleDeletedClicked]);


  useEffect(() => {

    //Lets format the props.sessions so we can always have the same type of array going down to print
    const placeHolderArray = props?.sessions?.map((session) => {
      const { name, teamSessions: { futureSessions } } = session;

      // Check if futureSessions is not empty
      if (futureSessions && futureSessions.length > 0) {
        // Flatten futureSessions and include name in the object
        const flattenedSessions = futureSessions.map((todaySession) => ({
          name,
          ...todaySession,
        }));

        return flattenedSessions;
      }

      // If futureSessions is empty, return an empty array
      return [];
    }).flat();

    setUpdatedSessionsArray(placeHolderArray)


    // if (props.sessions && props.futureSessions) {
    //   setActiveSessionArray(props.sessions.length > 0 ? props.sessions : props.futureSessions);
    // }

    props?.sessions.length > 0 ? setActiveSessionArray(placeHolderArray) : setActiveSessionArray(props.futureSessions)

    dispatch(setFutureSessions(props?.sessions.length > 0 ? placeHolderArray : props.futureSessions))

  }, [props])


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    props.refreshTeamSessions();
  };





  const ConfirmationModal = ({ open, onClose }) => {

    const handleNo = () => {
      // console.log('Handle No clicked');
      onClose();
    };

    const handleYes = () => {
      // console.log('Handle Yes clicked');
      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Session
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {sessionToBeDeleted.category} session on {sessionToBeDeleted.date} @ {sessionToBeDeleted.time} for {sessionToBeDeleted.name}?
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleNo} variant='contained'>No</Button>
              <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
            </div>

          </Box>
        </Modal>
      </div>
    );
  };



  async function handleClearOption(session) {

    setHandleDeletedClicked(true);
    setSessionToBeDeleted(session)
    handleOpen();
  }

  const handleYesClick = async () => {

    setIsLoading(true)
    try {

      // const sessionIWant = props.futureSessions.filter((session) => session.type === sessionToBeDeleted.type);
      // const sessionIdIWant = sessionIWant[0].id;


      console.log('Must Delete this session: ', sessionToBeDeleted.id)
      // Perform the API call to delete the Session
      const response = await sessionRequests.DeleteSession(sessionToBeDeleted.id);
      console.log('After delete line: ', response)
      console.log('Response from delete: ', response);

      if (response.status === 200) {

        setOpenSnackbar(true);
        setMessage('Session Deleted');
        setIsLoading(false)

      } else {
        setOpenSnackbar(true);
        setMessage('Something went wrong');
      }
    } catch (error) {
      // console.log('error: ', error);
    } finally {
      setHandleDeletedClicked(false);
      setIsLoading(false)
    }
  };

  function EditFutureSessions(event) {
    event.preventDefault();

    props.type === 'dashboard' ? navigate('/Sessions') : navigate('/Sessions/editFutureSessions');
  }


  function handleAddSessionForFuture() {
    // setCreateSession(true)
    navigate('/Sessions/addFutureSession');
  }


  // let updatedSessionsArray = [];
  // if (props.sessions && Array.isArray(props.sessions)) {
  //   updatedSessionsArray = props.sessions.flatMap((session) => {
  //     const { name, teamSessions: { futureSessions } } = session;

  //     // Check if futureSessions is not empty
  //     if (futureSessions && futureSessions.length > 0) {
  //       // Map over futureSessions and handle splits within this map
  //       const flattenedSessions = futureSessions.map((todaySession) => {
  //         const { splits, ...rest } = todaySession;

  //         // Check if splits is defined before accessing it
  //         const processedSplits = splits ? splits.map((splitValue) => ({
  //           ...splitValue,
  //           // Additional processing if needed
  //         })) : [];

  //         return {
  //           name,
  //           ...rest,
  //           splits: processedSplits,
  //         };
  //       });

  //       return flattenedSessions;
  //     }

  //     // If futureSessions is empty, return an empty array
  //     return [];
  //   });
  // }



  // Check if props.futureSessions is defined and is an array


  const futureSessionsArray = Array.isArray(props.futureSessions) ? props.futureSessions : [];

  return (

    <React.Fragment>

      {props.type !== 'dashboard' ?


        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button variant="contained" onClick={handleAddSessionForFuture}>
            Add Session For Future
          </Button>

          <PrintSessions
            weeklyFocusPoint={props.weeklyFocusPoint}
            type="Future"
            sessions={activeSessionArray}
            activeArray={activeSessionArray === props.futureSessions ? 'Team' : 'All'}
          />
        </Box>

        :
        <></>
      }


      <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>

        <Title>Future sessions</Title>
        <div style={{ overflowX: 'auto' }}>
          {
            activeSessionArray === props.futureSessions ?
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Team</TableCell>
                    <TableCell>Session Type</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    {Array.from({ length: futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0) }, (_, index) => (
                      <TableCell key={index}>{`Split ${index + 1}`}</TableCell>
                    ))}
                    {/* Add an empty TableCell in the header row that spans the remaining columns */}
                    <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
                    {/* Additional TableCell for the clear icon */}
                    <TableCell style={{ textAlign: 'right' }}>Option</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {futureSessionsArray.length !== 0 ? (
                    futureSessionsArray.map((session, sessionIndex) => {
                      return (
                        <TableRow key={sessionIndex}>

                          <TableCell>{props.team}</TableCell>
                          <TableCell>{session.category}</TableCell>
                          <TableCell>{session.date}</TableCell>
                          <TableCell>{session.time}</TableCell>
                          <TableCell>{session.duration}</TableCell>
                          {session.splits.map((splitValue, splitIndex) => (
                            <TableCell key={splitIndex} >
                              {splitValue.type ? `${splitValue.type} - ${splitValue.duration} min` : ''}
                            </TableCell>

                          ))}
                          {/* Add an empty TableCell that spans the remaining columns */}
                          <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>

                          <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => handleClearOption(session)} style={{ color: 'red' }}>
                              <ClearIcon />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      {/* Set colSpan to match the total number of columns */}
                      <TableCell colSpan={3 + futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}>{props.type === 'dashboard' ? 'Sessions not Loaded yet' : "No Sessions yet"}</TableCell>
                    </TableRow>
                  )}

                  {confirmationModalOpen && (
                    <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                  )}


                </TableBody>
              </Table>

              :

              // This is for all Sessions
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Team</TableCell>
                    <TableCell>Session Type</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    {Array.from({ length: updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0) }, (_, index) => (
                      <TableCell key={index}>{`Split ${index + 1}`}</TableCell>
                    ))}
                    {/* Add an empty TableCell in the header row that spans the remaining columns */}
                    <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
                    {/* Additional TableCell for the clear icon */}
                    <TableCell style={{ textAlign: 'right' }}>Option</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {updatedSessionsArray.length !== 0 ? (
                    updatedSessionsArray.map((session, sessionIndex) => {
                      return (
                        <TableRow key={sessionIndex}>

                          <TableCell>{session.name}</TableCell>
                          <TableCell>{session.category}</TableCell>
                          <TableCell>{session.date}</TableCell>
                          <TableCell>{session.time}</TableCell>
                          <TableCell>{session.duration}</TableCell>
                          {session.splits.map((splitValue, splitIndex) => (
                            <TableCell key={splitIndex} >
                              {splitValue.type ? `${splitValue.type} - ${splitValue.duration} min` : ''}
                            </TableCell>

                          ))}
                          {/* Add an empty TableCell that spans the remaining columns */}
                          <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>

                          <TableCell colSpan={updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => handleClearOption(session)} style={{ color: 'red' }}>
                              <ClearIcon />
                            </IconButton>
                          </TableCell>


                        </TableRow>


                      );
                    })
                  ) : (
                    <TableRow>
                      {/* Set colSpan to match the total number of columns */}
                      <TableCell colSpan={3 + updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}>{props.type === 'dashboard' ? 'Sessions not Loaded yet' : "No Sessions yet"}</TableCell>
                    </TableRow>
                  )}

                  {confirmationModalOpen && (
                    <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
                  )}


                </TableBody>
              </Table>
          }


        </div>
        <Link
          color="primary"
          href="#"
          onClick={EditFutureSessions}
          sx={{ mt: 2, display: 'block' }}
        >
          {
            props.type === 'dashboard' ?
              'Go To Session'
              :
              'Change session'
          }

        </Link>
      </Paper>

      {
        isLoading ?
          <Loader />
          :
          <></>
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={deleted ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>

    </React.Fragment>


  );
}


// <Table size="small">
//   <TableHead>
//     <TableRow>
//       <TableCell>Team</TableCell>
//       <TableCell>Session Type</TableCell>
//       <TableCell>Date</TableCell>
//       <TableCell>Time</TableCell>
//       <TableCell>Duration</TableCell>
//       {Array.from({ length: futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0) }, (_, index) => (
//         <TableCell key={index}>{`Split ${index + 1}`}</TableCell>
//       ))}
//       <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
//       <TableCell style={{ textAlign: 'right' }}>Option</TableCell>
//     </TableRow>
//   </TableHead>

//   <TableBody>
//     {futureSessionsArray.length !== 0 ? (
//       futureSessionsArray.map((session, sessionIndex) => {
//         return (
//           <TableRow key={sessionIndex}>
//             <TableCell>{props.team}</TableCell>
//             <TableCell>{session.category}</TableCell>
//             <TableCell>{session.date}</TableCell>
//             <TableCell>{session.time}</TableCell>
//             <TableCell>{session.duration}</TableCell>
//             {session.splits.map((splitValue, splitIndex) => (
//               <TableCell key={splitIndex} >
//                 {splitValue.type ? `${splitValue.type} - ${splitValue.duration} min` : ''}
//               </TableCell>
//             ))}
//             <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}></TableCell>
//             <TableCell colSpan={futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)} style={{ textAlign: 'right' }}>
//               <IconButton onClick={() => handleClearOption(session)} style={{ color: 'red' }}>
//                 <ClearIcon />
//               </IconButton>
//             </TableCell>
//           </TableRow>
//         );
//       })
//     ) : (
//       <TableRow>
//         <TableCell colSpan={3 + futureSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0)}>No Sessions</TableCell>
//       </TableRow>
//     )}

//     {confirmationModalOpen && (
//       <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
//     )}
//   </TableBody>
// </Table>