import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { team } from "./team";
import { actions } from "./actions";


const initialState = {
  runningTimer: "00:00:00", // Add the timer field to the initial state
  isSecondHalf: false, // Add the isSecondHalf field to track if it's the second half
  halfLength: 0,
}


export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setHalfLength: (state, action) => {
      state.halfLength = action.payload
    },
    setRunningTimer: (state, action) => {
      state.runningTimer = action.payload
    },
    setIsSecondHalfinTimer: (state, action) => {
      state.isSecondHalf = action.payload; // Update the isSecondHalf value in the state
    },
  }
})
    
//Selectors come below:
export const { setHalfLength, setRunningTimer, setIsSecondHalfinTimer, } = timerSlice.actions

export const selectRunningTimer = state => state.timer.runningTimer;

export default timerSlice.reducer