import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';


import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'


import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { devRequests, managerRequests } from '../../services/api-requests';
import Loader from '../loader';
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Schools = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [deleted, setDeleted] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [schools, setSchools] = React.useState([])
  const [allSchools, setAllSchools] = React.useState([]);
  const [coachesInSchool, setCoachesInSchool] = React.useState(0);
  const [combinedResult, setCombinedResult] = React.useState(false);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState();
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);
  const [schoolToBeDeleted, setSchoolToBeDeleted] = React.useState('')


  const ConfirmationModal = ({ open, onClose }) => {

    const handleNo = () => {
      console.log('Handle No clicked');
      onClose();
    };

    const handleYes = () => {
      console.log('Handle Yes clicked');
      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete school
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {schoolToBeDeleted}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleNo} variant='contained'>No</Button>
              <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
            </div>

          </Box>
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    if (handleDeletedClicked) {
      handleOpen();
    }
  }, [handleDeletedClicked]);

  useEffect(() => {
    fetchData()
  }, [])


  // const fetchData = async () => {
  //   setIsLoading(true)
  //   const mySchools = []
  //   try {
  //     const response = await devRequests.getSchoolList();
  //     setIsLoading(false)
  //     if (response.status === 200) {
  //       response.data.map(async (school) => {

  //         try {
  //           //MAKE API CALL FOR COACHES IN SCHOOL
  //           const response = await managerRequests.getAllCoaches(school.id)
  //           setCoachesInSchool(response.data.length)
  //           console.log('Response from coaches: ', response.data.length)
  //         }
  //         catch (error) {
  //           console.log('Error from coaches: ', error)
  //         }

  //         const schoolObj = {
  //           school: school.name,
  //           manager: school.manager.firstName + school.manager.lastName,
  //           location: 'Random',
  //           status: school.status,
  //           coachesInSchool: coachesInSchool
  //         };
  //         console.log('Coaches in school: ', coachesInSchool)

  //         mySchools.push(schoolObj)
  //       });

  //       setAllSchools(mySchools);
  //       // allSchools.push(combinedResult)
  //     }
  //     else {
  //       setMessage('Something went wrong')
  //       setDeleted(true)
  //     }

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  //   finally {
  //     setIsLoading(false)
  //   }
  // };

  const fetchData = async () => {
    setIsLoading(true);
    const mySchools = [];

    try {
      const response = await devRequests.getSchoolList();
      setIsLoading(false);

      if (response.status === 200) {
        const coachesPromises = response.data.map(async (school) => {
          try {
            const coachesResponse = await managerRequests.getAllCoaches(school.id);
            console.log('Response from coaches: ', coachesResponse.data.length);
            return coachesResponse.data.length;
          } catch (error) {
            console.log('Error from coaches: ', error);
            return 0;
          }
        });

        const coachesCounts = await Promise.all(coachesPromises);

        // response.data.forEach((school, index) => {
        //   const schoolObj = {
        //     school: school.name,
        //     manager: `${school.manager.firstName}  ${school.manager.lastName}`,
        //     location: 'Random',
        //     status: school.status,
        //     coachesInSchool: coachesCounts[index],
        //   };

        //   mySchools.push(schoolObj);
        // });

        response.data.forEach((school) => {
          const schoolObj = {
            school: school.name,
            manager: school.manager ? `${school.manager.firstName} ${school.manager.lastName}` : '',
            location: 'Random',
            status: school.status,
            coachesInSchool: 0, // Initialize it with a default value
          };

          mySchools.push(schoolObj);
        });


        setAllSchools(mySchools);
        setSchools(response.data)

      } else {
        setMessage('Something went wrong');
        setDeleted(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };


  async function handleDeleted(schoolName) {

    setHandleDeletedClicked(true);
    setSchoolToBeDeleted(schoolName)
    handleOpen();
  }

  const handleYesClick = async () => {
    setIsLoading(true)
    try {
      const schoolIwant = schools.filter((school) => school.name === schoolToBeDeleted);
      const schoolIDIwant = schoolIwant[0].id;
      console.log('School I want ID: ', schoolIDIwant)

      // Perform the API call to delete the school
      const response = await devRequests.deleteSchool(schoolIDIwant);
      console.log('Response from delete: ', response);

      fetchData()
      if (response.status === 200) {

        setOpenSnackbar(true);
        setMessage('School Deleted');
        setIsLoading(false)
      } else {
        setOpenSnackbar(true);
        setMessage('Something went wrong');
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setHandleDeletedClicked(false);
      setIsLoading(false)
    }
  };

  function handleEdit(schoolName) {
    console.log('SchoolName ', schoolName)
    const schoolIwant = schools.filter((school) => school.name === schoolName);
    const schoolIDIwant = schoolIwant[0].id;
    console.log('Handle Edit clicked')
    navigate(`/Managers/EditManager/${schoolIDIwant}`)
  }

  return (
    <>

      <TableContainer component={Paper}>
        <h3>Active Schools: </h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>School Name</TableCell>
              <TableCell align="right">Assigned Manager </TableCell>
              <TableCell align="right">Location</TableCell>
              <TableCell align="right">Subsription Status</TableCell>
              <TableCell align="right">Coaches in school</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {allSchools.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Schools waiting for approval
                </TableCell>
              </TableRow>
            ) : (
              allSchools.map((row) => (
                <TableRow
                  key={row.school}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.school}
                  </TableCell>
                  <TableCell align="right">{row.manager}</TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">{row.coachesInSchool}</TableCell>
                  <TableCell align='center'>
                    <Button onClick={() => { handleEdit(row.school) }} variant='text'>Edit</Button>
                    <Button color='error' onClick={() => handleDeleted(row.school)}>Delete School</Button>
                  </TableCell>
                </TableRow>
              ))
            )}

            {confirmationModalOpen && (
              <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        isLoading ?
          <Loader />
          :
          <></>
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={deleted ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </>
  );

}

export default Schools